import { barlowCond, satoshi } from '@/theme/fonts/fonts';
import { Global, css } from '@emotion/react';

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;
const GlobalContainer = () => (
  <Global
    styles={{
      GlobalStyles,
      satoshi,
      barlowCond,
    }}
  />
);

export default GlobalContainer;
