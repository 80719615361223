import { gql } from 'graphql-request';

export const GET_PEOPLE_LIST = gql`
  query PeopleList {
    person(orderBy: { name: ASC }) {
      id
      name
      email
      relation
      discussion_items {
        id
        personId
        isCompleted
      }
      promises {
        leverageRequestDueDate
        leverageRequestRepliedAt
        leverageRequestReplyText
        leverageRequestStatus
        leverageRequestText
        leverageRequestedAt
        kind
        id
        actionId
        personId
        action {
          progressStatus
          title
        }
      }
    }
  }
`;

export const CREATE_PERSON = gql`
  mutation CreatePerson($id: uuid!, $name: String!, $email: String, $relation: String, $notes: String) {
    insertPersonOne(object: { id: $id, name: $name, email: $email, relation: $relation, notes: $notes }) {
      id
      name
      email
      relation
    }
  }
`;

export const GET_PERSON = gql`
  query GetPerson($id: uuid!) {
    personByPk(id: $id) {
      id
      name
      email
      relation
      notes
      discussion_items {
        body
        duration
        id
        isCompleted
        order
        personId
      }
      promises {
        kind
        leverageRequestDueDate
        leverageRequestRepliedAt
        leverageRequestReplyText
        leverageRequestStatus
        leverageRequestText
        leverageRequestedAt
        id
        actionId
        personId
        action {
          progressStatus
          title
        }
      }
    }
  }
`;
export const EDIT_PERSON = gql`
  mutation EditPerson($id: uuid!, $name: String!, $email: String, $relation: String, $notes: String) {
    updatePersonByPk(pkColumns: { id: $id }, _set: { name: $name, email: $email, notes: $notes, relation: $relation }) {
      id
      name
      email
      relation
      notes
      discussion_items {
        body
        duration
        id
        isCompleted
        order
        personId
      }
      promises {
        kind
        leverageRequestStatus
        id
        actionId
        personId
        action {
          progressStatus
          title
        }
      }
    }
  }
`;

export const DELETE_PERSON = gql`
  mutation DeletePerson($id: uuid!) {
    deletePersonByPk(id: $id) {
      id
    }
  }
`;

export const CREATE_DISCUSSION_POINT = gql`
  mutation CreateDiscussionPoint($id: uuid!, $personId: uuid!, $body: String, $duration: interval, $order: Int) {
    insertDiscussionItemOne(
      object: { body: $body, duration: $duration, id: $id, personId: $personId, isCompleted: false, order: $order }
    ) {
      body
      duration
      id
      isCompleted
      order
      personId
    }
  }
`;

export const COMPLETE_DISCUSSION_POINT = gql`
  mutation CompleteDiscussionPoint($updates: [DiscussionItemUpdates!]!) {
    updateDiscussionItemMany(updates: $updates) {
      returning {
        body
        duration
        id
        isCompleted
        order
        personId
      }
    }
  }
`;

export const EDIT_DISCUSSION_POINT = gql`
  mutation EditDiscussionPoint($id: uuid!, $body: String, $duration: interval) {
    updateDiscussionItemByPk(pkColumns: { id: $id }, _set: { body: $body, duration: $duration }) {
      body
      duration
      id
      isCompleted
      order
      personId
    }
  }
`;

export const DELETE_DISCUSSION_POINT = gql`
  mutation DeleteDiscussionPoint($id: uuid!) {
    deleteDiscussionItemByPk(id: $id) {
      id
      personId
    }
  }
`;

export const UPDATE_DISCUSSION_POINTS_ORDER = gql`
  mutation UpdateDiscussionPointsOrder($updates: [DiscussionItemUpdates!]!) {
    updateDiscussionItemMany(updates: $updates) {
      returning {
        id
        order
      }
    }
  }
`;

export const UPDATE_COMMITTED_ACTIONS_TO_OTHER_ORDER = gql`
  mutation updateCommittedActionsToOtherOrder($actionUpdates: [ActionUpdates!]!) {
    updateActionMany(updates: $actionUpdates) {
      returning {
        id
        globalCommitOrder
      }
    }
  }
`;

export const UPDATE_LEVERAGED_ACTIONS_TO_OTHER_ORDER = gql`
  mutation updateLeveragedActionsToOtherOrder($actionUpdates: [ActionUpdates!]!) {
    updateActionMany(updates: $actionUpdates) {
      returning {
        id
        globalLeverageOrder
      }
    }
  }
`;

export const UPDATE_COMMITTED_ACTIONS_TO_SINGLE_USER_ORDER = gql`
  mutation updateCommittedActionsToSingleUserOrder($actionUpdates: [PromiseUpdates!]!) {
    updatePromiseMany(updates: $actionUpdates) {
      returning {
        actionId
        commitOrder
        action {
          title
        }
      }
    }
  }
`;

export const UPDATE_LEVERAGED_ACTIONS_TO_SINGLE_USER_ORDER = gql`
  mutation updateLeveragedActionsToSingleUserOrder($actionUpdates: [PromiseUpdates!]!) {
    updatePromiseMany(updates: $actionUpdates) {
      returning {
        actionId
        leverageOrder
        action {
          title
        }
      }
    }
  }
`;
