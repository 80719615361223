import { Action } from '@/types/actions';
import { KeyResult, ProjectType } from '@/types/project';
import { longEnLocale } from '@/utils/events';
import { isEmpty } from 'lodash';

import { getSecondsFromDuration, humanDuration } from '.';

export function getProjectById(projects: ProjectType[], projectId: string) {
  return projects?.find((project) => project.id === projectId);
}

function calculateTotalDurationOfCompleteIncompleteStarredActions(actions: Action[]) {
  let totalStarredDuration = 0;
  let completeStarredDuration = 0;

  for (let action of actions) {
    if (action.dateOfStarring !== null) {
      const actionDuration = getSecondsFromDuration(action.duration);
      totalStarredDuration += actionDuration;

      if (action.progressStatus === 'complete') {
        completeStarredDuration += actionDuration;
      }
    }
  }

  return {
    totalStarredDuration,
    completeStarredDuration,
  };
}

function calculateTotalDurationOfCompleteIncompletActions(actions: Action[]) {
  let totalDuration = 0;
  let completeDuration = 0;

  for (let action of actions) {
    const actionDuration = getSecondsFromDuration(action.duration);
    totalDuration += actionDuration;

    if (action.progressStatus === 'complete') {
      completeDuration += actionDuration;
    }
  }

  return {
    totalDuration,
    completeDuration,
  };
}

function getPercentageOfCompletedStarredActions(actions: Action[]) {
  if (isEmpty(actions)) {
    return 0;
  }

  const { totalStarredDuration, completeStarredDuration } =
    calculateTotalDurationOfCompleteIncompleteStarredActions(actions);

  return (completeStarredDuration * 100) / totalStarredDuration;
}

function getPercentageOfCompletedAllActions(actions: Action[]) {
  if (isEmpty(actions)) {
    return 0;
  }

  const { totalDuration, completeDuration } = calculateTotalDurationOfCompleteIncompletActions(actions);

  return (completeDuration * 100) / totalDuration;
}

function getTimeRemainingForStarredActions(actions: Action[]) {
  const { totalStarredDuration, completeStarredDuration } =
    calculateTotalDurationOfCompleteIncompleteStarredActions(actions);
  return humanDuration(totalStarredDuration - completeStarredDuration, longEnLocale);
}

function getTimeRemainingForAllActions(actions: Action[]) {
  const { totalDuration, completeDuration } = calculateTotalDurationOfCompleteIncompletActions(actions);

  return humanDuration(completeDuration - totalDuration, longEnLocale);
}

function hasActionsCheckingNonNullDateOfStarring(project: ProjectType, isStarred: boolean): boolean {
  const hasActions = project.actions.length > 0;

  if (hasActions) {
    if (isStarred) {
      return project.actions.some((action) => action.dateOfStarring !== null);
    } else {
      return true;
    }
  }

  return false;
}

function getKeyResultById(keyResults: KeyResult[], keyResultId: string) {
  return keyResults.find((keyResult) => keyResult.id === keyResultId);
}

export {
  getKeyResultById,
  getPercentageOfCompletedAllActions,
  getPercentageOfCompletedStarredActions,
  getTimeRemainingForAllActions,
  getTimeRemainingForStarredActions,
  hasActionsCheckingNonNullDateOfStarring,
};
