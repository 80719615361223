import CaptureList from '@/components/CaptureList';
import { CATEGORIES_ICONS } from '@/constants/category';
import { Action } from '@/types/actions';
import { Category } from '@/types/category';
import { getTotalDurationOfActions, getTotalDurationOfPreferredActions } from '@/utils/action';
import { getColorFromToken } from '@/utils/color';
import { fixUncategorizedName } from '@/utils/index';
import rem from '@/utils/rem';
import { Flex, Icon, Text, useColorMode } from '@chakra-ui/react';

type CategoryWithActions = {
  completedActions: Action[];
  incompleteActions: Action[];
} & Category;

type Props = {
  variant?: 'full' | 'selector';
  category: CategoryWithActions;
};

function CategoryDragOverlay({ category, variant = 'full' }: Props) {
  const { colorMode } = useColorMode();
  const isDarkTheme = colorMode === 'dark';

  return variant === 'selector' ? (
    <Flex
      alignItems="center"
      padding={rem(8)}
      borderWidth={rem(1)}
      borderStyle="solid"
      borderColor="stroke-primary"
      borderRadius={rem(4)}
      cursor="grab"
      backgroundColor="background-tertiary"
    >
      <Flex justifyContent="center" flexDirection="column">
        <Flex
          alignItems="center"
          justifyContent="center"
          width={rem(24)}
          height={rem(24)}
          marginRight={rem(8)}
          borderRadius="full"
          aspectRatio={1}
          backgroundColor={category?.color}
        >
          <Icon
            as={CATEGORIES_ICONS?.[category?.icon ?? 'uncategorized']}
            width={rem(14)}
            height={rem(14)}
            color="blue.1000"
          />
        </Flex>
      </Flex>
      <Text
        textStyle="h5BarSB"
        width="auto"
        marginTop={rem(-3)}
        color={getColorFromToken(category?.color, undefined, isDarkTheme ? '100' : '800')}
        textTransform="uppercase"
        noOfLines={2}
      >
        {fixUncategorizedName(category?.name)}
      </Text>
    </Flex>
  ) : (
    <CaptureList
      category={category}
      totalDurationOfIncompleteActions={getTotalDurationOfActions(category.incompleteActions)}
      totalDurationOfPreferredIncompleteActions={getTotalDurationOfPreferredActions(category.incompleteActions)}
      hideAccordion
      width={rem(400)}
    />
  );
}

export default CategoryDragOverlay;
