import {
  Active,
  ClientRect,
  DndContext,
  DndContextProps,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  TouchSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { DroppableContainer, RectMap } from '@dnd-kit/core/dist/store';
import { Coordinates } from '@dnd-kit/core/dist/types';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';

export type Arg = {
  active: Active;
  collisionRect: ClientRect;
  droppableRects: RectMap;
  droppableContainers: DroppableContainer[];
  pointerCoordinates: Coordinates | null;
};

const DndContainer: React.FC<Readonly<DndContextProps>> = ({
  children,
  collisionDetection = rectIntersection,
  ...rest
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetection}
      measuring={{
        droppable: {
          // using other strategies leads to flickering when sorting blocks
          strategy: MeasuringStrategy.Always,
        },
      }}
      {...rest}
    >
      {children}
    </DndContext>
  );
};

export default DndContainer;
