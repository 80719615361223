import AddActionButton from '@/components/AddActionButton';
import DetailsCard from '@/components/DetailsCard';
import { SortableItemContainer } from '@/components/DragAndDrop';
import KeyResultItem from '@/components/ProjectDetail/KeyResultItem';
import ManageKeyResultModal from '@/components/ProjectDetail/ManageKeyResultModal';
import { IconMountainTop } from '@/theme/icons';
import { KeyResult } from '@/types/project';
import rem from '@/utils/rem';
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { SortableContext } from '@dnd-kit/sortable';
import { useState } from 'react';

type Props = {
  color: string;
  keyResults: KeyResult[];
  projectId: string;
  onCreateAction: (name: string) => void;
  onCreateBlock: (name: string) => void;
};

function KeyResults({ color, keyResults, projectId, onCreateAction, onCreateBlock }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const {
    isOpen: isCreateKeyResultOpen,
    onOpen: onCreateKeyResultOpen,
    onClose: onCreateKeyResultClose,
  } = useDisclosure({ id: 'create' });

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };
  return (
    <DetailsCard
      paddingX={rem(16)}
      paddingY={rem(16)}
      color={color}
      icon={IconMountainTop}
      sectionTitle="Key Result"
      rightButton={
        <AddActionButton
          tooltipText="Add a new key result"
          onClick={onCreateKeyResultOpen}
          variant="secondary"
          width={rem(28)}
          height={rem(28)}
        />
      }
      flex="1"
      minWidth={0}
    >
      {keyResults.length ? (
        <Box width="full" marginTop={rem(-8)}>
          <SortableContext items={keyResults} disabled={isOpen}>
            {keyResults.map((keyResult, index) => (
              <SortableItemContainer
                key={keyResult.id}
                id={keyResult.id}
                payload={{ containerId: 'keyResult', category: null, index }}
              >
                <KeyResultItem
                  keyResult={keyResult}
                  projectCategoryColor={color}
                  projectId={projectId}
                  index={index + 1}
                  onOpen={onOpen}
                  onClose={onClose}
                  onCreateAction={onCreateAction}
                  onCreateBlock={onCreateBlock}
                />
              </SortableItemContainer>
            ))}
          </SortableContext>
        </Box>
      ) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap={rem(8)}
          width="full"
          height="full"
        >
          <Text textStyle="smallBlack" color="text-tertiary" textTransform="uppercase">
            Manifest Your Goals: Results Central
          </Text>
          <Text textStyle="medium" color="text-primary" textAlign="center">
            While no key results have been added, this is your moment to define success, set milestones, and embark on a
            journey of accomplishment. Seize this opportunity to shape your path forward!
          </Text>
        </Flex>
      )}
      <ManageKeyResultModal
        count={keyResults.length}
        projectId={projectId}
        isOpen={isCreateKeyResultOpen}
        onClose={onCreateKeyResultClose}
      />
    </DetailsCard>
  );
}

export default KeyResults;
