import { useActionModal } from '@/stores/useActionModal';
import { IconPlus } from '@/theme/icons';
import { getColorFromToken } from '@/utils/color';
import rem from '@/utils/rem';
import {
  Box,
  Button,
  ButtonProps,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';

type Props = {
  blockIsCompleted?: boolean;
  buttonName?: string;
  iconColor?: string;
  iconSize?: string;
  showButtonName?: boolean;
  withActionBlockCreationMenu?: boolean;
  tooltipText?: string;
  onExternalClick?: () => void;
  onCreateNewBlock?: () => void;
};

const AddActionButton = ({
  backgroundColor,
  blockIsCompleted = false,
  buttonName = 'Add Action',
  iconColor = 'text-primary',
  iconSize = rem(14),
  showButtonName = false,
  withActionBlockCreationMenu = false,
  textStyle = 'small',
  tooltipText = blockIsCompleted
    ? "This block is already completed, so you can't add any new actions."
    : withActionBlockCreationMenu
      ? 'Add a new action or block'
      : 'Add a new action',
  onExternalClick,
  onCreateNewBlock,
  ...rest
}: Props & ButtonProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { setActionModalOpen } = useActionModal();

  const onOpenActionCreateModal = useCallback(() => {
    // We need to close the tooltip when the action modal is opened, otherwise it will remain open for touch devices if the user clicks on the single button as mouseleave event is not triggered
    setIsTooltipOpen(false);

    if (onExternalClick) {
      onExternalClick();
      return;
    }

    setActionModalOpen(true);
  }, [onExternalClick, setActionModalOpen]);

  const handleMouseEnter = () => {
    setIsTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipOpen(false);
  };

  if (!showButtonName) {
    if (withActionBlockCreationMenu) {
      return (
        <Menu isLazy>
          <Tooltip pointerEvents="all" isOpen={isTooltipOpen} label={tooltipText} placement="top">
            <Box display="inline-block" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <MenuButton
                as={IconButton}
                minWidth="1.75em"
                height="1.75em"
                color="text-primary"
                textColor="stroke-primary"
                border={`${rem(1)} solid`}
                borderColor="stroke-primary"
                _hover={
                  backgroundColor && {
                    backgroundColor: getColorFromToken(String(backgroundColor)),
                    borderColor: getColorFromToken(String(backgroundColor)),
                    color: 'text-primary',
                  }
                }
                aria-label="Create new action or block"
                backgroundColor="transparent"
                icon={<Icon as={IconPlus} boxSize={iconSize} />}
                isDisabled={blockIsCompleted}
                variant="tertiary"
                {...rest}
              />
            </Box>
          </Tooltip>

          <MenuList
            zIndex="dropdown"
            minWidth="0"
            borderWidth={rem(1)}
            borderStyle="solid"
            borderColor="gray.700"
            borderRadius="4"
            backgroundColor="background-primary"
            paddingX={rem(8)}
          >
            <MenuItem
              textStyle="small"
              marginBottom={rem(8)}
              padding={`${rem(4)} ${rem(8)}`}
              color="text-primary"
              _hover={{ backgroundColor: 'background-secondary' }}
              backgroundColor="inherit"
              icon={<Icon as={IconPlus} boxSize={rem(14)} color="text-primary" />}
              iconSpacing={rem(8)}
              onClick={onOpenActionCreateModal}
            >
              Add Action
            </MenuItem>
            <MenuItem
              textStyle="small"
              padding={`${rem(4)} ${rem(8)}`}
              color="text-primary"
              _hover={{ backgroundColor: 'background-secondary' }}
              backgroundColor="inherit"
              icon={<Icon as={IconPlus} boxSize={rem(14)} color="text-primary" />}
              iconSpacing={rem(8)}
              onClick={onCreateNewBlock}
            >
              Create Block
            </MenuItem>
          </MenuList>
        </Menu>
      );
    }

    return (
      <Tooltip pointerEvents="all" isOpen={isTooltipOpen} label={tooltipText} placement="top">
        <Box display="inline-block" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <IconButton
            minWidth="1.75em"
            height="1.75em"
            color="text-primary"
            textColor="stroke-primary"
            border={`${rem(1)} solid`}
            borderColor="stroke-primary"
            _hover={
              backgroundColor && {
                backgroundColor: getColorFromToken(String(backgroundColor)),
                borderColor: getColorFromToken(String(backgroundColor)),
                color: 'text-primary',
              }
            }
            cursor={blockIsCompleted ? 'not-allowed' : 'pointer'}
            aria-label={tooltipText}
            backgroundColor="transparent"
            icon={<Icon as={IconPlus} boxSize={iconSize} color={iconColor} />}
            isDisabled={blockIsCompleted}
            onClick={onOpenActionCreateModal}
            variant="tertiary"
            {...rest}
          />
        </Box>
      </Tooltip>
    );
  }

  return (
    <Button
      color="text-primary"
      pointerEvents="visible"
      backgroundColor="transparent"
      isDisabled={blockIsCompleted}
      leftIcon={<Icon as={IconPlus} boxSize={iconSize} color={iconColor} />}
      onClick={onOpenActionCreateModal}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      <Text textStyle={textStyle}>{buttonName}</Text>
    </Button>
  );
};

export default AddActionButton;
