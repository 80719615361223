import { ActionModalProps } from '@/components/ActionModal';
import ActionRow from '@/components/ActionRow';
import { SortableItemContainer } from '@/components/DragAndDrop';
import { useActionModal } from '@/stores/useActionModal';
import { Action } from '@/types/actions';
import { deleteAction } from '@/utils/action';
import rem from '@/utils/rem';
import { FlexProps, ListItem } from '@chakra-ui/react';
import { Data } from '@dnd-kit/core';
import { memo, useCallback } from 'react';

export type ActionListItemProps = {
  disabled?: boolean;
  item: Action;
  index: number;
  dndPayload?: Data;
  readOnly?: boolean;
  showIndex?: boolean;
  actionModalProps?: Partial<ActionModalProps>;
  onDeleteAction?: (action: Action) => void;
  onUpdateBlock?: (blockId: string) => void;
  onClickCreateBlock?: (actionId: string) => void;
  onClickRemoveFromBlock?: (actionId: string) => void;
  withScheduleButton?: boolean;
  progressStatusButtonDisabled?: boolean;
  isCompleteMode?: boolean;
} & Omit<FlexProps, 'onClick'>;

function ActionListItem({
  disabled = false,
  item,
  index,
  dndPayload,
  readOnly = false,
  showIndex = true,
  actionModalProps,
  onDeleteAction,
  onUpdateBlock,
  onClickCreateBlock,
  onClickRemoveFromBlock,
  withScheduleButton = false,
  progressStatusButtonDisabled,
  ...rest
}: ActionListItemProps) {
  const { setActionModalOpen, onGetCurrentActionData: getCurrentActionData } = useActionModal();

  const handleDeleteAction = onDeleteAction ? onDeleteAction : deleteAction;

  const handleToggleEditActionModal = useCallback(
    (action: Action) => {
      getCurrentActionData(action);
      setActionModalOpen(true, {
        onDeleteAction: handleDeleteAction,
        ...actionModalProps,
      });
    },
    [setActionModalOpen, getCurrentActionData, actionModalProps, handleDeleteAction],
  );

  return (
    <SortableItemContainer id={item.id} disabled={disabled} payload={{ ...dndPayload, category: item.category }}>
      <ListItem marginBottom={rem(6)}>
        <ActionRow
          action={item}
          idx={index + 1}
          onClick={() => handleToggleEditActionModal(item)}
          onDeleteAction={handleDeleteAction}
          onUpdateBlock={onUpdateBlock}
          onClickCreateBlock={onClickCreateBlock}
          onClickRemoveFromBlock={onClickRemoveFromBlock}
          readOnly={readOnly}
          showIndex={showIndex}
          withScheduleButton={withScheduleButton}
          progressStatusButtonDisabled={progressStatusButtonDisabled}
          {...rest}
        />
      </ListItem>
    </SortableItemContainer>
  );
}

export default memo(ActionListItem);
