import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

const getHeaderHeight = () => {
  const header = document.querySelector('header');
  return header ? header.getBoundingClientRect().height : 0;
};

export function useHeaderHeight() {
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    const handleResize = debounce(() => setHeaderHeight(getHeaderHeight()), 100);
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      handleResize.cancel();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return headerHeight;
}
