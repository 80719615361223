import { CompleteAnimation } from '@/constants/animations';
import useIsDarkMode from '@/hooks/useIsDarkMode';
import { IconIncomplete, IconIncompleteDark, IconProgress } from '@/theme/icons';
import { ProgressStatus } from '@/types/actions';
import rem from '@/utils/rem';
import { Button, ButtonProps, Icon, Tooltip } from '@chakra-ui/react';
import Lottie from 'lottie-react';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';

type States = (typeof STATES)[number];

type Props = {
  state?: States;
  dimension?: string;
  onClick?: (progressStatus: ProgressStatus) => void;
  blockCompletion?: boolean;
  preUpdate?: boolean;
  progressBackgroundColor?: string;
  readOnly?: boolean;
} & Omit<ButtonProps, 'onClick'>;

const STATES = ['incomplete', 'in-progress', 'complete'] as const;

const INCOMPLETE_STATUS_INDEX = 0;
const COMPLETE_STATUS_INDEX = 2;

const IconProgressState = ({
  state = 'incomplete',
  progressBackgroundColor = 'var(--chakra-colors-background-tertiary)',
  dimension = rem(24),
  onClick,
  blockCompletion,
  preUpdate = true,
  disabled,
  ...rest
}: Props) => {
  const [progressState, setProgressState] = useState<States>(state);
  const isDarkMode = useIsDarkMode();

  // Block completion needs only two states
  const states = useMemo(
    () => (blockCompletion ? STATES.filter((state) => state !== 'in-progress') : STATES),
    [blockCompletion],
  );

  const labels = {
    incomplete: disabled ? 'In Progress' : 'Set as In Progress',
    'in-progress': disabled ? 'In Progress' : 'Set as Complete',
    complete: disabled ? 'Complete' : 'Set as Incomplete',
  };

  if (blockCompletion) labels.incomplete = 'Set as Complete';

  const completeState = useMemo(() => {
    return {
      incomplete: <Icon as={isDarkMode ? IconIncomplete : IconIncompleteDark} boxSize={dimension} />,
      'in-progress': (
        <Icon
          as={IconProgress}
          boxSize={dimension}
          style={{ '--progress-background': progressBackgroundColor } as React.CSSProperties}
        />
      ),
      complete: (
        <Lottie
          animationData={CompleteAnimation}
          loop={false}
          style={{
            width: dimension,
            height: dimension,
          }}
        />
      ),
    };
  }, [dimension, isDarkMode, progressBackgroundColor]);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (disabled) {
        return;
      }

      // TODO: refactor states handling, too complex and error prone
      const index = (states as string[]).indexOf(progressState) + 1;
      const nextState = index > COMPLETE_STATUS_INDEX ? INCOMPLETE_STATUS_INDEX : index;

      if (preUpdate) {
        setProgressState(states[nextState]);
      }

      e.stopPropagation();

      onClick && onClick(states[nextState]);
    },
    [progressState, onClick, states, preUpdate, disabled],
  );

  useEffect(() => {
    setProgressState(state);
  }, [state]);

  return (
    <Tooltip isDisabled={disabled} label={labels[progressState]} placement="bottom">
      <Button
        alignItems="center"
        display="flex"
        minWidth={dimension}
        height={dimension}
        cursor={disabled ? 'default' : 'pointer'}
        aria-label={labels[progressState]}
        isDisabled={disabled}
        onClick={!disabled ? handleClick : undefined}
        variant="unstyled"
        {...rest}
      >
        {completeState[progressState]}
      </Button>
    </Tooltip>
  );
};

export default IconProgressState;
