import { IconActionTrash, IconDuplicate, IconPencilDraw, IconThreeDotsVertical } from '@/theme/icons';
import rem from '@/utils/rem';
import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, SpaceProps, Tooltip } from '@chakra-ui/react';
import { MouseEventHandler, memo } from 'react';
import { BsPlusLg } from 'react-icons/bs';

type Props = {
  iconColor?: string;
  onRemoveActionItemClick: () => void;
  onDuplicateAction?: () => void;
  onEditAction: MouseEventHandler<HTMLButtonElement> | undefined;
  onCreateBlock?: MouseEventHandler<HTMLButtonElement> | undefined;
  onRemoveFromBlock?: MouseEventHandler<HTMLButtonElement> | undefined;
} & SpaceProps;

const ThreeDotsActionButton = ({
  iconColor = 'text-primary',
  onRemoveActionItemClick,
  onDuplicateAction,
  onEditAction,
  onCreateBlock,
  onRemoveFromBlock,
  ...rest
}: Props) => {
  return (
    <Menu isLazy>
      <MenuButton
        as={IconButton}
        width="auto"
        height="auto"
        borderRadius={rem(6)}
        _hover={{ backgroundColor: 'background-secondary' }}
        _active={{
          backgroundColor: 'background-secondary',
        }}
        __css={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
        aria-label="Action Menu"
        icon={<Icon as={IconThreeDotsVertical} boxSize={rem(14)} margin="0 auto" color={iconColor} />}
        variant="unstyled"
        {...rest}
      />

      <MenuList
        zIndex="dropdown"
        width={rem(154)}
        minWidth="0"
        borderWidth={rem(1)}
        borderStyle="solid"
        borderColor="gray.700"
        borderRadius="4"
        backgroundColor="background-primary"
        paddingX={rem(8)}
      >
        {onEditAction && (
          <MenuItem
            textStyle="small"
            marginBottom={rem(8)}
            padding={`${rem(4)} ${rem(8)}`}
            color="text-primary"
            _hover={{ backgroundColor: 'background-secondary' }}
            backgroundColor="inherit"
            icon={<Icon as={IconPencilDraw} boxSize={rem(14)} color="text-primary" />}
            iconSpacing={rem(8)}
            onClick={onEditAction}
          >
            Edit Action
          </MenuItem>
        )}
        <Tooltip
          isDisabled={!!onDuplicateAction}
          label="You first need to set a date for this action before you can duplicate it"
          openDelay={300}
        >
          <MenuItem
            textStyle="small"
            marginBottom={rem(8)}
            padding={`${rem(4)} ${rem(8)}`}
            color="text-primary"
            _hover={{ backgroundColor: 'background-secondary' }}
            backgroundColor="inherit"
            icon={<Icon as={IconDuplicate} boxSize={rem(14)} color="text-primary" />}
            iconSpacing={rem(8)}
            isDisabled={!onDuplicateAction}
            onClick={onDuplicateAction}
          >
            Duplicate Action
          </MenuItem>
        </Tooltip>
        {onCreateBlock && (
          <MenuItem
            textStyle="small"
            marginBottom={rem(8)}
            padding={`${rem(4)} ${rem(8)}`}
            color="text-primary"
            borderRadius="4"
            _hover={{ backgroundColor: 'background-secondary' }}
            backgroundColor="inherit"
            icon={<Icon as={BsPlusLg} boxSize={rem(14)} color="text-primary" />}
            iconSpacing={rem(8)}
            onClick={onCreateBlock}
          >
            Create Block
          </MenuItem>
        )}
        {onRemoveFromBlock && (
          <MenuItem
            textStyle="small"
            marginBottom={rem(8)}
            padding={`${rem(4)} ${rem(8)}`}
            color="text-primary"
            borderRadius="4"
            _hover={{ backgroundColor: 'background-secondary' }}
            backgroundColor="inherit"
            icon={<Icon as={IconActionTrash} boxSize={rem(14)} color="text-primary" />}
            iconSpacing={rem(8)}
            onClick={onRemoveFromBlock}
          >
            Remove From Block
          </MenuItem>
        )}
        <MenuItem
          textStyle="small"
          padding={`${rem(4)} ${rem(8)}`}
          color="red.500"
          borderRadius="4"
          _hover={{ backgroundColor: 'background-secondary' }}
          backgroundColor="inherit"
          icon={<Icon as={IconActionTrash} boxSize={rem(14)} />}
          onClick={onRemoveActionItemClick}
        >
          Delete Action
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default memo(ThreeDotsActionButton);
