import PersonAvatar from '@/components/PersonAvatar';
import { ActionRelation } from '@/constants/action';
import { ActionPromiseResponse } from '@/gql/actions/types';
import { IconCommitPersonOutline, IconLeveragePersonOutline } from '@/theme/icons';
import { getJoinedPromisesByPersonName } from '@/utils/promises';
import rem from '@/utils/rem';
import { AvatarGroup, Flex, HStack, Icon, Tooltip } from '@chakra-ui/react';
import { Children, memo } from 'react';

type Props = {
  promises: Omit<ActionPromiseResponse, 'id' | 'personId'>[];
};

const PersonActionPreview = ({ promises = [] }: Props) => {
  return (
    <HStack alignItems="center">
      {promises[0]?.kind === ActionRelation.Leverage && <Icon as={IconLeveragePersonOutline} boxSize={rem(14)} />}
      {promises[0]?.kind === ActionRelation.Commit && <Icon as={IconCommitPersonOutline} boxSize={rem(14)} />}

      <AvatarGroup className="person-avatar-action-preview" max={1} size="xs" spacing={rem(10)}>
        {Children.toArray(
          promises.map((person) => (
            <Tooltip
              aria-label={
                promises[0]?.kind === ActionRelation.Leverage
                  ? `Leveraged to: ${getJoinedPromisesByPersonName(promises, ActionRelation.Leverage)}`
                  : `Committed to: ${getJoinedPromisesByPersonName(promises, ActionRelation.Commit)}`
              }
              label={
                promises[0]?.kind === ActionRelation.Leverage
                  ? `Leveraged to: ${getJoinedPromisesByPersonName(promises, ActionRelation.Leverage)}`
                  : `Committed to: ${getJoinedPromisesByPersonName(promises, ActionRelation.Commit)}`
              }
              placement="top"
            >
              <Flex>
                <PersonAvatar
                  name={person?.person?.name}
                  backgroundColor="background-primary"
                  width={rem(20)}
                  height={rem(20)}
                  textStyle="xsmallBlack"
                  leverageStatus={person.leverageRequestStatus}
                  leverageStatusSize={rem(8)}
                />
              </Flex>
            </Tooltip>
          )),
        )}
      </AvatarGroup>
    </HStack>
  );
};

export default memo(PersonActionPreview);
