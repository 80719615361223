import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { endOfTheWeek, isThisWeek, startOfTheWeek } from '@/utils/calendar';
import rem from '@/utils/rem';
import { Box, HStack, Text } from '@chakra-ui/react';
import { format } from 'date-fns';

type Props = {
  selectedDate: Date;
};

const InnerDate = ({ selectedDate }: Props) => {
  const startDate = selectedDate && format(startOfTheWeek(selectedDate), 'MMM dd');
  const endDate = selectedDate && format(endOfTheWeek(selectedDate), 'MMM dd');
  const endDateYear = selectedDate && format(endOfTheWeek(selectedDate), 'yyyy');
  const isDailyPlanPage = useIsDailyPlanPage();

  const dateLabel = isDailyPlanPage ? format(selectedDate, 'EEE, MMM dd') : `${startDate} - ${endDate}`;

  const isCurrentWeek = isThisWeek(startOfTheWeek(selectedDate));

  return (
    <HStack textStyle="h4BarSB" gap={0} minWidth={rem(220)} textTransform="uppercase">
      <Box
        width={rem(6)}
        height={rem(6)}
        marginRight={rem(8)}
        borderRadius={99}
        backgroundColor={isCurrentWeek ? 'cyan.400' : 'gray.400'}
      />
      <Text as="span">{dateLabel}</Text>
      <Text as="span" opacity={0.5}>{`, ${endDateYear}`}</Text>
    </HStack>
  );
};
export default InnerDate;
