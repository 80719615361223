import rem from '@/utils/rem';

export const actionRow = {
  actionRowIndex: {
    fontSize: rem(12),
    fontWeight: 450,
    lineHeight: '130%',
    letterSpacing: '0',
  },
  actionRowTitle: {
    fontSize: rem(12),
    fontWeight: 500,
    lineHeight: '130%',
    letterSpacing: '0',
  },
  actionRowHours: {
    fontWeight: 400,
    fontSize: {
      base: rem(14),
      '5xl': rem(12),
    },
    lineHeight: rem(16),
  },
  actionProjectRowTitle: {
    fontWeight: 400,
    fontSize: rem(10),
    lineHeight: rem(14),
  },
};
