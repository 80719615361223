import { useCallback, useEffect, useState } from 'react';

const useAudio = (audioUrl: string, overwriteUnmount = false) => {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const audioElement = new Audio(audioUrl);
    setAudio(audioElement);
    audioElement.load();

    return () => {
      if (!overwriteUnmount) {
        // Clean up the audio element when the component unmounts
        audioElement.pause();
        audioElement.src = '';
      }
    };
  }, [audioUrl, overwriteUnmount]);

  const playAudio = useCallback(() => {
    if (audio) {
      audio.play();
      setIsPlaying(true);
    }
  }, [audio]);

  const pauseAudio = useCallback(() => {
    if (audio) {
      audio.pause();
      setIsPlaying(false);
    }
  }, [audio]);

  return { isPlaying, playAudio, pauseAudio };
};

export default useAudio;
