import ActionRow from '@/components/ActionRow';
import CategoriesMenu from '@/components/CategoriesMenu';
import EmptyActionList from '@/components/EmptyActionList';
import Input from '@/components/Input';
import ProjectsMenu from '@/components/ProjectsMenu';
import PurposeSelect from '@/components/PurposeSelect';
import StyledModal from '@/components/StyledModal';
import { COMPLETE } from '@/constants/action';
import { INSERT_BLOCK_ONE } from '@/gql/block';
import { CreateBlockResponse } from '@/gql/block/types';
import { useCMActions } from '@/services/action/hooks';
import { addBlockToCache, useAddActionsToBlock } from '@/services/block/hooks';
import { useCategories } from '@/services/categories/hooks';
import { fetchData } from '@/services/graphql';
import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { useWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { useProjects } from '@/services/project/hooks';
import { useActionModal } from '@/stores/useActionModal';
import { IconPlus } from '@/theme/icons';
import { Action } from '@/types/actions';
import { Block } from '@/types/block';
import { ProjectType } from '@/types/project';
import { addActionToCache, getDailyActions, getWeeklyActions } from '@/utils/action';
import { getCategoryById } from '@/utils/category';
import rem from '@/utils/rem';
import { trackActionAddedToBlock, trackBlockCreated } from '@/utils/tracking';
import {
  Button,
  Checkbox,
  FormLabel,
  HStack,
  IconButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  Tooltip,
  VStack,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { isEmpty, sortBy } from 'lodash';
import { ChangeEvent, Children, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

type FormData = {
  result: string;
  purpose: string;
};

export const BlockFormSchema: z.ZodSchema<FormData> = z.object({
  result: z
    .string()
    .trim()
    .min(1, { message: 'Result is required' })
    .max(512, { message: 'Result must be 512 characters or less' }),
  purpose: z
    .string()
    .trim()
    .min(1, { message: 'Purpose is required' })
    .max(10000, { message: 'Purpose must be 10000 characters or less' }),
});

type Props = {
  actionId?: string;
  isOpen: boolean;
  scheduledDate?: Date;
  categoryId?: string;
  projectId?: string;
  readyOnlyCategory?: boolean;
  readOnlyProject?: boolean;
  result?: string;
  onSuccess?: (block: Block) => void;
  onClose: () => void;
};

function CreateBlockModal({
  actionId,
  isOpen,
  scheduledDate,
  categoryId,
  projectId: tempProjectId,
  readyOnlyCategory = false,
  readOnlyProject = false,
  result = '',
  onSuccess,
  onClose,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useBoolean();
  const toast = useToast();
  const isDailyPlanPage = useIsDailyPlanPage();
  const [selectedCategoryId, setSelectedCategoryId] = useState(categoryId);

  useEffect(() => {
    setSelectedCategoryId(categoryId);
  }, [categoryId]);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selectedActions, setSelectedActions] = useState<Action[]>([]);
  const [selectedProject, setSelectedProject] = useState<ProjectType | null>(null);

  const { data: weeklyPlanId } = useWeeklyPlanId(scheduledDate ?? new Date());
  const { data: categories } = useCategories();
  const { data: projects } = useProjects();
  const { data: actions, isLoading: actionsLoading } = useCMActions(selectedCategoryId);

  const createBlockWithMultipleActions = useAddActionsToBlock();
  const { setActionModalOpen } = useActionModal();

  useEffect(() => {
    setSelectedProject(projects?.project.find((p: ProjectType) => p.id === tempProjectId) ?? null);
  }, [tempProjectId, projects]);

  const selectedCategory = useMemo(() => {
    if (selectedCategoryId) {
      return getCategoryById(categories?.category ?? [], selectedCategoryId);
    }
    const undefinedCategory = categories?.category?.find((category) => category.name.toLowerCase() === 'uncategorized');
    setSelectedCategoryId(undefinedCategory?.id);
    return undefinedCategory;
  }, [categories, selectedCategoryId]);

  const actionsNotAlreadyInBlock = useMemo(() => {
    let filteredActions = [];

    const incompleteActions = actions?.action.filter((action) => action.progressStatus !== COMPLETE);

    // do not filter by day/week if no scheduledDate
    // if no scheduledDate, the block should not be attached to a date or weeklyPlanId
    if (scheduledDate && isDailyPlanPage) {
      filteredActions = getDailyActions(incompleteActions ?? [], scheduledDate);
    } else if (scheduledDate && weeklyPlanId) {
      filteredActions = getWeeklyActions(incompleteActions ?? [], weeklyPlanId);
    } else {
      filteredActions = incompleteActions ?? [];
    }

    if (selectedProject?.id) {
      filteredActions = filteredActions.filter((action) => action.projectId === selectedProject.id);
    } else {
      filteredActions = filteredActions.filter((action) => !action.projectId);
    }

    filteredActions = filteredActions.filter((action) => !action.blockId);

    // if there is a pre-selected action, it should be at the top of the list
    return sortBy(filteredActions, ({ id }) => (id === actionId ? 0 : 1));
  }, [scheduledDate, weeklyPlanId, isDailyPlanPage, actions, selectedProject?.id, actionId]);

  const selectedAction = useMemo(
    () => actionsNotAlreadyInBlock?.find((action) => action.id === actionId),
    [actionsNotAlreadyInBlock, actionId],
  );

  const {
    control,
    handleSubmit,
    reset,
    setFocus,
    formState: { errors, isDirty, isValid },
  } = useForm<FormData>({
    defaultValues: {
      result,
      purpose: '',
    },
    resolver: zodResolver(BlockFormSchema),
    mode: 'onChange',
  });

  const createBlock = useMutation({
    mutationFn: (variables: Omit<Block, 'category'>) => fetchData<CreateBlockResponse>(INSERT_BLOCK_ONE, variables),

    onSuccess: (data) => {
      addBlockToCache(data.insertBlockOne);
      onSuccess && onSuccess(data.insertBlockOne);
      onCloseModal();
      trackBlockCreated();

      toast({
        title: 'Block created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const createBlockFromActions = useMutation({
    mutationFn: (variables: Omit<Block, 'category'>) => fetchData<CreateBlockResponse>(INSERT_BLOCK_ONE, variables),

    onSuccess: (data) => {
      addBlockToCache(data.insertBlockOne);

      createBlockWithMultipleActions.mutate({
        actionIds: selectedActions.map((action) => action.id),
        blockId: data.insertBlockOne.id,
        blockOrders: selectedActions.map((_, index) => index + 1),
        projectId: data.insertBlockOne.projectId,
        categoryId: data.insertBlockOne.categoryId,
      });

      onSuccess && onSuccess(data.insertBlockOne);

      trackBlockCreated();
      selectedActions.forEach((action) => trackActionAddedToBlock(!!action.dateOfStarring));
      onCloseModal();

      toast({
        title: 'Block created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const onSubmit = useCallback(
    ({ result, purpose }: FormData) => {
      if (!selectedCategory) {
        return;
      }

      setIsSubmitting.on();

      if (!isEmpty(selectedActions)) {
        createBlockFromActions.mutate({
          id: uuidv4(),
          result,
          purpose,
          categoryId: selectedCategory.id,
          scheduledDate: isDailyPlanPage && scheduledDate ? scheduledDate : null,
          weeklyPlanId: scheduledDate ? String(weeklyPlanId) : null,
          projectId: selectedProject?.id ?? undefined,
          isCompleted: false,
        });

        setIsSubmitting.off();
      } else {
        createBlock.mutate({
          id: uuidv4(),
          result,
          purpose,
          categoryId: selectedCategory.id,
          scheduledDate: isDailyPlanPage && scheduledDate ? scheduledDate : null,
          weeklyPlanId: scheduledDate ? String(weeklyPlanId) : null,
          projectId: selectedProject?.id ?? undefined,
          isCompleted: false,
        });

        setIsSubmitting.off();
      }
    },
    [
      setIsSubmitting,
      selectedActions,
      createBlockFromActions,
      scheduledDate,
      weeklyPlanId,
      selectedCategory,
      createBlock,
      isDailyPlanPage,
      selectedProject?.id,
    ],
  );

  const onCloseModal = useCallback(() => {
    reset();
    setSelectedActions([]);
    setIsCheckAll(false);
    setSelectedProject(null);
    setSelectedCategoryId(categoryId);
    onClose();
  }, [onClose, reset, categoryId]);

  const handleSelect = useCallback(
    (e: ChangeEvent<HTMLInputElement>, action: Action) => {
      const { checked } = e.target;
      const nextActions = checked
        ? [...selectedActions, action]
        : selectedActions.filter((item) => item?.id !== action?.id);
      setSelectedActions(nextActions);
    },
    [selectedActions],
  );

  const handleSelectAll = useCallback(() => {
    setIsCheckAll(!isCheckAll);

    if (isCheckAll) {
      setSelectedActions([]);
      setIsCheckAll(false);
    } else {
      setSelectedActions([...actionsNotAlreadyInBlock]);
      setIsCheckAll(true);
    }
  }, [actionsNotAlreadyInBlock, isCheckAll]);

  useEffect(() => {
    if (actionsNotAlreadyInBlock && selectedActions.length === actionsNotAlreadyInBlock.length) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  }, [actionsNotAlreadyInBlock, selectedActions]);

  useEffect(() => {
    if (selectedAction) setSelectedActions([selectedAction]);
  }, [selectedAction]);

  // unselect all actions when project changes
  useEffect(() => {
    setSelectedActions([]);
  }, [selectedProject?.id]);

  useEffect(() => {
    if (isOpen) {
      // Does not work without setTimeout
      // must be removed after focus trap is enabled
      // (lookup the comments in the codebase by "RRI-2918")
      const timeoutId = setTimeout(() => setFocus('result'), 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isOpen, setFocus]);

  return (
    <StyledModal isOpen={isOpen} onClose={onCloseModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader justifyContent="space-between" display="flex">
          Create a New Block{' '}
          <HStack alignItems="center">
            {categories?.category?.length && (
              <CategoriesMenu
                shouldCreateCategory={false}
                readonly={readyOnlyCategory}
                category={selectedCategory}
                onSelectCategory={(category) => {
                  setSelectedCategoryId(category.id);
                  setSelectedActions([]);
                  setIsCheckAll(false);
                }}
              />
            )}
            <ProjectsMenu
              variant="bordered"
              categoryId={selectedCategory?.id}
              project={selectedProject}
              setSelectedProject={setSelectedProject}
              readonly={readOnlyProject}
            />
          </HStack>
        </ModalHeader>
        <ModalBody>
          <VStack alignItems="normal" width="full" spacing={rem(24)}>
            <Controller
              name="result"
              control={control}
              render={({ field: { ref, value, onChange } }) => (
                <Input
                  ref={ref}
                  label="Result"
                  placeholder="A specific, measurable outcome that you are committed to achieving"
                  type="text"
                  autoComplete="off"
                  value={value}
                  onChange={onChange}
                  error={errors?.result?.message}
                  _placeholder={{
                    color: 'text-tertiary',
                    opacity: 0.3,
                    fontSize: '3xl',
                    fontWeight: 500,
                  }}
                  fontSize="3xl"
                  fontWeight={500}
                  paddingBottom={rem(16)}
                  errorMessageProps={{
                    color: 'red.500',
                    textStyle: 'large',
                  }}
                />
              )}
            />

            <Controller
              name="purpose"
              control={control}
              render={({ field }) => (
                <PurposeSelect
                  {...field}
                  category={selectedCategory}
                  error={errors?.purpose?.message}
                  placeholder="The deeper, emotional reason behind why you want to achieve this result"
                />
              )}
            />

            <>
              <VStack alignItems="start" gap={rem(24)}>
                <HStack width="full">
                  <FormLabel margin={0} color="text-tertiary" fontSize="sm" fontWeight="900" textTransform="uppercase">
                    Add actions to block
                  </FormLabel>
                  <Tooltip label="Add an Action to this Block" placement="top">
                    <IconButton
                      minWidth={rem(30)}
                      height={rem(28)}
                      marginLeft="auto"
                      aria-label="Add Action"
                      icon={<IconPlus height={rem(10)} width={rem(10)} />}
                      onClick={() => {
                        setActionModalOpen(true, {
                          categoryId: selectedCategory?.id,
                          projectId: selectedProject?.id,
                          showProjectSelector: !!selectedProject?.id,
                          readonlyProject: !!selectedProject?.id,
                          readonlyCategory: true,
                          readonlyTooltipLabel: 'To change the category you must go back to "Create a New Block"',
                          onCreateAction: (action) => {
                            addActionToCache(action);
                            setSelectedActions([...selectedActions, action]);
                          },
                          location: 'block modal',
                        });
                      }}
                      variant="secondary"
                    />
                  </Tooltip>

                  <Button
                    width="fit-content"
                    height="full"
                    isDisabled={isEmpty(actionsNotAlreadyInBlock)}
                    onClick={handleSelectAll}
                    paddingInline={rem(8)}
                    paddingY={rem(5)}
                    size="sm"
                    variant="secondary"
                  >
                    <Text textStyle="small">{`${isCheckAll ? 'Unselect' : 'Select'} All`}</Text>
                  </Button>
                </HStack>

                {!actionsLoading && (
                  <VStack overflowY="scroll" width="full" maxHeight={rem(200)}>
                    {!isEmpty(actionsNotAlreadyInBlock) ? (
                      Children.toArray(
                        actionsNotAlreadyInBlock?.map((action) => {
                          return (
                            <HStack width="full">
                              <ActionRow
                                action={action}
                                backgroundColor="background-primary"
                                readOnly
                                hideButtons
                                progressStatusButtonDisabled
                                minWidth={0}
                              />
                              <Checkbox
                                isChecked={selectedActions.some((obj) => obj.id === action.id)}
                                onChange={(e) => handleSelect(e, action)}
                              />
                            </HStack>
                          );
                        }),
                      )
                    ) : (
                      <EmptyActionList
                        title="No current actions"
                        message="To create a block, first click the + to add an action to this empty category."
                        motivational="small"
                        backgroundColor="background-primary"
                      />
                    )}
                  </VStack>
                )}
              </VStack>
            </>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCloseModal} size="lg" variant="secondary">
            Cancel
          </Button>
          <Button
            isDisabled={!isDirty || !isValid || createBlock.isLoading || isEmpty(selectedActions)}
            isLoading={isSubmitting}
            size="lg"
            type="submit"
            variant="primary"
          >
            Create block
          </Button>
        </ModalFooter>
      </form>
    </StyledModal>
  );
}

export default CreateBlockModal;
