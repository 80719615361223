import { CalendarDayHeader } from '@/components/CalendarDayHeader';
import { SortableItemContainer } from '@/components/DragAndDrop';
import { useToggleDurationVisibility } from '@/components/DragAndDropCalendar/Toolbar';
import CalendarProvider from '@/components/DragAndDropCalendar/provider';
import Body from '@/components/WeeklyCalendar/Body';
import Header from '@/components/WeeklyCalendar/Header';
import { ALL_ACTIONS } from '@/constants/action';
import useCaptureListData from '@/hooks/useCaptureListData';
import { RoutesList } from '@/routes/routesList';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { IconClock, IconDragAndDrop, IconStarFull } from '@/theme/icons';
import { Category } from '@/types/category';
import { ActionEventType, ActionType } from '@/types/myPlan';
import { formatDateToString } from '@/utils/calendar';
import { getColorFromToken } from '@/utils/color';
import { getTotalDuration } from '@/utils/events';
import { fixUncategorizedName } from '@/utils/index';
import { checkIfEventExists, isSameEventDay } from '@/utils/myplan';
import rem from '@/utils/rem';
import { trackMyDaySelected } from '@/utils/tracking';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  CloseButton,
  Flex,
  HStack,
  Icon,
  Table,
  TableContainer,
  Text,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { DragEndEvent, DragOverEvent, DragStartEvent, useDndMonitor } from '@dnd-kit/core';
import { SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { Children, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type HeaderType = {
  weekDay: string;
  monthDay: string;
  totalStarredActions: string;
  totalDuration: string;
  dateDay: Date;
};

export type EventsPerDayType = {
  events: ActionEventType[];
  day: string;
};

export type DataType = {
  category: Category;
  events: Record<string, EventsPerDayType>;
};

export type CalendarEventType = {
  header: HeaderType[];
  data: DataType[];
};

type Props = {
  events: CalendarEventType;
  isPlannerExpanded: boolean;
  // onExpandCollapsePlanner: () => void;
  onChangeEventTime: (time: Date, event: ActionEventType) => void;
  onClickEvent: (action: ActionType) => void;
  onRemoveTime: (dateWithoutTime: string, event: ActionEventType) => void;
  onUnplan: (eventId: string) => void;
};

const DUMMY_HEIGHT = 107;

function WeeklyMyPlanCalendar({ events, onClickEvent, onRemoveTime, onUnplan, isPlannerExpanded }: Props) {
  const navigate = useNavigate();
  const [activeCategoryId, setActiveCategoryId] = useState<string | null>(null);
  const [overContainerId, setOverContainerId] = useState<string | null>(null);
  const { notEmptyCategories } = useCaptureListData(ALL_ACTIONS);
  const tableContainerRef = useRef<HTMLTableElement>(null);
  const selectedDate = useCalendarMonthlyStore((state) => state.selectedDate);
  const updateSelectedDate = useCalendarMonthlyStore((state) => state.updateSelectedDate);
  const isDurationVisible = useToggleDurationVisibility((state) => state.isDurationVisible);

  const { isOpen: showDragAndDropMessage, onClose: closeDragAndDropMessage } = useDisclosure({
    defaultIsOpen: true,
  });

  const tableRef = useRef<HTMLTableElement>(null);

  useDndMonitor({
    onDragStart({ active }: DragStartEvent) {
      const data = active.data.current;

      if (!data) {
        return;
      }

      if (data.isExternal) {
        return;
      }

      setActiveCategoryId(data.category.id);
    },
    onDragOver({ active, over }: DragOverEvent) {
      if (!over) {
        return;
      }

      if (isSameEventDay(active.data?.current?.day, over.data?.current?.day)) {
        return;
      }

      setOverContainerId(over.id.toString());
    },
    onDragEnd({ active }: DragEndEvent) {
      const data = active.data.current;

      if (!data) {
        return;
      }

      setActiveCategoryId(null);
      setOverContainerId(null);
    },
    onDragCancel() {
      setActiveCategoryId(null);
      setOverContainerId(null);
    },
  });

  const handleDayHeaderClick = useCallback(
    (date: Date) => {
      updateSelectedDate(date);
      trackMyDaySelected('planner');
      navigate(RoutesList.MyPlanDailyPage);
    },
    [navigate, updateSelectedDate],
  );

  const shouldShowEvents = useCallback(
    (categoryId: string) => {
      return notEmptyCategories.find((el) => el.id === categoryId);
    },
    [notEmptyCategories],
  );

  const shouldShowExtraHeader = useCallback((events: Record<string, EventsPerDayType>) => {
    return Object.values(events).some((el) => el.events.length > 0);
  }, []);

  return (
    <Box position="relative" overflow="hidden" height="inherit">
      <TableContainer
        ref={tableContainerRef}
        overflowY="scroll"
        width="full"
        height="inherit"
        paddingBottom={rem(32)}
        paddingLeft={rem(8)}
      >
        <Table
          ref={tableRef}
          position="relative"
          width="full"
          height="inherit"
          __css={{
            borderCollapse: 'separate',
            borderSpacing: '0',
            width: '100%',
            tableLayout: isPlannerExpanded ? 'fixed' : 'auto',
          }}
        >
          <Header
            top="0"
            position="sticky"
            zIndex={3}
            backgroundColor="background-primary"
            dragMessage={
              showDragAndDropMessage && (
                <Tr
                  sx={{
                    '@media print': {
                      display: 'none',
                    },
                  }}
                >
                  <td colSpan={events.header.length + 1} valign="top" style={{ alignSelf: 'flex-start' }}>
                    <Alert
                      position="relative"
                      gap={rem(20)}
                      padding={`${rem(12)} ${rem(48)} ${rem(12)} ${rem(8)}`}
                      borderRadius={rem(4)}
                      backgroundColor="background-tertiary"
                      marginY={rem(8)}
                      status="info"
                    >
                      <AlertIcon as={IconDragAndDrop} width={rem(20)} height={rem(21)} color="text-primary" />
                      <AlertTitle textStyle="caption" fontWeight={900}>
                        Drag and Drop
                      </AlertTitle>
                      <AlertDescription textStyle="small" color="text-tertiary" opacity="0.5">
                        Drag and drop an action to begin planning
                      </AlertDescription>
                      <CloseButton
                        position="absolute"
                        top="50%"
                        right={rem(12)}
                        alignSelf="flex-start"
                        transform="translate(0, -50%)"
                        onClick={closeDragAndDropMessage}
                      />
                    </Alert>
                  </td>
                </Tr>
              )
            }
          >
            <Header.Cell border="none" width={rem(0)} padding={0} />
            <CalendarProvider date={selectedDate} view="week">
              {Children.toArray(
                events.header.map((header) => (
                  <Header.Cell
                    border="none"
                    padding={0}
                    // ref={isToday(new Date(header.dateDay)) ? tableHeaderContainerRef : undefined}
                    // id={isToday(new Date(header.dateDay)) ? 'todays-header' : undefined}
                  >
                    <CalendarDayHeader
                      date={header.dateDay}
                      showDurations={isDurationVisible}
                      starredEventsDuration={header.totalStarredActions}
                      scheduledEventsDuration={header.totalDuration}
                      onClick={() => handleDayHeaderClick(header.dateDay)}
                    />
                  </Header.Cell>
                )),
              )}
            </CalendarProvider>
          </Header>

          <Body>
            {Children.toArray(
              events.data
                .filter(({ category }) => shouldShowEvents(category?.id))
                .map(({ category, events }) => (
                  <>
                    <Tr
                      key={category.id}
                      position="relative"
                      height={activeCategoryId === category.id ? rem(180) : 0}
                      marginBottom={rem(8)}
                    >
                      <Body.Cell
                        categoryInfo={category}
                        position="absolute"
                        left={rem(6)}
                        width={0}
                        opacity={activeCategoryId && category.id !== activeCategoryId ? 0.5 : 1}
                      >
                        <Flex alignItems="center" width={rem(32)} height="100%" paddingX={rem(4)}>
                          <Badge
                            sx={{
                              '@media print': {
                                color: 'print-text',
                              },
                            }}
                            alignItems="center"
                            gap={rem(4)}
                            display="flex"
                            borderRadius={rem(100)}
                            backgroundColor={getColorFromToken(category.color, '0.4')}
                            paddingX={rem(8)}
                            paddingY={rem(3)}
                          >
                            <Text textStyle="xsmallBlack" textTransform="uppercase">
                              {fixUncategorizedName(category.name)}
                            </Text>
                            {shouldShowExtraHeader(events) && (
                              <>
                                <Text textStyle="xsmall" textTransform="uppercase">
                                  <span style={{ marginRight: rem(2) }}>|</span> Weekly Total
                                </Text>
                                <HStack gap={rem(4)} marginLeft={rem(2)}>
                                  <Icon as={IconStarFull} boxSize={rem(12)} color="text-secondary" />
                                  <Text textStyle="xsmall" textTransform="lowercase">
                                    {getTotalDuration(events, true) || '0h'}
                                  </Text>
                                </HStack>
                                <HStack gap={rem(4)} marginLeft={rem(2)}>
                                  <Icon as={IconClock} boxSize={rem(12)} color="text-secondary" />
                                  <Text textStyle="xsmall" textTransform="lowercase">
                                    {getTotalDuration(events)}
                                  </Text>
                                </HStack>
                              </>
                            )}
                          </Badge>
                        </Flex>
                      </Body.Cell>
                      {Object.entries(events).map(([key, { events, day }], index) => (
                        <Body.Cell
                          key={key}
                          categoryInfo={category}
                          opacity={activeCategoryId && category.id !== activeCategoryId ? 0.5 : 1}
                          bgColorOpacity={activeCategoryId && category.id === activeCategoryId ? '0.2' : '0.1'}
                          paddingTop={rem(35)}
                          borderTopLeftRadius={index === 0 ? rem(8) : 0}
                          borderBottomLeftRadius={index === 0 ? rem(8) : 0}
                          paddingLeft={rem(8)}
                          paddingBottom={events.length > 0 ? rem(8) : 0}
                        >
                          <SortableContext items={events} strategy={horizontalListSortingStrategy}>
                            <Body.DroppableContainer
                              id={key}
                              height="100%"
                              payload={{ category, containerId: key, day: formatDateToString(new Date(day)) }}
                              overColor={category.color}
                              disabled={key.includes('external')}
                              isOver={
                                overContainerId
                                  ? overContainerId === key || checkIfEventExists(overContainerId, events)
                                  : false
                              }
                              gap={rem(16)}
                            >
                              {events.map((event) => (
                                <SortableItemContainer
                                  id={event.id}
                                  key={event.id}
                                  payload={{
                                    category,
                                    containerId: key,
                                    day: formatDateToString(new Date(day)),
                                    isExternal: event.action.isExternal,
                                  }}
                                  disabled={event.action.isExternal}
                                >
                                  <Body.WeeklyEventItem
                                    event={event}
                                    onRemoveTime={(dateWithoutTime) => onRemoveTime(dateWithoutTime, event)}
                                    onUnplan={onUnplan}
                                    onClickEvent={onClickEvent}
                                    boxShadow="2px 2px 9px 0px rgba(0, 0, 0, 0.25)"
                                  />
                                </SortableItemContainer>
                              ))}
                              {overContainerId &&
                                (overContainerId === key ||
                                  (checkIfEventExists(overContainerId, events) && (
                                    <Box height={`${DUMMY_HEIGHT}px`} />
                                  )))}
                            </Body.DroppableContainer>
                          </SortableContext>
                        </Body.Cell>
                      ))}
                    </Tr>
                    <Tr height={rem(8)} />
                  </>
                )),
            )}
            {/* This a trick to make the table rows (<tr>) heights behave like auto. Otherwise they height value will be based by the number of rows and remaining space */}
            <Tr height="100%" />
          </Body>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default WeeklyMyPlanCalendar;
