!(function (date) {
  function standardizeArgs(args) {
    if (args.length === 1 && typeof args[0] === 'string' && isNaN(date.parse(args[0]))) {
      args[0] = args[0].replace(/-/g, '/');
    }
    return Array.prototype.slice.call(args);
  }
  function CustomDate() {
    if (this instanceof CustomDate) {
      return new (Function.prototype.bind.apply(date, [null].concat(standardizeArgs(arguments))))();
    }
    return date();
  }
  CustomDate.prototype = date.prototype;
  CustomDate.now = date.now;
  CustomDate.UTC = date.UTC;
  CustomDate.parse = function () {
    return date.parse.apply(date, standardizeArgs(arguments));
  };
  // eslint-disable-next-line no-global-assign
  Date = CustomDate;
})(Date);
