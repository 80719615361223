import PrintableBlock from '@/components/BlockItem/PrintableBlock';
import { useBlocks } from '@/services/block/hooks';
import { Box, GlobalStyle, LightMode } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useSearchParams } from 'react-router-dom';

function PrintBlockPage() {
  const { id: blockId } = useParams();
  const [searchParams] = useSearchParams();
  const view = searchParams.get('view');
  const date = searchParams.get('date');

  const { data: blocks } = useBlocks();

  const categoryId = useMemo(
    () => blocks?.block.find((b) => blockId === b.id)?.categoryId ?? '',
    [blockId, blocks?.block],
  );

  return (
    <LightMode>
      <GlobalStyle />
      <Helmet>
        <title>Print a Block</title>
      </Helmet>
      {blockId && categoryId && (
        <Box as="main" overflowY="scroll" width="full" maxWidth="full" height="100vh" backgroundColor="white">
          <PrintableBlock categoryId={categoryId} blockId={blockId} view={view} date={date} />
        </Box>
      )}
    </LightMode>
  );
}

export default PrintBlockPage;
