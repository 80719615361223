import { useUpdateUserInfo, useUser, useUserInfo } from '@/services/user/hooks';
import rem from '@/utils/rem';
import { Text, Textarea, VStack, useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

function CaptureThrive() {
  const toast = useToast();
  const [captureThriveText, setCaptureThriveText] = useState('');
  const user = useUser();
  const { data: userInfo } = useUserInfo();
  const updateUserInfo = useUpdateUserInfo();

  const handleSubmit = useCallback(() => {
    if (!user?.data?.id) {
      return;
    }

    updateUserInfo.mutate(
      {
        id: user.data.id,
        soundsEnabled: userInfo?.me.soundsEnabled ?? false,
        toThrive: captureThriveText,
      },
      {
        onSuccess: () => {
          toast({
            title: '3-5 to thrive saved',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          toast({
            title: error?.response?.errors?.[0]?.message ?? 'Failed to save 3-5 to thrive',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  }, [updateUserInfo, user.data?.id, userInfo?.me.soundsEnabled, captureThriveText, toast]);

  useEffect(() => {
    setCaptureThriveText(userInfo?.me.toThrive ?? '');
  }, [userInfo?.me?.toThrive, setCaptureThriveText]);

  return (
    <VStack
      alignItems="start"
      gap={rem(16)}
      overflow="hidden"
      width="full"
      borderRadius={rem(8)}
      backdropFilter="blur(4px)"
      backgroundColor="background-tertiary"
      paddingX={rem(32)}
      paddingY={rem(24)}
    >
      <Text textStyle="mediumBlack" align="center" width="full">
        Capture your 3-5 to thrive{' '}
      </Text>
      <Textarea
        width="full"
        minHeight={rem(310)}
        padding={rem(12)}
        fontSize={rem(12)}
        fontWeight={500}
        lineHeight="130%"
        border={'none'}
        borderRadius={rem(8)}
        resize="none"
        backgroundColor="background-default"
        maxLength={10000}
        onBlur={handleSubmit}
        onChange={(e) => setCaptureThriveText(e.target.value)}
        value={captureThriveText}
      />
    </VStack>
  );
}

export default CaptureThrive;
