export const pageTransition = {
  ease: 'easeInOut',
  delay: 0.3,
  duration: 0.2,
};

export const pageVariantsFadeInOut = {
  initial: {
    opacity: 0,
    x: 0,
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: 0,
  },
};
