import { Text, TextProps, Tooltip } from '@chakra-ui/react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

function EllipsisTooltipText({ children, ...props }: TextProps) {
  const ref = useRef<HTMLParagraphElement | null>(null);
  const [isOverflown, setIsOverflown] = useState(false);

  const checkOverflow = useCallback(() => {
    if (ref.current) {
      setIsOverflown(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    checkOverflow();

    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [checkOverflow]);

  return (
    <Tooltip isDisabled={!isOverflown} label={children} placement="top">
      <Text
        ref={ref}
        position="relative"
        // Workaround to suppress the native browser tooltip for truncated text in Safari.
        // source: https://stackoverflow.com/a/43915246/8687318
        _after={{
          content: "''",
          display: 'block',
        }}
        isTruncated
        {...props}
      >
        {children}
      </Text>
    </Tooltip>
  );
}

export default memo(EllipsisTooltipText);
