import { useAnimatePlanPages } from '@/contexts/AnimatedPlanPages';
import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { endOfTheWeek, startOfTheWeek } from '@/utils/calendar';
import { isPlanningPage } from '@/utils/index';
import { pageTransition } from '@/utils/pageAnimation';
import rem from '@/utils/rem';
import { Flex, HTMLChakraProps, Text, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import { memo } from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  background?: string;
  forceWeek?: boolean;
  showYear?: boolean;
  height?: string;
  animated?: boolean;
} & HTMLChakraProps<'div'>;

const Hero = ({ background, forceWeek = false, showYear = false, animated = false, ...rest }: Props) => {
  const location = useLocation();
  const { selectedDate } = useCalendarMonthlyStore((state) => state);
  const { pageNavigationEffect } = useAnimatePlanPages();
  const isDailyPlanPage = useIsDailyPlanPage();

  const currentMonth = selectedDate && format(selectedDate, 'MMMM');
  const startDate = selectedDate && format(startOfTheWeek(selectedDate), 'MMM dd');
  const endDate = selectedDate && format(endOfTheWeek(selectedDate), 'MMM dd');
  const currentYear = selectedDate && format(selectedDate, 'yyyy');

  const dateLabel = isDailyPlanPage && !forceWeek ? format(selectedDate, 'EEEE, MMMM dd') : `${startDate} - ${endDate}`;

  if (background && animated) {
    return (
      <motion.div
        className="row"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageNavigationEffect}
        transition={pageTransition}
      >
        <Flex
          minHeight={rem(304)}
          maxHeight={rem(640)}
          marginBottom={isPlanningPage(location) ? rem(13) : rem(-80)}
          backgroundImage={background}
          backgroundSize="cover"
          backgroundPosition="center"
          {...rest}
        />
      </motion.div>
    );
  }

  if (background && !animated) {
    return (
      <Flex
        minHeight={rem(304)}
        maxHeight={rem(640)}
        marginBottom={isPlanningPage(location) ? rem(13) : rem(-80)}
        backgroundImage={background}
        backgroundSize="cover"
        backgroundPosition="center"
        {...rest}
      />
    );
  }

  if (!isPlanningPage(location) && selectedDate) {
    return (
      <VStack alignItems="flex-start" {...rest}>
        <Text
          as="span"
          padding={`${rem(8)} ${rem(16)}`}
          fontSize={{ base: 'sm', '4xl': 'sm', '5xl': 'md' }}
          fontWeight="normal"
          lineHeight={rem(18)}
          background="background-tertiary"
          borderRadius={rem(54)}
        >
          {showYear ? currentYear : dateLabel}
        </Text>

        <Text fontSize={{ base: '4xl', lg: '5xl', '4xl': '6xl', '5xl': '8xl' }} fontWeight="normal" lineHeight="1">
          {currentMonth}
        </Text>
      </VStack>
    );
  }

  return <></>;
};

export default memo(Hero);
