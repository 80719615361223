import { PersonListItem } from '@/types/people';
import React, { createContext, useContext } from 'react';

export type FilterOptions = {
  value: 'all' | 'open' | 'leverage' | 'commitment' | 'discussion';
  label: string;
};

export const selectFilterOptions: FilterOptions[] = [
  { value: 'all', label: 'View All' },
  { value: 'open', label: 'Open Items' },
  { value: 'leverage', label: 'Action Leveraged' },
  { value: 'commitment', label: 'Action Committed' },
  { value: 'discussion', label: 'Open Discussion' },
];

export interface PeopleContextState {
  people: PersonListItem[];
  filteredPeopleDeferred: PersonListItem[] | undefined;
  filterFn: any;
  searchQuery: string;
  filterSelected: 'all' | 'open' | 'leverage' | 'commitment' | 'discussion';
  setSearchQuery: (value: string) => void;
  setFilterSelected: (value: FilterOptions['value']) => void;
  allFilteredPeople: PersonListItem[] | undefined;
}

export const PeopleContext = createContext<PeopleContextState | undefined>(undefined);

export interface PeopleProviderProps {
  children: React.ReactNode;
}

export const usePeopleList = () => {
  const context = useContext(PeopleContext);

  if (context === undefined) {
    throw Error('usePeopleList must be used within a PeopleProvider');
  }

  return context;
};
