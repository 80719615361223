import useIsDarkMode from '@/hooks/useIsDarkMode';
import { Category } from '@/types/category';
import { resolveCategoryImageByName } from '@/utils/category';
import { Box, VStack, chakra, useBreakpointValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

type Props = {
  category?: Category | null;
  imageUrl?: string | null;
  children?: JSX.Element;
  fadeIn?: boolean;
};

const MotionDiv = chakra(motion.div);

const HeroImage = ({ category, imageUrl, fadeIn = false, children }: Props) => {
  const isDarkMode = useIsDarkMode();
  const [bgImageWidth, setBgImageWidth] = useState(0);
  const [bgImageHeight, setBgImageHeight] = useState(0);
  useEffect(() => {
    setBgImageWidth(window.innerWidth);
    setBgImageHeight(Math.ceil(window.innerHeight * 0.7));
  }, []);
  const backgroundImageUrl =
    bgImageWidth && bgImageHeight && imageUrl
      ? resolveCategoryImageByName(imageUrl) +
        `&w=${bgImageWidth}&h=${bgImageHeight}&fit=crop&crop=faces%2Cedges&dpr=2&q=37`
      : '';
  return (
    <MotionDiv
      initial={{ opacity: fadeIn && category ? 0 : 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      width="full"
      position="relative"
      zIndex={5}
    >
      <Box
        sx={{
          '@media print': {
            backgroundImage: 'unset',
            _before: {
              bgGradient: 'unset',
            },
            _after: {
              bgGradient: 'unset',
            },
          },
        }}
        position="absolute"
        zIndex={-1}
        top={0}
        right={0}
        left={0}
        width="full"
        height="70vh"
        backgroundImage={`url(${backgroundImageUrl})`}
        backgroundSize="cover"
        backgroundPosition="center center"
        backgroundRepeat="no-repeat"
        _before={{
          content: '""',
          display: 'block',
          width: 'full',
          height: 'full',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bgGradient: `linear-gradient(180deg, transparent, ${isDarkMode ? 'blue.1000' : 'gray.50'} 80%)`,

          zIndex: 0,
        }}
        _after={{
          content: "''",
          width: 'full',
          height: 'full',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bgGradient: `radial-gradient(50% 75.96% at 17.36% 20%, ${category?.color}, rgba(97, 154, 250, 0.00) 100%)`,
          zIndex: 0,
        }}
      />

      <VStack
        position="relative"
        zIndex={1}
        alignItems="start"
        width="full"
        height="full"
        paddingX={useBreakpointValue({ base: 4, md: 10, lg: 16 })}
        paddingY={useBreakpointValue({ base: 10, lg: 0 })}
      >
        {children}
      </VStack>
    </MotionDiv>
  );
};

export default HeroImage;
