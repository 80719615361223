import withDashboardLayout from '@/HOCs/withDashboardLayout';
import { useHeaderHeight } from '@/hooks/useHeaderHeight';
import Capture from '@/pages/Capture';
import rem from '@/utils/rem';
import { Box, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const MotionBox = motion(Box);

const EnhancedCaptureComponent = withDashboardLayout(Capture);

type Props = {
  isVisible: boolean;
};

const FullCaptureListOffset = ({ isVisible }: Props) => {
  const headerHeight = useHeaderHeight();

  return (
    <Flex
      className="capture-offset"
      style={{
        position: 'fixed',
        top: headerHeight,
        width: '100%',
      }}
    >
      <MotionBox
        initial="visible"
        animate={isVisible ? 'visible' : 'hidden'}
        variants={variants}
        transition={{ type: 'tween', duration: 0.7 }}
        height="full"
        width="full"
      >
        <Flex
          justifyContent="center"
          overflowY="auto"
          width="full"
          height={`calc(100vh - ${rem(headerHeight)})`}
          paddingTop={rem(16)}
          paddingBottom={rem(16)}
          backgroundColor="background-primary"
          paddingX={rem(16)}
        >
          <EnhancedCaptureComponent showAsPage={false} />
        </Flex>
      </MotionBox>
    </Flex>
  );
};
export default FullCaptureListOffset;
