import { Category } from '@/types/category';
import { create } from 'zustand';

type StateSelectedCategory = {
  selectedCategory: Category | null;
};

type ActionSelectedCAtegory = {
  updateSelectedCategory: (selectedCategory: StateSelectedCategory['selectedCategory']) => void;
};

const useActionsCategories = create<StateSelectedCategory & ActionSelectedCAtegory>((set) => ({
  selectedCategory: null,
  updateSelectedCategory: (selectedCategory) => set(() => ({ selectedCategory })),
}));

export { useActionsCategories };
