import DayWeekNavigator from '@/components/DayWeekNavigator';
import RPMDateTimePicker from '@/components/RPMDateTimePicker/RPMDateTimePicker';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { IconCalendar } from '@/theme/icons';
import rem from '@/utils/rem';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import FocusLock from 'react-focus-lock';

function PlannerControls() {
  const calendarToolbarIconButtonBG = useColorModeValue('rgba(20, 39, 55, 0.40)', 'rgba(255, 255, 255, 0.40)');
  const { selectedDate, updateSelectedDate } = useCalendarMonthlyStore((state) => state);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <HStack justifyContent="space-between">
      <Text textStyle="h4SatMedium">Project Planner</Text>
      <Flex gap={rem(8)}>
        <DayWeekNavigator selectedDate={selectedDate} />

        <Popover isOpen={isOpen} offset={[-170, 10]} onClose={onClose} onOpen={onOpen}>
          <PopoverTrigger>
            <IconButton
              height="auto"
              borderWidth={rem(1)}
              borderStyle="solid"
              borderColor="stroke-primary"
              borderRadius="4"
              aria-label="Select a date range"
              backgroundColor={isOpen ? calendarToolbarIconButtonBG : ''}
              icon={
                <Tooltip label="Select a date range" placement="top">
                  <Box color={isOpen ? 'black.500' : 'text-primary'}>
                    <IconCalendar width={rem(16)} height={rem(16)} />
                  </Box>
                </Tooltip>
              }
              size="sm"
              variant="calendarToolbarNextPrev"
            />
          </PopoverTrigger>

          <PopoverContent
            width={{ lg: rem(416) }}
            padding={{ base: rem(8), lg: rem(16) }}
            border={`${rem(1)} solid`}
            borderColor="stroke-primary"
            borderRadius={rem(4)}
            backgroundColor="background-primary"
          >
            <PopoverBody className="week-style" padding={0}>
              <FocusLock returnFocus persistentFocus>
                <RPMDateTimePicker
                  dateRange
                  selectedDate={selectedDate}
                  updateSelectedDate={updateSelectedDate}
                  withActionButtons={false}
                />
              </FocusLock>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    </HStack>
  );
}

export default PlannerControls;
