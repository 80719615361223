import { BlockEvent } from '@/gql/blockEvent/types';
import { CategoryEvent } from '@/gql/categoryEvent/types';
import { MyEvent } from '@/types/calendar';
import { ActionEventType } from '@/types/myPlan';
import { humanDuration } from '@/utils/index';
import { ReactNode, createContext, useContext, useMemo } from 'react';

export type DurationType = {
  starredEventsDuration: number;
  scheduledEventsDuration: number;
  plannedEventsDuration: number;
};

export type CalendarContextType = {
  actionEvents: ActionEventType[];
  categoryEvents?: CategoryEvent[];
  blockEvents?: BlockEvent[];
  combinedEvents: MyEvent[];
  makeHeaderDuration: (day: Date) => DurationType;
};

export const CalendarContext = createContext<CalendarContextType | null>(null);

export type CalendarProviderProps = {
  children: ReactNode;
  date: Date;
  view: string;
};

export const useCalendar = () => {
  const context = useContext(CalendarContext);

  if (!context) {
    throw new Error('CalendarContext should be used inside CalendarProvider');
  }

  return context;
};

export const useDateEventsDuration = (date: Date) => {
  const { makeHeaderDuration } = useCalendar();
  const { starredEventsDuration, scheduledEventsDuration } = useMemo(() => {
    const duration = makeHeaderDuration(date);
    return {
      // makeHeaderDuration returns minutes, but humanDuration wants seconds
      starredEventsDuration: humanDuration(duration.starredEventsDuration * 60) || '0h',
      scheduledEventsDuration: humanDuration(duration.scheduledEventsDuration * 60) || '0h',
    };
  }, [date, makeHeaderDuration]);

  return { starredEventsDuration, scheduledEventsDuration };
};
