import { pageVariantsFadeInOut } from '@/utils/pageAnimation';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AnimatePlanPagesContext, AnimatePlanPagesProviderProps, pagesNew } from './AnimatedPlanPages';

const AnimatePlanPagesProvider = ({ children }: AnimatePlanPagesProviderProps) => {
  const location = useLocation();
  const [currentIndex, setCurrentIndex] = useState(pagesNew[location.pathname]);
  const oldIndexRef = useRef(pagesNew[location.pathname]);

  useEffect(() => {
    oldIndexRef.current = currentIndex;
  }, [currentIndex]);

  return (
    <AnimatePlanPagesContext.Provider
      value={{
        pageNavigationEffect: currentIndex !== 0 && oldIndexRef.current !== 0 ? undefined : pageVariantsFadeInOut,
        setCurrentIndex,
      }}
    >
      {children}
    </AnimatePlanPagesContext.Provider>
  );
};

export default AnimatePlanPagesProvider;
