import { useMemo } from 'react';

export function useMapBy<T>(array: T[], key: keyof T) {
  return useMemo(() => {
    const map = new Map<string, T>();
    for (const item of array) {
      map.set(String(item[key]), item);
    }
    return map;
  }, [array, key]);
}
