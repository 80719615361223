import theme from '@/theme/index';
import { createStandaloneToast } from '@chakra-ui/react';
import { QueryClient } from '@tanstack/react-query';

const { toast } = createStandaloneToast({ theme });

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,

      // set the amount of time that the data should be cached
      cacheTime: 15 * 60 * 1000, // 15 minutes

      // set the amount of time that the data can be considered stale
      staleTime: 10 * 60 * 1000, // 10 minutes

      refetchOnMount: false,
      onError: (error: any) => {
        toast({
          title: error?.response?.errors?.[0]?.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    },
    mutations: {
      onError: (error: any) => {
        toast({
          title: error?.response?.errors?.[0]?.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  },
});
