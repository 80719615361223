import { create } from 'zustand';

type CompleteBlock = {
  blockId: string | null;
  setBlockId: (blockId: string | null) => void;
};

export const useCompleteBlock = create<CompleteBlock>((set) => ({
  blockId: null,
  setBlockId: (blockId) => set(() => ({ blockId })),
}));
