import rem from '@/utils/rem';
import { Flex, Spinner } from '@chakra-ui/react';

function Loader() {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width={rem(50)}
      height={rem(50)}
      borderRadius={rem(8)}
      backgroundColor="gray.700"
    >
      <Spinner color="cyan.500" size="md" />
    </Flex>
  );
}

export default Loader;
