import { useDateEventsDuration } from '@/components/DragAndDropCalendar/context';
import Header from '@/components/WeeklyCalendar/Header';
import { IconStarFull } from '@/theme/icons';
import { humanDuration } from '@/utils/index';
import { AiOutlineClockCircle } from 'react-icons/ai';

const emptyDurationPlaceholder = humanDuration(0);

type Props = {
  date: Date;
  textColor?: string;
  small?: boolean;
};

const DayEventsDuration = ({ date, small = false, textColor = 'text-primary' }: Props) => {
  const { starredEventsDuration, scheduledEventsDuration } = useDateEventsDuration(date);
  return (
    <>
      <Header.Duration
        leftIcon={IconStarFull}
        label="Time remaining to complete all of your starred scheduled actions"
        color={textColor}
        containerProps={{ flexDirection: 'row' }}
        xxsmall={small}
      >
        {starredEventsDuration ?? emptyDurationPlaceholder}
      </Header.Duration>

      <Header.Duration
        leftIcon={AiOutlineClockCircle}
        label="Time remaining to complete all of your scheduled actions"
        color={textColor}
        containerProps={{ flexDirection: 'row' }}
        xxsmall={small}
      >
        {scheduledEventsDuration ?? emptyDurationPlaceholder}
      </Header.Duration>
    </>
  );
};

export default DayEventsDuration;
