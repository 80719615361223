import { GetActionsResponse } from '@/gql/actions/types';
import { ADD_ACTIONS_TO_BLOCK, GET_ONLY_BLOCKS, TOGGLE_BLOCK_COMPLETE } from '@/gql/block';
import {
  AddActionsToBlockPayload,
  AddActionsToBlockResponse,
  GetBlocksResponse,
  UpdateBlockProgressStatusPayload,
  UpdateBlockResponse,
} from '@/gql/block/types';
import { keys } from '@/gql/global/keys';
import { APIError } from '@/gql/global/types';
import { useCategories } from '@/services/categories/hooks';
import { fetchData } from '@/services/graphql';
import { queryClient } from '@/services/graphql/queryClient';
import { getWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { addActionsToBlockOnProjectCache, addCategoryToBlocks, addProjectBlockToCache } from '@/services/project/hooks';
import { getSelectedDate } from '@/stores/useCalendar';
import { Block } from '@/types/block';
import { getBlockById } from '@/utils/block';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { pick } from 'lodash';

export const useBlocks = (options?: UseQueryOptions<GetBlocksResponse, APIError>) => {
  const { data: categoriesList, status } = useCategories();

  return useQuery({
    ...keys.blocks.all,
    queryFn: async () => {
      const response = await fetchData<GetBlocksResponse>(GET_ONLY_BLOCKS);

      return {
        block: addCategoryToBlocks(response.block, categoriesList?.category ?? []),
      } satisfies GetBlocksResponse;
    },
    ...options,
    enabled: status === 'success',
  });
};

export const useToggleBlockCompletion = (
  options?: UseMutationOptions<UpdateBlockResponse, APIError, UpdateBlockProgressStatusPayload>,
) => {
  return useMutation({
    mutationFn: ({ id, isCompleted }: UpdateBlockProgressStatusPayload) =>
      fetchData<UpdateBlockResponse>(TOGGLE_BLOCK_COMPLETE, {
        id,
        isCompleted,
      }),

    ...options,
  });
};

export const addBlockToCache = (block: Block) => {
  queryClient.setQueryData<GetBlocksResponse>(keys.blocks.all.queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      ...state,
      block: [...state.block, block],
    };
  });

  if (block.projectId) {
    addProjectBlockToCache(block, block.projectId);
  }
};

export const useAddActionsToBlock = (
  options?: UseMutationOptions<AddActionsToBlockResponse, APIError, AddActionsToBlockPayload>,
) => {
  return useMutation({
    mutationFn: (payload: AddActionsToBlockPayload) =>
      fetchData<AddActionsToBlockResponse>(
        ADD_ACTIONS_TO_BLOCK,
        pick(payload, ['actionIds', 'blockId', 'blockOrders']),
      ),

    onMutate: ({ actionIds, blockId, projectId, categoryId }) => {
      const selectedDate = getSelectedDate();

      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      const blocks = queryClient.getQueryData<GetBlocksResponse>(keys.blocks.all.queryKey);

      const block = getBlockById(blocks?.block ?? [], blockId);

      const cacheKeys = [];
      weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
      categoryId && cacheKeys.push(keys.actions.all._ctx.categoryManager(categoryId).queryKey);

      cacheKeys.forEach((cache) =>
        queryClient.setQueryData<GetActionsResponse>(cache, (state) => {
          if (state == null) {
            return state;
          }

          return {
            ...state,
            action: state.action.map((action) => {
              if (actionIds.includes(action.id)) {
                const index = actionIds.findIndex((item) => item === action.id);

                return {
                  ...action,
                  blockId,
                  blockOrder: index + 1,
                  projectId,
                  project: block?.project,
                };
              }

              return action;
            }),
          };
        }),
      );

      if (projectId) {
        addActionsToBlockOnProjectCache(blockId, projectId, actionIds);
      }
    },

    ...options,
  });
};
