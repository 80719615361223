import { createContext, useContext } from 'react';

export interface ProviderProps {
  children: React.ReactNode;
}

interface SoundEffectsContextStore {
  playCompleteAudio: () => void;
  playHallelujaAudio: () => void;
}

export const SoundEffectsContext = createContext<SoundEffectsContextStore>({
  playCompleteAudio: () => {},
  playHallelujaAudio: () => {},
});

export function useSoundEffects() {
  return useContext(SoundEffectsContext);
}
