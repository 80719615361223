import rem from '@/utils/rem';
import { Box, Flex } from '@chakra-ui/react';
import { CSSProperties, Fragment, memo, useMemo } from 'react';

import createArrayOfElements from './utils';

const masonryColumnStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};

export type MasonryProps<T> = {
  dataArray: T[];
  columnCount: number;
  renderChild: (item: T, index: number) => React.ReactNode;
};

function MasonryContainer<T>({ dataArray, columnCount, renderChild }: MasonryProps<T>) {
  return useMemo(() => {
    const arr = createArrayOfElements(dataArray, columnCount);
    const columnWidth = `calc(100% / ${columnCount})`;

    return (
      <Flex justifyContent="center" gap={rem(32)}>
        {arr?.map((itm, columnIndex) => (
          <Box key={columnIndex} width={columnWidth} minWidth={0} style={masonryColumnStyle}>
            {itm.map((elm, index) => (
              <Fragment key={index}>{renderChild(elm, index)}</Fragment>
            ))}
          </Box>
        ))}

        {/* empty box to force other item to stay 50% width */}
        {arr?.length === 1 && columnCount > 1 && <Box width={columnWidth} aria-hidden style={masonryColumnStyle} />}
      </Flex>
    );
  }, [dataArray, columnCount, renderChild]);
}

export default memo(MasonryContainer);
