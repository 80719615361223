/**
 * Converts pixels to rem, returning a string with the CSS value.
 *
 * Example: rem(24) -> "1.5rem"
 *
 * If the second parameter is true the rems will be returned as ems, for use
 * in media queries (where rems create problems with Safari, see
 * https://t.ly/ASJ0)
 *
 * Example: rem(32, true) -> "2em"
 */
function rem(pixels: number, forMediaQuery = false) {
  const rem = pixels / 16;
  return rem.toString() + (forMediaQuery ? 'em' : 'rem');
}

export default rem;
