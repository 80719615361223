import { CATEGORIES_ICONS } from '@/constants/category';
import { RoutesList } from '@/routes/routesList';
import { useCategories } from '@/services/categories/hooks';
import { ProjectType } from '@/types/project';
import { resolveCategoryImageByName } from '@/utils/category';
import { generateLinearGradient, getColorFromToken } from '@/utils/color';
import { fixUncategorizedName } from '@/utils/index';
import rem from '@/utils/rem';
import { Flex, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
  project: ProjectType;
};

function ProjectListItem({ project }: Props) {
  const { data: categories } = useCategories();

  const category = useMemo(() => {
    if (!categories) {
      return null;
    }

    return categories.category.find((c) => c.id === project.categoryId);
  }, [categories, project.categoryId]);

  return (
    <Link
      as={RouterLink}
      background={
        project.imageUrl
          ? `linear-gradient(180deg, ${getColorFromToken(category?.color, '0')} 0%, ${getColorFromToken(
              category?.color,
              '0.35',
            )} 60%), linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.70) 100%), url(${resolveCategoryImageByName(
              `${project.imageUrl}&w=600&dpr=2&q=65`,
              // 600 is closest to max cell size which is defined by grid based on the page width
            )}) center / cover no-repeat`
          : `linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.70) 100%), ${generateLinearGradient(
              category?.color ?? 'gray.300',
              getColorFromToken(category?.color, '0.2', '900'),
              -360,
            )}`
      }
      borderBottomWidth={rem(8)}
      borderBottomStyle="solid"
      borderBottomColor={category?.color}
      borderRadius={rem(8)}
      _hover={{
        textDecoration: 'none',
      }}
      backgroundColor="background-tertiary"
      to={`${RoutesList.ProjectsPage}/${project.id}`}
    >
      <VStack alignItems="start" justifyContent="end" gap={rem(12)} height={rem(223)} padding={`${rem(28)} ${rem(20)}`}>
        <VStack alignItems="start" gap={rem(4)}>
          <Flex
            alignItems="center"
            justifyContent="center"
            width={rem(28)}
            minWidth={rem(28)}
            height={rem(28)}
            minHeight={rem(28)}
            borderRadius="full"
            backgroundColor={category?.color ?? 'gray.300'}
          >
            <Icon
              as={CATEGORIES_ICONS[category?.icon ?? 'uncategorized']}
              width={rem(16)}
              height={rem(16)}
              color="blue.1000"
            />
          </Flex>

          <Text textStyle="xsmall" color="white" noOfLines={1}>
            {fixUncategorizedName(category?.name ?? '')}
          </Text>
        </VStack>

        <VStack alignItems="start" gap={rem(4)}>
          <Text textStyle="h4BarSB" color="white" textTransform="uppercase" noOfLines={1}>
            {project.name}
          </Text>

          <Text textStyle="small" color="white" opacity="0.75" noOfLines={1}>
            {project.result}
          </Text>
        </VStack>
      </VStack>
    </Link>
  );
}

export default ProjectListItem;
