import {
  DELETE_CATEGORY_EVENT,
  GET_CATEGORY_EVENTS,
  INSERT_CATEGORY_EVENT,
  UPDATE_CATEGORY_EVENT,
} from '@/gql/categoryEvent';
import {
  CreateCategoryEventPayload,
  CreateCategoryEventResponse,
  DeleteCategoryEventPayload,
  DeleteCategoryEventResponse,
  GetCategoryEventResponse as GetCategoryEventsResponse,
  UpdateCategoryEventPayload,
  UpdateCategoryEventResponse,
} from '@/gql/categoryEvent/types';
import { keys } from '@/gql/global/keys';
import { useCronofySyncEvent } from '@/services/cronofy/hooks';
import { fetchData } from '@/services/graphql';
import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { endOfDay, startOfDay } from 'date-fns';

export const useCategoryEvents = (fromDate: Date, toDate: Date) => {
  return useQuery({
    ...keys.categoryEvents.list(fromDate, toDate),
    queryFn: () => fetchData<GetCategoryEventsResponse>(GET_CATEGORY_EVENTS, { fromDate, toDate }),
  });
};

export const useCreateCategoryEvent = () => {
  const queryClient = useQueryClient();
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();
  const toast = useToast();

  return useMutation({
    mutationFn: (payload: CreateCategoryEventPayload) =>
      fetchData<CreateCategoryEventResponse>(INSERT_CATEGORY_EVENT, payload),
    onMutate: (payload) => {
      // Optimistically add the category event to the cache
      const categoryEventDate = new Date(payload.scheduledDate);

      // First fetch all the category events timeframes queried
      const categoryEventsQueries = queryClient.getQueriesData<GetCategoryEventsResponse>({
        queryKey: keys.categoryEvents.list._def,
        exact: false,
      });

      // For each timeframe, if the new event is within the timeframe add it to the cache
      for (const [key, data] of categoryEventsQueries) {
        if (!data) continue;
        // The key will be like ["categoryEvents", "list", "2021-06-01", "2021-06-30"]
        const queryStartDate = startOfDay(new Date(key[2] as string));
        const queryEndDate = endOfDay(new Date(key[3] as string));

        if (categoryEventDate >= queryStartDate && categoryEventDate <= queryEndDate) {
          queryClient.setQueryData(key, {
            ...data,
            categoryEvent: [...data.categoryEvent, payload],
          });
        }
      }
    },
    onSuccess: (data) => {
      cronofySyncEvent({ eventId: data.insertCategoryEventOne.id, eventType: 'CategoryEvent' });
    },
    onError: (error: any, payload) => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      // Rollback the optimistic update, removing the category event id just added
      queryClient.setQueriesData<GetCategoryEventsResponse>(keys.categoryEvents.list._def, (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          categoryEvent: oldData.categoryEvent.filter((item) => item.id !== payload.id),
        };
      });
    },
  });
};

export const useUpdateCategoryEvent = () => {
  const queryClient = useQueryClient();
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();
  const toast = useToast();

  return useMutation({
    mutationFn: (payload: UpdateCategoryEventPayload) =>
      fetchData<UpdateCategoryEventResponse>(UPDATE_CATEGORY_EVENT, payload),
    onMutate: (payload) => {
      // Optimistically update the category event in the cache

      // First fetch all the category events timeframes queried
      const prevData = queryClient.getQueriesData<GetCategoryEventsResponse>({
        queryKey: keys.categoryEvents.list._def,
        exact: false,
      });

      queryClient.setQueriesData<GetCategoryEventsResponse>(keys.categoryEvents.list._def, (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          categoryEvent: oldData.categoryEvent.map((item) => {
            if (item.id === payload.id) return { ...item, ...payload };
            return item;
          }),
        };
      });

      return { prevData };
    },
    onSuccess: (data) => {
      cronofySyncEvent({ eventId: data.updateCategoryEventByPk.id, eventType: 'CategoryEvent' });
    },
    onError: (error: any, payload, context) => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      // Rollback the optimistic update
      if (!context?.prevData) return;
      for (const [key, data] of context.prevData) {
        queryClient.setQueryData(key, data);
      }
    },
  });
};

export const useDeleteCategoryEvent = () => {
  const queryClient = useQueryClient();
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();
  const toast = useToast();

  return useMutation({
    mutationFn: (payload: DeleteCategoryEventPayload) =>
      fetchData<DeleteCategoryEventResponse>(DELETE_CATEGORY_EVENT, payload),
    onMutate: (payload) => {
      // Optimistically remove the category event from the cache

      // First fetch all the category events timeframes queried
      const prevData = queryClient.getQueriesData<GetCategoryEventsResponse>({
        queryKey: keys.categoryEvents.list._def,
        exact: false,
      });

      queryClient.setQueriesData<GetCategoryEventsResponse>(keys.categoryEvents.list._def, (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          categoryEvent: oldData.categoryEvent.filter((item) => item.id !== payload.id),
        };
      });

      return { prevData };
    },
    onSuccess: (data) => {
      cronofySyncEvent({ eventId: data.deleteCategoryEventByPk.id, eventType: 'CategoryEvent', deleted: true });
    },
    onError: (error: any, payload, context) => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      // Rollback the optimistic update
      if (!context?.prevData) return;
      for (const [key, data] of context.prevData) {
        queryClient.setQueryData(key, data);
      }
    },
  });
};
