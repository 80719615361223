import { BLOCK_FRAGMENT_WITH_ACTIONS } from '@/gql/block';
import { gql } from 'graphql-request';

export const ACTION_FRAGMENT = gql`
  fragment Action on Action {
    id
    categoryId
    category {
      name
      icon
      id
      color
    }
    blockId
    block {
      id
      categoryId
      result
      actions {
        id
      }
      isCompleted
    }
    title
    duration
    dateOfStarring
    isLocked
    categoryOrder
    blockOrder
    notes
    progressStatus
    scheduledDate
    scheduledTime
    timezone
    weeklyPlanId
    weekly_plan {
      dateFrom
      dateTo
    }
    event {
      id
    }
    projectId
    project {
      name
    }
    promises {
      kind
      person {
        name
        email
      }
      personId
      id
      leverageOrder
      leverageRequestDueDate
      leverageRequestRepliedAt
      leverageRequestReplyText
      leverageRequestStatus
      leverageRequestText
      leverageRequestedAt
      commitOrder
      createdAt
    }
    globalCommitOrder
    globalLeverageOrder
    createdAt
  }
`;

export const GET_ACTIONS = gql`
  query getActions($where: ActionBoolExp) {
    action(where: $where, orderBy: { categoryOrder: ASC }) {
      id
      categoryId
      category {
        name
        icon
        id
        color
      }
      blockId
      title
      duration
      dateOfStarring
      isLocked
      categoryOrder
      blockOrder
      notes
      progressStatus
      scheduledDate
      scheduledTime
      timezone
      weeklyPlanId
      weekly_plan {
        dateFrom
        dateTo
      }
      event {
        id
      }
      projectId
      project {
        categoryId
        id
        name
      }
      promises {
        kind
        person {
          name
          email
        }
        personId
        id
        leverageOrder
        leverageRequestDueDate
        leverageRequestRepliedAt
        leverageRequestReplyText
        leverageRequestStatus
        leverageRequestText
        leverageRequestedAt
        commitOrder
      }
      createdAt
      modifiedAt
      typename: __typename
    }
  }
`;

export const INSERT_ACTION_ONE = gql`
  mutation insertActionOne(
    $id: uuid
    $duration: interval
    $title: String
    $blockId: uuid
    $categoryId: uuid
    $categoryOrder: smallint
    $dateOfStarring: timestamptz
    $isLocked: Boolean
    $notes: String
    $progressStatus: String
    $projectId: uuid
    # $projectOrder: smallint
    $weeklyPlanId: uuid
    $scheduledDate: date
    $scheduledTime: time
    $timezone: String
  ) {
    insertActionOne(
      object: {
        id: $id
        duration: $duration
        title: $title
        blockId: $blockId
        categoryId: $categoryId
        categoryOrder: $categoryOrder
        dateOfStarring: $dateOfStarring
        isLocked: $isLocked
        notes: $notes
        progressStatus: $progressStatus
        projectId: $projectId
        # projectOrder: $projectOrder
        weeklyPlanId: $weeklyPlanId
        scheduledDate: $scheduledDate
        scheduledTime: $scheduledTime
        timezone: $timezone
      }
    ) {
      id
      duration
      title
      blockId
      categoryId
      categoryOrder
      dateOfStarring
      isLocked
      notes
      progressStatus
      category {
        name
        icon
        id
        color
      }
      event {
        id
      }
      scheduledDate
      scheduledTime
      timezone
      weeklyPlanId
      weekly_plan {
        dateFrom
        dateTo
      }
      projectId
      projectOrder
      project {
        id
        name
      }
      promises {
        kind
        person {
          name
          email
        }
        personId
        id
        leverageOrder
        leverageRequestDueDate
        leverageRequestRepliedAt
        leverageRequestReplyText
        leverageRequestStatus
        leverageRequestText
        leverageRequestedAt
        commitOrder
      }
      globalCommitOrder
      globalLeverageOrder
    }
  }
`;

export const INSERT_ACTIONS = gql`
  mutation insertAction($objects: [ActionInsertInput!]!) {
    insertAction(objects: $objects) {
      returning {
        id
        duration
        title
        blockId
        categoryId
        categoryOrder
        dateOfStarring
        isLocked
        notes
        progressStatus
        category {
          name
          icon
          id
          color
        }
        event {
          id
        }
        scheduledDate
        scheduledTime
        timezone
        weeklyPlanId
        weekly_plan {
          dateFrom
          dateTo
        }
        projectId
        projectOrder
        project {
          id
          name
        }
        promises {
          kind
          person {
            name
            email
          }
          personId
          id
          leverageOrder
          leverageRequestDueDate
          leverageRequestRepliedAt
          leverageRequestReplyText
          leverageRequestStatus
          leverageRequestText
          leverageRequestedAt
          commitOrder
        }
        globalCommitOrder
        globalLeverageOrder
      }
    }
  }
`;

export const UPDATE_ACTION_ONE = gql`
  mutation updateActionByPk(
    $duration: interval
    $title: String
    $categoryId: uuid
    $projectId: uuid
    $actionId: uuid!
    $blockId: uuid
    $blockOrder: smallint
    $dateOfStarring: timestamptz
    $isLocked: Boolean!
    $notes: String
    $progressStatus: String
    $weeklyPlanId: uuid
    $categoryOrder: smallint
    $scheduledDate: date
    $scheduledTime: time
    $timezone: String
    $globalCommitOrder: smallint
    $globalLeverageOrder: smallint
  ) {
    updateActionByPk(
      _set: {
        duration: $duration
        title: $title
        blockId: $blockId
        categoryId: $categoryId
        projectId: $projectId
        blockOrder: $blockOrder
        dateOfStarring: $dateOfStarring
        isLocked: $isLocked
        notes: $notes
        progressStatus: $progressStatus
        weeklyPlanId: $weeklyPlanId
        categoryOrder: $categoryOrder
        scheduledDate: $scheduledDate
        scheduledTime: $scheduledTime
        timezone: $timezone
        globalCommitOrder: $globalCommitOrder
        globalLeverageOrder: $globalLeverageOrder
      }
      pkColumns: { id: $actionId }
    ) {
      id
      categoryId
      projectId
      blockId
      block {
        id
        result
        purpose
        categoryId
        isCompleted
        actions {
          id
        }
      }
      title
      duration
      dateOfStarring
      isLocked
      categoryOrder
      blockOrder
      notes
      progressStatus
      globalCommitOrder
      globalLeverageOrder
      createdAt
      category {
        name
        icon
        id
        color
      }
      weeklyPlanId
      weekly_plan {
        dateFrom
        dateTo
      }
      scheduledDate
      scheduledTime
      timezone
      event {
        id
      }
      projectId
      project {
        id
        name
      }
      promises {
        kind
        person {
          name
          email
        }
        personId
        id
        leverageOrder
        leverageRequestDueDate
        leverageRequestRepliedAt
        leverageRequestReplyText
        leverageRequestStatus
        leverageRequestText
        leverageRequestedAt
        commitOrder
        actionId
        action {
          progressStatus
          title
        }
      }
    }
  }
`;

export const UPDATE_ACTIONS = gql`
  mutation updateActionMany($actionUpdates: [ActionUpdates!]!) {
    updateActionMany(updates: $actionUpdates) {
      returning {
        id
        categoryId
        blockId
        block {
          id
          actions {
            id
          }
          isCompleted
        }
        title
        duration
        dateOfStarring
        isLocked
        categoryOrder
        blockOrder
        projectOrder
        notes
        progressStatus
        category {
          name
          icon
          id
          color
        }
        weeklyPlanId
        weekly_plan {
          dateFrom
          dateTo
        }
        scheduledDate
        scheduledTime
        timezone
        event {
          id
        }
        projectId
        project {
          name
        }
        promises {
          kind
          person {
            name
            email
          }
          personId
          id
          leverageOrder
          leverageRequestDueDate
          leverageRequestRepliedAt
          leverageRequestReplyText
          leverageRequestStatus
          leverageRequestText
          leverageRequestedAt
          commitOrder
          actionId
          action {
            progressStatus
            title
          }
        }
      }
    }
  }
`;

export const DELETE_ACTION = gql`
  mutation deleteActionWithEvents($actionId: uuid!) {
    deleteActionWithEvents(actionId: $actionId) {
      ok
    }
  }
`;

export const UPDATE_ACTION_BY_PK = gql`
  mutation updateActionByPk($actionId: uuid!, $set: ActionSetInput) {
    updateActionByPk(_set: $set, pkColumns: { id: $actionId }) {
      id
      blockId
      duration
      title
      categoryId
      blockId
      title
      duration
      dateOfStarring
      globalCommitOrder
      globalLeverageOrder
      categoryOrder
      blockOrder
      projectOrder
      isLocked
      notes
      progressStatus
      category {
        name
        icon
        id
        color
      }
      scheduledDate
      scheduledTime
      timezone
      weeklyPlanId
      weekly_plan {
        dateFrom
        dateTo
      }
      event {
        id
      }
      projectId
      project {
        name
      }
      promises {
        kind
        person {
          name
          email
        }
        personId
        id
        leverageOrder
        leverageRequestDueDate
        leverageRequestRepliedAt
        leverageRequestReplyText
        leverageRequestStatus
        leverageRequestText
        leverageRequestedAt
        commitOrder
        action {
          progressStatus
          title
        }
        person {
          name
        }
      }
      modifiedAt
      createdAt
    }
  }
`;

export const GET_COMPLETED_ACTIONS_BLOCKS = gql`
  ${ACTION_FRAGMENT}
  ${BLOCK_FRAGMENT_WITH_ACTIONS}

  query getCompletedActionAndBlock($whereActions: ActionBoolExp, $whereBlock: BlockBoolExp) {
    action(where: $whereActions) {
      ...Action
    }
    block(where: $whereBlock) {
      ...Block
      project {
        name
      }
    }
  }
`;

export const CREATE_PROMISES = gql`
  mutation CreatePromises($objects: [PromiseInsertInput!]!) {
    insertPromise(objects: $objects) {
      returning {
        kind
        person {
          name
          email
        }
        id
        actionId
        personId
        action {
          progressStatus
          title
        }
      }
    }
  }
`;

export const GET_LEVERAGED_AND_COMMITTED_ACTIONS = gql`
  ${ACTION_FRAGMENT}

  query GetLeveragedAndCommittedActions {
    leveragedActions: action(where: { progressStatus: { _neq: "complete" }, promises: { kind: { _eq: "leverage" } } }) {
      ...Action
    }
    committedActions: action(
      where: { progressStatus: { _neq: "complete" }, promises: { kind: { _eq: "commitment" } } }
    ) {
      ...Action
    }
  }
`;

export const DELETE_PROMISES_BY_ACTION = gql`
  mutation DeletePromises($actionId: uuid) {
    deletePromise(where: { actionId: { _eq: $actionId } }) {
      returning {
        id
        personId
      }
    }
  }
`;

export const UPDATE_PROMISE = gql`
  mutation UpdatePromise($personId: uuid, $kind: String, $id: uuid!) {
    updatePromiseByPk(pkColumns: { id: $id }, _set: { kind: $kind, personId: $personId }) {
      id
      kind
      personId
    }
  }
`;

export const UPDATE_PROMISE_WITH_CREATE_LEVERAGE = gql`
  mutation UpdatePromiseWithLeverage(
    $id: uuid!
    $leverageRequestDueDate: timestamptz
    $leverageRequestedAt: timestamptz
    $leverageRequestStatus: String
    $leverageRequestText: String
  ) {
    updatePromiseByPk(
      pkColumns: { id: $id }
      _set: {
        leverageRequestDueDate: $leverageRequestDueDate
        leverageRequestStatus: $leverageRequestStatus
        leverageRequestedAt: $leverageRequestedAt
        leverageRequestText: $leverageRequestText
      }
    ) {
      id
      personId
      leverageRequestDueDate
      leverageRequestStatus
      leverageRequestText
      leverageRequestedAt
      action {
        id
        weeklyPlanId
        projectId
        categoryId
        scheduledDate
      }
    }
  }
`;

export const SEND_LEVERAGE_REQUEST_EMAIL = gql`
  mutation SendLeverageRequestEmail($promiseId: uuid!) {
    sendLeverageRequestEmail(promiseId: $promiseId) {
      ok
    }
  }
`;

export const UPDATE_PROMISE_REMOVE_LEVERAGE = gql`
  mutation UpdatePromiseWithRemoveLeverage($id: uuid!) {
    updatePromiseByPk(
      pkColumns: { id: $id }
      _set: {
        leverageRequestDueDate: null
        leverageRequestStatus: null
        leverageRequestedAt: null
        leverageRequestText: null
        leverageRequestReplyText: null
        leverageRequestRepliedAt: null
      }
    ) {
      id
      personId
      action {
        id
        weeklyPlanId
        projectId
        categoryId
        scheduledDate
      }
    }
  }
`;

export const REPLACE_PROMISES = gql`
  mutation ReplacePromises($actionId: uuid, $objects: [PromiseInsertInput!]!) {
    deletePromise(where: { actionId: { _eq: $actionId } }) {
      returning {
        personId
      }
    }
    insertPromise(objects: $objects) {
      returning {
        personId
      }
    }
  }
`;

export const UPDATE_ACTION_STATUS = gql`
  mutation updateActionStatus($data: ActionData) {
    updateActionStatus(action: { data: $data }) {
      success
    }
  }
`;

export const GET_MONTHLY_CALENDAR_ACTIONS = gql`
  query getMonthlyActions($where: ActionBoolExp) {
    action(where: $where, orderBy: { categoryOrder: ASC }) {
      id
      categoryId
      category {
        name
        icon
        id
        color
      }
      blockId
      title
      duration
      dateOfStarring
      isLocked
      categoryOrder
      blockOrder
      notes
      progressStatus
      scheduledDate
      scheduledTime
      timezone
      weeklyPlanId
      weekly_plan {
        dateFrom
        dateTo
      }
      event {
        id
      }
      projectId
      project {
        categoryId
        id
        name
      }
      promises {
        kind
        person {
          name
          email
        }
        personId
        id
        leverageOrder
        leverageRequestDueDate
        leverageRequestRepliedAt
        leverageRequestReplyText
        leverageRequestStatus
        leverageRequestText
        leverageRequestedAt
        commitOrder
      }
      createdAt
      modifiedAt
      typename: __typename
    }
  }
`;
