import Input from '@/components/Input';
import StyledModal from '@/components/StyledModal';
import { useEditPerson } from '@/services/people/hooks';
import { PersonListItem } from '@/types/people';
import rem from '@/utils/rem';
import { Button, ModalBody, ModalFooter, ModalHeader, Text, useToast } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useId } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

const AddEmailSchema = z.object({
  email: z.string().email('Please enter a valid email'),
});

type FormData = z.infer<typeof AddEmailSchema>;

type AddEmailToPersonModalProps = {
  person: PersonListItem;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

function AddEmailToPersonModal({ person, isOpen, onClose, onSuccess }: AddEmailToPersonModalProps) {
  const addEmailFormId = useId();

  const editPerson = useEditPerson();
  const toast = useToast();

  const {
    control,
    reset,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(AddEmailSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const onCancel = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const onSubmit = useCallback(
    async (data: FormData) => {
      if (!person) {
        return;
      }

      await editPerson.mutateAsync({ id: person.id, name: person.name, email: data.email, relation: person.relation });

      toast({
        title: 'Person edited successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onSuccess();
      setTimeout(() => onCancel(), 1000);
    },
    [onSuccess, editPerson, person, onCancel, toast],
  );

  useEffect(() => {
    if (isOpen) {
      // Does not work without setTimeout
      // must be removed after focus trap is enabled
      // (lookup the comments in the codebase by "RRI-2918")
      const timeoutId = setTimeout(() => setFocus('email'), 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isOpen, setFocus]);

  return (
    <StyledModal isOpen={isOpen} onClose={onCancel} size="3xl">
      <ModalHeader>
        <Text as="span" color="cyan.400">
          {person.name}
        </Text>{' '}
        does not have an email, please add one in order to send the request
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)} id={addEmailFormId}>
          <Controller
            name="email"
            control={control}
            render={({ field: { value, onChange, ref } }) => (
              <Input
                ref={ref}
                label="Email"
                type="email"
                paddingBottom={rem(16)}
                error={errors.email?.message}
                onChange={onChange}
                value={value}
                maxWidth="100%"
                fontWeight={500}
                fontSize="3xl"
                autoComplete="off"
                placeholder="e.g. johndoe@email.com"
                _placeholder={{
                  color: 'text-tertiary',
                  opacity: 0.3,
                  fontSize: '3xl',
                  fontWeight: 500,
                }}
              />
            )}
          />
        </form>
      </ModalBody>

      <ModalFooter>
        <Button onClick={onCancel} size="lg" variant="secondary">
          Cancel
        </Button>
        <Button form={addEmailFormId} size="lg" type="submit" variant="primary">
          Update Person
        </Button>
      </ModalFooter>
    </StyledModal>
  );
}

export default AddEmailToPersonModal;
