import CreateEditCategoryModal from '@/components/CreateEditCategoryModal';
import DeleteCategoryModal from '@/components/DialogModal';
import DynamicSegmentedButton from '@/components/DynamicSegmentedButton';
import ThreeDotsCMButton from '@/components/ThreeDotsCMButton';
import UnsplashModal from '@/components/UnsplashModal';
import { CATEGORIES_ICONS } from '@/constants/category';
import { GetBlocksResponse } from '@/gql/block/types';
import { GetCategoriesResponse } from '@/gql/category/types';
import { GetCategoryEventResponse } from '@/gql/categoryEvent/types';
import { keys } from '@/gql/global/keys';
import { RoutesList } from '@/routes/routesList';
import { cleanCategoryForMutation } from '@/services/categories';
import { useCategories, useDeleteCategory, useUpdateCategoryMutation } from '@/services/categories/hooks';
import { queryClient } from '@/services/graphql/queryClient';
import { getWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { IconChevronLeft, IconMountain } from '@/theme/icons';
import { Category } from '@/types/category';
import { ImageInfo } from '@/types/inspirations';
import { updateCategoryInCache } from '@/utils/category';
import { getColorFromToken } from '@/utils/color';
import { fixUncategorizedName, isUncategorized } from '@/utils/index';
import rem from '@/utils/rem';
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from 'src/layouts/Dashboard';

import InnerSections from './InnerSections';
import { ACTIONS_AND_BLOCKS, MAGNIFICENT_7, OPTIONS } from './constants';

const CategoryManagerDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();
  const { data: categoriesList, isLoading: isCategoriesListLoading } = useCategories();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isSavingImage, setIsSavingImage] = useState(false);

  const updateCategory = useUpdateCategoryMutation();

  const {
    isOpen: isEditCategoryOpen,
    onOpen: onEditCategoryOpen,
    onClose: onEditCategoryClose,
  } = useDisclosure({ id: 'editCategory' });

  const {
    isOpen: isImagesModalOpen,
    onOpen: onOpenImagesModal,
    onClose: onCloseImagesModal,
  } = useDisclosure({ id: 'unsplashModal' });

  const selectedDate = useCalendarMonthlyStore((state) => state.selectedDate);
  const weeklyPlanId = getWeeklyPlanId(selectedDate);

  const categoryInfo = useMemo(
    () => categoriesList?.category?.find((el: Category) => el?.id === id),
    [categoriesList?.category, id],
  );

  const uncategorized = useMemo(
    () => categoriesList?.category.find((c) => c.name.toLowerCase() === 'uncategorized'),
    [categoriesList?.category],
  );

  const checkingUncategorized = isUncategorized(categoryInfo) ? ACTIONS_AND_BLOCKS : MAGNIFICENT_7;
  const UPDATED_OPTIONS = isUncategorized(categoryInfo) ? [null, OPTIONS[1]] : OPTIONS;
  const [pageIndex, setPageIndex] = useState(checkingUncategorized);

  const isMobileScreen = useBreakpointValue({ base: true, lg: false });

  const uncategorizedFields = useMemo(() => {
    if (!uncategorized) {
      return {};
    }

    return {
      categoryId: uncategorized.id,
      category: {
        color: uncategorized.color,
        order: uncategorized.order,
        icon: uncategorized.icon,
        id: uncategorized.id,
        name: uncategorized.name,
      },
      categoryOrder: null,
    };
  }, [uncategorized]);

  const onGoBack = useCallback(() => {
    navigate(RoutesList.CategoryManagerPage);
  }, [navigate]);

  const deleteCategory = useDeleteCategory({
    onSuccess: (_, variables) => {
      if (!uncategorizedFields) {
        return;
      }

      queryClient.setQueryData<GetBlocksResponse>(keys.blocks.all.queryKey, (state) => {
        if (state == null) {
          return state;
        }

        return {
          block: state.block.map((block) => {
            if (block.categoryId === id) {
              return {
                ...block,
                ...uncategorizedFields,
              };
            }

            return block;
          }),
        };
      });

      queryClient.setQueryData<GetCategoriesResponse>(keys.categories.all.queryKey, (state) => {
        if (state == null) {
          return state;
        }

        return {
          category: state.category.filter((c) => c.id !== variables.categoryId),
        };
      });

      queryClient.setQueriesData<GetCategoryEventResponse>(keys.categoryEvents.list._def, (state) => {
        if (state == null) {
          return state;
        }

        return {
          ...state,
          categoryEvent: state.categoryEvent.filter((item) => item.categoryId !== id),
        };
      });

      toast({
        title: 'Category deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onGoBack();
    },
  });

  const onDeleteCategory = useCallback(async () => {
    if (!id) return;

    deleteCategory.mutateAsync({
      categoryId: id,
    });
  }, [deleteCategory, id]);

  const onInsertCategoryImage = useCallback(
    (selectedImage: ImageInfo) => {
      if (!selectedImage?.imageUrl || !categoryInfo) {
        return;
      }

      updateCategory.mutate(
        {
          ...cleanCategoryForMutation(categoryInfo),
          selectedImageUrl: selectedImage?.imageUrl ?? '',
        },
        {
          onSuccess: (_, variables) => {
            onCloseImagesModal();
            setIsSavingImage(false);
            updateCategoryInCache(variables);

            toast({
              title: 'Category Image saved',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          },

          onError: (error) => {
            setIsSavingImage(false);

            toast({
              title: error?.response?.errors?.[0]?.message,
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    },
    [categoryInfo, onCloseImagesModal, toast, updateCategory],
  );

  useEffect(
    () => () => {
      queryClient.removeQueries(keys.actions.all._ctx.week(weeklyPlanId ?? '').queryKey);
    },
    [weeklyPlanId],
  );

  useEffect(() => {
    if (uncategorized !== undefined) {
      setPageIndex(checkingUncategorized);
    }
  }, [checkingUncategorized, uncategorized]);

  return (
    <DashboardLayout
      paddingX={0}
      paddingTop={0}
      pageTitle="Category Manager Details"
      editActionModalTooltip="The category of this action cannot be changed when creating"
    >
      <Flex
        position="sticky"
        zIndex="banner"
        top="0"
        alignItems="center"
        flexDirection={{ base: 'column', lg: 'row' }}
        gap={{ base: rem(10), lg: rem(24) }}
        width="full"
        height={{ lg: rem(44) }}
        padding={`${rem(6)} ${rem(16)}`}
        boxShadow="0px 3px 10px 0px rgba(0,0,0,0.3)"
        backgroundColor="background-tertiary"
      >
        <HStack gap={rem(12)} width={{ base: 'full', lg: 'fit-content' }} height="full">
          <HStack height="full">
            <Button
              flexDirection="row"
              gap={rem(12)}
              display="flex"
              marginRight={rem(12)}
              onClick={onGoBack}
              variant="unstyled"
            >
              <Icon as={IconChevronLeft} boxSize={rem(14)} />
              <Text textStyle="large">Categories</Text>
            </Button>

            <Divider color="stroke-primary" orientation="vertical" />
          </HStack>

          {categoryInfo && (
            <>
              <HStack gap={rem(12)} height="full" marginLeft={{ base: 'auto', lg: 'unset' }}>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  width={rem(24)}
                  minWidth={rem(24)}
                  height={rem(24)}
                  minHeight={rem(24)}
                  borderRadius="full"
                  backgroundColor={categoryInfo.color}
                >
                  <Icon
                    as={CATEGORIES_ICONS[categoryInfo.icon ?? 'uncategorized']}
                    width={rem(11)}
                    height={rem(11)}
                    color={getColorFromToken(categoryInfo.color, undefined, '800')}
                  />
                </Flex>
                <Text textStyle="h5BarSB" textTransform="uppercase" noOfLines={1}>
                  {fixUncategorizedName(categoryInfo.name)}
                </Text>

                {isMobileScreen && (
                  <ThreeDotsCMButton
                    onEdit={onEditCategoryOpen}
                    onDelete={() => setOpenDeleteModal(true)}
                    isUncategorized={isUncategorized(categoryInfo)}
                  />
                )}
              </HStack>
            </>
          )}
        </HStack>

        <HStack flexGrow={1} width={{ base: 'full', lg: 'unset' }}>
          <Box marginRight="auto">
            <DynamicSegmentedButton
              onChange={setPageIndex}
              options={UPDATED_OPTIONS}
              startIndex={isUncategorized(categoryInfo) ? 1 : pageIndex}
              disabled={isUncategorized(categoryInfo)}
              layoutId="CategoryManagerDetails"
              buttonPaddingX={{ base: rem(4), sm: rem(10) }}
            />
          </Box>

          <HStack height="full" marginLeft="auto">
            {pageIndex === MAGNIFICENT_7 && !isUncategorized(categoryInfo) && (
              <Button height="full" onClick={onOpenImagesModal} paddingInline={rem(8)} size="sm" variant="secondary">
                <HStack alignItems="center">
                  <Icon as={IconMountain} boxSize={rem(14)} color="gray.300" />
                  <Text textStyle="small" color="text-primary" fontWeight={900}>
                    {!isMobileScreen && `Change `}Cover Image
                  </Text>
                </HStack>
              </Button>
            )}

            {!isMobileScreen && (
              <ThreeDotsCMButton
                onEdit={onEditCategoryOpen}
                onDelete={() => setOpenDeleteModal(true)}
                isUncategorized={isUncategorized(categoryInfo)}
              />
            )}
          </HStack>
        </HStack>
      </Flex>

      <InnerSections pageIndex={pageIndex} isLoading={isCategoriesListLoading} data={categoryInfo} />

      <CreateEditCategoryModal
        category={categoryInfo}
        isOpen={isEditCategoryOpen}
        onClose={onEditCategoryClose}
        isEditMode
      />

      {openDeleteModal && (
        <DeleteCategoryModal
          isOpen={openDeleteModal}
          title="Delete Category"
          message="Are you sure you want to delete this category?"
          confirmText="Delete Category"
          cancelText="Cancel"
          confirmVariant="danger"
          onCancel={() => setOpenDeleteModal(false)}
          onConfirm={onDeleteCategory}
        />
      )}

      <UnsplashModal
        isImagesModalOpen={isImagesModalOpen}
        onCloseImagesModal={onCloseImagesModal}
        onInsertInspirationImage={onInsertCategoryImage}
        isSavingImage={isSavingImage}
        setIsSavingImage={setIsSavingImage}
      />
    </DashboardLayout>
  );
};

export default CategoryManagerDetails;
