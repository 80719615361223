import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  thumb: {
    backgroundColor: 'gray.900',
  },
  track: {
    backgroundColor: 'gray.300',
    _checked: {
      backgroundColor: 'cyan.400',
    },
    _focusVisible: {
      boxShadow: 'none',
    },
  },
});

export default defineMultiStyleConfig({ baseStyle });
