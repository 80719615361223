import ManageProjectModal from '@/components/ManageProjectModal';
import { NoProjects, ProjectList } from '@/components/ProjectStep';
import { useAnimatePlanPages } from '@/contexts/AnimatedPlanPages';
import DashboardLayout from '@/layouts/Dashboard';
import { RoutesList } from '@/routes/routesList';
import { useCategories } from '@/services/categories/hooks';
import { useCreateProject, useProjects } from '@/services/project/hooks';
import { IconPlus } from '@/theme/icons';
import { pageTransition } from '@/utils/pageAnimation';
import rem from '@/utils/rem';
import { Button, Flex, Icon, Spinner, Text, useDisclosure } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

type CreateProjectPayload = {
  name: string;
  result: string;
  purpose: string;
  categoryId: string;
};

function Project() {
  const navigate = useNavigate();

  const { data: categories } = useCategories();

  const { data: projects, isLoading } = useProjects({
    refetchOnMount: true,
  });

  const { pageNavigationEffect } = useAnimatePlanPages();

  const {
    isOpen: isOpenCreateProjectModal,
    onOpen: openCreateProjectModal,
    onClose: onCloseCreateProjectModal,
  } = useDisclosure();

  const { mutateAsync: createProject, isLoading: isCreatingProject } = useCreateProject();

  const onCreateProject = useCallback(
    async ({ name, purpose, result, categoryId }: CreateProjectPayload) => {
      const id = uuidv4();

      const category = categories?.category.find((c) => c.id === categoryId);

      await createProject({
        id,
        name,
        categoryId,
        result,
        purpose,
        imageUrl: category?.selectedImageUrl ?? '',
      });

      navigate(`${RoutesList.ProjectsPage}/${id}`);
    },
    [categories?.category, createProject, navigate],
  );

  useEffect(() => {
    return () => onCloseCreateProjectModal();
  }, [onCloseCreateProjectModal]);

  if (isLoading || !projects) {
    return (
      <DashboardLayout pageTitle="Projects" paddingX={rem(16)}>
        <Flex alignItems="center" justifyContent="center" marginTop={rem(128)}>
          <Spinner />
        </Flex>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout
      pageTitle="Projects"
      paddingX={projects.project.length ? rem(16) : 0}
      rightSectionElements={
        <Button
          height="full"
          marginLeft="auto"
          padding={rem(8)}
          leftIcon={<Icon as={IconPlus} color="text-primary" />}
          onClick={openCreateProjectModal}
          variant="secondary"
        >
          <Text textStyle="small" color="text-primary" fontWeight={900}>
            Create New Project
          </Text>
        </Button>
      }
    >
      <motion.div
        className="row"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageNavigationEffect}
        transition={pageTransition}
      >
        {!projects.project.length ? (
          <NoProjects onCreateProjectClick={openCreateProjectModal} />
        ) : (
          <ProjectList projects={projects.project} />
        )}

        {isOpenCreateProjectModal && (
          <ManageProjectModal
            isOpen={isOpenCreateProjectModal}
            isLoading={isCreatingProject}
            onSubmit={onCreateProject}
            onClose={onCloseCreateProjectModal}
          />
        )}
      </motion.div>
    </DashboardLayout>
  );
}

export default Project;
