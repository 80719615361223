import { keys } from '@/gql/global/keys';
import { APIError } from '@/gql/global/types';
import { GET_WEEKLY_ID, UPDATE_WEEKLYPLAN_BY_PK } from '@/gql/weeklyPlan';
import { UpdateWeeklyPlanPayload, UpdateWeeklyPlanResponse, WeeklyPlanUpdate } from '@/gql/weeklyPlan/types';
import { useLazyQuery } from '@/hooks/useLazyQuery';
import { fetchData } from '@/services/graphql';
import { queryClient } from '@/services/graphql/queryClient';
import { endOfTheWeek, formatDateToString, startOfTheWeek } from '@/utils/calendar';
import { UseMutationOptions, useMutation, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useState } from 'react';

type WeeklyPlan = {
  id: string;
};

export type WeeklyPlanType = {
  getOrCreateWeeklyPlan: WeeklyPlan;
};

type Props = {
  selectedDate: Date;
  enabled?: boolean;
};

const useWeeklyPlan = ({ selectedDate /*, enabled*/ }: Props) => {
  const startDate = formatDateToString(startOfTheWeek(selectedDate));
  const endDate = formatDateToString(endOfTheWeek(selectedDate));

  // We should always execute this query for daily and weekly plans,
  // as an action can belong and be visible for both experiences
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: keys.weeklyPlan.all._ctx.from(startDate).queryKey,

    queryFn: () =>
      fetchData(GET_WEEKLY_ID, {
        dateFrom: startDate,
        dateTo: endDate,
      }),
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (!data) {
        throw new Error('No weeklyPlanId found');
      }

      // setWeeklyPlanId(data.getOrCreateWeeklyPlan.id);
      // setWeeklyPlanReflections(data.getOrCreateWeeklyPlan.reflection);
    },
  });

  const weeklyPlanId = data?.getOrCreateWeeklyPlan.id || undefined;
  const weeklyPlanReflections = data?.getOrCreateWeeklyPlan.reflection || undefined;

  return { error, isLoading, weeklyPlanId, weeklyPlanReflections, refetch };
};

export const useLazyWeeklyPlan = ({ selectedDate /*, enabled*/ }: Props) => {
  const [weeklyPlanId, setWeeklyPlanId] = useState<string | undefined>(undefined);
  const [weeklyPlanReflections, setWeeklyPlanReflections] = useState<string | undefined>(undefined);

  const startDate = formatDateToString(startOfTheWeek(selectedDate));
  const endDate = formatDateToString(endOfTheWeek(selectedDate));

  // We should always execute this query for daily and weekly plans,
  // as an action can belong and be visible for both experiences
  const [getWeeklyPlan, weeklyPlanQueryResult] = useLazyQuery(
    keys.weeklyPlan.all._ctx.from(startDate).queryKey,
    async () =>
      fetchData(GET_WEEKLY_ID, {
        dateFrom: startDate,
        dateTo: endDate,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (!data) {
          throw new Error('No weeklyPlanId found');
        }

        setWeeklyPlanId(data.getOrCreateWeeklyPlan.id);
        setWeeklyPlanReflections(data.getOrCreateWeeklyPlan.reflection ?? '');
      },
    },
  );

  return { getWeeklyPlan, weeklyPlanQueryResult, weeklyPlanId, weeklyPlanReflections };
};

export const getWeeklyPlanId = (selectedDate: Date): string | null => {
  const startDate = formatDateToString(startOfTheWeek(selectedDate));

  const weeklyPlan = queryClient.getQueryData<WeeklyPlanType>(keys.weeklyPlan.all._ctx.from(startDate).queryKey);

  if (!weeklyPlan) {
    return null;
  }

  return weeklyPlan?.getOrCreateWeeklyPlan?.id;
};

export function useWeeklyPlanByDate(date: Date) {
  const startDate = formatDateToString(startOfTheWeek(date));
  const endDate = formatDateToString(endOfTheWeek(date));

  return useQuery({
    queryKey: keys.weeklyPlan.all._ctx.from(startDate).queryKey,
    queryFn: () =>
      fetchData(GET_WEEKLY_ID, {
        dateFrom: startDate,
        dateTo: endDate,
      }),
  });
}

export function useWeeklyPlanId(date: Date) {
  const response = useWeeklyPlanByDate(date);

  const weeklyPlanId = response.data?.getOrCreateWeeklyPlan?.id;

  return {
    ...response,
    data: weeklyPlanId,
    reflection: response.data?.getOrCreateWeeklyPlan?.reflection,
  };
}

export const useUpdateWeeklyPlanByPk = (
  selectedDate: Date,
  options?: Omit<UseMutationOptions<UpdateWeeklyPlanResponse, APIError, UpdateWeeklyPlanPayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload: UpdateWeeklyPlanPayload) =>
      fetchData<UpdateWeeklyPlanResponse>(UPDATE_WEEKLYPLAN_BY_PK, payload),
    ...options,

    onSuccess: (payload) => {
      const startDate = formatDateToString(startOfTheWeek(selectedDate));

      if (isEmpty(payload?.updateWeeklyPlanByPk)) {
        return;
      }

      queryClient.setQueryData<WeeklyPlanUpdate>(keys.weeklyPlan.all._ctx.from(startDate).queryKey, (cache) => {
        if (isEmpty(cache)) {
          return;
        }

        return {
          getOrCreateWeeklyPlan: {
            id: payload?.updateWeeklyPlanByPk?.id,
            reflection: payload?.updateWeeklyPlanByPk?.reflection,
            modifiedAt: payload?.updateWeeklyPlanByPk?.modifiedAt,
          },
        };
      });
    },
  });
};

export default useWeeklyPlan;
