import { COMPLETE } from '@/constants/action';
import { GetBlocksResponse } from '@/gql/block/types';
import { keys } from '@/gql/global/keys';
import { GetProjectResponse } from '@/gql/project/types';
import { queryClient } from '@/services/graphql/queryClient';
import { onMutateBlockStatus, useIncompleteBlockStatus } from '@/services/myPlan/hooks';
import { useWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { useActionProgressStatus } from '@/stores/useActionProgressStatus';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { useCompleteBlock } from '@/stores/useCompleteBlock';
import { Action, ActionResponse } from '@/types/actions';
import { deleteAction, getActionById, getActionsByBlockId, updateActionInPersonCache } from '@/utils/action';
import { getBlockById } from '@/utils/block';
import { getMonthlyCalendarQueryKey } from '@/utils/query';
import { isEmpty, orderBy } from 'lodash';
import { useCallback } from 'react';

const useUpdateBlockAction = () => {
  const { selectedDate } = useCalendarMonthlyStore();
  const { data: weeklyPlanId } = useWeeklyPlanId(selectedDate);
  const { setBlockId: setBlockToCompleteId } = useCompleteBlock();
  const incompleteBlockStatus = useIncompleteBlockStatus({
    onMutate: ({ id }) => onMutateBlockStatus(id),
  });
  const setIsCompleted = useActionProgressStatus((state) => state.setIsCompleted);

  const onUpdateBlockAction = useCallback(
    (updatedAction: Action) => {
      const blocks = queryClient.getQueryData<GetBlocksResponse>(keys.blocks.all.queryKey);

      if (weeklyPlanId !== updatedAction.weeklyPlanId) {
        deleteAction(updatedAction);
      }

      const cacheKeys = [];
      updatedAction?.weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(updatedAction.weeklyPlanId).queryKey);
      updatedAction?.categoryId &&
        cacheKeys.push(keys.actions.all._ctx.categoryManager(updatedAction.categoryId).queryKey);

      // Update the monthly calendar cache
      cacheKeys.push(getMonthlyCalendarQueryKey());

      cacheKeys.forEach((cache) =>
        queryClient.setQueryData<ActionResponse>(cache, (state) => {
          if (state == null || !updatedAction.blockId) {
            return state;
          }

          const actionExists = getActionById(state.action, updatedAction.id);

          if (!actionExists) {
            return {
              action: orderBy([...state.action, updatedAction], 'blockOrder', 'asc'),
            };
          }

          const newActions = state.action.map((action) => {
            if (action.id === updatedAction.id) {
              return {
                ...updatedAction,
                categoryId: updatedAction?.categoryId ?? '',
                blockId: action.blockId,
                blockOrder: action.blockOrder,
              };
            }

            return action;
          });

          const block = getBlockById(blocks?.block ?? [], updatedAction.blockId);

          if (block?.isCompleted && updatedAction.progressStatus !== COMPLETE) {
            incompleteBlockStatus.mutate({ id: updatedAction.blockId });
          }

          let incompleteActions = getActionsByBlockId(updatedAction.blockId, newActions).filter(
            (action) => action.progressStatus !== COMPLETE,
          );

          if (isEmpty(incompleteActions)) {
            setBlockToCompleteId(updatedAction.blockId);
            setIsCompleted(true);
          }

          return {
            action: newActions,
          };
        }),
      );

      if (updatedAction.projectId) {
        queryClient.setQueryData<GetProjectResponse>(keys.project.detail(updatedAction.projectId).queryKey, (state) => {
          if (state == null) {
            return state;
          }

          let actions: Action[] = state.projectByPk.actions;

          const actionExists = getActionById(state.projectByPk.actions, updatedAction.id);

          if (!actionExists) {
            return {
              projectByPk: {
                ...state.projectByPk,
                actions: [...state.projectByPk.actions, updatedAction],
              },
            };
          }

          return {
            projectByPk: {
              ...state.projectByPk,
              actions: actions.map((action) => {
                if (action.id === updatedAction.id) {
                  return {
                    ...updatedAction,
                    blockId: action.blockId,
                    blockOrder: action.blockOrder,
                    projectOrder: action.projectOrder,
                  };
                }

                return action;
              }),
            },
          };
        });
      }

      if (updatedAction?.promises) {
        updateActionInPersonCache(updatedAction);
      }
    },
    [incompleteBlockStatus, setBlockToCompleteId, setIsCompleted, weeklyPlanId],
  );

  return onUpdateBlockAction;
};

export default useUpdateBlockAction;
