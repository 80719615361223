import rem from '@/utils/rem';
import {
  As,
  Flex,
  FlexProps,
  Icon,
  StackProps,
  TableColumnHeaderProps,
  TableHeadProps,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

type DayProps = {
  children: string | number;
  color?: string;
};

function WeekDay({ children, ...rest }: DayProps) {
  return (
    <Text textStyle="xsmall" fontWeight="inherit" textTransform="capitalize" {...rest}>
      {children}
    </Text>
  );
}

function MonthDay({ children, ...rest }: DayProps) {
  return (
    <Text textStyle="xsmall" fontWeight="inherit" {...rest}>
      {children}
    </Text>
  );
}

type DurationProps = {
  children: string | number;
  color?: string;
  containerProps?: FlexProps;
  label: string;
  leftIcon: As;
  xxsmall?: boolean;
};

function Duration({
  children,
  color = 'text-primary',
  containerProps,
  label,
  leftIcon,
  xxsmall = false,
}: DurationProps) {
  return (
    <Tooltip label={label} placement="top">
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={rem(4)}
        placement="top"
        {...containerProps}
      >
        <Icon as={leftIcon} width={xxsmall ? rem(12) : rem(16)} height={xxsmall ? rem(12) : rem(16)} color={color} />
        <Text textStyle="xsmall" color={color} fontSize={rem(8)} textTransform="lowercase">
          {children}
        </Text>
      </Flex>
    </Tooltip>
  );
}

interface CellProps extends TableColumnHeaderProps {
  stackProps?: StackProps;
}

const Cell = forwardRef<HTMLTableCellElement, CellProps>(({ children, stackProps, ...rest }, ref) => {
  return (
    <Th ref={ref} width={rem(180)} paddingTop={rem(6)} paddingBottom="0" paddingInline={rem(8)} {...rest}>
      <VStack gap="0" height="full" {...stackProps}>
        {children}
      </VStack>
    </Th>
  );
});

function Header({ children, dragMessage, ...rest }: TableHeadProps & { dragMessage?: React.ReactNode }) {
  return (
    <Thead {...rest}>
      <Tr>{children}</Tr>
      {dragMessage}
    </Thead>
  );
}

Header.WeekDay = WeekDay;
Header.MonthDay = MonthDay;
Header.Duration = Duration;
Header.Cell = Cell;

export default Header;
