import { RoutesList } from '@/routes/routesList';
import { IconCategories, IconCelebrate, IconFocus, IconPeople, IconPlan, IconProjects } from '@/theme/icons';

const navList = [
  {
    icon: IconPeople,
    label: 'People',
    href: RoutesList.PeoplePage,
  },
  {
    icon: IconProjects,
    label: 'Projects',
    href: RoutesList.ProjectsPage,
  },
  {
    icon: IconCategories,
    label: 'Categories',
    href: RoutesList.CategoryManagerPage,
  },
];

const dropdownNavList = [
  {
    icon: IconFocus,
    label: 'Weekly Capture',
    href: RoutesList.WeeklyCapturePage,
  },
  {
    icon: IconPlan,
    label: 'Weekly Plan',
    href: RoutesList.MyPlanPage,
  },
  {
    icon: IconCelebrate,
    label: 'Weekly Reflection',
    href: RoutesList.CelebratePage,
  },
];

export { dropdownNavList, navList };
