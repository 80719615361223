import { gql } from 'graphql-request';

export const GET_OR_CREATE_USER = gql`
  query GetOrCreateUser {
    getOrCreateUser {
      id
      soundsEnabled
      toThrive
    }
  }
`;

export const UPDATE_USER_BY_PK = gql`
  mutation updateUserByPk($id: uuid!, $soundsEnabled: Boolean!, $toThrive: String!) {
    updateUserByPk(pkColumns: { id: $id }, _set: { soundsEnabled: $soundsEnabled, toThrive: $toThrive }) {
      id
    }
  }
`;

export const GET_USER_INFO = gql`
  query UserInfo {
    me {
      toThrive
      soundsEnabled
    }
  }
`;
