import useIsDarkMode from '@/hooks/useIsDarkMode';
import { IconStar, IconStarFull } from '@/theme/icons';
import rem from '@/utils/rem';
import { Icon, IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react';
import { MouseEvent, memo, useState } from 'react';

type PreferredButtonProps = {
  isStarred: boolean;
  iconColor?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  readOnly?: boolean;
} & Omit<IconButtonProps, 'aria-label'>;

function PreferredButton({
  isStarred,
  iconColor = 'white.500',
  onClick,
  readOnly = false,
  ...rest
}: PreferredButtonProps) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const isDarkMode = useIsDarkMode();

  const handleMouseEnter = () => {
    setIsTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipOpen(false);
  };

  const iconHoverColor = isDarkMode ? 'gray.700' : 'gray.200';

  return (
    <Tooltip isOpen={isTooltipOpen} label={isStarred ? 'Must do' : 'Not a must do'} placement="top">
      <IconButton
        width="auto"
        height="auto"
        borderRadius={rem(6)}
        _hover={{
          backgroundColor: !readOnly ? iconHoverColor : 'transparent',
        }}
        cursor="pointer"
        __css={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
        aria-checked={isStarred}
        aria-label={isStarred ? 'Must do' : 'Not a must do'}
        backgroundColor="transparent"
        icon={<Icon as={isStarred ? IconStarFull : IconStar} boxSize={rem(14)} color={iconColor} />}
        isActive={isStarred}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        role="checkbox"
        {...rest}
      />
    </Tooltip>
  );
}

export default memo(PreferredButton);
