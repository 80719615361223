import RPMDateTimePickerWithPlaceholder from '@/components/RPMDateTimePicker/RPMDateTimePickerWithPlaceholder';
import StyledModal from '@/components/StyledModal';
import Textarea from '@/components/Textarea';
import { DAY, WEEK } from '@/constants/calendar';
import {
  useSendLeverageRequestEmail,
  useUpdatePromiseRemoveLeverageRequest,
  useUpdatePromiseWithLeverageRequest,
} from '@/services/action/hooks';
import { ActionPromise } from '@/types/actions';
import { PersonListItem } from '@/types/people';
import { endOfTheWeek } from '@/utils/calendar';
import rem from '@/utils/rem';
import { trackLeverageRequestStatus } from '@/utils/tracking';
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { isToday } from 'date-fns';
import { useCallback, useEffect, useId } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

const CreateLeverageRequestSchema = z.object({
  note: z.string().optional(),
  dueDate: z
    .literal(null)
    .or(z.date())
    .refine((data) => data && (isToday(data) || data >= new Date()), { message: 'Due date must not be in the past' }),
});

type FormData = z.infer<typeof CreateLeverageRequestSchema>;
export type LeverageRequestPromise = Pick<ActionPromise, 'id' | 'kind' | 'personId'>;

type Props = {
  title: string;
  person: PersonListItem;
  isOpen: boolean;
  onClose: () => void;
  leverageRequestPromise: LeverageRequestPromise;
  dueDate: Date | null;
  tabIndex: string;
};

function CreateLeverageRequestModal({
  title,
  person,
  isOpen,
  onClose,
  dueDate,
  tabIndex,
  leverageRequestPromise,
}: Props) {
  const formId = useId();
  const dueDateInputId = useId();
  const updatePromiseWithLeverageRequest = useUpdatePromiseWithLeverageRequest();
  const sendLeverageRequestEmail = useSendLeverageRequestEmail();
  const updatePromiseRemoveLeverageRequest = useUpdatePromiseRemoveLeverageRequest();
  const toast = useToast();

  const formattedDueDate = tabIndex === WEEK ? endOfTheWeek(dueDate ?? new Date()) : dueDate;

  const {
    isOpen: isDatePickerOpen,
    onOpen: onDatePickerOpen,
    onClose: onDatePickerClose,
  } = useDisclosure({ id: 'datepicker' });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    setFocus,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(CreateLeverageRequestSchema),
    mode: 'onChange',
    defaultValues: {
      dueDate: formattedDueDate ?? undefined,
    },
  });

  const onSubmit = useCallback(
    async (form: FormData) => {
      try {
        await updatePromiseWithLeverageRequest.mutateAsync({
          id: leverageRequestPromise.id,
          leverageRequestDueDate: form.dueDate ?? new Date(),
          leverageRequestStatus: 'pending',
          leverageRequestedAt: new Date(),
          leverageRequestText: form.note,
        });

        await sendLeverageRequestEmail.mutateAsync({ promiseId: leverageRequestPromise.id });

        reset();
        onClose();

        toast({
          title: 'Leverage request sent',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        trackLeverageRequestStatus(leverageRequestPromise.id, 'sent');
      } catch {
        try {
          await updatePromiseRemoveLeverageRequest.mutateAsync({
            id: leverageRequestPromise.id,
          });
        } finally {
          toast({
            title: 'Unable to send leverage request email, please try again',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }
    },
    [
      leverageRequestPromise,
      toast,
      updatePromiseWithLeverageRequest,
      sendLeverageRequestEmail,
      onClose,
      reset,
      updatePromiseRemoveLeverageRequest,
    ],
  );

  const onCancel = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const onClickUnplan = useCallback(() => {
    setValue('dueDate', null);
    onDatePickerClose();
  }, [onDatePickerClose, setValue]);

  useEffect(() => {
    if (isOpen) {
      // Does not work without setTimeout
      // must be removed after focus trap is enabled
      // (lookup the comments in the codebase by "RRI-2918")
      const timeoutId = setTimeout(() => setFocus('note'), 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isOpen, setFocus]);

  return (
    <StyledModal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalHeader paddingTop="8" fontSize="3xl" fontWeight="medium">
        {title}
        <Divider marginTop={rem(19)} opacity="1" borderColor="stroke-primary" aria-hidden />
      </ModalHeader>
      <ModalBody>
        <Text as="h2" textStyle="caption" color="text-tertiary">
          Name
        </Text>
        <HStack gap={rem(16)}>
          <Text textStyle="h5SatMedium">{person.name}</Text>
          {person.email && (
            <Text textStyle="large" color="cyan.400">
              {person.email}
            </Text>
          )}
        </HStack>
        <Divider marginTop={rem(8)} marginBottom={rem(16)} opacity="1" borderColor="stroke-primary" aria-hidden />
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <Controller
            name="dueDate"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl isInvalid={!!errors.dueDate?.message}>
                <FormLabel
                  marginBottom={rem(10)}
                  color="text-tertiary"
                  fontSize="sm"
                  fontWeight="900"
                  textTransform="uppercase"
                  htmlFor={dueDateInputId}
                >
                  Due Date
                </FormLabel>
                <RPMDateTimePickerWithPlaceholder
                  id={dueDateInputId}
                  placeholder="Select a due date"
                  isDatePickerOpen={isDatePickerOpen}
                  tabIndex={DAY}
                  onDatePickerOpen={onDatePickerOpen}
                  onClickUnplan={onClickUnplan}
                  onSave={onDatePickerClose}
                  onCancel={onDatePickerClose}
                  scheduledDate={value}
                  updateSelectedDate={() => null} // handled by onChange below (using react-hook-form)
                  datePickerProps={{
                    selected: value,
                    onChange: (date: any) => onChange(date),
                  }}
                />
                {errors.dueDate?.message && (
                  <FormErrorMessage color="red.500">{errors.dueDate.message}</FormErrorMessage>
                )}
              </FormControl>
            )}
          />
          <Controller
            name="note"
            control={control}
            render={({ field: { ref, value, onChange } }) => (
              <Textarea
                ref={ref}
                autoComplete="off"
                placeholder="Add notes"
                label="Note"
                value={value}
                borderRadius={rem(5)}
                border={`${rem(1)} solid`}
                borderColor="stroke-primary"
                onChange={onChange}
                error={errors?.note?.message}
                containerProps={{
                  marginTop: `${rem(40)}`,
                  variant: 'floatingWithPlaceholder',
                }}
                _placeholder={{ color: 'text-primary', opacity: 0.5, textStyle: 'large' }}
                fontSize="lg"
                fontWeight={500}
                lineHeight="130%"
                letterSpacing="0"
                marginBottom={rem(12)}
                padding={`${rem(16)} ${rem(10)}`}
                width="full"
                resize="none"
                rows={4}
                errorMessageProps={{
                  marginTop: 0,
                  marginBottom: rem(8),
                }}
              />
            )}
          />
        </form>
      </ModalBody>

      <ModalFooter>
        <Button onClick={onCancel} size="lg" variant="secondary">
          Cancel Request
        </Button>
        <Button form={formId} isLoading={isSubmitting} size="lg" type="submit" variant="primary">
          Send Request
        </Button>
      </ModalFooter>
    </StyledModal>
  );
}

export default CreateLeverageRequestModal;
