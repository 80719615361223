import DialogModal from '@/components/DialogModal';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

function ConfirmDeleteBlockModal({ isOpen, onClose, onSubmit }: Props) {
  return (
    <DialogModal
      isOpen={isOpen}
      onCancel={onClose}
      title="Confirm"
      message="You are moving the last action out of this block. By doing so you will delete the block."
      onConfirm={onSubmit}
      confirmText="Move action & delete block"
      confirmVariant="danger"
    />
  );
}

export default ConfirmDeleteBlockModal;
