import React, { useCallback, useState } from 'react';

// import ArrowDown from './ArrowDown';
import KeyDown from './KeyDown';
import UnitDropdown from './UnitDropdown';
import { getSameInputProps, timers, useOnSideArrowTap } from './actions';

const data = ['AM', 'PM'];

const AmPmInputHelper = (props) => {
  const [inputFocused, setInputFocused] = useState(false);
  const { amPm, moveNext, toggleAmPm, setValue, inputRef, movePrev, showAMPMDropdown, ...otherProps } = props;

  const onMoveNext = useCallback(() => {
    if (moveNext) {
      moveNext();
      setInputFocused(false);
    }
  }, [moveNext, setInputFocused]);

  const propsAndState = { ...props, inputFocused, setInputFocused };

  const onSideArrowTap = useOnSideArrowTap(moveNext, movePrev);

  const onKeyDown = useCallback(
    (e) => {
      const { key } = e || {};
      e.preventDefault();
      e.stopPropagation();
      onSideArrowTap(e);

      const aPressed = key?.toLocaleLowerCase?.() === 'a';

      if (key === 'ArrowUp' || key === 'ArrowDown') {
        toggleAmPm();
      } else if (key?.toLocaleLowerCase?.() === 'p' || aPressed) {
        setValue(aPressed ? 'AM' : 'PM');
        onMoveNext();
      }
    },
    [onMoveNext, onSideArrowTap, setValue, toggleAmPm],
  );

  return (
    <React.Fragment>
      <KeyDown onKeyDown={onKeyDown} reference={inputRef}>
        {(onKeyDown, onKeyUp) => (
          <input
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            {...getSameInputProps(propsAndState)}
            value={amPm}
            type="text"
            {...otherProps}
            readOnly
            onClick={() => {
              // eslint-disable-next-line no-undef
              timers.push(setTimeout(() => setInputFocused(true), 30));
            }}
          />
        )}
      </KeyDown>
      {/* {showAMPMDropdown && (
        // (eachInputDropdown && manuallyDisplayDropdown && (
        <ArrowDown
          onClick={() => {
            // eslint-disable-next-line no-undef
            timers.push(setTimeout(() => setInputFocused(!inputFocused), 15));
          }}
        />
      )} */}

      {/* Show the Option selectors */}
      <UnitDropdown
        data={data}
        shouldDisplay={showAMPMDropdown}
        // manuallyDisplayDropdown={manuallyDisplayDropdown}
        type="notRange"
        className="amPm"
        {...{
          moveNext: onMoveNext,
          setValue,
          value: amPm,
          dropdownVisibility: inputFocused,
          setDropdownVisibility: setInputFocused,
        }}
      />
    </React.Fragment>
  );
};

export default AmPmInputHelper;
