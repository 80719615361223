import CategoriesMenu from '@/components/CategoriesMenu';
import CreateEditCategoryModal from '@/components/CreateEditCategoryModal';
import DialogModal from '@/components/DialogModal';
import Input from '@/components/Input';
import StyledModal from '@/components/StyledModal';
import { useCategories } from '@/services/categories/hooks';
import { IconTrash } from '@/theme/icons';
import { Category } from '@/types/category';
import { ProjectType } from '@/types/project';
import { getCategoryById } from '@/utils/category';
import { isUncategorized } from '@/utils/index';
import rem from '@/utils/rem';
import { trackCreateCategorySelected } from '@/utils/tracking';
import {
  Button,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  ModalBody,
  ModalFooter,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

type FormData = {
  name: string;
  result: string;
  purpose: string;
  categoryId: string;
  imageUrl?: string | null;
};

const ManageProjectFormSchema: z.ZodSchema<FormData> = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: 'Project name is a required field' })
    .max(300, { message: 'Project name must be 300 characters or less' }),
  result: z
    .string()
    .trim()
    .min(1, { message: 'Ultimate Result is a required field' })
    .max(300, { message: 'Ultimate Result must be 300 characters or less' }),
  purpose: z
    .string()
    .trim()
    .min(1, { message: 'Ultimate Purpose is a required field' })
    .max(300, { message: 'Ultimate Purpose must be 300 characters or less' }),
  categoryId: z.string(),
  imageUrl: z.string().nullable(),
});

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (payload: FormData) => void;
  onDelete?: () => void;
  onClose: () => void;
  project?: ProjectType;
  categoryId?: string;
  readonly?: boolean;
  readonlyTooltipLabel?: string;
};

function ManageProjectModal({
  isOpen,
  isLoading,
  onSubmit,
  onDelete,
  onClose,
  project,
  categoryId = undefined,
  readonly = false,
  readonlyTooltipLabel = '',
}: Props) {
  const { data: categories } = useCategories();

  const {
    isOpen: isCreateCategoryModalOpen,
    onOpen: onOpenCreateCategoryModal,
    onClose: onCloseCreateCategoryModal,
  } = useDisclosure();

  const isEdit = !!project;

  const { isOpen: isConfirmModalOpen, onOpen: onOpenConfirmModal, onClose: onCloseConfirmModal } = useDisclosure();
  const { isOpen: isDeleteModalOpen, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();

  const [hasDirtyFields, setHasDirtyFields] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setFocus,
    setValue,
    formState: { errors, isDirty, isValid },
    getValues,
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      categoryId:
        categoryId ?? categories?.category?.find((category) => category.name.toLowerCase() === 'uncategorized')?.id,
      purpose: '',
      result: '',
      imageUrl: project?.imageUrl ?? null,
    },
    resolver: zodResolver(ManageProjectFormSchema),
    mode: 'onChange',
  });

  const onCloseModal = useCallback(() => {
    onCloseConfirmModal();
    onClose();
    reset();
  }, [onCloseConfirmModal, onClose, reset]);

  // !TODO: we should bring the submit functionality fully into the modal component
  // and clear the form on success, but this will do for a short term solution. if the
  // modal is closed but there are still form fields, we should reset them
  useEffect(() => {
    !isOpen && isDirty && reset();
  }, [isOpen, isDirty, reset]);

  const onCancel = useCallback(() => {
    if (hasDirtyFields && !isEdit) {
      onOpenConfirmModal();
      return;
    } else {
      onCloseModal();
    }
  }, [hasDirtyFields, isEdit, onCloseModal, onOpenConfirmModal]);

  const handleCreateCategoryModalClose = useCallback(
    (category?: Category) => {
      if (category) {
        setValue('categoryId', category.id, {
          shouldDirty: true,
        });
      }
      onCloseCreateCategoryModal();
    },
    [onCloseCreateCategoryModal, setValue],
  );

  useEffect(() => {
    if (!isEmpty(project)) {
      const { name, result, purpose, categoryId, imageUrl } = project;
      reset({ name, result, purpose, categoryId, imageUrl });
    }
    return () => reset();
  }, [project, reset]);

  useEffect(() => {
    const subscription = watch((_, { type }) => {
      if (type !== 'change') {
        return;
      }

      setHasDirtyFields(true);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    // if the form already has a categoryId, don't need to set it again
    const currentCategoryId = getValues('categoryId');
    if (currentCategoryId) {
      return;
    }

    const givenId = project?.categoryId ? project.categoryId : categoryId;
    const defaultId = categories?.category?.find((category) => isUncategorized(category))?.id;
    const id = givenId ? givenId : defaultId;

    id && setValue('categoryId', id);
  }, [categoryId, categories?.category, project?.categoryId, setValue, getValues]);

  useEffect(() => {
    if (isOpen) {
      // Does not work without setTimeout
      // must be removed after focus trap is enabled
      // (lookup the comments in the codebase by "RRI-2918")
      const timeoutId = setTimeout(() => setFocus('name'), 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isOpen, setFocus]);

  return (
    <>
      <StyledModal isOpen={isOpen} onClose={onCancel} title={`${isEdit ? 'Edit' : 'Create a new'} Project`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <VStack alignItems="normal" width="full" spacing={rem(24)}>
              <Controller
                name="name"
                control={control}
                render={({ field: { ref, value, onChange } }) => (
                  <Input
                    ref={ref}
                    label="Name"
                    placeholder="Name of Project"
                    type="text"
                    autoComplete="off"
                    value={value}
                    onChange={onChange}
                    error={errors?.name?.message}
                    _placeholder={{
                      color: 'text-tertiary',
                      opacity: 0.3,
                      fontSize: '3xl',
                      fontWeight: 500,
                    }}
                    fontSize="3xl"
                    fontWeight={500}
                    paddingBottom={rem(16)}
                  />
                )}
              />
              <Controller
                name="result"
                control={control}
                render={({ field: { ref, value, onChange } }) => (
                  <Input
                    ref={ref}
                    autoComplete="off"
                    error={errors?.result?.message}
                    label="Ultimate Result"
                    onChange={onChange}
                    placeholder="What you'll gain from completing this Project"
                    value={value}
                    _placeholder={{
                      color: 'text-tertiary',
                      opacity: 0.3,
                      fontSize: '3xl',
                      fontWeight: 500,
                    }}
                    fontSize="3xl"
                    fontWeight={500}
                    paddingBottom={rem(16)}
                    resize="none"
                  />
                )}
              />
              <Controller
                name="purpose"
                control={control}
                render={({ field: { ref, value, onChange } }) => (
                  <Input
                    ref={ref}
                    autoComplete="off"
                    error={errors?.purpose?.message}
                    label="Ultimate Purpose"
                    onChange={onChange}
                    placeholder="Ultimate purpose for completing this Project"
                    value={value}
                    _placeholder={{
                      color: 'text-tertiary',
                      opacity: 0.3,
                      fontSize: '3xl',
                      fontWeight: 500,
                    }}
                    fontSize="3xl"
                    fontWeight={500}
                    paddingBottom={rem(16)}
                    resize="none"
                  />
                )}
              />

              {categories && (
                <Controller
                  name="categoryId"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <VStack alignItems="start" gap={rem(16)}>
                      <FormLabel
                        margin={0}
                        color="text-tertiary"
                        fontSize="sm"
                        fontWeight="900"
                        textTransform="uppercase"
                      >
                        Choose category
                      </FormLabel>

                      <CategoriesMenu
                        category={getCategoryById(categories.category, value)}
                        menuButtonVariant="categoriesMenuShrink"
                        onOpenCreateCategoryModal={() => {
                          onOpenCreateCategoryModal();
                          trackCreateCategorySelected('project modal');
                        }}
                        onSelectCategory={(category) => onChange(category.id)}
                        readonly={readonly}
                        readonlyTooltipLabel={readonlyTooltipLabel}
                      />
                    </VStack>
                  )}
                />
              )}
            </VStack>
          </ModalBody>

          <ModalFooter justifyContent={isEdit ? 'space-between' : 'flex-end'} display="flex">
            {isEdit && (
              <IconButton
                aria-label="Delete Action"
                icon={<Icon as={IconTrash} boxSize={rem(20)} />}
                onClick={onOpenDeleteModal}
                variant="secondary"
              />
            )}
            <HStack>
              <Button onClick={onCancel} size="lg" variant="secondary">
                Cancel
              </Button>
              <Button
                isDisabled={!isDirty || !isValid || isLoading}
                isLoading={isLoading}
                size="lg"
                type="submit"
                variant="primary"
              >
                {isEdit ? 'Save' : 'Create Project'}
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </StyledModal>

      <CreateEditCategoryModal isOpen={isCreateCategoryModalOpen} onClose={handleCreateCategoryModalClose} />

      {!isEdit && (
        <DialogModal
          isOpen={isConfirmModalOpen}
          title="Confirm"
          message="Are you sure you want to cancel creating a new project?"
          confirmText="Continue"
          cancelText="Cancel"
          confirmVariant="danger"
          onCancel={onCloseConfirmModal}
          onConfirm={onCloseModal}
        />
      )}

      {isEdit && onDelete && (
        <DialogModal
          isOpen={isDeleteModalOpen}
          title="Confirm"
          message="Do you really want to remove this project?"
          confirmText="Delete Project"
          cancelText="Cancel"
          confirmVariant="danger"
          onCancel={onCloseDeleteModal}
          onConfirm={onDelete}
        />
      )}
    </>
  );
}

export default ManageProjectModal;
