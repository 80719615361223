import { UniqueIdentifier } from '@dnd-kit/core';
import { isUndefined } from 'lodash';

function findContainerById(id: UniqueIdentifier, items: Record<string, any[]>) {
  if (id in items) {
    return id;
  }

  return Object.keys(items)?.find((key) => items[key]?.find((k) => k.id === id));
}

function getItemById(currentId: UniqueIdentifier | string, items: Record<string, any[]>) {
  if (currentId) {
    const activeContainer = findContainerById(currentId, items);

    if (!activeContainer) {
      return;
    }

    const currentItem = items[activeContainer]?.find(({ id }) => id === currentId);

    return currentItem || undefined;
  }
}

function getIdx(overlayItemId: UniqueIdentifier, items: Record<string, any[]>) {
  const container = findContainerById(overlayItemId, items);

  if (isUndefined(container)) {
    return 0;
  }

  const currentContainer = items[container];

  return currentContainer.findIndex(({ id }) => id === overlayItemId);
}

export { findContainerById, getItemById, getIdx };
