import { List, ListProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

type ActionListProps = {
  children: ReactNode;
  emptyListComponent?: ReactNode;
  isEmpty?: boolean;
};

function ActionList({
  children,
  emptyListComponent: EmptyListComponent,
  isEmpty = false,
  ...rest
}: ActionListProps & ListProps) {
  if (EmptyListComponent && isEmpty) {
    return <>{EmptyListComponent}</>;
  }

  return <List {...rest}>{children}</List>;
}

export default ActionList;
