import { CATEGORIES_ICONS } from '@/constants/category';
import { RoutesList } from '@/routes/routesList';
import { ProjectById } from '@/types/project';
import { resolveCategoryImageByName } from '@/utils/category';
import { generateLinearGradient, getColorFromToken } from '@/utils/color';
import rem from '@/utils/rem';
import { EventLocation, trackProjectsSelected } from '@/utils/tracking';
import { Flex, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { NavLink as RouterLink } from 'react-router-dom';

type Props = {
  project: ProjectById;
  location: EventLocation;
};

const ProjectsOverviewCard = ({ project, location }: Props) => {
  let projectImage = project.imageUrl ? resolveCategoryImageByName(project.imageUrl) : '';
  if (projectImage) {
    projectImage += `&w=600&dpr=2&ar=6%3A5&fit=crop&crop=faces%2Cedges&q=65`;
    // AR 6:5 is closest to the most square-like image (can be more elongated due to grid layout and on mobile)
  }

  return (
    <Link
      as={RouterLink}
      minHeight={rem(246)}
      background={
        project.imageUrl
          ? `linear-gradient(180deg, ${getColorFromToken(project?.category?.color, '0')} 0%, ${getColorFromToken(
              project?.category?.color,
              '0.35',
            )} 60%),linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.70) 100%), url(${projectImage}) center / cover no-repeat`
          : generateLinearGradient(
              project?.category?.color ?? 'gray.300',
              getColorFromToken(project?.category?.color, '0.2', '900'),
              -360,
            )
      }
      borderBottom="7px solid"
      borderBottomColor={project.category.color}
      borderRadius={rem(8)}
      _hover={{
        textDecoration: 'none',
      }}
      backgroundColor="background-primary"
      onClick={() => trackProjectsSelected(location)}
      to={`${RoutesList.ProjectsPage}/${project.id}`}
    >
      <VStack alignItems="start" justifyContent="space-between" width="full" height="full" padding={rem(24)}>
        <Flex
          alignItems="center"
          justifyContent="center"
          width={rem(40)}
          minWidth={rem(40)}
          height={rem(40)}
          minHeight={rem(40)}
          borderRadius="full"
          backgroundColor={project.category.color}
        >
          <Icon
            as={CATEGORIES_ICONS[project.category?.icon ?? 'uncategorized']}
            width={rem(24)}
            height={rem(24)}
            color="blue.1000"
          />
        </Flex>

        <VStack alignItems="start" gap={rem(3)} width="full">
          <Text textStyle="h3BarSB" color="white" textTransform="uppercase" noOfLines={1}>
            {project.name}
          </Text>

          {project.purpose && (
            <Text textStyle="large" color="white" noOfLines={1}>
              {project.purpose}
            </Text>
          )}
        </VStack>
      </VStack>
    </Link>
  );
};

export default ProjectsOverviewCard;
