import { ActionModalProps } from '@/components/ActionModal';
import { Action } from '@/types/actions';
import { create } from 'zustand';

type useActionModal = {
  opened: boolean;
  action: Action | null;
  data: Partial<ActionModalProps>;
  createLeverageModalOpen: boolean;
  setActionModalOpen: (value: boolean, data?: Partial<ActionModalProps>) => void;
  onGetCurrentActionData: (action: Action | null) => void;
  setCreateLeverageModalOpen: (createLeverageModalOpen: boolean) => void;
};

const useActionModal = create<useActionModal>((set, get) => ({
  opened: false,
  action: null,
  data: {},
  createLeverageModalOpen: false,
  onToggleModal: () => set(() => ({ opened: !get().opened })),
  onGetCurrentActionData: (action) => set(() => ({ action })),
  setActionModalOpen: (opened: boolean, data?: Partial<ActionModalProps>) => set({ opened, data }),
  setCreateLeverageModalOpen: (createLeverageModalOpen) => set({ createLeverageModalOpen }),
}));

export { useActionModal };
