import { Box } from '@chakra-ui/react';
import { Data, UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { memo } from 'react';

type SortableItemContainerProps = {
  children: React.ReactNode;
  disabled?: boolean;
  id: UniqueIdentifier;
  payload?: Data;
};
function SortableItemContainer({ children, disabled = false, id, payload }: SortableItemContainerProps) {
  const { setNodeRef, attributes, listeners, transform, transition } = useSortable({
    id,
    data: payload,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'grab',
  };

  if (disabled) {
    return children;
  }

  return (
    <Box ref={setNodeRef} style={style} {...attributes} {...listeners} width="full">
      {children}
    </Box>
  );
}

export default memo(SortableItemContainer);
