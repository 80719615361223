import rem from '@/utils/rem';
import { Box, Input, ListItem, UnorderedList } from '@chakra-ui/react';
import { UseComboboxState, UseComboboxStateChange, UseComboboxStateChangeOptions, useCombobox } from 'downshift';
import { CSSProperties, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

type Props = {
  leadingZero: boolean;
  max: number;
  onChange: (segment: string) => void;
  options: string[];
  style?: React.CSSProperties;
  testId: string;
  unit: string;
  value: string;
};

const DurationSegment = ({ leadingZero, max, onChange, options, style, testId, unit, value }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    ({ inputValue = '' }: UseComboboxStateChange<string>) => {
      onChange(inputValue);
    },
    [onChange],
  );

  // remove unit when user begins editing
  const handleFocus = useCallback(() => {
    const timeoutID = setTimeout(() => {
      if (!inputRef.current) return;
      inputRef.current.value = inputRef.current.value.replace(unit, '');
    }, 0);
    return () => clearTimeout(timeoutID);
  }, [unit]);

  // append unit when user finishes editing
  const handleBlur = useCallback(() => {
    const timeoutID = setTimeout(() => {
      if (!inputRef.current) return;
      inputRef.current.value = inputRef.current.value.replace(unit, '') + unit;
    }, 0);
    return () => clearTimeout(timeoutID);
  }, [unit]);

  // ensure units on non-active segments every render
  useEffect(() => {
    if (!inputRef.current || inputRef.current === document.activeElement) {
      return;
    }

    inputRef.current.value = leadingZero
      ? inputRef.current.value.replace(unit, '') + unit
      : String(parseInt(inputRef.current.value, 10)) + unit;
  });

  const { isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps } = useCombobox({
    items: options,
    initialSelectedItem: String(parseInt(value, 10)), // remove leading zeroes
    onInputValueChange: handleChange,
    inputValue: value,
    stateReducer: (state: UseComboboxState<string>, { type, changes }: UseComboboxStateChangeOptions<string>) => {
      const { inputValue = '' } = changes;

      switch (type) {
        case useCombobox.stateChangeTypes.InputClick: {
          const parsed = String(parseInt(inputValue, 10)); // remove leading zeroes

          return {
            ...changes,
            inputValue: parsed,
            highlightedIndex: options.indexOf(leadingZero ? inputValue : parsed),
          };
        }
        case useCombobox.stateChangeTypes.InputBlur: {
          const parsed = parseInt(inputValue, 10);
          const val = isNaN(parsed) ? '' : String(parsed);

          return {
            ...changes,
            inputValue: val.padStart(leadingZero ? 2 : 1, '0'),
          };
        }
        case useCombobox.stateChangeTypes.InputChange: {
          const parsed = Math.min(parseInt(inputValue, 10), max);

          return {
            ...changes,
            inputValue: isNaN(parsed) ? '' : String(parsed),
            highlightedIndex: inputValue === '' || inputValue === '0' ? 0 : options.indexOf(inputValue),
          };
        }
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          return {
            ...changes,
            inputValue: state.inputValue, // don't reset, just use previous state
          };
        default:
          return changes;
      }
    },
  });
  const [dropdownPosition, setDropdownPosition] = useState('bottom');

  const updateDropdownPosition = useCallback(() => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      const spaceAbove = rect.top;
      const spaceBelow = window.innerHeight - rect.bottom;
      const dropdownHeight = 270; // set this to the height of your dropdown

      if (spaceBelow < dropdownHeight && spaceAbove > spaceBelow) {
        setDropdownPosition('top');
      } else {
        setDropdownPosition('bottom');
      }
    }
  }, []);

  // Adjust the position of the UnorderedList based on the calculated position
  const dropdownStyle = useMemo((): CSSProperties => {
    return {
      position: 'absolute',
      zIndex: 2,
      overflowY: 'scroll' as const,
      width: rem(45),
      maxHeight: rem(270),
      margin: dropdownPosition === 'bottom' ? `${rem(16)} 0 0 ${rem(-7)}` : `0 0 ${rem(16)} ${rem(-7)}`,
      padding: '0',
      fontSize: rem(12),
      lineHeight: '130%',
      textAlign: 'center',
      listStyle: 'none',
      boxShadow: isOpen ? '0px 0px 10px 0px rgba(0, 0, 0, 0.6)' : undefined,
      top: dropdownPosition === 'bottom' ? '30%' : undefined,
      bottom: dropdownPosition === 'top' ? '90%' : undefined,
    };
  }, [dropdownPosition, isOpen]);

  useEffect(() => {
    window.addEventListener('resize', updateDropdownPosition);
    return () => window.removeEventListener('resize', updateDropdownPosition);
  }, [updateDropdownPosition]);

  // Trigger position update when the dropdown is about to open
  useEffect(() => {
    if (isOpen) {
      updateDropdownPosition();
    }
  }, [isOpen, updateDropdownPosition]);

  return (
    <Box style={style}>
      <Box>
        <Input
          // This fixes an issue with the caret being visible on touch devices
          sx={isOpen ? { caretColor: 'transparent' } : {}}
          width={style?.width ?? rem(56)}
          height={rem(28)}
          minHeight={rem(28)}
          padding={0}
          color="text-primary"
          fontSize={rem(12)}
          fontWeight={500}
          lineHeight="130%"
          textAlign="center"
          border="none"
          borderRadius={0}
          _hover={{ bg: 'background-quaternary' }}
          _focusVisible={{ bg: 'background-quaternary', boxShadow: 'none' }}
          {...getInputProps({ ref: inputRef, onBlur: handleBlur, onFocus: handleFocus })}
          data-testid={testId}
        />
      </Box>

      <UnorderedList {...getMenuProps()} position="absolute" zIndex={2} overflowY="scroll" style={dropdownStyle}>
        {isOpen &&
          options.map((item, index) => (
            <ListItem
              key={`${item}-${index}`}
              padding={rem(4)}
              background={highlightedIndex === index ? 'background-secondary' : 'background-primary'}
              {...getItemProps({
                item,
                index,
              })}
            >
              {item}
            </ListItem>
          ))}
      </UnorderedList>
    </Box>
  );
};

export default memo(DurationSegment);
