import { ACTIONS_AND_BLOCKS, MAGNIFICENT_7 } from '@/pages/CategoryManagerDetails/constants';
import { useCategories } from '@/services/categories/hooks';
import rem from '@/utils/rem';
import { Flex, Spinner, StyleProps } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTimeout } from 'usehooks-ts';

import ActionAndBlocksSection from './ActionsBlocks';
import Magnificent7Section from './Magnificent7';

type Props = {
  pageIndex: number;
  paddingX?: StyleProps['padding'];
  paddingTop?: StyleProps['paddingTop'];
  isLoading?: boolean;
  data?: any;
};

const InnerSections = ({ pageIndex, isLoading = false, ...rest }: Props) => {
  const { id } = useParams();
  const [visible, setVisible] = useState(false);

  const show = () => setVisible(true);

  const { data } = useCategories();

  const category = useMemo(() => {
    return data?.category?.filter((c) => c.id === id)?.[0];
  }, [data?.category, id]);

  useTimeout(show, 1000);

  if (isLoading && !data) {
    return (
      <Flex justifyContent="center">
        <Spinner size="xl" speed="0.65s" thickness={rem(4)} />
      </Flex>
    );
  }

  switch (pageIndex) {
    default:
    case MAGNIFICENT_7:
      if (visible) {
        return <Magnificent7Section category={category} {...rest} />;
      }

      return (
        <Flex justifyContent="center">
          <Spinner size="xl" speed="0.65s" thickness={rem(4)} />
        </Flex>
      );

    case ACTIONS_AND_BLOCKS:
      return <ActionAndBlocksSection paddingX={rem(16)} {...rest} />;
  }
};

export default InnerSections;
