import { PersonListItem } from '@/types/people';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type StateSelectedProject = {
  peopleList: PersonListItem[];
  searchQuery: string;
  filterSelected: 'all' | 'open' | 'leverage' | 'commitment' | 'discussion';
};

type ActionSelectedProject = {
  updatePeopleList: (peopleList: StateSelectedProject['peopleList']) => void;
  updateSearchQuery: (searchQuery: StateSelectedProject['searchQuery']) => void;
  updateFilterSelected: (filterSelected: StateSelectedProject['filterSelected']) => void;
};

const usePeopleStore = create(
  persist<StateSelectedProject & ActionSelectedProject>(
    (set) => ({
      peopleList: [],
      updatePeopleList: (peopleList) => set(() => ({ peopleList })),
      searchQuery: '',
      updateSearchQuery: (searchQuery) => set(() => ({ searchQuery })),
      filterSelected: 'all',
      updateFilterSelected: (filterSelected) => set(() => ({ filterSelected })),
    }),
    {
      name: 'people', // name of item in the storage (must be unique)
    },
  ),
);

export { usePeopleStore };
