import rem from '@/utils/rem';
import {
  Textarea as ChakraTextarea,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps,
  FormLabel,
  FormLabelProps,
  InputGroup,
  StyleProps,
  TextareaProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

type Props = {
  label?: string;
  value?: string;
  inlineLabel?: boolean;
  error?: string;
  containerProps?: FormControlProps;
  errorMessageProps?: FormErrorMessageProps;
  containerWidth?: StyleProps['width'];
  labelProps?: FormLabelProps;
  variant?: 'outline' | 'filled' | 'flushed' | 'unstyled' | 'floating';
} & TextareaProps;

const Textarea = forwardRef<HTMLTextAreaElement | null, Props>(
  (
    {
      label,
      error,
      containerProps,
      errorMessageProps,
      containerWidth = 'auto',
      variant = 'floating',
      labelProps,
      ...rest
    },
    ref,
  ) => {
    return (
      <FormControl isInvalid={!!error} variant={variant} {...containerProps} width={containerWidth}>
        <InputGroup flexDirection="column">
          <ChakraTextarea
            ref={ref}
            order={2}
            padding="0"
            // fontWeight="normal"
            border="none"
            borderBottomWidth={rem(1)}
            borderBottomStyle="solid"
            borderBottomColor="white.500a015"
            borderRadius="0"
            _focusVisible={{
              boxShadow: 'none',
              borderColor: 'cyan.400 !important',
            }}
            _invalid={{ boxShadow: 'none', borderBottom: '1px solid red' }}
            _placeholder={{ color: 'gray.400' }}
            placeholder=" "
            {...rest}
          />
          {/* It is important that the Label comes after the Control due to css selectors */}
          <FormLabel
            order={1}
            marginBottom="0"
            color="text-tertiary"
            fontSize={rem(12)}
            fontWeight={900}
            lineHeight="130%"
            letterSpacing="0"
            textTransform="uppercase"
            // opacity="0.75"
            {...labelProps}
          >
            {label}
          </FormLabel>
        </InputGroup>
        {error && (
          <FormErrorMessage color="red.500" {...errorMessageProps}>
            {error}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  },
);

export default Textarea;
