import { IconPlus, IconThreeDotsVertical } from '@/theme/icons';
import rem from '@/utils/rem';
import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, SpaceProps } from '@chakra-ui/react';

type Props = {
  onClick: () => void;
} & SpaceProps;

const ThreeDotsLaterButton = ({ onClick, ...menuButtonProps }: Props) => {
  return (
    <Menu isLazy>
      <MenuButton
        as={IconButton}
        width="auto"
        height="auto"
        borderRadius={rem(6)}
        _hover={{ backgroundColor: 'background-secondary' }}
        _active={{
          backgroundColor: 'background-secondary',
        }}
        __css={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
        aria-label="Menu"
        icon={<Icon as={IconThreeDotsVertical} boxSize={rem(14)} margin="0 auto" color="text-primary" />}
        variant="unstyled"
        {...menuButtonProps}
      />
      <MenuList
        zIndex="dropdown"
        width={rem(128)}
        minWidth="0"
        borderWidth={rem(1)}
        borderStyle="solid"
        borderColor="gray.700"
        borderRadius="4"
        backgroundColor="background-primary"
        paddingX={rem(8)}
      >
        <MenuItem
          textStyle="small"
          padding={`${rem(4)} ${rem(8)}`}
          color="text-primary"
          borderRadius="4"
          _hover={{ backgroundColor: 'background-secondary' }}
          backgroundColor="inherit"
          icon={<Icon as={IconPlus} boxSize={rem(14)} color="text-primary" />}
          iconSpacing={rem(8)}
          onClick={onClick}
        >
          Do It Later
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ThreeDotsLaterButton;
