import StyledModal from '@/components/StyledModal';
import { Button, ModalBody, ModalFooter, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export type Entity = {
  id: string;
  name: string;
  slug: string;
  type: 'Action' | 'Block' | 'Project' | 'Category' | 'Person';
};

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  entity: Entity;
};

function CreateEntityModal({ isOpen, onCancel, entity }: Props) {
  const navigate = useNavigate();

  return (
    <StyledModal isOpen={isOpen} onClose={onCancel} size="3xl" title={`${entity.type} Created!`}>
      <ModalBody>
        <Text color="text-primary" fontSize="lg" fontWeight={500}>
          Click view {entity.type} below to view it now.
        </Text>
      </ModalBody>

      <ModalFooter>
        <Button onClick={onCancel} size="lg" variant="secondary">
          Close
        </Button>
        <Button
          onClick={() => {
            onCancel();
            navigate(entity.slug);
          }}
          size="lg"
          variant="primary"
        >
          View {entity.type}
        </Button>
      </ModalFooter>
    </StyledModal>
  );
}

export default CreateEntityModal;
