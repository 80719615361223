import StyledModal from '@/components/StyledModal';
import { PublicLeverageRequest } from '@/gql/publicLeverageRequests/types';
import { useUpdatePublicLeverageRequest } from '@/services/publicLeverageRequests/hooks';
import rem from '@/utils/rem';
import { Button, FormControl, FormLabel, ModalBody, ModalFooter, Textarea } from '@chakra-ui/react';
import { useState } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  personToken: string;
  leverageRequest: Pick<PublicLeverageRequest, 'promiseId' | 'senderName'>;
  variant?: 'complete' | 'decline';
};

function CompleteDeclineLeverageRequestModal({
  isOpen,
  onClose,
  personToken,
  leverageRequest,
  variant = 'complete',
}: Props) {
  const { mutate, isLoading } = useUpdatePublicLeverageRequest();

  const [noteValue, setNoteValue] = useState('');

  const handleClose = () => {
    onClose();
    setNoteValue('');
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={handleClose}
      size="3xl"
      title={`Are you sure you want to ${variant === 'complete' ? 'complete' : 'decline'} this action?`}
    >
      <ModalBody>
        <FormControl>
          <FormLabel
            marginBottom={rem(24)}
            color="text-tertiary"
            fontSize="sm"
            fontWeight={900}
            textTransform="uppercase"
          >
            Leave a note for: {leverageRequest.senderName}
          </FormLabel>
          <Textarea
            width="full"
            height={rem(185)}
            padding={rem(16)}
            fontSize="md"
            fontWeight={500}
            lineHeight="130%"
            borderColor="gray.700"
            borderRadius={rem(4)}
            _focusVisible={{ borderColor: 'cyan.400' }}
            backgroundColor="background-primary"
            onChange={(e) => setNoteValue(e.currentTarget.value)}
            placeholder="Write your note here"
            rows={4}
            value={noteValue}
          />
        </FormControl>
      </ModalBody>

      <ModalFooter justifyContent={{ base: 'center', sm: 'end' }}>
        <Button onClick={handleClose} size="lg" variant="secondary">
          Cancel
        </Button>
        <Button
          background={variant === 'complete' ? 'green.400' : 'red.500'}
          _hover={{ backgroundColor: variant === 'complete' ? 'green.300' : 'red.400' }}
          isLoading={isLoading}
          onClick={() => {
            mutate(
              {
                token: personToken,
                promiseId: leverageRequest.promiseId,
                leverageRequestStatus: variant === 'complete' ? 'completed' : 'declined',
                leverageRequestReplyText: noteValue,
              },
              { onSuccess: handleClose },
            );
          }}
          size="lg"
          variant="primary"
        >
          {variant === 'complete' ? 'Complete' : 'Decline'}
        </Button>
      </ModalFooter>
    </StyledModal>
  );
}

export default CompleteDeclineLeverageRequestModal;
