import ActionListCompletedActions from '@/components/ActionListCompletedActions';
import ActionRow from '@/components/ActionRow';
import AddActionButton from '@/components/AddActionButton';
import BlockDragOverlay from '@/components/BlockDragOverlay';
import Callout from '@/components/Callout';
import CaptureList from '@/components/CaptureList';
import CategoryDragOverlay from '@/components/CategoryDragOverlay';
import CategorySelector from '@/components/CategorySelector';
import ConfirmDeleteBlockModal from '@/components/ConfirmDeleteBlockModal';
import CreateBlockModal from '@/components/CreateBlockModal';
import DeleteBlockModal from '@/components/DeleteBlockModal';
import DndContainer from '@/components/DndContainer';
import { ActionList, DroppableContainer } from '@/components/DragAndDrop';
import ActionListItem from '@/components/DragAndDrop/ActionListItem';
import DnDBlockItem from '@/components/DragAndDrop/DnDBlockItem';
import { DragAndDropCalendarWrapper } from '@/components/DragAndDropCalendar';
import CalendarProvider from '@/components/DragAndDropCalendar/provider';
import DynamicSegmentedButton from '@/components/DynamicSegmentedButton';
import EditBlockModal from '@/components/EditBlockModal';
import EmptyActionList from '@/components/EmptyActionList';
import EmptyCategories from '@/components/EmptyCategories';
import EmptyCategoryCallout from '@/components/EmptyCategoryCallout';
import ScheduleEventModal, { ScheduleEventDateTime } from '@/components/MyPlanStep/ScheduleEventModal';
import Timeline from '@/components/MyPlanStep/Timeline';
import EventItem from '@/components/MyPlanStep/WeeklyCalendar/WeeklyEventItem';
import UncompletedActionsModal from '@/components/UncompletedActionsModal';
import { ALL_ACTIONS, COMPLETE, SCHEDULE_LIST_SEGMENTED_BUTTON_OPTIONS } from '@/constants/action';
import { DAY, WEEK } from '@/constants/calendar';
import getPlannerMotionVariants from '@/constants/plannerMotionVariants';
import useCaptureListData from '@/hooks/useCaptureListData';
import DashboardLayout from '@/layouts/Dashboard';
import { useUpdateAction } from '@/services/action/hooks';
import { addBlockToCache, useBlocks } from '@/services/block/hooks';
import {
  useBlockEvents,
  useCreateBlockEvent,
  useDeleteBlockEvent,
  useUpdateBlockEvent,
} from '@/services/blockEvent/hooks';
import { useCategories } from '@/services/categories/hooks';
import {
  useCategoryEvents,
  useCreateCategoryEvent,
  useDeleteCategoryEvent,
  useUpdateCategoryEvent,
} from '@/services/categoryEvent/hooks';
import {
  addEventToCache,
  completeActionsOfBlock,
  onMutateBlockStatus,
  removeIncompleteActionsFromBlock,
  updateBlockInsideCache,
  useActionsBlocksAndEvents,
  useAddActionToBlock,
  useCompleteBlockStatus,
  useCreateEvent,
  useDeleteBlock,
  useDeleteEventAndUpdateAction,
  useDeleteEventAndUpdateBlockAction,
  useIncompleteBlockStatus,
  useRemoveActionFromBlock,
  useUpdateActionsCategoryOrder,
  useUpdateBlockActionsBlockOrder,
} from '@/services/myPlan/hooks';
import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { useWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { useActionModal } from '@/stores/useActionModal';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import useToggleExpandCalendar, { DailyPlanView } from '@/stores/useToggleExpandCalendar';
import { IconCategories, IconPlus } from '@/theme/icons';
import { Action, isPlannedAction } from '@/types/actions';
import { Block } from '@/types/block';
import { MyEvent } from '@/types/calendar';
import { ActionEventType, CategoryActionType } from '@/types/myPlan';
import {
  deleteAction,
  getActionById,
  getActionsByBlockId,
  getActionsByCategoryId,
  getIncompleteActions,
  getIncompleteActionsByBlockId,
  getTotalDurationOfActions,
  getTotalDurationOfPreferredActions,
} from '@/utils/action';
import { getBlockById } from '@/utils/block';
import {
  endOfTheWeek,
  formatDateToString,
  getDaysWithinDatesRange,
  localDateToUTC,
  startOfTheWeek,
  utcToLocalDate,
} from '@/utils/calendar';
import { findContainerById, getIdx, getItemById } from '@/utils/dnd';
import { isUncategorized } from '@/utils/index';
import {
  ItemType,
  createActionEvent,
  formatActions,
  getEventByActionId,
  getEventById,
  groupActionsByBlockId,
  groupEventsByWeekDay,
} from '@/utils/myplan';
import rem from '@/utils/rem';
import {
  trackActionScheduledByDrag,
  trackBlockTimeScheduled,
  trackBulkActionCompleted,
  trackCategoryTimeScheduled,
} from '@/utils/tracking';
import { useMapBy } from '@/utils/useMapBy';
import { Accordion, Flex, HStack, Icon, Spinner, Text, useDisclosure } from '@chakra-ui/react';
import {
  CollisionDetection,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  UniqueIdentifier,
  closestCenter,
  defaultDropAnimationSideEffects,
  getFirstCollision,
  pointerWithin,
  rectIntersection,
} from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { AnimatePresence, motion } from 'framer-motion';
import { isEmpty, isNull, isUndefined, orderBy } from 'lodash';
import { CSSProperties, Fragment, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { v4 as uuidv4 } from 'uuid';

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

function Schedule() {
  const [selectedSegmented, setSelectedSegmented] = useState(ALL_ACTIONS);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [activeItemCameFrom, setActiveItemCameFrom] = useState('');
  const [tempBlockActionId, setTempBlockActionId] = useState<string | null>(null);
  const [tempCategoryId, setTempCategoryId] = useState<string | null>(null);
  const [tempProjectId, setTempProjectId] = useState<string | null>(null);
  const [selectedBlock, setSelectedBlock] = useState<Block | null>(null);
  const [eventToUnplan, setEventToUnplan] = useState<MyEvent | null>(null);

  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);

  const actionsContainerRef = useRef<HTMLDivElement | null>(null);

  const isDailyPlanPage = useIsDailyPlanPage();
  const { CAPTURE_LIST_VARIANTS } = getPlannerMotionVariants(isDailyPlanPage);

  const { isCalendarExpanded, dailyPlanView } = useToggleExpandCalendar();

  const { formattedBlocks, categories, notEmptyCategories, filterDailyScheduledActions } =
    useCaptureListData(selectedSegmented);

  const {
    isOpen: isOpenCreateBlockModal,
    onOpen: openCreateBlockModal,
    onClose: onCloseCreateBlockModal,
  } = useDisclosure();

  const { isOpen: isOpenEditBlockModal, onOpen: openEditBlockModal, onClose: onCloseEditBlockModal } = useDisclosure();

  const {
    isOpen: isOpenSetUncompletedActionsModal,
    onOpen: openSetUncompletedActionsModal,
    onClose: onCloseSetUncompletedActionsModal,
  } = useDisclosure();

  const {
    isOpen: isOpenDeleteBlockModal,
    onOpen: openDeleteBlockModal,
    onClose: onCloseDeleteBlockModal,
  } = useDisclosure();

  const {
    isOpen: isOpenConfirmDeleteBlockModal,
    onOpen: openConfirmDeleteBlockModal,
    onClose: onCloseConfirmDeleteBlockModal,
  } = useDisclosure();

  const {
    isOpen: isScheduleEventModalOpen,
    onOpen: onOpenScheduleEventModal,
    onClose: onCloseScheduleEventModal,
  } = useDisclosure();

  const { data: categoriesData } = useCategories();
  const categoriesMap = useMapBy(categoriesData?.category ?? [], 'id');

  const { data: blocksData } = useBlocks();
  const blocksMap = useMapBy(blocksData?.block ?? [], 'id');

  const { onGetCurrentActionData: getCurrentActionData, setActionModalOpen } = useActionModal();

  const selectedDate = useCalendarMonthlyStore((state) => state.selectedDate);
  const startDate = selectedDate && startOfTheWeek(selectedDate);
  const endDate = selectedDate && endOfTheWeek(selectedDate);
  const weekDays = getDaysWithinDatesRange(startDate, endDate);

  const { data: weeklyPlanId } = useWeeklyPlanId(selectedDate);
  const { data: categoryEventsData } = useCategoryEvents(startDate, endDate);
  const { data: blockEventsData } = useBlockEvents(startDate, endDate);

  const { data, isLoading } = useActionsBlocksAndEvents();

  const items = useMemo<ItemType>(() => {
    if (!data) {
      return {
        captureList: [],
      };
    }

    let captureList = isDailyPlanPage
      ? filterDailyScheduledActions(formatActions(data.action))
      : formatActions(data.action);

    const categories = categoriesData?.category?.map((category) => {
      const actionsEvents = getActionsByCategoryId(data.action, category.id).filter(isPlannedAction);

      const events: CategoryActionType[] = actionsEvents.map((action) => ({
        event: {
          id: String(action?.event?.id),
          action: { ...action, typeName: 'Action' },
          actionId: action.id,
          typeName: 'Event',
        },
      }));

      return {
        ...category,
        actions: events,
      };
    });

    return {
      ...groupEventsByWeekDay(categories ?? [], weekDays),
      ...groupActionsByBlockId(data.block, data.action),
      captureList,
    };
  }, [data, categoriesData?.category, weekDays, filterDailyScheduledActions, isDailyPlanPage]);

  const updateActionMutation = useUpdateAction();

  const updateCategoryEvent = useUpdateCategoryEvent();

  const updateBlockEvent = useUpdateBlockEvent();

  const addActionToBlock = useAddActionToBlock();

  const removeActionFromBlock = useRemoveActionFromBlock();

  const updateActionsCategoryOrder = useUpdateActionsCategoryOrder();

  const updateBlockActionsBlockOrder = useUpdateBlockActionsBlockOrder();

  const createEvent = useCreateEvent();

  const createCategoryEvent = useCreateCategoryEvent();

  const createBlockEvent = useCreateBlockEvent();

  const deleteEventAndUpdateAction = useDeleteEventAndUpdateAction();

  const deleteEventAndUpdateBlockAction = useDeleteEventAndUpdateBlockAction();

  const deleteBlock = useDeleteBlock();

  const deleteCategoryEvent = useDeleteCategoryEvent();

  const deleteBlockEvent = useDeleteBlockEvent();

  const completeBlockStatus = useCompleteBlockStatus({
    onMutate: ({ id }) => onMutateBlockStatus(id, true),
  });

  const incompleteBlockStatus = useIncompleteBlockStatus({
    onMutate: ({ id }) => onMutateBlockStatus(id),
  });

  /* <Dnd> */
  const collisionDetectionStrategy = useCallback<CollisionDetection>(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter((container) => container.id in items),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);

      let overId = getFirstCollision(intersections, 'id');

      // We have to ensure that overId is not null or undefined
      if (overId != null) {
        if (overId in items && !overId.toString().startsWith('event')) {
          const containerItems = items[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            const tempOverId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId && containerItems.find((item) => item.action.id === container.id),
              ),
            })[0]?.id;

            if (!isUndefined(tempOverId)) {
              overId = tempOverId;
            }
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items],
  );

  const onDragStart = ({ active }: DragStartEvent) => {
    setActiveId(active.id);
    setActiveItemCameFrom(active?.data?.current?.containerId);
  };

  const updateEventDate = useCallback(
    (actionId: string, scheduledDate: string, scheduledTime: string | null) => {
      updateActionMutation.mutate({
        actionId,
        set: {
          scheduledDate,
          scheduledTime,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });
    },
    [updateActionMutation],
  );

  const createEventIfNotExistsAndUpdateAction = useCallback(
    (actionId: string, scheduledDate: string, scheduledTime: string | null = null) => {
      if (!data?.action) {
        return;
      }

      if (!getEventByActionId(data.action, actionId)) {
        createEvent.mutate({
          id: uuidv4(),
          actionId,
          scheduledDate,
          scheduledTime,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });

        return;
      }

      const event = getEventByActionId(data.action, actionId);

      if (!event) {
        return;
      }

      updateEventDate(actionId, scheduledDate, scheduledTime ?? event.action.scheduledTime);
    },
    [data?.action, updateEventDate, createEvent],
  );

  const moveActions = (reorderItems: ActionEventType[]) => {
    return reorderItems.map((action, index) => ({
      ...action,
      action: {
        ...action.action,
        categoryOrder: index + 1,
      },
    }));
  };

  const updateCaptureListActionsOrder = useCallback(
    (actions: ActionEventType[]) => {
      const mutationPayload = actions.map((action, index) => ({
        _set: {
          categoryOrder: index + 1,
        },
        where: {
          id: {
            _eq: action.action.id,
          },
        },
      }));

      updateActionsCategoryOrder.mutate(mutationPayload);
    },
    [updateActionsCategoryOrder],
  );

  const updateBlockActionsOrder = useCallback(
    (actions: Action[]) => {
      const mutationPayload = actions.map((action, index) => ({
        _set: {
          blockOrder: index + 1,
        },
        where: {
          id: {
            _eq: action.id,
          },
        },
      }));

      updateBlockActionsBlockOrder.mutate({
        updates: mutationPayload,
        categoryId: actions[0].categoryId,
        projectId: actions[0].projectId,
      });
    },
    [updateBlockActionsBlockOrder],
  );

  const handleDeleteEventAndUpdateAction = useCallback(
    async (eventId: string, actions: ActionEventType[], blockId: string | null) => {
      const event = getEventById(data.action, eventId);

      if (event == null) {
        return;
      }

      if (!event.action.scheduledDate) {
        return;
      }

      let shouldDeleteBlock = false;

      if (blockId) {
        const block = data.block.find((block) => block.id === blockId);
        const blockActions = block?.actions;

        const blockUpdatedAction = blockActions?.find((action) => action.id === event.actionId);

        if (blockUpdatedAction != null) {
          actions.push(createActionEvent({ ...blockUpdatedAction, typeName: 'Action' }));
        }

        if (blockActions?.length === 1) {
          shouldDeleteBlock = true;
        }
      }

      let categoryOrder = 0;

      if (data.action.length) {
        categoryOrder = data.action.length;
      }

      await deleteEventAndUpdateAction.mutateAsync({
        eventId,
        actionId: event.actionId,
        scheduledDate: isDailyPlanPage
          ? formatDateToString(utcToLocalDate(event.action.scheduledDate, event.action.scheduledTime))
          : null,
        categoryOrder,
      });

      if (shouldDeleteBlock && blockId) {
        deleteBlock.mutate({
          blockId,
        });
      }
    },
    [data.action, data.block, deleteEventAndUpdateAction, deleteBlock, isDailyPlanPage],
  );

  const handleAddActionToBlock = useCallback(
    (actionId: string, block: Block) => {
      if (!data?.block) {
        return;
      }

      const isNewBlock = getBlockById(data.block, block.id) === undefined;

      if (isNewBlock) {
        addBlockToCache(block);
      }

      addActionToBlock.mutate({
        actionId,
        blockId: block.id,
        blockOrder: data?.block?.length ?? 0,
        projectId: block.projectId,
        categoryId: block.categoryId,
      });

      incompleteBlockStatus.mutate({ id: block.id });

      onCloseCreateBlockModal();
      setTempBlockActionId(null);
    },
    [data.block, addActionToBlock, incompleteBlockStatus, onCloseCreateBlockModal],
  );

  const moveActionFromBlockToCaptureList = useCallback(
    (actionId: string, blockId: string, shouldDeleteBlock: boolean) => {
      if (!data) {
        return;
      }

      const block = getBlockById(data.block, blockId);

      if (shouldDeleteBlock && block) {
        setSelectedBlock(block);
        openConfirmDeleteBlockModal();
        return;
      }

      removeActionFromBlock.mutate({
        actionId,
        categoryId: block?.categoryId,
        projectId: block?.projectId,
      });
    },
    [data, openConfirmDeleteBlockModal, removeActionFromBlock],
  );

  const handleDeleteEventAndUpdateBlockAction = useCallback(
    (actions: ActionEventType[], eventId: string, blockId: string) => {
      const event = getEventById(data.action, eventId);

      if (event == null) {
        return;
      }

      deleteEventAndUpdateBlockAction.mutate({
        eventId,
        actionId: event.actionId,
        scheduledDate: isDailyPlanPage ? event.action.scheduledDate : null,
        blockId,
        blockOrder: event.action.blockOrder,
      });
    },
    [data.action, deleteEventAndUpdateBlockAction, isDailyPlanPage],
  );

  const handleDailyActionDropped = useCallback(
    async (dateTime: ScheduleEventDateTime) => {
      if (!activeId) {
        return;
      }

      const actionId = String(activeId);
      const { scheduledDate, scheduledTime } = dateTime;
      const action = getActionById(data.action, actionId);

      if (!action) {
        return;
      }

      if (!action.scheduledDate) {
        await updateActionMutation.mutateAsync({
          actionId,
          set: {
            scheduledDate,
            scheduledTime,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        });
      }

      createEventIfNotExistsAndUpdateAction(actionId, scheduledDate, scheduledTime);
      trackActionScheduledByDrag({ ...action, scheduledDate });
      onCloseScheduleEventModal();
    },
    [activeId, createEventIfNotExistsAndUpdateAction, data.action, onCloseScheduleEventModal, updateActionMutation],
  );

  const onDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      const activeContainer = findContainerById(active.id, items);

      if (!activeContainer || active.id == null) {
        setActiveId(null);
        setActiveItemCameFrom('');
        return;
      }

      let overId = over?.id;
      if (over?.data?.current?.containerId === 'blocks') {
        // special case when an item is dropped over a block but not over the list of actions
        overId = `block-${overId}`;
      }

      if (overId == null) {
        setActiveId(null);
        setActiveItemCameFrom('');
        return;
      }

      if (overId === 'timeline') {
        setActiveId(active.id);
        onOpenScheduleEventModal();
        return;
      }

      const overContainer = findContainerById(overId, items);

      if (overContainer) {
        let activeIndex = items[activeContainer].findIndex(({ id }) => id === active?.id);
        let overIndex = items[overContainer].findIndex(({ id }) => id === over?.id);

        const overContainerId = over?.data?.current?.containerId;

        if (
          active?.data?.current?.category?.id !== over?.data?.current?.category?.id &&
          over?.data?.current?.category?.id !== 'captureListContainer'
        ) {
          return;
        }

        const activeProjectId = active?.data?.current?.projectId;
        const overProjectId = over?.data?.current?.projectId;

        if (
          overContainerId.toString().startsWith('block-') &&
          ![null, undefined].includes(activeProjectId, overProjectId) &&
          activeProjectId !== overProjectId
        ) {
          return;
        }

        if (activeItemCameFrom === 'captureList' && overContainerId.startsWith('event')) {
          createEventIfNotExistsAndUpdateAction(active.id.toString(), over?.data?.current?.day);
        }

        if (activeItemCameFrom.startsWith('event') && overContainerId.startsWith('event')) {
          const event = getEventById(data.action, active.id.toString());

          if (!event) {
            return;
          }

          if (over?.data?.current?.day) {
            updateEventDate(event.actionId, over.data.current.day, null);
          }
        }

        if (activeItemCameFrom === 'captureList' && overContainerId === 'captureList') {
          updateCaptureListActionsOrder(moveActions(arrayMove(items[overContainer], activeIndex, overIndex)));
        }

        if (activeItemCameFrom.startsWith('event') && overContainerId === 'captureList') {
          let blockId = getEventById(data.action, active.id.toString())?.action?.blockId ?? null;

          let actions: ActionEventType[] = [];

          if (items[overContainer].length) {
            actions = moveActions(arrayMove(items[overContainer], activeIndex, overIndex));
          }

          handleDeleteEventAndUpdateAction(active.id.toString(), actions, blockId);
        }

        if (activeItemCameFrom.startsWith('block') && overContainerId.startsWith('event')) {
          createEventIfNotExistsAndUpdateAction(active.id.toString(), over?.data?.current?.day);
        }

        if (activeItemCameFrom === 'captureList' && overContainerId.toString().startsWith('block')) {
          const block = getBlockById(data?.block ?? [], over?.data?.current?.blockId);

          if (block) {
            handleAddActionToBlock(active.id.toString(), block);
          }
        }

        if (activeItemCameFrom.startsWith('block') && overContainerId === 'captureList') {
          moveActionFromBlockToCaptureList(
            active.id.toString(),
            active?.data?.current?.blockId,
            items[activeContainer].length === 1,
          );
        }

        if (
          activeContainer.toString().startsWith('block') &&
          overContainerId.startsWith('block') &&
          activeContainer.toString() !== overContainerId
        ) {
          const block = getBlockById(data?.block ?? [], over?.data?.current?.blockId);

          if (block) {
            handleAddActionToBlock(active.id.toString(), block);
          }
        }

        if (
          activeContainer.toString().startsWith('block') &&
          overContainerId.startsWith('block') &&
          activeContainer.toString() === overContainerId
        ) {
          const blockIncompleteActions = orderBy(
            getIncompleteActionsByBlockId(active?.data?.current?.blockId, data.action),
            'blockOrder',
            'asc',
          );

          activeIndex = blockIncompleteActions.findIndex(({ id }) => id === active?.id);
          overIndex = blockIncompleteActions.findIndex(({ id }) => id === over?.id);

          updateBlockActionsOrder(arrayMove(blockIncompleteActions, activeIndex, overIndex));
        }

        if (activeItemCameFrom.startsWith('event') && overContainerId.startsWith('block')) {
          let actions: ActionEventType[] = [];

          if (items[overContainer].length) {
            actions = moveActions(arrayMove(items[overContainer], activeIndex, overIndex));
          }

          handleDeleteEventAndUpdateBlockAction(actions, active.id.toString(), over?.data?.current?.blockId);
        }
      }

      setActiveId(null);
      setActiveItemCameFrom('');
    },
    [
      items,
      activeItemCameFrom,
      createEventIfNotExistsAndUpdateAction,
      data.action,
      data?.block,
      updateEventDate,
      updateCaptureListActionsOrder,
      handleDeleteEventAndUpdateAction,
      handleAddActionToBlock,
      moveActionFromBlockToCaptureList,
      updateBlockActionsOrder,
      handleDeleteEventAndUpdateBlockAction,
      onOpenScheduleEventModal,
    ],
  );

  const handleTimeChange = useCallback(
    (event: MyEvent, time: Date) => {
      const { scheduledDate, scheduledTime } = localDateToUTC(time);

      if (event.__metadata?.action) {
        updateActionMutation.mutate({
          actionId: event.__metadata?.action.id,
          set: {
            scheduledDate,
            scheduledTime,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        });
      } else if (event.__metadata?.categoryEvent) {
        updateCategoryEvent.mutate({
          id: event.__metadata?.categoryEvent.id,
          scheduledDate,
          scheduledTime,
          duration: event.__metadata?.categoryEvent.duration,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
      } else if (event.__metadata?.blockEvent) {
        updateBlockEvent.mutate({
          id: event.__metadata?.blockEvent.id,
          scheduledDate,
          scheduledTime,
          duration: event.__metadata?.blockEvent.duration,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
      }
    },
    [updateActionMutation, updateCategoryEvent, updateBlockEvent],
  );

  const handleRemoveActionFromBlock = useCallback(
    (actionId: string, blockId: string) => {
      const key = `block-${blockId}`;

      moveActionFromBlockToCaptureList(actionId, blockId, items[key].length === 1);
    },
    [items, moveActionFromBlockToCaptureList],
  );

  const handleClickEvent = useCallback(
    (event: MyEvent) => {
      const action = event.__metadata?.action;
      if (!action) return;

      if (action?.blockId) {
        const block = getBlockById(formattedBlocks, action.blockId);

        action.block = block ?? null;
        action.isLastBlockAction = block?.actions?.length === 1;
      }

      getCurrentActionData(action);
      setActionModalOpen(true, {
        onDeleteAction: deleteAction,
        onCreateEvent: addEventToCache,
        readonlyCategory: true,
        readonlyTooltipLabel: 'The category of this action cannot be changed on the planner',
      });
    },
    [formattedBlocks, getCurrentActionData, setActionModalOpen],
  );

  const onDragCancel = () => {
    setActiveId(null);
    setActiveItemCameFrom('');
  };

  const ItemOverlay = useMemo(() => {
    if (!activeId) {
      return <></>;
    }

    let overlayElement: ReactNode = null;
    let dragOverlayStyle: CSSProperties | undefined;

    const draggedBlock = data.block?.find((b) => b.id === String(activeId));
    const draggedCategory = categories?.find((c) => c.id === String(activeId));

    if (draggedBlock) {
      const blockActions = getActionsByBlockId(draggedBlock.id, data.action);
      overlayElement = <BlockDragOverlay block={{ ...draggedBlock, actions: blockActions }} />;
      dragOverlayStyle = { maxWidth: rem(350) };
    } else if (draggedCategory) {
      overlayElement = <CategoryDragOverlay category={draggedCategory} />;
      dragOverlayStyle = { width: 'unset' };
    } else {
      const item = getItemById(activeId, items);

      if (!item) {
        return <></>;
      }

      const index = getIdx(activeId, items);

      overlayElement = (
        <>
          {item?.typeName?.toLowerCase() === 'event' ? (
            <EventItem event={item} onUnplan={() => null} onClickEvent={() => null} />
          ) : (
            <ActionRow action={item.action} idx={index + 1} />
          )}
        </>
      );
    }

    return createPortal(
      <DragOverlay dropAnimation={dropAnimation} style={dragOverlayStyle}>
        {overlayElement}
      </DragOverlay>,
      document.body,
    );
  }, [activeId, categories, items, data.action, data.block]);

  /* </Dnd> */

  const onUpdateBlock = useCallback(
    (updatedBlock: Block) => {
      updateBlockInsideCache(updatedBlock);

      onCloseEditBlockModal();
      setSelectedBlock(null);
    },
    [onCloseEditBlockModal],
  );

  const handleDeleteBlock = useCallback(
    (block: Block | undefined) => {
      if (!block) {
        return;
      }

      setSelectedBlock(block);
      openDeleteBlockModal();
    },
    [openDeleteBlockModal],
  );

  const onCompleteBlockActions = useCallback(() => {
    if (!selectedBlock) {
      return;
    }

    if (selectedBlock.actions) {
      const incompleteActions = getIncompleteActions(selectedBlock.actions);
      incompleteActions.forEach((action) => trackBulkActionCompleted(action));
    }

    completeActionsOfBlock(selectedBlock.id, selectedBlock.categoryId);

    setSelectedBlock(null);
  }, [selectedBlock]);

  const onCompleteBlock = useCallback(
    (blockId: string) => {
      completeBlockStatus.mutate({
        id: blockId,
      });
    },
    [completeBlockStatus],
  );

  const onRemoveBlockActions = useCallback(() => {
    if (!selectedBlock) {
      return;
    }

    removeIncompleteActionsFromBlock(selectedBlock);
    onCompleteBlock(selectedBlock.id);

    setSelectedBlock(null);
  }, [onCompleteBlock, selectedBlock]);

  const onUncompleteBlock = useCallback(
    (blockId: string) => {
      if (!data) {
        return;
      }

      const block = getBlockById(data.block, blockId);

      if (!block) {
        return;
      }

      incompleteBlockStatus.mutate({
        id: blockId,
      });
    },
    [data, incompleteBlockStatus],
  );

  const unplanEvent = useCallback(
    (event: MyEvent) => {
      const action = event.__metadata?.action;
      if (action?.event?.id) {
        if (action.blockId) {
          handleDeleteEventAndUpdateBlockAction(items[action.blockId], action.event.id, action.blockId);
          return;
        }

        handleDeleteEventAndUpdateAction(action.event.id, items.captureList, null);
      }

      const categoryEvent = event.__metadata?.categoryEvent;
      if (categoryEvent?.id) {
        if (!categoryEventsData?.categoryEvent.find((ce) => ce.id === categoryEvent.id)) {
          // The event was already deleted
          return;
        }
        // TODO figure out why this setTimeout is here or remove if no issues are reported
        // setTimeout(() => deleteCategoryEvent.mutate({ id: categoryEvent.id }), 0);
        deleteCategoryEvent.mutate({ id: categoryEvent.id });
      }

      const blockEvent = event.__metadata?.blockEvent;
      if (blockEvent?.id) {
        if (!blockEventsData?.blockEvent.find((be) => be.id === blockEvent.id)) {
          // The event was already deleted
          return;
        }
        // TODO figure out why this setTimeout is here or remove if no issues are reported
        // setTimeout(() => deleteCategoryEvent.mutate({ id: categoryEvent.id }), 0);
        deleteBlockEvent.mutate({ id: blockEvent.id });
      }

      setEventToUnplan(null);
    },
    [
      categoryEventsData?.categoryEvent,
      blockEventsData?.blockEvent,
      deleteCategoryEvent,
      deleteBlockEvent,
      handleDeleteEventAndUpdateAction,
      handleDeleteEventAndUpdateBlockAction,
      items,
    ],
  );

  const onDeleteBlock = useCallback(() => {
    if (!selectedBlock) {
      return;
    }

    deleteBlock.mutate({ blockId: selectedBlock.id });

    onCloseDeleteBlockModal();
    onCloseConfirmDeleteBlockModal();
    setSelectedBlock(null);
    onCloseEditBlockModal();
  }, [deleteBlock, onCloseConfirmDeleteBlockModal, onCloseDeleteBlockModal, onCloseEditBlockModal, selectedBlock]);

  const handleDurationChange = useCallback(
    (event: MyEvent, start: Date, duration: string) => {
      const { scheduledDate, scheduledTime } = localDateToUTC(start);
      if (event.__metadata?.action) {
        updateActionMutation.mutate({
          actionId: event.__metadata?.action.id,
          set: {
            scheduledDate,
            scheduledTime,
            duration,
          },
        });
      } else if (event.__metadata?.categoryEvent) {
        updateCategoryEvent.mutate({
          id: event.__metadata?.categoryEvent.id,
          scheduledDate,
          scheduledTime,
          duration,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
      } else if (event.__metadata?.blockEvent) {
        updateBlockEvent.mutate({
          id: event.__metadata?.blockEvent.id,
          scheduledDate,
          scheduledTime,
          duration,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
      }
    },
    [updateActionMutation, updateCategoryEvent, updateBlockEvent],
  );

  const handleEntityDropped = useCallback(
    async (entityId: string, date: Date) => {
      // Dropping an action into daily view. Set the action info and create event
      const { scheduledDate, scheduledTime } = localDateToUTC(date);

      const category = categoriesMap.get(entityId);
      if (category) {
        createCategoryEvent.mutate({
          id: uuidv4(),
          categoryId: entityId,
          scheduledDate,
          scheduledTime,
          duration: '01:00:00',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });

        trackCategoryTimeScheduled(isDailyPlanPage ? DAY : WEEK);

        return;
      }

      const block = blocksMap.get(entityId);
      if (block) {
        createBlockEvent.mutate({
          id: uuidv4(),
          blockId: entityId,
          scheduledDate,
          scheduledTime,
          duration: '01:00:00',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });

        trackBlockTimeScheduled(isDailyPlanPage ? DAY : WEEK);

        return;
      }

      const action = getActionById(data.action, entityId);

      if (!action) {
        return;
      }

      if (!action.scheduledDate) {
        await updateActionMutation.mutateAsync({
          actionId: entityId,
          set: {
            scheduledDate,
            scheduledTime,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        });
      }

      // Check if the action already has an associated event.
      createEventIfNotExistsAndUpdateAction(entityId, scheduledDate, scheduledTime);
      trackActionScheduledByDrag({ ...action, scheduledDate });
    },
    [
      categoriesMap,
      createCategoryEvent,
      createEventIfNotExistsAndUpdateAction,
      data.action,
      updateActionMutation,
      isDailyPlanPage,
      blocksMap,
      createBlockEvent,
    ],
  );

  const onCreateBlockFromAction = useCallback(
    (actionId: string) => {
      setTempBlockActionId(actionId);
      const tempAction = getActionById(data.action, actionId);
      setTempCategoryId(tempAction?.categoryId ?? null);
      setTempProjectId(tempAction?.projectId ?? null);
      openCreateBlockModal();
    },
    [openCreateBlockModal, data.action],
  );

  const onCloseAndResetCreateBlockModal = useCallback(() => {
    setTempBlockActionId(null);
    setTempCategoryId(null);
    setTempProjectId(null);
    onCloseCreateBlockModal();
  }, [onCloseCreateBlockModal]);

  const captureListAnimate = useMemo(() => {
    if (isCalendarExpanded) {
      return 'plannerExpanded';
    }

    return 'default';
  }, [isCalendarExpanded]);

  // I tried to remove this effect, but without it the unplan of actions
  // sometimes doesn't work, because the getEventById used inside the unplan
  // function doesn't find the action provided. Was unable to find the cause in
  // the time I had available.
  // TODO: find a way to remove this effect
  useEffect(() => {
    if (eventToUnplan === null) {
      return;
    }

    unplanEvent(eventToUnplan);

    return () => setEventToUnplan(null);
  }, [eventToUnplan, unplanEvent]);

  if (isLoading) {
    return (
      <Flex justifyContent="center" marginTop={rem(80)}>
        <Spinner size="xl" speed="0.65s" thickness={rem(4)} />
      </Flex>
    );
  }

  return (
    <DashboardLayout
      pageTitle="Daily Plan"
      editActionModalTooltip="The category of this action cannot be changed because it is included in a block"
    >
      <Flex height={`calc(100vh - 9rem)`} transition="right 0.8s">
        <CategorySelector />
        <DndContainer
          collisionDetection={collisionDetectionStrategy}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragCancel={onDragCancel}
        >
          <AnimatePresence initial={false}>
            <motion.div
              variants={CAPTURE_LIST_VARIANTS}
              animate={captureListAnimate}
              exit="normalWidth"
              transition={{ duration: 0.5 }}
              style={{ height: 'inherit' }}
            >
              <Flex
                ref={actionsContainerRef}
                flexDirection="column"
                overflowY="auto"
                height="inherit"
                marginRight={rem(8)}
                paddingRight={rem(16)}
              >
                <DroppableContainer
                  id="captureList"
                  payload={{
                    containerId: 'captureList',
                    category: { id: 'captureListContainer' },
                  }}
                  height="full"
                >
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap="nowrap"
                    flexDirection="row"
                    marginBottom={rem(24)}
                  >
                    <Text as="h2" textStyle="largeBlack">
                      Capture List
                    </Text>
                    <DynamicSegmentedButton
                      onChange={setSelectedSegmented}
                      options={SCHEDULE_LIST_SEGMENTED_BUTTON_OPTIONS}
                      layoutId="capture-list-plan"
                    />
                  </Flex>
                  {notEmptyCategories.map((category) => (
                    <Fragment key={category.id}>
                      <CaptureList
                        className={`${category.id}-completed-actions`}
                        category={category}
                        totalDurationOfIncompleteActions={getTotalDurationOfActions(category.incompleteActions)}
                        totalDurationOfPreferredIncompleteActions={getTotalDurationOfPreferredActions(
                          category.incompleteActions,
                        )}
                        marginBottom={rem(16)}
                        draggableCategory={dailyPlanView === DailyPlanView.Expand}
                      >
                        <ActionList
                          emptyListComponent={
                            <DroppableContainer
                              id="captureList"
                              payload={{
                                containerId: 'captureList',
                                category,
                              }}
                            >
                              <EmptyActionList
                                title={`All actions in this category have been ${
                                  selectedSegmented !== ALL_ACTIONS && !isEmpty(category.scheduledActions)
                                    ? 'scheduled'
                                    : 'completed'
                                }`}
                                motivational="small"
                              />
                            </DroppableContainer>
                          }
                          isEmpty={isEmpty(category.incompleteActions)}
                        >
                          <SortableContext items={category.incompleteActions}>
                            {category.incompleteActions.map((action, index) => (
                              <motion.div
                                key={action.id}
                                initial="hidden"
                                whileInView="visible"
                                transition={{ duration: 0.5 }}
                                viewport={{ root: actionsContainerRef }}
                                variants={{
                                  visible: { opacity: 1 },
                                  hidden: { opacity: 0 },
                                }}
                              >
                                <ActionListItem
                                  item={action}
                                  index={index}
                                  onClickCreateBlock={onCreateBlockFromAction}
                                  dndPayload={{
                                    containerId: 'captureList',
                                    categoryId: action.categoryId,
                                    category,
                                    projectId: action?.projectId,
                                  }}
                                />
                              </motion.div>
                            ))}
                          </SortableContext>
                        </ActionList>
                        <HStack justifyContent="flex-end" width="100%">
                          <AddActionButton
                            marginTop={rem(8)}
                            backgroundColor={category?.color}
                            withActionBlockCreationMenu={true}
                            onExternalClick={() => {
                              setActionModalOpen(true, { categoryId: category?.id, location: 'schedule' });
                            }}
                            onCreateNewBlock={() => {
                              setTempCategoryId(category.id);
                              openCreateBlockModal();
                            }}
                            color="text-primary"
                            textColor="text-primary"
                            iconSize={rem(10)}
                            minWidth={rem(24)}
                            width={rem(24)}
                            height={rem(24)}
                          />
                        </HStack>
                        <ActionListCompletedActions
                          className={`${category.id}-completed-actions`}
                          actions={category.completedActions}
                          onClickCreateBlock={onCreateBlockFromAction}
                          onDeleteAction={deleteAction}
                        />
                      </CaptureList>
                      {category.blocks.map((block, index) => (
                        <DroppableContainer
                          style={{ marginBottom: rem(16) }}
                          id={block.key}
                          key={block.id}
                          payload={{
                            containerId: block.key,
                            blockId: block.id,
                            category: block.category,
                            projectId: block?.projectId,
                          }}
                        >
                          <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ root: actionsContainerRef }}
                            transition={{ duration: 0.5 }}
                            variants={{
                              visible: { opacity: 1 },
                              hidden: { opacity: 0 },
                            }}
                          >
                            <DnDBlockItem
                              key={block.id}
                              actions={block?.actions ?? []}
                              block={block}
                              index={index}
                              weeklyPlanId={weeklyPlanId}
                              onCompleteBlock={onCompleteBlock}
                              onSelectBlock={() => setSelectedBlock(block)}
                              openSetUncompletedActionsModal={openSetUncompletedActionsModal}
                              onUncompleteBlock={onUncompleteBlock}
                              onClickRemoveFromBlock={handleRemoveActionFromBlock}
                              onDeleteBlock={handleDeleteBlock}
                              openEditBlockModal={openEditBlockModal}
                              draggable={dailyPlanView === DailyPlanView.Expand}
                            />
                          </motion.div>
                        </DroppableContainer>
                      ))}
                    </Fragment>
                  ))}
                  {notEmptyCategories.length === 0 && <EmptyCategoryCallout />}
                  {categories &&
                    categories.length === 1 &&
                    isUncategorized(categories[0]) &&
                    categories[0].blocks.length === 0 && (
                      <Callout title="How can I get started?" backgroundColor="background-tertiary">
                        <Text textStyle="small">
                          A category serves as a cohesive grouping mechanism for actions, each with its distinct name,
                          intended purpose, and expected outcome. To incorporate an action in this empty category, click
                          the + Add Action button located at the bottom of the category.
                        </Text>
                        <Text
                          sx={{
                            'span:not(.block)': {
                              opacity: '0.5',
                            },
                            '.block': {
                              display: 'inline-flex',
                              gap: rem(4),
                              verticalAlign: 'top',
                              marginRight: rem(8),
                            },
                          }}
                          textStyle="small"
                          marginTop={rem(14)}
                          color="text-secondary"
                        >
                          <span>To create or edit a category, click the</span>
                          <Icon as={IconPlus} boxSize={rem(14)} verticalAlign="top" marginX={rem(4)} />
                          <span style={{ marginRight: rem(8) }}>category filter, or click</span>
                          <span className="block">
                            <Icon as={IconCategories} boxSize={rem(14)} /> Categories
                          </span>
                          <span>in the header.</span>
                        </Text>
                      </Callout>
                    )}
                  <Accordion allowMultiple defaultIndex={[-1]}>
                    <EmptyCategories
                      viewportRef={actionsContainerRef}
                      openCreateBlockModal={openCreateBlockModal}
                      setActionModalOpen={setActionModalOpen}
                      setTempCategoryId={setTempCategoryId}
                    />
                  </Accordion>
                </DroppableContainer>
                {ItemOverlay}
              </Flex>
            </motion.div>
          </AnimatePresence>

          {dailyPlanView === DailyPlanView.Condense ? (
            <CalendarProvider date={selectedDate} view="day">
              <DroppableContainer id="timeline" height="full" payload={{ containerId: 'timeline' }}>
                <Timeline onClickEvent={handleClickEvent} onUnplan={setEventToUnplan} />
              </DroppableContainer>
            </CalendarProvider>
          ) : (
            <DragAndDropCalendarWrapper
              date={selectedDate}
              height="inherit"
              view={isDailyPlanPage ? DAY : WEEK}
              onEntityDropped={handleEntityDropped}
              onTimeChange={handleTimeChange}
              onDurationChange={handleDurationChange}
              onUnplan={setEventToUnplan}
              onClickEvent={handleClickEvent}
            />
          )}
        </DndContainer>
      </Flex>

      {isOpenEditBlockModal && !isNull(selectedBlock) && (
        <EditBlockModal
          isOpen
          block={selectedBlock}
          weeklyPlanId={weeklyPlanId}
          onUpdateBlock={onUpdateBlock}
          onDeleteBlock={handleDeleteBlock}
          onCancel={onCloseEditBlockModal}
        />
      )}

      {isOpenSetUncompletedActionsModal && selectedBlock && (
        <UncompletedActionsModal
          blockId={selectedBlock.id}
          completedActions={selectedBlock?.actions?.filter((action) => action.progressStatus === COMPLETE) ?? []}
          isOpen={isOpenSetUncompletedActionsModal}
          onClose={onCloseSetUncompletedActionsModal}
          onDeleteBlock={({ id }) => deleteBlock.mutate({ blockId: id })}
          onCompleteBlockActions={onCompleteBlockActions}
          onRemoveBlockActions={onRemoveBlockActions}
          onCompleteBlock={onCompleteBlock}
        />
      )}
      {isOpenDeleteBlockModal && (
        <DeleteBlockModal isOpen={isOpenDeleteBlockModal} onClose={onCloseDeleteBlockModal} onSubmit={onDeleteBlock} />
      )}
      {isOpenConfirmDeleteBlockModal && (
        <ConfirmDeleteBlockModal
          isOpen={isOpenConfirmDeleteBlockModal}
          onClose={onCloseConfirmDeleteBlockModal}
          onSubmit={onDeleteBlock}
        />
      )}
      {isOpenCreateBlockModal && data && (
        <CreateBlockModal
          isOpen={isOpenCreateBlockModal}
          onClose={onCloseAndResetCreateBlockModal}
          scheduledDate={selectedDate}
          actionId={tempBlockActionId ?? ''}
          categoryId={tempCategoryId ?? ''}
          projectId={tempProjectId ?? ''}
        />
      )}
      {isScheduleEventModalOpen && (
        <ScheduleEventModal isOpen onSave={handleDailyActionDropped} onClose={onCloseScheduleEventModal} />
      )}
    </DashboardLayout>
  );
}

export default Schedule;
