import { WEEK } from '@/constants/calendar';
import rem from '@/utils/rem';
import { Popover, PopoverBody, PopoverContent, PopoverTrigger, useBreakpointValue } from '@chakra-ui/react';

import DatePickerPlaceholder, { DateTimePickerPlaceholderProps } from './DateTimePickerPlaceholder';
import RPMDateTimePicker, { DateTimePickerProps } from './RPMDateTimePicker';

type Props = DateTimePickerPlaceholderProps & Omit<DateTimePickerProps, 'selectedDate'>;

const RPMDateTimePickerWithPlaceholder = ({
  placeholder,
  isDatePickerOpen,
  scheduledDate,
  scheduledTime,
  tabIndex,
  onDatePickerOpen,
  onCancel,
  onClickUnplan,
  dateRange,
  onChangeTabs,
  onSave,
  updateScheduledTime,
  updateSelectedDate,
  withTimePicker = false,
  datePickerProps,
  border,
  selectedTimezone,
  setSelectedTimezone,
}: Props) => {
  const isMobileScreen = useBreakpointValue({ base: true, lg: false });

  return (
    <Popover
      isLazy
      isOpen={isDatePickerOpen}
      offset={isMobileScreen ? undefined : [15, -180]}
      onClose={onCancel}
      placement={isMobileScreen ? 'auto' : 'start'}
    >
      <PopoverTrigger>
        <DatePickerPlaceholder
          placeholder={placeholder}
          isDatePickerOpen={isDatePickerOpen}
          scheduledDate={scheduledDate}
          scheduledTime={scheduledTime}
          tabIndex={tabIndex}
          onDatePickerOpen={onDatePickerOpen}
          onClickUnplan={onClickUnplan}
          border={border}
          selectedTimezone={selectedTimezone}
        />
      </PopoverTrigger>

      <PopoverContent
        width={{ lg: rem(416) }}
        padding={{ base: rem(8), lg: rem(16) }}
        border={`${rem(1)} solid`}
        borderColor="stroke-primary"
        borderRadius={rem(4)}
        backgroundColor="background-primary"
      >
        <PopoverBody className={tabIndex === WEEK ? 'week-style' : 'day-style'} padding={0}>
          <RPMDateTimePicker
            dateRange={dateRange}
            onChangeTabs={onChangeTabs}
            onSave={onSave}
            onCancel={onCancel}
            scheduledTime={scheduledTime}
            selectedDate={scheduledDate ?? new Date()}
            tabIndex={tabIndex}
            updateScheduledTime={updateScheduledTime}
            updateSelectedDate={updateSelectedDate}
            withTimePicker={withTimePicker}
            datePickerProps={datePickerProps}
            setSelectedTimezone={setSelectedTimezone}
            selectedTimezone={selectedTimezone}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default RPMDateTimePickerWithPlaceholder;
