import { IconClock } from '@/theme/icons';
import rem from '@/utils/rem';
import { HStack, Icon } from '@chakra-ui/react';
import { memo, useCallback } from 'react';

import DurationSegment from './DurationSegment';

const count = 12; // this is to have an array from 0 to 55

const hOptions = [...Array(24).keys()].map((value) => String(value));
const mOptions = Array.from({ length: count }, (_, i) => (i * 5).toString().padStart(2, '0'));

type Props = {
  onChange: (duration: string) => void;
  testId?: string;
  value?: string | null;
  showClockIcon?: boolean;
  variant?: 'default' | 'bordered';
};

const InputDuration = ({
  onChange,
  testId = 'duration-input',
  value,
  showClockIcon = true,
  variant = 'default',
}: Props) => {
  const [hr, min] = (value || '00:00').split(':');

  const handleHrChange = useCallback((val: string) => onChange(`${val}:${min}`), [min, onChange]);
  const handleMinChange = useCallback((val: string) => onChange(`${hr}:${val}`), [hr, onChange]);

  return (
    <HStack
      gap={0}
      overflow="hidden"
      paddingLeft={rem(6)}
      background="background-primary"
      border={variant === 'bordered' ? `${rem(1)} solid var(--chakra-colors-stroke-primary)` : undefined}
      borderRadius={rem(4)}
    >
      {showClockIcon && <Icon as={IconClock} boxSize={rem(14)} marginRight={rem(5)} />}

      <DurationSegment
        leadingZero={false}
        max={23}
        options={hOptions}
        onChange={handleHrChange}
        style={{
          width: rem(32),
        }}
        testId={`${testId}-hours`}
        unit="h"
        value={hr}
      />
      <DurationSegment
        leadingZero={true}
        max={59}
        options={mOptions}
        onChange={handleMinChange}
        style={{
          width: rem(45),
        }}
        testId={`${testId}-minutes`}
        unit="m"
        value={min}
      />
    </HStack>
  );
};

export default memo(InputDuration);
