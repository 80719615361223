import {
  CREATE_PROMISES,
  DELETE_ACTION,
  DELETE_PROMISES_BY_ACTION,
  GET_ACTIONS,
  GET_LEVERAGED_AND_COMMITTED_ACTIONS,
  INSERT_ACTION_ONE,
  REPLACE_PROMISES,
  SEND_LEVERAGE_REQUEST_EMAIL,
  UPDATE_ACTIONS,
  UPDATE_ACTION_BY_PK,
  UPDATE_ACTION_STATUS,
  UPDATE_PROMISE,
  UPDATE_PROMISE_REMOVE_LEVERAGE,
  UPDATE_PROMISE_WITH_CREATE_LEVERAGE,
} from '@/gql/actions';
import {
  CreateActionResponse,
  CreatePromisesPayload,
  CreatePromisesResponse,
  DeleteActionResponse,
  DeletePromisesByActionPayload,
  DeletePromisesResponse,
  GetLeveragedAndCommittedActionsResponse,
  QuickCreateActionPayload,
  ReplacePromisesPayload,
  ReplacePromisesResponse,
  SendLeverageRequestEmailPayload,
  SendLeverageRequestEmailResponse,
  UpdateActionsResponse,
  UpdatePromisePayload,
  UpdatePromiseRemoveLeverageRequestPayload,
  UpdatePromiseRemoveLevereageRequestResponse,
  UpdatePromiseResponse,
  UpdatePromiseWithLeverageRequestPayload,
  UpdatePromiseWithLevereageRequestResponse,
} from '@/gql/actions/types';
import { keys } from '@/gql/global/keys';
import { APIError } from '@/gql/global/types';
import useUpdateBlockAction from '@/hooks/useBlockActions';
import { useCategories } from '@/services/categories/hooks';
import { useCronofySyncEvent } from '@/services/cronofy/hooks';
import { fetchData } from '@/services/graphql';
import { queryClient } from '@/services/graphql/queryClient';
import { useWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { addCategoryToActions } from '@/services/project/hooks';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import {
  ActionDeletePayload,
  ActionResponse,
  ActionStatusDeletePayload,
  ActionStatusDeleteResponse,
  ActionUpdate,
  UpdateActionPayload,
  UpdateActionResponse,
} from '@/types/actions';
import { updateAction } from '@/utils/action';
import { endOfTheWeek, formatDateToString, startOfTheWeek } from '@/utils/calendar';
import {
  addPromiseToCache,
  updatePromiseWithLeverageToLeverageCommittedCache,
  updatePromiseWithLeverageToPersonCache,
  updatePromiseWithLeverageToProjectDetailCache,
  updatePromiseWithLevereageToActionCache,
} from '@/utils/promises';
import { useToast } from '@chakra-ui/react';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

export const useActions = (
  options?: UseQueryOptions<ActionResponse, APIError, ActionResponse>,
  selectedDate = useCalendarMonthlyStore.getState().selectedDate,
) => {
  const { data: weeklyPlanId } = useWeeklyPlanId(selectedDate);
  const { data: categoriesList, status } = useCategories();

  return useQuery<ActionResponse, APIError, ActionResponse>({
    ...keys.actions.all._ctx.week(weeklyPlanId ?? ''),
    queryFn: async () => {
      const response = await fetchData(GET_ACTIONS, {
        where: {
          // weeklyPlanId: {
          //   _eq: weeklyPlanId,
          // },
          weekly_plan: {
            dateFrom: {
              _eq: formatDateToString(startOfTheWeek(selectedDate)),
            },
            dateTo: {
              _eq: formatDateToString(endOfTheWeek(selectedDate)),
            },
          },
        },
      });

      return {
        action: addCategoryToActions(response.action, categoriesList?.category ?? []),
      };
    },
    ...options,
    enabled: !!weeklyPlanId && status === 'success',
  });
};

export const useUpdateAction = (options?: UseMutationOptions<UpdateActionResponse, APIError, UpdateActionPayload>) => {
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();
  const updateBlockAction = useUpdateBlockAction();

  return useMutation({
    mutationFn: (payload: UpdateActionPayload) => fetchData<UpdateActionResponse>(UPDATE_ACTION_BY_PK, payload),
    ...options,
    onSuccess: (data, variables, context) => {
      cronofySyncEvent({ eventId: data.updateActionByPk.id, eventType: 'Action' });

      if (data.updateActionByPk.blockId) {
        updateBlockAction(data.updateActionByPk);
      } else {
        updateAction(data.updateActionByPk);
      }

      options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useUpdateActions = (options?: UseMutationOptions<UpdateActionsResponse, APIError, ActionUpdate[]>) => {
  const toast = useToast();
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();

  return useMutation({
    mutationFn: (actionUpdates: ActionUpdate[]) => fetchData<UpdateActionsResponse>(UPDATE_ACTIONS, { actionUpdates }),

    ...options,

    onSuccess: (data, variables, context) => {
      data.updateActionMany.forEach((updateResult) =>
        updateResult.returning.forEach((action) => cronofySyncEvent({ eventId: action.id, eventType: 'Action' })),
      );

      if (!Array.isArray(data?.updateActionMany)) {
        return;
      }

      const actionsUpdated = data?.updateActionMany?.map((action) => action.returning[0]);

      for (let action of actionsUpdated) {
        updateAction(action);
      }

      toast({
        title: 'All actions were updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useDeleteAction = (options?: UseMutationOptions<DeleteActionResponse, APIError, ActionDeletePayload>) => {
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();

  return useMutation({
    mutationFn: (payload: ActionDeletePayload) => fetchData<DeleteActionResponse>(DELETE_ACTION, payload),
    ...options,

    onSuccess: (data, variables, context) => {
      cronofySyncEvent({ eventId: variables.actionId, eventType: 'Action', deleted: true });
      options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useCreateAction = (
  options?: Omit<UseMutationOptions<CreateActionResponse, APIError, QuickCreateActionPayload>, 'onSettled'>,
) => {
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();

  return useMutation({
    mutationFn: (payload: QuickCreateActionPayload) => fetchData<CreateActionResponse>(INSERT_ACTION_ONE, payload),
    ...options,

    onSuccess: (data, variables, context) => {
      if (data.insertActionOne.scheduledDate && data.insertActionOne.scheduledTime) {
        cronofySyncEvent({ eventId: data.insertActionOne.id, eventType: 'Action' });
      }
      options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useCreatePromises = (
  options?: Omit<UseMutationOptions<CreatePromisesResponse, APIError, CreatePromisesPayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload: CreatePromisesPayload) => fetchData<CreatePromisesResponse>(CREATE_PROMISES, payload),
    ...options,

    onSuccess: (data) => {
      queryClient.invalidateQueries(keys.actions.leveragedAndCommitted.queryKey, { type: 'all' });

      addPromiseToCache(data);
    },
  });
};

export const useLeveragedAndCommittedActions = (
  options?: UseQueryOptions<GetLeveragedAndCommittedActionsResponse, APIError, GetLeveragedAndCommittedActionsResponse>,
) => {
  return useQuery<GetLeveragedAndCommittedActionsResponse, APIError, GetLeveragedAndCommittedActionsResponse>({
    ...keys.actions.leveragedAndCommitted,
    queryFn: () => fetchData(GET_LEVERAGED_AND_COMMITTED_ACTIONS),
    ...options,
  });
};

export const useDeletePromisesByAction = (
  options?: Omit<UseMutationOptions<DeletePromisesResponse, APIError, DeletePromisesByActionPayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload: DeletePromisesByActionPayload) =>
      fetchData<DeletePromisesResponse>(DELETE_PROMISES_BY_ACTION, payload),
    ...options,

    onSuccess: () => {
      queryClient.invalidateQueries(keys.actions.leveragedAndCommitted.queryKey, { type: 'all' });
      // queryClient.resetQueries(keys.people.all.queryKey, { type: 'all' });
    },
  });
};

export const useUpdatePromise = (
  options?: Omit<UseMutationOptions<UpdatePromiseResponse, APIError, UpdatePromisePayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload: UpdatePromisePayload) => fetchData<UpdatePromiseResponse>(UPDATE_PROMISE, payload),
    ...options,

    onSuccess: () => {
      queryClient.refetchQueries(keys.actions.all.queryKey, { type: 'all' });
      queryClient.refetchQueries(keys.people.all.queryKey, { type: 'all' });
    },
  });
};

export const useUpdatePromiseRemoveLeverageRequest = (
  options?: Omit<
    UseMutationOptions<
      UpdatePromiseRemoveLevereageRequestResponse,
      APIError,
      UpdatePromiseRemoveLeverageRequestPayload
    >,
    'onSuccess'
  >,
) => {
  return useMutation({
    mutationFn: (payload: UpdatePromiseRemoveLeverageRequestPayload) =>
      fetchData<UpdatePromiseRemoveLevereageRequestResponse>(UPDATE_PROMISE_REMOVE_LEVERAGE, payload),
    ...options,

    onSuccess: (data) => {
      const cacheData = {
        updatePromiseByPk: {
          ...data.updatePromiseByPk,
          leverageRequestDueDate: null,
          leverageRequestedAt: null,
          leverageRequestStatus: null,
          leverageRequestText: null,
        },
      };

      updatePromiseWithLeverageToPersonCache(cacheData);
      updatePromiseWithLeverageToLeverageCommittedCache(cacheData);
      updatePromiseWithLevereageToActionCache(cacheData);
      updatePromiseWithLeverageToProjectDetailCache(cacheData);
    },
  });
};

export const useUpdatePromiseWithLeverageRequest = (
  options?: Omit<
    UseMutationOptions<UpdatePromiseWithLevereageRequestResponse, APIError, UpdatePromiseWithLeverageRequestPayload>,
    'onSuccess'
  >,
) => {
  return useMutation({
    mutationFn: (payload: UpdatePromiseWithLeverageRequestPayload) =>
      fetchData<UpdatePromiseWithLevereageRequestResponse>(UPDATE_PROMISE_WITH_CREATE_LEVERAGE, payload),
    ...options,

    onSuccess: (data) => {
      updatePromiseWithLeverageToPersonCache(data);
      updatePromiseWithLeverageToLeverageCommittedCache(data);
      updatePromiseWithLevereageToActionCache(data);
      updatePromiseWithLeverageToProjectDetailCache(data);
    },
  });
};

export const useSendLeverageRequestEmail = (
  options?: Omit<
    UseMutationOptions<SendLeverageRequestEmailResponse, APIError, SendLeverageRequestEmailPayload>,
    'onSuccess'
  >,
) => {
  return useMutation({
    mutationFn: (payload: SendLeverageRequestEmailPayload) =>
      fetchData<SendLeverageRequestEmailResponse>(SEND_LEVERAGE_REQUEST_EMAIL, payload),
    ...options,
  });
};

export const useReplacePromises = (
  options?: Omit<UseMutationOptions<ReplacePromisesResponse, APIError, ReplacePromisesPayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload: ReplacePromisesPayload) => fetchData<ReplacePromisesResponse>(REPLACE_PROMISES, payload),
    ...options,

    onSuccess: () => {
      queryClient.invalidateQueries(keys.actions.leveragedAndCommitted.queryKey);
      // queryClient.resetQueries(keys.people.all.queryKey, { type: 'all' });
    },
  });
};

export const useUpdateActionStatus = (
  options?: Omit<UseMutationOptions<ActionStatusDeleteResponse, APIError, ActionStatusDeletePayload>, 'onSettled'>,
) => {
  return useMutation({
    mutationFn: async (payload: ActionStatusDeletePayload) => {
      // When "new !== null", the backend does only update Cronofy, which now
      // should be done with cronofySyncEvent instead.
      // When "new === null" instead, the backend deletes the parent block of the
      // action in case it's now empty.
      // TODO: Move the block deletion logic to the frontend and fully deprecate updateActionStatus
      // Backend code: https://github.com/Fueled/rri-rpm-planner-backend/blob/251270af87c2d93c0849591ac81aea3d9426fab5/rpm_planner/plans/api.py#L55
      if (payload.new !== null) {
        console.error('Call to updateActionStatus with new !== null is deprecated. Use cronofySyncEvent instead.');
        return { success: true };
      }
      return await fetchData<ActionStatusDeleteResponse>(UPDATE_ACTION_STATUS, {
        data: payload,
      });
    },

    ...options,
  });
};
export const useCMActions = (categoryId?: string, options?: UseQueryOptions<ActionResponse, APIError>) => {
  return useQuery({
    ...keys.actions.all._ctx.categoryManager(categoryId ?? ''),
    queryFn: () =>
      fetchData<ActionResponse>(GET_ACTIONS, {
        where: { categoryId: { _eq: categoryId } },
      }),
    ...options,
    enabled: !!categoryId,
    cacheTime: 0,
  });
};
