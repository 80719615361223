import { getColorFromToken } from '@/utils/color';
import rem from '@/utils/rem';
import { StackProps, StyleProps, VStack, useColorMode } from '@chakra-ui/react';
import { FC, JSXElementConstructor, ReactNode, SVGProps } from 'react';

import TitleSection from './titleSection';

type Props = {
  children: ReactNode;
  color: string | undefined;
  icon?: FC<SVGProps<SVGSVGElement>> | JSXElementConstructor<SVGProps<SVGSVGElement>>;
  rightButton?: ReactNode;
  sectionTitle?: string;
  gap?: number | string;
  height?: StyleProps['height'];
  border?: StyleProps['border'];
  opacized?: boolean;
} & StackProps;

const DetailsCard = ({ children, color, icon, rightButton, sectionTitle, opacized = false, ...rest }: Props) => {
  const { colorMode } = useColorMode();

  if (opacized) {
    const isDarkTheme = colorMode === 'dark';
    const bgOpacized = getColorFromToken(isDarkTheme ? 'blue.1000' : 'gray.400', '0.70');

    return (
      <VStack
        sx={{
          '@media print': {
            border: `${rem(1)} solid var(--chakra-colors-print-stroke)`,
          },
        }}
        alignItems="start"
        gap={rem(24)}
        width="full"
        borderRadius={4}
        backdropFilter="blur(4px)"
        backgroundColor={bgOpacized}
        paddingX={rem(28)}
        paddingY={rem(30)}
        {...rest}
      >
        <TitleSection color={color} icon={icon} rightButton={rightButton} sectionTitle={sectionTitle} />

        {children}
      </VStack>
    );
  }

  return (
    <VStack
      sx={{
        '@media print': {
          border: `${rem(1)} solid var(--chakra-colors-print-stroke)`,
        },
      }}
      alignItems="start"
      gap={rem(24)}
      width="full"
      borderRadius={4}
      backgroundColor="background-tertiary"
      paddingX={rem(28)}
      paddingY={rem(30)}
      {...rest}
    >
      <TitleSection color={color} icon={icon} rightButton={rightButton} sectionTitle={sectionTitle} />

      {children}
    </VStack>
  );
};

export default DetailsCard;
