import rem from '@/utils/rem';
import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  control: {
    borderRadius: rem(4),
    border: `${rem(1)} solid`,
    borderColor: 'stroke-primary',
    backgroundColor: 'background-primary',
    outline: 'none',
    boxShadow: 'none',

    _focus: {
      boxShadow: 'none',
      outline: 'none',
    },

    _checked: {
      backgroundColor: 'cyan.400',
      border: `${rem(1)} solid`,
      borderColor: 'stroke-primary',
      boxShadow: 'none',
      outline: 'none',

      _hover: {
        backgroundColor: 'cyan.400',
        border: `${rem(1)} solid`,
        borderColor: 'stroke-primary',
        boxShadow: 'none',
        outline: 'none',
      },
    },
  },
});

const variantRound = definePartsStyle({
  control: defineStyle({
    rounded: 'full',

    _checked: {
      backgroundColor: 'cyan.400',
      borderColor: 'cyan.400',
    },
  }),
});

const variants = {
  round: variantRound,
};

const sizes = {
  md: definePartsStyle({
    control: defineStyle({
      width: rem(28), // Width of the checkbox
      height: rem(28), // Height of the checkbox
    }),
  }),
  sm: definePartsStyle({
    control: defineStyle({
      width: rem(18),
      height: rem(18),
    }),
  }),
};

export default defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    size: 'md',
  },
});
