import { CRONOFY_GET_EVENTS } from '@/gql/cronofy';
import { cronofyGetEventsPayload, cronofyGetEventsResponse } from '@/gql/cronofy/types';
import { CREATE_EVENT } from '@/gql/events';
import { CreateEventResponse, EventPayload } from '@/gql/events/types';
import { keys } from '@/gql/global/keys';
import { APIError } from '@/gql/global/types';
import { useCronofyProfiles, useCronofySyncEvent } from '@/services/cronofy/hooks';
import { fetchData } from '@/services/graphql';
import { UseMutationOptions, useMutation, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

// TODO: Remove this in favour of queryClient stale time setting.
// Not setting now as certain pages do not handle stale data well yet.
const STALE_TIME = 10 * 60 * 60 * 1000;

export const useCreateEvent = (
  options?: Omit<UseMutationOptions<CreateEventResponse, APIError, EventPayload>, 'onSettled'>,
) => {
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();

  return useMutation({
    mutationFn: (payload: EventPayload) => fetchData<CreateEventResponse>(CREATE_EVENT, payload),
    ...options,

    onSuccess: (data, variables, context) => {
      cronofySyncEvent({ eventId: data.insertEventOne.action.id, eventType: 'Action' });
      options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useExternalEvents = (from: Date, to: Date) => {
  const { data: cronofyProfiles } = useCronofyProfiles();

  const enabledCalendarsIds = useMemo(
    () =>
      cronofyProfiles?.flatMap((profile) =>
        profile.calendars.filter((calendar) => calendar.inboundSync).map((calendar) => calendar.id),
      ) || [],
    [cronofyProfiles],
  );

  const queryState = useQuery({
    ...keys.cronofy.events(from, to, enabledCalendarsIds),
    queryFn: () => {
      // This is needed because once enabled turns true, ReactQuery immediately
      // fires the query that was previously disabled, causing an error because
      // enabledCalendarsIds is empty (and wasting a call)
      if (enabledCalendarsIds.length === 0) return Promise.resolve({ cronofyEvents: [] });
      return fetchData<cronofyGetEventsResponse>(CRONOFY_GET_EVENTS, {
        calendarsIds: enabledCalendarsIds,
        fromDt: from,
        toDt: to,
      } satisfies cronofyGetEventsPayload);
    },
    select: (data) => data?.cronofyEvents,
    enabled: enabledCalendarsIds.length > 0,
    staleTime: STALE_TIME,
  });

  return queryState;
};
