import { gql } from 'graphql-request';

export const GET_PROJECTS_LIST = gql`
  query ProjectsList {
    project(orderBy: { createdAt: DESC }) {
      id
      name
      result
      purpose
      categoryId
      imageUrl
      actions {
        id
        duration
        dateOfStarring
        progressStatus
      }
    }
  }
`;

export const GET_PROJECTS_LIST_BY_ID = gql`
  query ProjectsListById($categoryId: uuid) {
    project(where: { categoryId: { _eq: $categoryId } }) {
      id
      name
      result
      purpose
      categoryId
      imageUrl
      category {
        icon
        color
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation createProject(
    $id: uuid
    $name: String
    $result: String
    $purpose: String
    $categoryId: uuid
    $imageUrl: String
  ) {
    insertProjectOne(
      object: { id: $id, name: $name, result: $result, purpose: $purpose, categoryId: $categoryId, imageUrl: $imageUrl }
    ) {
      id
      categoryId
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject(
    $id: uuid!
    $name: String
    $result: String
    $purpose: String
    $categoryId: uuid!
    $imageUrl: String
  ) {
    updateProjectByPk(
      pkColumns: { id: $id }
      _set: { name: $name, purpose: $purpose, result: $result, imageUrl: $imageUrl }
    ) {
      id
    }

    updateProjectCategory(categoryId: $categoryId, projectId: $id) {
      ok
    }
  }
`;

export const UPDATE_PROJECT_IMAGE = gql`
  mutation updateProject($id: uuid!, $imageUrl: String) {
    updateProjectByPk(pkColumns: { id: $id }, _set: { imageUrl: $imageUrl }) {
      id
      imageUrl
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation deleteProject($id: uuid!) {
    deleteProjectByPk(id: $id) {
      id
    }
  }
`;

export const GET_PROJECT = gql`
  query GetProjectById($id: uuid!) {
    projectByPk(id: $id) {
      id
      name
      purpose
      result
      categoryId
      imageUrl
      actions(orderBy: { projectOrder: ASC }) {
        id
        title
        notes
        categoryId
        projectId
        categoryOrder
        projectOrder
        duration
        blockId
        blockOrder
        dateOfStarring
        progressStatus
        isLocked
        scheduledDate
        scheduledTime
        timezone
        event {
          id
          typeName: __typename
        }
        project {
          name
        }
        promises {
          kind
          leverageRequestDueDate
          leverageRequestRepliedAt
          leverageRequestReplyText
          leverageRequestStatus
          leverageRequestText
          leverageRequestedAt
          person {
            name
            email
          }
          personId
          id
        }
        weeklyPlanId
        weekly_plan {
          dateFrom
          dateTo
        }
        modifiedAt
      }
      blocks {
        id
        name
        purpose
        result
        isCompleted
        categoryId
        projectOrder
      }
      key_results(orderBy: { order: ASC }) {
        # key result list
        id
        name
        dueDate
        isStarred
        order
        isCompleted
      }
      inspiration_board_items {
        # board item list
        id
        imageUrl
        mimeType
        body
        color
        order
      }
    }
  }
`;

export const UPDATE_ACTIONS_PROJECT_ORDER = gql`
  mutation updateActionsCategoryOrder($actionUpdates: [ActionUpdates!]!) {
    updateActionMany(updates: $actionUpdates) {
      returning {
        id
        projectId
        projectOrder
      }
    }
  }
`;
export const INSERT_IMAGE_ONE = gql`
  mutation insertInspirationBoardItemOne($id: uuid, $imageUrl: String, $mimeType: String, $projectId: uuid) {
    insertInspirationBoardItemOne(
      object: { id: $id, imageUrl: $imageUrl, mimeType: $mimeType, projectId: $projectId }
    ) {
      id
      imageUrl
    }
  }
`;

export const GET_INSPIRATIONAL_LIST = gql`
  query boardImages($id: uuid!) {
    inspirationBoardItem(where: { projectId: { _eq: $id } }) {
      id
      imageUrl
      mimeType
      body
      color
    }
  }
`;

export const DELETE_INSPIRATION_BOARD_ITEM_BY_PK = gql`
  mutation deleteInspirationBoardItemByPk($id: uuid!) {
    deleteInspirationBoardItemByPk(id: $id) {
      id
    }
  }
`;

export const UPDATE_BLOCK_ORDER = gql`
  mutation updateBlockOrder($blockUpdates: [BlockUpdates!]!) {
    updateBlockMany(updates: $blockUpdates) {
      returning {
        id
        weeklyOrder
        dailyOrder
        projectOrder
      }
    }
  }
`;

export const INSERT_NOTE_ONE = gql`
  mutation insertInspirationBoardNoteOne(
    $id: uuid
    $body: String
    $color: String
    $mimeType: String
    $projectId: uuid
  ) {
    insertInspirationBoardItemOne(
      object: { id: $id, body: $body, color: $color, mimeType: $mimeType, projectId: $projectId }
    ) {
      id
      body
      color
    }
  }
`;

export const UPDATE_NOTE_BY_PK = gql`
  mutation updateInspirationBoardNoteByPk($id: uuid!, $body: String, $color: String) {
    updateInspirationBoardItemByPk(pkColumns: { id: $id }, _set: { body: $body, color: $color }) {
      id
      body
      color
    }
  }
`;

export const CREATE_KEY_RESULT = gql`
  mutation CreateKeyResult(
    $id: uuid
    $name: String
    $dueDate: date
    $projectId: uuid
    $order: Int
    $isStarred: Boolean
    $isCompleted: Boolean
  ) {
    insertKeyResultOne(
      object: {
        id: $id
        name: $name
        dueDate: $dueDate
        projectId: $projectId
        isStarred: $isStarred
        isCompleted: $isCompleted
        order: $order
      }
    ) {
      id
      dueDate
      isCompleted
      isStarred
      name
      order
      projectId
    }
  }
`;

export const UPDATE_KEY_RESULT = gql`
  mutation UpdateKeyResult($id: uuid!, $dueDate: date, $isCompleted: Boolean, $isStarred: Boolean, $name: String) {
    updateKeyResultByPk(
      pkColumns: { id: $id }
      _set: { dueDate: $dueDate, isCompleted: $isCompleted, isStarred: $isStarred, name: $name }
    ) {
      id
      dueDate
      isCompleted
      isStarred
      name
      order
      projectId
    }
  }
`;

export const UPDATE_KEY_RESULT_ORDER = gql`
  mutation UpdateKeyResultOrder($updates: [KeyResultUpdates!]!) {
    updateKeyResultMany(updates: $updates) {
      returning {
        id
        order
      }
    }
  }
`;

export const DELETE_KEY_RESULT = gql`
  mutation DeleteKeyResult($id: uuid!) {
    deleteKeyResultByPk(id: $id) {
      id
      projectId
    }
  }
`;
