import rem from '@/utils/rem';
import { IconButton, IconButtonProps } from '@chakra-ui/react';

const SquareIconButton = ({ onClick, ...buttonProps }: IconButtonProps) => {
  return (
    <IconButton
      minWidth="1.75em"
      height="1.75em"
      color="text-primary"
      textColor="text-primary"
      border={`${rem(1)} solid`}
      borderColor="stroke-primary"
      cursor={'pointer'}
      backgroundColor="transparent"
      onClick={onClick}
      variant="tertiary"
      {...buttonProps}
    />
  );
};

export default SquareIconButton;
