import rem from '@/utils/rem';
import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from '@chakra-ui/react';
import { isDesktop } from 'react-device-detect';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  closeButton: JSX.Element;
  children?: JSX.Element;
} & ModalProps;

const FullScreenModal = ({ isOpen, onClose, closeButton, title, children, ...modalProps }: Props) => {
  return (
    <>
      <Modal
        blockScrollOnMount={isDesktop ? true : false}
        isCentered={isDesktop}
        isOpen={isOpen}
        onClose={onClose}
        size="full"
        // Focus trap disabled as it prevents copy-pasting from the chatbot (RRI-2918)
        // TODO: find a better fix
        // TODO: after focus trap is enabled, remove the manual focus
        // (lookup the comments in the codebase by "RRI-2918")
        trapFocus={false}
        {...modalProps}
      >
        <ModalOverlay />
        <ModalContent
          maxWidth="calc(100% - 40px)"
          minHeight="calc(100% - 150px)"
          borderRadius="2xl"
          backgroundColor="background-tertiary"
        >
          <ModalHeader marginBottom={rem(16)} paddingTop={rem(40)} paddingBottom={0} paddingX={rem(32)}>
            <HStack alignItems="center" justifyContent="space-between">
              <Text fontSize="2xl" fontWeight={900}>
                {title}
              </Text>
              <Box display="inline-block">{closeButton}</Box>
            </HStack>
          </ModalHeader>

          <ModalBody display="flex" paddingBottom={rem(32)} paddingX={rem(32)}>
            {children}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FullScreenModal;
