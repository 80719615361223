// export const NOTE_COLORS = [
//   '#FDA4B3',
//   '#F9A8D0',
//   '#F1ABFC',
//   '#DAB4FE',
//   '#93BBFD',
//   '#7DD5FC',
//   '#5EEADB',
//   '#86EFAD',
//   '#BAF264',
//   '#FDD847',
//   '#FCBB4D',
//   '##FDAC74',
// ];

export const NOTE_COLORS = [
  'red.300',
  'rose.300',
  'magenta.300',
  'purple.300',
  'blue.300',
  'cyan.300',
  'teal.300',
  'green.300',
  'lime.300',
  'yellow.300',
  'amber.300',
  'orange.300',
];
