import { keys } from '@/gql/global/keys';
import { APIError } from '@/gql/global/types';
import { GET_PUBLIC_LEVERAGE_REQUESTS, UPDATE_PUBLIC_LEVERAGE_REQUESTS } from '@/gql/publicLeverageRequests';
import {
  GetPublicLeverageRequestsResponse,
  UpdatePublicLeverageRequestPayload,
  UpdatePublicLeverageRequestResponse,
} from '@/gql/publicLeverageRequests/types';
import { fetchData } from '@/services/graphql';
import { trackLeverageRequestStatus } from '@/utils/tracking';
import { useToast } from '@chakra-ui/react';
import { UseMutationOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const usePublicLeverageRequests = (token: string) => {
  return useQuery<GetPublicLeverageRequestsResponse, APIError>({
    ...keys.publicLeverageRequests.list(token),
    queryFn: () => fetchData(GET_PUBLIC_LEVERAGE_REQUESTS, { token }),
  });
};

export const useUpdatePublicLeverageRequest = (
  options?: UseMutationOptions<UpdatePublicLeverageRequestResponse, APIError, UpdatePublicLeverageRequestPayload>,
) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation({
    ...options,
    mutationFn: (payload: UpdatePublicLeverageRequestPayload) =>
      fetchData<UpdatePublicLeverageRequestResponse>(UPDATE_PUBLIC_LEVERAGE_REQUESTS, payload),
    onMutate: async (payload) => {
      // Optimistically update the cache
      await queryClient.cancelQueries({ queryKey: keys.publicLeverageRequests.list(payload.token).queryKey });

      const prevData = queryClient.getQueryData<GetPublicLeverageRequestsResponse>(
        keys.publicLeverageRequests.list(payload.token).queryKey,
      );

      queryClient.setQueryData<GetPublicLeverageRequestsResponse>(
        keys.publicLeverageRequests.list(payload.token).queryKey,
        (oldData) => {
          if (!oldData) return oldData;

          if (payload.leverageRequestStatus === 'completed' || payload.leverageRequestStatus === 'declined') {
            // Completed or declined requests are removed from the list
            return {
              publicLeverageRequests: oldData.publicLeverageRequests.filter(
                (item) => item.promiseId !== payload.promiseId,
              ),
            };
          }

          return {
            publicLeverageRequests: oldData.publicLeverageRequests.map((item) => {
              if (item.promiseId === payload.promiseId)
                return {
                  ...item,
                  leverageRequestStatus: payload.leverageRequestStatus,
                  leverageRequestReplyText: payload.leverageRequestReplyText,
                };
              return item;
            }),
          };
        },
      );

      return { prevData };
    },
    onSuccess: (data, variables) => {
      if (variables.leverageRequestStatus !== 'accepted') {
        toast({
          title:
            variables.leverageRequestStatus === 'completed'
              ? 'Request successfully completed, thanks!'
              : 'Request successfully declined',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }

      trackLeverageRequestStatus(variables.promiseId, variables.leverageRequestStatus);
    },
    onError: (error, payload, context) => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      // Rollback the optimistic update
      if (!context?.prevData) return;
      queryClient.setQueryData(keys.publicLeverageRequests.list(payload.token).queryKey, context.prevData);
    },
  });
};
