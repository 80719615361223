import { DiscussionPoint } from '@/types/discussionPoints';
import { ReactNode, createContext, useContext } from 'react';

export type EqType = {
  _eq: string;
};

export type WhereType = {
  id: EqType;
};

export type SetWhereType = {
  _set: Partial<DiscussionPoint>;
  where: WhereType;
};

export type DiscussionPointsListContextType = {
  items: DiscussionPoint[];
  onCompleteDiscussionPoint?: (discussionPointId: string) => SetWhereType[];
  onIncompleteDiscussionPoint?: (discussionPointId: string) => SetWhereType[];
  onToggleProgressDiscussionPoint?: (discussionPointId: string) => SetWhereType[];
};

export const DiscussionPointsListContext = createContext<DiscussionPointsListContextType | null>(null);

export interface DiscussionPointListProviderProps extends Pick<DiscussionPointsListContextType, 'items'> {
  children: ReactNode;
}

export const useDiscussionPointList = () => {
  const context = useContext(DiscussionPointsListContext);

  if (!context) {
    return {
      items: [],
      onCompleteDiscussionPoint: undefined,
      onIncompleteDiscussionPoint: undefined,
      onToggleProgressDiscussionPoint: undefined,
    };
  }

  return context;
};
