import ActionListCompletedActions from '@/components/ActionListCompletedActions';
import ActionRow from '@/components/ActionRow';
import AddActionButton from '@/components/AddActionButton';
import Callout from '@/components/Callout';
import ConfirmDeleteBlockModal from '@/components/ConfirmDeleteBlockModal';
import CreateBlockModal from '@/components/CreateBlockModal';
import DeleteBlockModal from '@/components/DeleteBlockModal';
import DndContainer from '@/components/DndContainer';
import ActionListItem from '@/components/DragAndDrop/ActionListItem';
import DnDBlockItem from '@/components/DragAndDrop/DnDBlockItem';
import EditBlockModal from '@/components/EditBlockModal';
import Input from '@/components/Input';
import UncompletedActionsModal from '@/components/UncompletedActionsModal';
import { COMPLETE } from '@/constants/action';
import { FilterOptions, selectFilterOptions } from '@/pages/CategoryManagerDetails/constants';
import { useCategoryManager } from '@/services/categories/hooks';
import {
  completeActionsOfBlock,
  onMutateBlockStatus,
  removeIncompleteActionsFromBlock,
  updateBlockInsideCache,
  useAddActionToBlock,
  useCompleteBlockStatus,
  useDeleteBlock,
  useIncompleteBlockStatus,
  useRemoveActionFromBlock,
  useUpdateBlockActionsBlockOrder,
} from '@/services/myPlan/hooks';
import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { useActionModal } from '@/stores/useActionModal';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { selectStyles } from '@/theme/components/custom/select';
import { IconClear, IconClock, IconStarFull } from '@/theme/icons';
import { Action } from '@/types/actions';
import { Block } from '@/types/block';
import { ActionType } from '@/types/myPlan';
import {
  deleteAction,
  getActionById,
  getActionsByBlockId,
  getCompletedActions,
  getIncompleteActions,
  getIncompleteActionsByBlockId,
  getTotalDurationOfActions,
  getTotalDurationOfPreferredActions,
} from '@/utils/action';
import { getBlockById } from '@/utils/block';
import { formatDateToString } from '@/utils/calendar';
import rem from '@/utils/rem';
import { trackBulkActionCompleted } from '@/utils/tracking';
import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  SimpleGrid,
  Spinner,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { DragEndEvent, DragOverlay, DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { isEmpty, isNull, orderBy } from 'lodash';
import { useCallback, useDeferredValue, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { BsSearch } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

const ActionAndBlocksSection = ({ ...rest }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filterSelected, setFilterSelected] = useState<FilterOptions['value']>('all');
  const [selectedBlock, setSelectedBlock] = useState<Block | null>(null);
  const { id } = useParams();

  const {
    data: CMdata,
    isLoadingActions,
    isLoadingBlocks,
  } = useCategoryManager({
    categoryId: id ?? '',
  });

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [activeItemCameFrom, setActiveItemCameFrom] = useState('');
  const [tempBlockId, setTempBlockId] = useState<string | null>(null);
  const [tempBlockActionId, setTempBlockActionId] = useState<string | null>(null);
  const [addActionToBlockAfterDelete, setAddActionToBlockAfterDelete] = useState(false);

  const {
    isOpen: isOpenSetUncompletedActionsModal,
    onOpen: openSetUncompletedActionsModal,
    onClose: onCloseSetUncompletedActionsModal,
  } = useDisclosure();

  const {
    isOpen: isOpenConfirmDeleteBlockModal,
    onOpen: openConfirmDeleteBlockModal,
    onClose: onCloseConfirmDeleteBlockModal,
  } = useDisclosure();

  const {
    isOpen: isOpenDeleteBlockModal,
    onOpen: openDeleteBlockModal,
    onClose: onCloseDeleteBlockModal,
  } = useDisclosure();

  const { isOpen: isOpenEditBlockModal, onOpen: openEditBlockModal, onClose: onCloseEditBlockModal } = useDisclosure();

  const {
    isOpen: isOpenCreateBlockModal,
    onOpen: openCreateBlockModal,
    onClose: onCloseCreateBlockModal,
  } = useDisclosure();

  const { setActionModalOpen } = useActionModal();

  const category = CMdata?.category ?? null;

  const selectedDate = useCalendarMonthlyStore((state) => state.selectedDate);
  const isDailyPlanPage = useIsDailyPlanPage();

  const filterFn = useMemo(() => {
    return {
      all: (a: Action) => a,
      starred: (a: Action) => a.dateOfStarring !== null,
      planned: (a: Action) =>
        isDailyPlanPage ? a?.scheduledDate === formatDateToString(selectedDate) : a.weeklyPlanId !== null,
      unplanned: (a: Action) => (isDailyPlanPage ? a?.scheduledDate === null : a.weeklyPlanId === null),
      completed: (a: Action) => a.progressStatus === COMPLETE,
    };
  }, [selectedDate, isDailyPlanPage]);

  const allActions = useMemo(() => {
    // Both title and searchQuery are in lowercase for case-insensitive search
    const actions = CMdata?.actions.filter((item) => item.title.toLowerCase().includes(searchQuery?.toLowerCase()));

    // Use the selected filter function from filterFn
    const selectedFilterFn = filterFn[filterSelected];

    // Apply the selected filter function to filter the actions
    return selectedFilterFn ? actions.filter(selectedFilterFn) : actions;
  }, [CMdata?.actions, filterFn, filterSelected, searchQuery]);

  const blocks = useMemo(() => CMdata?.blocks ?? [], [CMdata?.blocks]);

  const incompleteActions = getIncompleteActions(allActions, 'createdAt');
  const completedActions = getCompletedActions(allActions, 'modifiedAt');

  const allActionsOrderedByCreatedAt = useMemo(() => {
    return orderBy(allActions, 'createdAt');
  }, [allActions]);

  const incompleteActionsDeferred = useDeferredValue(incompleteActions);
  const completeActionsDeferred = useDeferredValue(completedActions);

  const deleteBlock = useDeleteBlock();
  const removeActionFromBlock = useRemoveActionFromBlock();

  const completeBlockStatus = useCompleteBlockStatus({
    onMutate: ({ id }) => onMutateBlockStatus(id, true),
  });

  const incompleteBlockStatus = useIncompleteBlockStatus({
    onMutate: ({ id }) => onMutateBlockStatus(id),
  });

  const filteredBlocks = useMemo<Block[]>(() => {
    if (!CMdata && !allActions) {
      return [];
    }

    return orderBy(
      CMdata?.blocks?.filter((block) => getActionsByBlockId(block.id, allActions ?? [])?.length),
      'weeklyOrder',
      'asc',
    );
  }, [allActions, CMdata]);

  // TODO: Check if this is the correct way to use deferred value
  const deferredFilteredBlocks = useDeferredValue(filteredBlocks);

  const onCompleteBlockActions = useCallback(() => {
    if (!selectedBlock) {
      return;
    }

    if (selectedBlock.actions) {
      const incompleteActions = getIncompleteActions(selectedBlock.actions);
      incompleteActions.forEach((action) => trackBulkActionCompleted(action));
    }

    id && completeActionsOfBlock(selectedBlock.id, id);

    setSelectedBlock(null);
  }, [selectedBlock, id]);

  const onRemoveBlockActions = useCallback(() => {
    if (!selectedBlock) {
      return;
    }

    removeIncompleteActionsFromBlock(selectedBlock);
    setSelectedBlock(null);
  }, [selectedBlock]);

  const onCompleteBlock = useCallback(
    (blockId: string) => {
      completeBlockStatus.mutate({
        id: blockId,
      });
    },
    [completeBlockStatus],
  );

  const onUncompleteBlock = useCallback(
    (blockId: string) => {
      if (!blocks) {
        return;
      }

      const block = getBlockById(blocks, blockId);

      if (!block) {
        return;
      }

      incompleteBlockStatus.mutate({
        id: blockId,
      });
    },
    [blocks, incompleteBlockStatus],
  );

  const onClickAddButton = useCallback(() => {
    setActionModalOpen(true, {
      categoryId: category?.id,
      readonlyCategory: true,
      onDeleteAction: deleteAction,
      readonlyTooltipLabel:
        'The category of this action cannot be changed because it is not allowed from Category Manager',
      location: 'categories',
    });
  }, [category, setActionModalOpen]);

  const moveActionFromBlockToCaptureList = useCallback(
    (actionId: string, blockId: string, shouldDeleteBlock: boolean) => {
      if (!blocks) {
        return;
      }

      const block = getBlockById(blocks, blockId);

      if (shouldDeleteBlock && block) {
        setSelectedBlock(block);
        openConfirmDeleteBlockModal();
        return;
      }

      removeActionFromBlock.mutate({
        actionId,
        categoryId: block?.categoryId,
        projectId: block?.projectId,
      });
    },
    [blocks, openConfirmDeleteBlockModal, removeActionFromBlock],
  );

  const addActionToBlock = useAddActionToBlock();

  const handleAddActionToBlock = useCallback(
    (actionId: string, blockId: string) => {
      const block = getBlockById(blocks, blockId);

      if (!block) {
        return;
      }

      const blockActions = incompleteActionsDeferred.filter((action) => action.blockId === blockId);
      const blockOrder = blockActions.length;
      addActionToBlock.mutate({
        actionId,
        blockId,
        blockOrder,
        categoryId: block.categoryId,
      });
    },
    [blocks, addActionToBlock, incompleteActionsDeferred],
  );

  const onDeleteBlock = useCallback(async () => {
    if (!selectedBlock) {
      return;
    }

    removeIncompleteActionsFromBlock(selectedBlock);

    if (addActionToBlockAfterDelete && tempBlockActionId && tempBlockId && incompleteActionsDeferred) {
      const blockOrder = incompleteActionsDeferred.filter((action) => action.blockId === selectedBlock.id).length;
      const tempBlock = getBlockById(blocks, tempBlockId);
      await addActionToBlock.mutate({
        actionId: tempBlockActionId,
        blockId: tempBlockId,
        blockOrder,
        categoryId: tempBlock?.categoryId ?? '',
      });
    }

    deleteBlock.mutate({ blockId: selectedBlock.id });

    onCloseDeleteBlockModal();
    onCloseConfirmDeleteBlockModal();
    setSelectedBlock(null);
    onCloseEditBlockModal();
  }, [
    addActionToBlock,
    addActionToBlockAfterDelete,
    incompleteActionsDeferred,
    tempBlockActionId,
    tempBlockId,
    deleteBlock,
    onCloseConfirmDeleteBlockModal,
    onCloseDeleteBlockModal,
    onCloseEditBlockModal,
    selectedBlock,
    blocks,
  ]);

  const handleRemoveActionFromBlock = useCallback(
    (actionId: string, blockId: string) => {
      const key = `block-${blockId}`;

      const block = orderBy(blocks, 'blockOrder', 'asc');

      let blockActions = getActionsByBlockId(blockId, allActions) as ActionType[];

      if (isEmpty(blockActions)) {
        return {
          ...block,
          key,
          incompleteActions: [],
          completedActions: [],
          totalDuration: '0',
          starredActionsTotalDuration: '0',
        };
      }

      blockActions = blockActions.map((action) => ({
        ...action,
        isLastBlockAction: blockActions.length === 1,
        typeName: 'Action',
      }));

      moveActionFromBlockToCaptureList(actionId, blockId, blockActions.length === 1);
    },
    [allActions, blocks, moveActionFromBlockToCaptureList],
  );

  const handleDeleteBlock = useCallback(
    (block: Block | undefined) => {
      if (!block) {
        return;
      }

      setSelectedBlock(block);
      openDeleteBlockModal();
    },
    [openDeleteBlockModal],
  );

  const handleUpdateBlock = useCallback(
    (updatedBlock: Block) => {
      updateBlockInsideCache(updatedBlock);

      onCloseEditBlockModal();
      setSelectedBlock(null);
    },
    [onCloseEditBlockModal],
  );

  const handleClearSearch = () => setSearchQuery('');

  const hasAnyActions = !isEmpty(allActions);
  const areAnyFiltersApplied = filterSelected !== 'all' || searchQuery.length > 0;

  const { mutate: updateBlockActionsBlockOrder } = useUpdateBlockActionsBlockOrder();

  const updateBlockActionsOrder = useCallback(
    (actions: Action[]) => {
      const mutationPayload = actions.map((action, index) => ({
        _set: {
          blockOrder: index + 1,
        },
        where: {
          id: {
            _eq: action.id,
          },
        },
      }));

      updateBlockActionsBlockOrder({
        updates: mutationPayload,
        categoryId: actions[0].categoryId,
        projectId: actions[0].projectId,
      });
    },
    [updateBlockActionsBlockOrder],
  );

  const handleDeleteBlockAndAddActionToOtherBlock = useCallback(
    (fromBlockId: string, toBlockId: string, actionId: string) => {
      const block = getBlockById(blocks, fromBlockId);

      if (block) {
        setSelectedBlock(block);
        openConfirmDeleteBlockModal();

        setAddActionToBlockAfterDelete(true);
        setTempBlockId(toBlockId);
        setTempBlockActionId(actionId);
      }
    },
    [blocks, openConfirmDeleteBlockModal],
  );

  const onDragStart = ({ active }: DragStartEvent) => {
    setActiveId(active.id);
    setActiveItemCameFrom(active?.data?.current?.containerId);
    setActiveItemIndex(active?.data?.current?.index);
  };
  const onDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (active.id == null) {
        setActiveId(null);
        setActiveItemCameFrom('');
        setActiveItemIndex(0);
        return;
      }

      let overId = over?.id;
      if (over?.data?.current?.containerId === 'blocks') {
        // special case when an item is dropped over a block but not over the list of actions
        overId = `block-${overId}`;
      }

      if (!overId) {
        setActiveItemCameFrom('');
        setActiveItemIndex(0);
        return;
      }

      const activeIndex = active?.data?.current?.index;
      const overIndex = over?.data?.current?.index;

      const activeContainerId = active?.data?.current?.containerId;
      const overContainerId = over?.data?.current?.containerId;

      if (
        activeItemCameFrom === 'incompleteActionsList' &&
        (overContainerId.toString().startsWith('block-') || overContainerId === 'blocks')
      ) {
        handleAddActionToBlock(active.id.toString(), over?.data?.current?.blockId);
      }

      if (
        activeContainerId?.toString().startsWith('block-') &&
        overContainerId.startsWith('block-') &&
        activeContainerId === overContainerId
      ) {
        const blockActions = orderBy(
          getIncompleteActionsByBlockId(active?.data?.current?.blockId, incompleteActionsDeferred),
          'blockOrder',
          'asc',
        );
        const reorderedBlockActions = arrayMove(blockActions, activeIndex, overIndex);
        updateBlockActionsOrder(reorderedBlockActions);
      }

      if (
        activeContainerId.toString().startsWith('block-') &&
        overContainerId.startsWith('block-') &&
        activeContainerId !== overContainerId
      ) {
        if (getActionsByBlockId(active?.data?.current?.blockId, incompleteActionsDeferred).length === 1) {
          // delete block after removing last action from it, show conf modal first
          handleDeleteBlockAndAddActionToOtherBlock(
            active?.data?.current?.blockId,
            over?.data?.current?.blockId,
            active.id.toString(),
          );

          setActiveId(null);
          setActiveItemCameFrom('');
          setActiveItemIndex(0);

          return;
        }

        handleAddActionToBlock(active.id.toString(), over?.data?.current?.blockId);
      }

      setActiveId(null);
      setActiveItemCameFrom('');
      setActiveItemIndex(0);
    },
    [
      activeItemCameFrom,
      handleAddActionToBlock,
      handleDeleteBlockAndAddActionToOtherBlock,
      incompleteActionsDeferred,
      updateBlockActionsOrder,
    ],
  );
  const onDragCancel = () => {
    setActiveId(null);
    setActiveItemCameFrom('');
    setActiveItemIndex(0);
  };

  const ActionsListBlocksItemOverlay = useMemo(() => {
    if (!activeId) {
      return <></>;
    }

    if (activeItemCameFrom === 'blocks') {
      // there's no concept of "order of blocks within a category"
      // only "order of blocks within a project"
      return <></>;
    }

    const item = getActionById(incompleteActionsDeferred, activeId.toString());
    if (!item) {
      return <></>;
    }

    return createPortal(
      <DragOverlay dropAnimation={{ easing: 'ease-in', duration: 200 }}>
        <ActionRow action={item} idx={activeItemIndex + 1} backgroundColor="background-primary" showIndex={false} />
      </DragOverlay>,
      document.body,
    );
  }, [activeId, activeItemCameFrom, activeItemIndex, incompleteActionsDeferred]);

  const incompleteActionsDeferredNotInBlock = incompleteActionsDeferred.filter((action) => !action.blockId);
  const completedActionsDeferredNotInBlock = completeActionsDeferred.filter((action) => !action.blockId);

  return (
    <>
      <SimpleGrid
        rowGap={rem(16)}
        columnGap={rem(16)}
        marginTop={rem(16)}
        marginBottom={rem(32)}
        columns={{ base: 1, xl: 2 }}
        {...rest}
      >
        <DndContainer onDragStart={onDragStart} onDragEnd={onDragEnd} onDragCancel={onDragCancel}>
          <VStack width="full">
            <VStack justifyContent="center" width="full">
              <HStack justifyContent="space-between" width="full" marginBottom={rem(13)}>
                <Text textStyle="large" marginRight={rem(16)} color="text-primary">
                  Actions
                </Text>
                <HStack justifyContent="start" width="full">
                  <Box
                    as={Select}
                    textStyle="small"
                    width={rem(128)}
                    minWidth={rem(128)}
                    classNamePrefix="react-select"
                    defaultValue={selectFilterOptions[0]}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onChange={(selectedOption: FilterOptions) => {
                      const selectedValue = selectedOption.value;

                      if (Object.keys(filterFn).includes(selectedValue)) {
                        setFilterSelected(selectedValue);
                      }
                    }}
                    options={selectFilterOptions}
                    styles={{ ...selectStyles() }}
                  />

                  <InputGroup width="full">
                    <InputLeftElement height="full" children={<Icon as={BsSearch} boxSize={rem(14)} />} />

                    <Input
                      textStyle="small"
                      width="full"
                      height={rem(28)}
                      paddingRight={rem(45)}
                      paddingLeft={rem(38)}
                      border={rem(1)}
                      borderStyle="solid"
                      borderColor="stroke-primary"
                      borderRadius={4}
                      autoComplete="off"
                      formControlWidth="full"
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Search an action"
                      type="text"
                      value={searchQuery}
                      _placeholder={{
                        fontSize: 'small',
                        color: 'text-primary',
                      }}
                      _focus={{
                        _placeholder: {
                          opacity: 0.5,
                        },
                      }}
                      fontSize="small"
                      color="text-primary"
                      marginRight={rem(16)}
                    />
                    {searchQuery.length && (
                      <InputRightElement
                        right={rem(22)}
                        width={rem(30)}
                        height="full"
                        children={
                          <IconButton
                            width={rem(30)}
                            height={rem(28)}
                            _hover={{
                              backgroundColor: 'transparent',
                            }}
                            aria-label="Delete search field"
                            backgroundColor="transparent"
                            icon={<Icon as={IconClear} width={rem(10)} color="text-primary" />}
                            onClick={handleClearSearch}
                          />
                        }
                      />
                    )}
                  </InputGroup>
                </HStack>

                <HStack>
                  <Tooltip label="Time remaining to complete all of your starred actions" placement="top">
                    <Flex alignItems="center" gap={rem(4)}>
                      <Icon as={IconStarFull} boxSize={rem(12)} color="gray.300" />
                      <Text textStyle="xsmall" color="text-primary">
                        {getTotalDurationOfPreferredActions(incompleteActionsDeferredNotInBlock ?? []) || '0h'}
                      </Text>
                    </Flex>
                  </Tooltip>

                  <Tooltip label="Time remaining to complete all of your actions" placement="top">
                    <Flex alignItems="center" gap={rem(4)}>
                      <Icon as={IconClock} boxSize={rem(12)} color="gray.300" />
                      <Text textStyle="xsmall" color="text-primary">
                        {getTotalDurationOfActions(incompleteActionsDeferredNotInBlock ?? []) || '0h'}
                      </Text>
                    </Flex>
                  </Tooltip>
                </HStack>

                <AddActionButton backgroundColor={category?.color} onExternalClick={onClickAddButton} />
              </HStack>

              {isLoadingActions ? (
                <Flex justifyContent="center" marginTop={rem(80)}>
                  <Spinner size="xl" speed="0.65s" thickness={rem(4)} />
                </Flex>
              ) : hasAnyActions ? (
                <VStack width="full">
                  <List width="full">
                    {incompleteActionsDeferredNotInBlock.map((action, index) => (
                      <ActionListItem
                        key={action.id}
                        item={action}
                        index={index}
                        dndPayload={{ index, containerId: 'incompleteActionsList' }}
                        showIndex={false}
                        onDeleteAction={deleteAction}
                        withScheduleButton
                      />
                    ))}
                  </List>

                  <Box width="full">
                    <ActionListCompletedActions
                      className={`${category?.id}-completed-actions`}
                      actions={completedActionsDeferredNotInBlock}
                      dndPayload={{ containerId: 'completedActionsList' }}
                      onDeleteAction={deleteAction}
                    />
                  </Box>
                </VStack>
              ) : areAnyFiltersApplied ? (
                <p>There are no actions that match the criteria</p>
              ) : (
                <Callout title="What is an Action?" variant="bordered">
                  An action is a behavior or activity that you undertake to move closer to your desired outcome or goal.
                  It is a conscious decision and effort you make to make progress and create change in your life. Within
                  the RPM process, actions are crucial for success and turning your goals into reality. To add an action
                  to your capture list, click the + in the upper right corner.
                </Callout>
              )}
            </VStack>
          </VStack>

          {/* Blocks List */}
          <VStack width="full">
            <HStack justifyContent="space-between" width="full" minHeight={rem(30)} marginBottom={rem(13)}>
              <Text textStyle="large" marginRight={rem(16)} color="text-primary">
                RPM Blocks
              </Text>

              <AddActionButton
                tooltipText="Add a new block"
                onClick={openCreateBlockModal}
                variant="secondary"
                width={rem(28)}
                height={rem(28)}
              />
            </HStack>

            {isLoadingBlocks || isLoadingActions ? (
              <Flex justifyContent="center" marginTop={rem(80)}>
                <Spinner size="xl" speed="0.65s" thickness={rem(4)} />
              </Flex>
            ) : (
              <>
                {deferredFilteredBlocks.length ? (
                  deferredFilteredBlocks.map((block, index) => (
                    <DnDBlockItem
                      key={block.id}
                      actions={getActionsByBlockId(block.id, allActionsOrderedByCreatedAt ?? [])}
                      block={block}
                      index={index}
                      weeklyPlanId=""
                      onCompleteBlock={onCompleteBlock}
                      openSetUncompletedActionsModal={openSetUncompletedActionsModal}
                      onUncompleteBlock={onUncompleteBlock}
                      onSelectBlock={() => setSelectedBlock(block)}
                      onClickRemoveFromBlock={handleRemoveActionFromBlock}
                      withScheduleButton
                      openEditBlockModal={openEditBlockModal}
                      onDeleteBlock={handleDeleteBlock}
                    />
                  ))
                ) : !areAnyFiltersApplied ? (
                  <Callout title="What is a Block?" variant="bordered">
                    A block is a selection of actions that you can group together, give it’s own name, purpose and
                    result. So you can organize your time in a more generic way. Click the + icon in the upper right
                    hand corner to create a block. You can always create actions within blocks, or drag actions from
                    anywhere, into a block to help group them in the most meaningful way.
                  </Callout>
                ) : (
                  <p>There are no blocks that match the criteria</p>
                )}
              </>
            )}
          </VStack>

          {ActionsListBlocksItemOverlay}
        </DndContainer>
      </SimpleGrid>

      {isOpenSetUncompletedActionsModal && selectedBlock && (
        <UncompletedActionsModal
          blockId={selectedBlock.id}
          completedActions={selectedBlock?.actions?.filter((action) => action.progressStatus === 'complete') ?? []}
          isOpen={isOpenSetUncompletedActionsModal}
          onClose={onCloseSetUncompletedActionsModal}
          onDeleteBlock={({ id }) => deleteBlock.mutate({ blockId: id })}
          onCompleteBlockActions={onCompleteBlockActions}
          onRemoveBlockActions={onRemoveBlockActions}
          onCompleteBlock={onCompleteBlock}
        />
      )}

      {isOpenConfirmDeleteBlockModal && (
        <ConfirmDeleteBlockModal
          isOpen={isOpenConfirmDeleteBlockModal}
          onClose={onCloseConfirmDeleteBlockModal}
          onSubmit={onDeleteBlock}
        />
      )}

      {isOpenDeleteBlockModal && (
        <DeleteBlockModal isOpen={isOpenDeleteBlockModal} onClose={onCloseDeleteBlockModal} onSubmit={onDeleteBlock} />
      )}

      {isOpenEditBlockModal && !isNull(selectedBlock) && (
        <EditBlockModal
          isOpen
          block={selectedBlock}
          onUpdateBlock={handleUpdateBlock}
          onDeleteBlock={handleDeleteBlock}
          onCancel={onCloseEditBlockModal}
        />
      )}

      {isOpenCreateBlockModal && (
        <CreateBlockModal
          isOpen={isOpenCreateBlockModal}
          onClose={onCloseCreateBlockModal}
          categoryId={category?.id}
          readyOnlyCategory
        />
      )}
    </>
  );
};

export default ActionAndBlocksSection;
