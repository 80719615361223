import rem from '@/utils/rem';
import { Badge, BadgeProps, Text } from '@chakra-ui/react';

export default function ActionBadge({ children, ...badgeProps }: BadgeProps) {
  return (
    <Badge borderRadius={rem(100)} paddingX={rem(6)} paddingY={rem(2)} {...badgeProps}>
      <Text textStyle="xsmall" color="text-primary" fontSize="2xs" textTransform="initial">
        {children}
      </Text>
    </Badge>
  );
}
