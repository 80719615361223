import { create } from 'zustand';

type StateSelectedPageIndex = {
  selectedPlanSchedulePageIndex: number;
  updateSelectedPlanSchedulePageIndex: (selectedPlanSchedulePageIndex: number) => void;
};

const useSelectPlanSchedulePage = create<StateSelectedPageIndex>((set) => ({
  selectedPlanSchedulePageIndex: 0,
  updateSelectedPlanSchedulePageIndex: (selectedPlanSchedulePageIndex) =>
    set(() => ({ selectedPlanSchedulePageIndex })),
}));

export { useSelectPlanSchedulePage };
