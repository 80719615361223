import { SIGN_IN_URL } from '@/services/user/hooks';
import { GraphQLClient, RequestDocument, Variables } from 'graphql-request';

export const graphQLClient = new GraphQLClient(import.meta.env.VITE_GRAPHQL_API_URL, {
  credentials: `include`,
});

export async function fetchData<T = any>(query: RequestDocument, variables?: Variables): Promise<T> {
  try {
    return await graphQLClient.request<T>(query, variables);
  } catch (error: any) {
    if (
      error?.response?.errors?.some((error: any) => error?.extensions?.code === 'invalid-headers') ||
      error?.response?.errors?.some((error: any) => error?.message === 'Auth token is not valid!')
    ) {
      console.log('🔒 Session expired, redirecting to sign in page');
      window.location.assign(SIGN_IN_URL);
    }
    throw error;
  }
}
