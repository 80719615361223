import { keys } from '@/gql/global/keys';

import { endOfTheMonth, startOfTheMonth } from './calendar';

export const getMonthlyCalendarQueryKey = (date = new Date()) => {
  const currentMonthStartDate = startOfTheMonth(date);
  const currentMonthEndDate = endOfTheMonth(date);

  return keys.actions.all._ctx.month(currentMonthStartDate, currentMonthEndDate).queryKey;
};
