import DialogModal from '@/components/DialogModal';
import StyledModal from '@/components/StyledModal';
import Textarea from '@/components/Textarea';
import { NOTE_COLORS } from '@/constants/note';
import { NoteProps } from '@/gql/project/types';
import { IconTrash } from '@/theme/icons';
import { NoteInfo } from '@/types/inspirations';
import rem from '@/utils/rem';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Children, useCallback, useEffect, useId } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

const NoteModalFormSchema = z.object({
  body: z
    .string()
    .trim()
    .min(1, { message: 'Note requires at least 1 character' })
    .max(255, { message: 'Note must be 255 characters or less' }),
  color: z.string().min(3, { message: 'Color is required' }),
});

type FormData = z.infer<typeof NoteModalFormSchema>;

type Props = {
  isNotesModalOpen: boolean;
  onCloseNotesModal: () => void;
  onInsertInspirationNote: (noteData: NoteInfo) => void;
  onDeleteInspirationBoardItem: (noteId: string, isNote: boolean) => void;
  selectedNoteData: NoteProps | null;
  onEditNote: ({ body, color }: NoteInfo) => void;
  isEditingNote?: boolean;
  isLoadingInsertNote?: boolean;
  iseDeletingInspirationBoardItem?: boolean;
};

const initialValues = {
  body: '',
  color: NOTE_COLORS?.[0],
};

const NotesModal = ({
  isNotesModalOpen,
  onCloseNotesModal,
  onInsertInspirationNote,
  selectedNoteData,
  onEditNote,
  onDeleteInspirationBoardItem,
  isEditingNote = false,
  isLoadingInsertNote = false,
  iseDeletingInspirationBoardItem = false,
}: Props) => {
  const formId = useId();

  const {
    control,
    handleSubmit,
    reset,
    setFocus,
    setValue,
    formState: { errors, isValid },
    watch,
  } = useForm<FormData>({
    defaultValues: {
      ...initialValues,
    },
    resolver: zodResolver(NoteModalFormSchema),
    mode: 'onChange',
  });

  const selectedColor = watch('color');

  const {
    isOpen: isDeleteNoteModalOpen,
    onOpen: onOpenDeleteNoteModal,
    onClose: onCloseDeleteNoteModal,
  } = useDisclosure();

  const onCancel = useCallback(() => {
    reset({ ...initialValues });
    onCloseNotesModal();
  }, [onCloseNotesModal, reset]);

  const onSubmit = useCallback(
    async (data: NoteInfo) => {
      if (selectedNoteData?.id) {
        onEditNote({
          body: data?.body,
          color: data?.color,
        });

        reset({ ...initialValues });

        return;
      }

      onInsertInspirationNote({
        body: data?.body,
        color: data?.color,
      });

      setTimeout(() => {
        reset({ ...initialValues });
      }, 700);
    },
    [onEditNote, onInsertInspirationNote, reset, selectedNoteData?.id],
  );

  const onConfirm = useCallback(() => {
    if (!selectedNoteData?.id) {
      return;
    }

    onDeleteInspirationBoardItem(selectedNoteData.id, true);
    onCloseDeleteNoteModal();
  }, [onCloseDeleteNoteModal, onDeleteInspirationBoardItem, selectedNoteData]);

  useEffect(() => {
    if (selectedNoteData === null) {
      reset({ ...initialValues });
      return;
    }

    setValue('body', selectedNoteData?.body);
    setValue('color', selectedNoteData?.color);

    return () => {
      reset({ ...initialValues });
    };
  }, [reset, selectedNoteData, selectedNoteData?.body, selectedNoteData?.color, setValue]);

  useEffect(() => {
    if (isNotesModalOpen) {
      // Does not work without setTimeout
      // must be removed after focus trap is enabled
      // (lookup the comments in the codebase by "RRI-2918")
      const timeoutId = setTimeout(() => setFocus('body'), 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isNotesModalOpen, setFocus]);

  return (
    <>
      <StyledModal isOpen={isNotesModalOpen} onClose={onCloseNotesModal} size="3xl">
        <ModalHeader>
          <Text fontSize="2xl" fontWeight={900}>
            {selectedNoteData?.id ? 'Edit Note' : 'Add Note'}
          </Text>
        </ModalHeader>

        <ModalBody>
          <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={!!errors.body?.message}>
              <FormLabel
                marginBottom={rem(12)}
                color="text-tertiary"
                fontSize="sm"
                fontWeight={900}
                textTransform="uppercase"
              >
                Note Preview
              </FormLabel>

              <Controller
                name="body"
                control={control}
                render={({ field: { ref, value, onChange } }) => {
                  const [token] = selectedColor.split('.');

                  return (
                    <Textarea
                      ref={ref}
                      height={rem(323)}
                      padding={`${rem(16)} ${rem(21)}`}
                      color="blue.1000"
                      fontSize={rem(24)}
                      fontWeight={500}
                      border="none"
                      _focusVisible={{
                        outlineOffset: 5,
                        outline: `${rem(1)} solid`,
                        outlineColor: 'cyan.400',
                      }}
                      _placeholder={{ color: 'blue.1000', opacity: 0.5 }}
                      resize="none"
                      backgroundColor={`${token}.200`}
                      error={errors.body?.message}
                      onChange={onChange}
                      placeholder="Add your note..."
                      value={value}
                    />
                  );
                }}
              />
            </FormControl>

            <FormLabel
              marginTop={rem(28)}
              marginBottom={rem(12)}
              color="text-tertiary"
              fontSize="sm"
              fontWeight="900"
              textTransform="uppercase"
            >
              Choose note color
            </FormLabel>

            <Flex role="radiogroup">
              {Children.toArray(
                NOTE_COLORS.map((color) => {
                  return (
                    <Controller
                      name="color"
                      control={control}
                      render={({ field: { ref, value, onChange } }) => (
                        <Flex
                          position="relative"
                          alignItems="center"
                          justifyContent="center"
                          width={rem(32)}
                          height={rem(32)}
                          margin="0rem 0.8125rem 0rem 0"
                          borderRadius="full"
                          _hover={{ backgroundColor: `${color}99` }}
                          backgroundColor={color}
                        >
                          {value === color && (
                            <Box
                              position="absolute"
                              width={rem(36)}
                              height={rem(36)}
                              borderWidth={rem(4)}
                              borderStyle="solid"
                              borderColor="white"
                              borderRadius="full"
                            />
                          )}
                          <Checkbox
                            ref={ref}
                            zIndex={10}
                            width="100%"
                            height="100%"
                            opacity="0"
                            isChecked={value === color}
                            onChange={() => onChange(color)}
                          />
                        </Flex>
                      )}
                    />
                  );
                }),
              )}
            </Flex>
          </form>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-between" width="full">
            <Box>
              {selectedNoteData?.id && (
                <IconButton
                  aria-label="Delete Note"
                  icon={<Icon as={IconTrash} boxSize={rem(20)} />}
                  isDisabled={iseDeletingInspirationBoardItem}
                  isLoading={iseDeletingInspirationBoardItem}
                  onClick={onOpenDeleteNoteModal}
                  size="lg"
                  variant="secondary"
                />
              )}
            </Box>
            <HStack>
              <Button onClick={onCancel} size="lg" variant="secondary">
                Cancel
              </Button>
              <Button
                bgGradient="linear(to-b, whiteAlpha.300 0%, whiteAlpha.100 100%);"
                form={formId}
                isDisabled={!isValid || isEditingNote || isLoadingInsertNote}
                isLoading={isEditingNote || isLoadingInsertNote}
                size="lg"
                type="submit"
                variant="primary"
              >
                {selectedNoteData?.id ? 'Edit note' : 'Add Note'}
              </Button>
            </HStack>
          </HStack>
        </ModalFooter>
      </StyledModal>

      {isDeleteNoteModalOpen && (
        <DialogModal
          cancelText="Cancel"
          confirmText="Delete Note"
          confirmVariant="primary"
          isOpen={isDeleteNoteModalOpen}
          message="Do you really want to delete this note?"
          onCancel={onCloseDeleteNoteModal}
          onConfirm={onConfirm}
          title="Delete Note"
        />
      )}
    </>
  );
};

export default NotesModal;
