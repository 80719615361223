import {
  IconAppleCat,
  IconArrow,
  IconBackpack,
  IconBasketball,
  IconBicycleRider,
  IconBoat,
  IconBookEmpty,
  IconChatBubble,
  IconConfetti,
  IconDie,
  IconDog,
  IconDollar,
  IconDrum,
  IconExternalCalendar,
  IconFerrisWheel,
  IconGroceryBasket,
  IconHeadphone,
  IconHotBowl,
  IconHotDrink,
  IconIdea,
  IconIron,
  IconLaptop,
  IconLargeSuitcase,
  IconLuggage,
  IconMagicWand,
  IconMasks,
  IconMedal,
  IconMicrophoneStand,
  IconMoney,
  IconMountain,
  IconMovie,
  IconMusicNotesTwoEight,
  IconPaintbrush,
  IconPainting,
  IconPencilDraw,
  IconPieChartQuarter,
  IconPlacemarker,
  IconPlaneTakeoff,
  IconPlant,
  IconStethoscope,
  IconStore,
  IconStroller,
  IconTShirt,
  IconTelevision,
  IconTequila,
  IconTicket,
  IconUncategorized,
  IconUserGroup,
  IconUsers,
  IconWeight,
} from '@/theme/icons';
import { SVGProps } from 'react';

type IconsProps = {
  [key: string]: React.FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
};

export const CATEGORY_COLORS = [
  'rose.400',
  'magenta.400',
  'purple.400',
  'blue.400',
  'teal.400',
  'lime.400',
  'yellow.400',
  'orange.400',
];

export const CATEGORIES_ICONS: IconsProps = {
  medal: IconMedal,
  apple: IconAppleCat,
  dollar: IconDollar,
  users: IconUsers,
  arrow: IconArrow,
  weight: IconWeight,
  mountain: IconMountain,
  masks: IconMasks,
  plant: IconPlant,
  dog: IconDog,
  idea: IconIdea,
  suitcase: IconLargeSuitcase,
  placemarker: IconPlacemarker,
  store: IconStore,
  tshirt: IconTShirt,
  pencildraw: IconPencilDraw,
  ferriswheel: IconFerrisWheel,
  chatbubble: IconChatBubble,
  bookempty: IconBookEmpty,
  backpack: IconBackpack,
  luggage: IconLuggage,
  money: IconMoney,
  piechartquarter: IconPieChartQuarter,
  musicnotestwoeight: IconMusicNotesTwoEight,
  drum: IconDrum,
  magicwand: IconMagicWand,
  planetakeoff: IconPlaneTakeoff,
  bicyclerider: IconBicycleRider,
  painting: IconPainting,
  headphone: IconHeadphone,
  television: IconTelevision,
  movie: IconMovie,
  microphonestand: IconMicrophoneStand,
  boat: IconBoat,
  paintbrush: IconPaintbrush,
  confetti: IconConfetti,
  stroller: IconStroller,
  iron: IconIron,
  die: IconDie,
  stethoscope: IconStethoscope,
  ticket: IconTicket,
  hotdrink: IconHotDrink,
  grocerybasket: IconGroceryBasket,
  hotbowl: IconHotBowl,
  basketball: IconBasketball,
  laptop: IconLaptop,
  usergroup: IconUserGroup,
  tequila: IconTequila,
  external: IconExternalCalendar,
  uncategorized: IconUncategorized,
};
