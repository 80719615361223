import { ActionList } from '@/components/DragAndDrop';
import ActionListItem, { ActionListItemProps } from '@/components/DragAndDrop/ActionListItem';
import EmptyActionList from '@/components/EmptyActionList';
import { IconChevronDown } from '@/theme/icons';
import { Action } from '@/types/actions';
import rem from '@/utils/rem';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Text } from '@chakra-ui/react';
import { isEmpty, size } from 'lodash';

type ActionListCompletedActionsProps = {
  // @TODO: TBC if it's possible to revert to a required prop when all the pages that uses this component plus capture list are done.
  className?: string;
  actions: Action[];
  dndPayload?: ActionListItemProps['dndPayload'];
} & Pick<ActionListItemProps, 'onDeleteAction' | 'onClickCreateBlock'>;

function ActionListCompletedActions({
  actions,
  className,
  dndPayload,
  ...actionListItemProps
}: ActionListCompletedActionsProps) {
  return (
    <Accordion width="full" marginTop={rem(16)} allowToggle reduceMotion>
      <AccordionItem>
        <AccordionButton className={className} gap={rem(4)} padding="0">
          <Flex flex="1" textAlign="left">
            <Text textStyle="xsmall" color="text-secondary" textTransform="uppercase">
              {size(actions)} Completed Actions
            </Text>
          </Flex>
          <AccordionIcon as={IconChevronDown} width={rem(14)} height={rem(14)} color="text-primary" />
        </AccordionButton>
        <AccordionPanel marginTop={rem(8)} padding={0} paddingBottom={4}>
          <ActionList isEmpty={isEmpty(actions)} emptyListComponent={<EmptyActionList message="No complete actions" />}>
            {actions.map((action, index) => (
              <ActionListItem
                key={action.id}
                disabled
                dndPayload={dndPayload ? { ...dndPayload, index } : undefined}
                item={action}
                index={index}
                readOnly
                {...actionListItemProps}
              />
            ))}
          </ActionList>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default ActionListCompletedActions;
