import ScheduledWeeklyTimeSlot from '@/components/DragAndDropCalendar/TimeSlot';
import { DayToolbar } from '@/components/DragAndDropCalendar/Toolbar';
import { CalendarWeeklyHeader } from '@/components/DragAndDropCalendar/WeekHeader';
import { useCalendar } from '@/components/DragAndDropCalendar/context';
import { DAY, WEEK } from '@/constants/calendar';
import { MyEvent } from '@/types/calendar';
import { localizer, minutesToDurationString } from '@/utils/calendar';
import rem from '@/utils/rem';
import { Box, VStack, useToast, useToken } from '@chakra-ui/react';
import { useDndMonitor, useDroppable } from '@dnd-kit/core';
import { addMinutes, differenceInMinutes, format, getMonth } from 'date-fns';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Calendar, ToolbarProps } from 'react-big-calendar';
import withDragAndDrop, {
  DragFromOutsideItemArgs,
  EventInteractionArgs,
} from 'react-big-calendar/lib/addons/dragAndDrop';

import CalendarProvider from './provider';

function CalendarToolbar({ view, ...props }: ToolbarProps) {
  if (view === DAY) return <DayToolbar {...props} />;
  return null;
}

type CalendarResource = {
  id: string;
};

type DragAndDropCalendarProps = {
  date?: Date;
  eventResizable?: boolean;
  height?: number | string;
  view: typeof DAY | typeof WEEK;
  onEntityDropped: (entityId: string, date: Date) => void;
  onDurationChange: (event: MyEvent, start: Date, duration: string) => void;
  onTimeChange: (event: MyEvent, date: Date) => void;
  onUnplan: (event: MyEvent) => void;
  onClickEvent: (event: MyEvent) => void;
};

const DragAndDropCalendar = withDragAndDrop<MyEvent, CalendarResource>(Calendar);

const DROPPABLE_CONTAINER_ID = 'dailyView';
const TIME_STEP = 15; // 15 minute blocks on calendar.
const MINUTES_OFFSET = -120; // offset of minutes to show the current time more in view.
const MINUTES_IN_HOUR = 60; // 1 hour
const HOURS_IN_DAY = 24; // 24 hours in day.
const CALENDAR_MAX_HEIGHT = 2774;

function getSlotCount(timeStep: number): number {
  return HOURS_IN_DAY / (timeStep / MINUTES_IN_HOUR);
}

function Component({
  date = new Date(),
  eventResizable = true,
  height = 760,
  view,
  onEntityDropped,
  onDurationChange,
  onTimeChange,
  onUnplan,
  onClickEvent,
}: DragAndDropCalendarProps) {
  const { combinedEvents } = useCalendar();

  const [draggedOutsideElement, setDraggedOutsideElement] = useState<string | null>(null);

  const { setNodeRef } = useDroppable({ id: DROPPABLE_CONTAINER_ID });

  const toast = useToast();

  const [fallbackColor, nowColor] = useToken('colors', ['gray.500', 'cyan.300']);

  const handleEventResize = useCallback(
    ({ event, start, end }: EventInteractionArgs<MyEvent>) => {
      // get difference in minutes;
      const totalMinutes = differenceInMinutes(new Date(end), new Date(start));
      const duration = minutesToDurationString(totalMinutes);
      if (totalMinutes === 0) return;

      onDurationChange(event, new Date(start), duration);
    },
    [onDurationChange],
  );

  const handleEventDrop = useCallback(
    ({ event, start }: EventInteractionArgs<MyEvent>) => {
      onTimeChange(event, new Date(start));
    },
    [onTimeChange],
  );

  const pointerPosition = useRef({ x: 0, y: 0 });

  useEffect(() => {
    function handleMove(e: PointerEvent) {
      pointerPosition.current = {
        x: e.clientX,
        y: e.clientY,
      };
    }

    window.addEventListener('pointermove', handleMove);

    return () => {
      window.removeEventListener('pointermove', handleMove);
    };
  }, []);

  useDndMonitor({
    onDragStart(event) {
      setDraggedOutsideElement(event.active.id.toString());
    },
    onDragCancel() {
      setDraggedOutsideElement(null);
    },
    onDragEnd({ over }) {
      setDraggedOutsideElement(null);
      if (over?.id !== DROPPABLE_CONTAINER_ID || !draggedOutsideElement) {
        return;
      }

      // Target the react big calendar containers.
      const dailyViewContainer = document.querySelector('.rbc-time-content');
      const dailyColumn = document.querySelector('.rbc-time-column');

      if (!dailyViewContainer || !dailyColumn) {
        return;
      }

      // Find the closest .rbc-timeslot-group
      const dailyColumnRect = dailyColumn.getBoundingClientRect();
      const viewportRect = dailyViewContainer.getBoundingClientRect();

      // Convert to local space
      const scrolledDifference = viewportRect.top - dailyColumnRect.top;
      const mouseDifference = pointerPosition.current.y - viewportRect.top;
      const localPosition = scrolledDifference + mouseDifference;

      // Get the minute offset position
      const numOfSlots = getSlotCount(TIME_STEP);
      const shouldGoInSlot = Math.floor((localPosition / dailyColumnRect.height) * numOfSlots);
      const totalMinutes = shouldGoInSlot * TIME_STEP;

      // Convert minutes to hour and minutes
      const hours = Math.floor(totalMinutes / MINUTES_IN_HOUR);
      const minutes = totalMinutes - hours * MINUTES_IN_HOUR;

      // Selected date set to hours minutes
      const newDateTime = new Date(date);
      newDateTime.setHours(hours);
      newDateTime.setMinutes(minutes);

      if (view === WEEK) {
        const headerContainer = document.querySelector('.rbc-time-header-content');
        const daysOfWeek = document.querySelectorAll('.rbc-header');

        if (!headerContainer || !daysOfWeek) {
          return;
        }

        const headerContainerRect = headerContainer.getBoundingClientRect();

        const scrolledDifferenceX = viewportRect.left - headerContainerRect.left;
        const mouseDifferenceX = pointerPosition.current.x - viewportRect.left;
        const localPositionX = scrolledDifferenceX + mouseDifferenceX;

        const index = Math.floor((localPositionX / headerContainerRect.width) * 7);

        const dayOfWeek = daysOfWeek[index];
        const droppedDateString = (dayOfWeek.querySelector('[data-date]') as HTMLElement).dataset.date;

        if (!droppedDateString) {
          toast({
            title: 'Something happened, please try again!',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        const droppedDate = new Date(droppedDateString);
        const day = format(droppedDate, 'dd');
        const month = getMonth(droppedDate);

        newDateTime.setDate(Number(day));
        newDateTime.setMonth(Number(month));
      }

      // Update action and create a new event
      onEntityDropped(draggedOutsideElement, newDateTime);
    },
  });

  const onDropFromOutside = useCallback(
    (e: DragFromOutsideItemArgs) => {
      if (!draggedOutsideElement) {
        return;
      }

      const event = {
        id: draggedOutsideElement,
      } as MyEvent;

      handleEventDrop({ event, start: e.start, end: e.end, isAllDay: e.allDay });

      setDraggedOutsideElement('');
    },
    [draggedOutsideElement, handleEventDrop],
  );

  const TimeSlotWrapper = useCallback(
    ({ value, children }: any) => (
      <Box color={value.getHours() === new Date().getHours() ? nowColor : fallbackColor}>{children}</Box>
    ),
    [fallbackColor, nowColor],
  );

  const containerHeight = useMemo(() => {
    if (typeof height === 'string') {
      return height;
    }

    if (height > CALENDAR_MAX_HEIGHT) {
      return `${CALENDAR_MAX_HEIGHT}px`;
    }

    return `${height}px`;
  }, [height]);

  return (
    <VStack ref={setNodeRef} position="relative" overflow="hidden" width="full" height={containerHeight}>
      <motion.div
        layout
        initial={{ opacity: 0, width: '100%', flex: 1, overflow: 'hidden' }}
        animate={{ opacity: 1, width: '100%' }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          height: 'inherit',
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
      >
        <DragAndDropCalendar
          onEventDrop={handleEventDrop}
          onDragStart={(e) => setDraggedOutsideElement(String(e.event.id))}
          onDropFromOutside={onDropFromOutside}
          onEventResize={eventResizable ? handleEventResize : undefined}
          className={view === WEEK ? 'extended-calendar schedule-calendar' : 'extended-calendar day'}
          showMultiDayTimes
          views={['day', 'week']}
          events={combinedEvents}
          step={TIME_STEP} // duration of the slot
          timeslots={4}
          defaultDate={addMinutes(new Date(), MINUTES_OFFSET)} // rollback time to show current time more in view.
          date={date}
          view={view}
          localizer={localizer}
          dayLayoutAlgorithm={view === DAY ? 'no-overlap' : 'overlap'}
          startAccessor="start"
          endAccessor="end"
          onNavigate={() => null}
          onView={() => null}
          draggableAccessor={(event) => !event.resource.isExternal}
          resizableAccessor={(event) => eventResizable && !(event.resource.isExternal || event.resource.planned)}
          allDayAccessor={(event) => event.resource.planned}
          formats={{
            timeGutterFormat: 'h a',
          }}
          popup
          allDayMaxRows={2}
          scrollToTime={addMinutes(new Date(), MINUTES_OFFSET)} // show 2 hours before so that the current time is more centered.
          components={{
            toolbar: CalendarToolbar,
            day: {
              header: () => <></>,
              event: (props) => (
                <ScheduledWeeklyTimeSlot {...props} onUnplan={onUnplan} onClick={onClickEvent} minHeight={rem(15)} />
              ),
            },
            week: {
              header: CalendarWeeklyHeader,
              event: (props) => (
                <ScheduledWeeklyTimeSlot {...props} onUnplan={onUnplan} onClick={onClickEvent} minHeight={rem(15)} />
              ),
            },
            timeSlotWrapper: view === WEEK ? TimeSlotWrapper : undefined,
          }}
          eventPropGetter={(event) => {
            return {
              className:
                !event.allDay && differenceInMinutes(event.end, event.start) < 30 ? 'short-duration' : undefined,
              style: {
                background: 'none',
                border: 'none',
                overflow: 'unset',
                padding: 0,
                margin: 0,
                boxShadow: '2px 2px 9px 0px rgba(0, 0, 0, 0.25)',
              },
            };
          }}
        />
      </motion.div>
    </VStack>
  );
}

export function DragAndDropCalendarWrapper({ date = new Date(), view, ...rest }: DragAndDropCalendarProps) {
  return (
    <CalendarProvider date={date} view={view}>
      <Component date={date} view={view} {...rest} />
    </CalendarProvider>
  );
}
