import { LeverageRequestStatus } from '@/gql/actions/types';
import { initials } from '@/utils/people';
import { getLeverageStatusIcon } from '@/utils/promises';
import rem from '@/utils/rem';
import { Button, Flex, Icon, StyleProps, Text } from '@chakra-ui/react';
import { memo } from 'react';

type Props = {
  name: string | undefined;
  isActive?: boolean;
  backgroundColor?: StyleProps['backgroundColor'];
  width?: StyleProps['width'];
  height?: StyleProps['height'];
  leverageStatusSize?: StyleProps['width'];
  textStyle?: StyleProps['textStyle'];
  border?: StyleProps['border'];
  leverageStatus?: LeverageRequestStatus;
  onClick?: () => void;
};

const PersonAvatar = ({
  name,
  isActive = false,
  backgroundColor = 'background-secondary',
  width = rem(40),
  height = rem(40),
  leverageStatusSize = rem(12),
  textStyle = 'mediumBlack',
  border,
  leverageStatus,
  onClick,
}: Props) => {
  return (
    <Flex
      as={onClick ? Button : 'div'}
      position="relative"
      alignItems="center"
      justifyContent="center"
      width={width}
      minWidth="auto"
      height={height}
      border={border}
      borderRadius={rem(4)}
      _hover={
        onClick
          ? {
              backgroundColor: 'background-quaternary',
            }
          : undefined
      }
      _focusVisible={{
        boxShadow: 'var(--chakra-shadows-outline)',
      }}
      aspectRatio={1}
      backgroundColor={isActive ? 'cyan.400' : backgroundColor}
      onClick={onClick}
      paddingInlineEnd={0}
      paddingInlineStart={0}
    >
      <Text
        as="span"
        textStyle={textStyle}
        color={isActive ? 'background-primary' : 'text-primary'}
        textTransform="uppercase"
      >
        {initials(name ?? '')}
      </Text>

      {leverageStatus && (
        <Icon
          as={getLeverageStatusIcon(leverageStatus)}
          position="absolute"
          right="-10%"
          bottom="-1%"
          width={leverageStatusSize}
          height={leverageStatusSize}
          aria-label={leverageStatus}
        />
      )}
    </Flex>
  );
};

export default memo(PersonAvatar);
