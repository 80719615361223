import Callout from '@/components/Callout';
import { IconCategories, IconPlus } from '@/theme/icons';
import rem from '@/utils/rem';
import { Icon, Text } from '@chakra-ui/react';

const EmptyCategoryCallout = () => (
  <Callout title="All your categories are empty" backgroundColor="background-tertiary">
    <Text textStyle="small">
      To create an action, expand your empty categories and click{' '}
      <Icon
        as={IconPlus}
        verticalAlign="middle"
        width={rem(22)}
        height={rem(22)}
        padding={rem(4)}
        color="text-primary"
        borderWidth={rem(1)}
        borderStyle="solid"
        borderColor="gray.700"
        borderRadius={rem(4)}
        marginX={rem(4)}
      />{' '}
      in the category you want to add an action to
    </Text>
    <Text
      sx={{
        'span:not(.block)': {
          opacity: '0.5',
        },
        '.block': {
          display: 'inline-flex',
          gap: rem(4),
          verticalAlign: 'top',
          marginRight: rem(8),
        },
      }}
      textStyle="small"
      marginTop={rem(14)}
      color="text-secondary"
    >
      <span>To create a category, click the</span>
      <Icon as={IconPlus} boxSize={rem(14)} verticalAlign="middle" marginX={rem(4)} />
      <span style={{ marginRight: rem(8) }}>under the category circles on the left, or click</span>
      <span className="block">
        <Icon as={IconCategories} boxSize={rem(14)} /> Categories
      </span>
      <span>in the header.</span>
    </Text>
  </Callout>
);

export default EmptyCategoryCallout;
