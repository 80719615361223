import DynamicSegmentedButton from '@/components/DynamicSegmentedButton';
import useToggleExpandCalendar, { DailyPlanView, DailyPlanViewType } from '@/stores/useToggleExpandCalendar';
import rem from '@/utils/rem';
import { HStack, StackProps, Text, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';

type DayToolbarProps = {
  date: Date;
} & StackProps;

export default function DayToolbar(props: DayToolbarProps) {
  const { dailyPlanView, setDailyPlanView } = useToggleExpandCalendar();

  return (
    <VStack alignItems="flex-start" gap={rem(20)} {...props}>
      <HStack alignItems="center" justifyContent="space-between" gap={rem(8)} width="full">
        <Text textStyle="small">
          <span
            style={{
              fontWeight: 900,
            }}
          >
            {format(props.date, 'EEEE')}
          </span>{' '}
          {format(props.date, 'MMMM dd')}
        </Text>
        <DynamicSegmentedButton
          onChange={(value: number) => setDailyPlanView(value as DailyPlanViewType)}
          options={[
            {
              value: DailyPlanView.Condense,
              label: 'Condense',
            },
            {
              value: DailyPlanView.Expand,
              label: 'Expand',
            },
          ]}
          layoutId="daily-plan-schedule-options"
          startIndex={dailyPlanView}
        />
      </HStack>
    </VStack>
  );
}
