import { CalendarDayHeader } from '@/components/CalendarDayHeader';
import { RoutesList } from '@/routes/routesList';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import useToggleDurationVisibility from '@/stores/useToggleDurationVisibility';
import { trackMyWeekSelected } from '@/utils/tracking';
import { useCallback } from 'react';
import { HeaderProps } from 'react-big-calendar';
import { useNavigate } from 'react-router-dom';

export function CalendarWeeklyHeader({ date }: HeaderProps) {
  const isDurationVisible = useToggleDurationVisibility((state) => state.isDurationVisible);
  const navigate = useNavigate();
  const updateSelectedDate = useCalendarMonthlyStore((state) => state.updateSelectedDate);

  const handleClick = useCallback(() => {
    updateSelectedDate(date);
    trackMyWeekSelected('planner');
    navigate(RoutesList.MyPlanDailyPage);
  }, [date, navigate, updateSelectedDate]);

  return <CalendarDayHeader date={date} showBorder showDurations={isDurationVisible} onClick={handleClick} />;
}
