import { ReactNode, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  children: ReactNode;
};

const ScrollRestoration = ({ children }: Props) => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById('')?.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
};

export default ScrollRestoration;
