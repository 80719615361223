import rem from '@/utils/rem';
import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  InputGroup,
  InputLeftElement,
  StyleProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

export type InputProps = {
  label?: string;
  value?: string;
  inlineLabel?: boolean;
  error?: string;
  leftIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  leftIconProps?: IconButtonProps;
  rightIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  rightIconProps?: IconButtonProps;
  containerProps?: FormControlProps;
  errorMessageProps?: FormErrorMessageProps;
  formControlWidth?: StyleProps['width'];
  labelProps?: StyleProps;
  optional?: boolean;
  variant?: 'outline' | 'filled' | 'flushed' | 'unstyled' | 'floating';
} & ChakraInputProps;

const Input = forwardRef<HTMLInputElement | null, InputProps>(
  (
    {
      label,
      error,
      leftIcon: LeftIcon,
      leftIconProps,
      rightIcon: RightIcon,
      rightIconProps,
      containerProps,
      errorMessageProps,
      formControlWidth = 'full',
      labelProps,
      variant = 'floatingWithPlaceholder',
      optional = false,
      ...rest
    },
    ref,
  ) => {
    return (
      <VStack alignItems="start" gap={0} width="full">
        {label && (
          <HStack marginBottom={rem(16)}>
            <Text color="text-tertiary" fontSize="sm" fontWeight={900} textTransform="uppercase" {...labelProps}>
              {label}
            </Text>
            {optional && (
              <Text
                color="text-tertiary"
                fontSize="sm"
                fontWeight={900}
                textTransform="uppercase"
                opacity={0.5}
                {...labelProps}
              >
                (optional)
              </Text>
            )}
          </HStack>
        )}
        <FormControl isInvalid={!!error} variant={variant} {...containerProps} width={formControlWidth}>
          <InputGroup alignItems="center" width="full">
            <ChakraInput
              ref={ref}
              padding="0"
              color="text-primary"
              fontWeight="normal"
              border="none"
              borderBottomWidth={rem(1)}
              borderBottomStyle="solid"
              borderBottomColor="stroke-primary"
              borderRadius="0"
              _hover={{
                boxShadow: 'none',
              }}
              _focusVisible={{
                boxShadow: 'none',
                borderColor: 'cyan.400 !important',
              }}
              _invalid={{ boxShadow: 'none', borderBottom: '1px solid red' }}
              _placeholder={{ color: 'text-tertiary' }}
              placeholder=" "
              {...rest}
            />
            {LeftIcon && (
              <InputLeftElement>
                <IconButton
                  border="none"
                  _hover={{ backgroundColor: 'gray.100' }}
                  aria-label="Right Icon Button"
                  backgroundColor="transparent"
                  icon={<LeftIcon />}
                  {...leftIconProps}
                />
              </InputLeftElement>
            )}
          </InputGroup>
          {error && (
            <FormErrorMessage color="red.500" {...errorMessageProps}>
              {error}
            </FormErrorMessage>
          )}
        </FormControl>

        {RightIcon && (
          <IconButton
            aria-label="Right Icon Button"
            icon={<Icon as={RightIcon} boxSize={rem(24)} />}
            variant="secondary"
            {...rightIconProps}
          />
        )}
      </VStack>
    );
  },
);

export default Input;
