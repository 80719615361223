import CategoryManagerOverviewCard from '@/components/CategoryManagerOverviewCard';
import CreateEditCategoryModal from '@/components/CreateEditCategoryModal';
import { useAnimatePlanPages } from '@/contexts/AnimatedPlanPages';
import { useCategories } from '@/services/categories/hooks';
import { IconPlus } from '@/theme/icons';
import { pageTransition } from '@/utils/pageAnimation';
import rem from '@/utils/rem';
import { trackCreateCategorySelected } from '@/utils/tracking';
import { Button, Grid, Icon, Text, useDisclosure } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Children } from 'react';
import DashboardLayout from 'src/layouts/Dashboard';

const CategoryManagerPage = () => {
  const { pageNavigationEffect } = useAnimatePlanPages();

  const { data: categoriesList } = useCategories();

  const {
    isOpen: isCreateCategoryOpen,
    onOpen: onCreateCategoryOpen,
    onClose: onCreateCategoryClose,
  } = useDisclosure({ id: 'createNewCategory' });

  return (
    <DashboardLayout
      pageTitle="Category Manager"
      rightSectionElements={
        <Button
          height="full"
          marginLeft="auto"
          padding={rem(8)}
          borderWidth={rem(1)}
          borderStyle="solid"
          borderColor="stroke-primary"
          borderRadius={rem(4)}
          backgroundColor="background-primary"
          leftIcon={<Icon as={IconPlus} color="text-primary" />}
          onClick={() => {
            onCreateCategoryOpen();
            trackCreateCategorySelected('category manager');
          }}
          variant="secondary"
        >
          <Text textStyle="small" color="text-primary" fontWeight={900}>
            Create New Category
          </Text>
        </Button>
      }
    >
      <motion.div
        className="row"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageNavigationEffect}
        transition={pageTransition}
      >
        <Grid
          gridRowGap={rem(16)}
          gridColumnGap={rem(16)}
          gridTemplateColumns={`repeat(auto-fill, minmax(${rem(500)}, 1fr))`}
          width="full"
          paddingBottom={rem(32)}
        >
          {Children.toArray(categoriesList?.category?.map((c) => <CategoryManagerOverviewCard category={c} />))}
        </Grid>
      </motion.div>

      <CreateEditCategoryModal isOpen={isCreateCategoryOpen} onClose={onCreateCategoryClose} showEntityModalOnCreate />
    </DashboardLayout>
  );
};

export default CategoryManagerPage;
