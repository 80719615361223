export const DASHBOARD = 'dashboard';
export const WEEK = 'week';
export const CALENDAR = 'calendar';
export const DAY = 'day';

export const CALENDAR_DASHBOARD_VIEW_SEGMENTED = [
  {
    id: 1,
    value: CALENDAR,
    label: 'Calendar',
  },
  {
    id: 2,
    value: DASHBOARD,
    label: 'Dashboard',
  },
];

export const DAILY_WEEKLY_VIEW_SEGMENTED = [
  {
    value: 0,
    label: 'Day',
  },
  {
    value: 1,
    label: 'Week',
  },
];

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// keep this in line with Date.getDay() = 0 for Sunday,
export const WEEKDAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
