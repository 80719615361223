import rem from '@/utils/rem';
import { As, Box, Flex, HStack, Icon, Progress, Text } from '@chakra-ui/react';

type Props = {
  color: string;
  icon: As;
  title: string;
  totalDuration: string;
  completedActionsPercentage: number;
};

function ProjectProgress({ color, icon, title, totalDuration, completedActionsPercentage }: Props) {
  return (
    <Box width="full">
      <Flex justifyContent="space-between" marginBottom={rem(16)}>
        <Text textStyle="smallBlack" color="text-primary" textTransform="uppercase">
          {title}
        </Text>
        <HStack gap={rem(4)}>
          <Icon as={icon} boxSize={rem(14)} color="text-primary" />
          <Text textStyle="small" color="text-primary">
            {totalDuration}
          </Text>
        </HStack>
      </Flex>

      <Progress
        sx={{
          '> div': {
            transitionProperty: 'width',
            backgroundColor: `var(--chakra-colors-${color.replace('.', '-')})`,
          },
          '@media print': {
            backgroundColor: 'print-background',
            border: `${rem(1)} solid var(--chakra-colors-print-stroke)`,
            '> div': {
              backgroundColor: 'print-text',
            },
          },
        }}
        height={rem(8)}
        borderRadius="lg"
        backgroundColor="background-secondary"
        value={completedActionsPercentage}
      />
    </Box>
  );
}

export default ProjectProgress;
