import { gql } from 'graphql-request';

export const GET_WEEKLY_ID = gql`
  mutation getOrCreateWeeklyPlan($dateFrom: Date!, $dateTo: Date!) {
    getOrCreateWeeklyPlan(dateFrom: $dateFrom, dateTo: $dateTo) {
      id
      reflection
      modifiedAt
    }
  }
`;

export const GET_OR_CREATE_WEEKLY_PLANS_BATCH = gql`
  mutation getOrCreateWeeklyPlans($objects: [WeeklyPlanInput!]!) {
    getOrCreateWeeklyPlans(objects: $objects) {
      plans {
        id
        dateFrom
      }
    }
  }
`;

export const UPDATE_WEEKLYPLAN_BY_PK = gql`
  mutation updateWeeklyPlanByPk($id: uuid!, $reflection: String) {
    updateWeeklyPlanByPk(pkColumns: { id: $id }, _set: { reflection: $reflection }) {
      id
      reflection
      modifiedAt
    }
  }
`;
