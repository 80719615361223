import Input from '@/components/Input';
import StyledModal from '@/components/StyledModal';
import { keys } from '@/gql/global/keys';
import { IconClear } from '@/theme/icons';
import { ImageInfo } from '@/types/inspirations';
import rem from '@/utils/rem';
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  IconButton,
  Image,
  InputGroup,
  InputRightElement,
  ModalBody,
  ModalHeader,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import { Children, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';
import { createApi } from 'unsplash-js';
import { z } from 'zod';

const UnsplashModalFormSchema = z.object({
  query: z.string().trim().min(1, { message: 'Name requires at least 1 character' }).max(100),
});

type FormData = z.infer<typeof UnsplashModalFormSchema>;

type Props = {
  isImagesModalOpen: boolean;
  onCloseImagesModal: () => void;
  onInsertInspirationImage: (imageInfo: ImageInfo) => void;
  isSavingImage: boolean;
  setIsSavingImage: (val: boolean) => void;
};

type Images = {
  full: string;
  raw: string;
  regular: string;
  small: string;
  small_s3?: string;
  thumb: string;
};

interface UnsplashPhoto {
  id: string;
  links: {
    download_location: string;
  };
  urls: Images;
  user: {
    links: {
      html: string;
    };
    name: string;
  };
}

interface UnsplashResponse {
  results: UnsplashPhoto[];
  total_pages: number;
}

const unsplash = () =>
  createApi({
    apiUrl: import.meta.env.VITE_GRAPHQL_API_URL.replace('/v1/graphql', '/api/rest/unsplash'),
    credentials: 'include' as RequestCredentials,
  });

async function getImageMimeType(imageUrl: string): Promise<string> {
  const response = await fetch(imageUrl);
  const contentType = response.headers.get('content-type');
  return contentType!.split('/')[1];
}

const trackUnsplashDownload = async (url: string) => {
  unsplash().photos.trackDownload({ downloadLocation: url });
};

const UnsplashModal = ({
  isImagesModalOpen,
  onCloseImagesModal,
  onInsertInspirationImage,
  isSavingImage = false,
  setIsSavingImage,
}: Props) => {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const { ref: loadMoreButtonRef, inView } = useInView();
  const [hoverPhotoId, setHoverPhotoId] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    reset,
    setFocus,
    formState: { errors, isValid },
  } = useForm<FormData>({
    defaultValues: {
      query: '',
    },
    resolver: zodResolver(UnsplashModalFormSchema),
    mode: 'onChange',
  });

  async function fetchUnsplashPhotos(page: number): Promise<UnsplashResponse> {
    const rawResponse = await unsplash().search.getPhotos({
      query: searchQuery,
      page,
      perPage: 20,
      orientation: 'landscape',
    });

    if (!rawResponse || rawResponse.type === 'error') {
      throw new Error('Error fetching photos');
    }

    const response = (rawResponse as { response?: { unsplashProxy?: typeof rawResponse } })?.response?.unsplashProxy;
    // if called through RPM backend proxy, the Unsplash response will be put into the `unsplashProxy` property
    const { results, total_pages } = response as unknown as UnsplashResponse;

    return {
      results,
      total_pages,
    };
  }

  async function fetchRandomUnsplashPhotos() {
    const rawResponse = await unsplash().photos.getRandom({
      count: 20,
      orientation: 'landscape',
    });

    if (!rawResponse || rawResponse.type === 'error') {
      throw new Error('Error fetching photos');
    }

    // if called through RPM backend proxy, the Unsplash response will be put into the `unsplashProxy` property
    const results = (rawResponse as { response?: { unsplashProxy?: typeof rawResponse } })?.response
      ?.unsplashProxy as unknown as UnsplashPhoto[];

    return {
      results,
      total_pages: 1,
    };
  }

  const { data: randomUnsplashImages, isLoading: isFetchingRandomImages } = useQuery({
    ...keys.unsplash.all,
    queryFn: fetchRandomUnsplashPhotos,
    enabled: isImagesModalOpen && !searchQuery,
  });

  const { data, error, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    keys.unsplash.list(searchQuery).queryKey,
    ({ pageParam = 1 }) => fetchUnsplashPhotos(pageParam),
    {
      enabled: isImagesModalOpen && searchQuery.length > 1,
      getNextPageParam: (lastPage) => {
        const nextPage = page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : undefined;
      },
    },
  );

  const onCancel = useCallback(() => {
    onCloseImagesModal();
    reset();
    setPage(1);
    setSearchQuery('');
  }, [onCloseImagesModal, reset]);

  const onSubmit = useCallback((form: FormData) => {
    setPage(1);
    setSearchQuery(form.query);
  }, []);

  const onImageSelection = useCallback(
    async (photo: UnsplashPhoto) => {
      setIsSavingImage(true);

      const mimeType = await getImageMimeType(photo?.urls?.raw);

      trackUnsplashDownload(photo.links.download_location);

      onInsertInspirationImage({
        imageUrl: photo?.urls?.raw,
        mimeType: mimeType ?? '',
      });
    },
    [onInsertInspirationImage, setIsSavingImage],
  );

  const handleClearSearch = useCallback(() => {
    reset();
    fetchRandomUnsplashPhotos();
    setSearchQuery('');
  }, [reset]);

  useEffect(() => {
    if (inView && !!searchQuery) {
      fetchNextPage();
      setPage((prevState) => prevState + 1);
    }
  }, [fetchNextPage, inView, searchQuery]);

  useEffect(() => {
    if (isImagesModalOpen) {
      // Does not work without setTimeout
      // must be removed after focus trap is enabled
      // (lookup the comments in the codebase by "RRI-2918")
      const timeoutId = setTimeout(() => setFocus('query'), 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isImagesModalOpen, setFocus]);

  if (error) {
    return <Box>Error fetching photos</Box>;
  }

  const photos = data?.pages ? data?.pages.flatMap((page) => page.results) : (randomUnsplashImages?.results ?? []);

  return (
    <StyledModal isOpen={isImagesModalOpen} onClose={onCancel} scrollBehavior="inside" size="5xl" showCloseButton>
      <ModalHeader>
        <Text>Photos by Unsplash</Text>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row" width="full" marginTop={rem(30)} spacing={8}>
            <Controller
              name="query"
              control={control}
              render={({ field: { value, onChange, ref } }) => (
                <InputGroup width="full">
                  <Input
                    ref={ref}
                    label="Search Unsplash photos by topics or colors*"
                    error={errors.query?.message}
                    onChange={onChange}
                    value={value}
                    formControlWidth="100%"
                    fontWeight="normal"
                    fontSize={rem(16)}
                    autoComplete="off"
                  />

                  {!!searchQuery.length && (
                    <InputRightElement>
                      <IconButton
                        minWidth={rem(16)}
                        height={rem(16)}
                        padding={rem(3)}
                        border="none"
                        borderRadius="full"
                        aria-label="Clear search"
                        backgroundColor="transparent"
                        icon={<IconClear />}
                        onClick={handleClearSearch}
                      />
                    </InputRightElement>
                  )}
                </InputGroup>
              )}
            />
            <Button alignSelf="end" height={rem(50)} isDisabled={!isValid} type="submit" variant="primary">
              Search
            </Button>
          </Stack>
        </form>
      </ModalHeader>
      <ModalBody>
        {(isFetchingNextPage || isFetchingRandomImages) && (
          <Flex alignItems="center" justifyContent="center" width="full" height="full">
            <Spinner color="white.500" />
          </Flex>
        )}
        <Grid
          position="relative"
          gridGap={6}
          gridTemplateColumns={`repeat(auto-fill, minmax(${rem(200)}, 1fr))`}
          width="full"
        >
          {isSavingImage && (
            <Flex
              position="absolute"
              zIndex={1}
              alignItems="center"
              justifyContent="center"
              width="full"
              height="full"
              backgroundColor="blackAlpha.800"
            >
              <Spinner color="white.500" />
            </Flex>
          )}
          {!isFetchingRandomImages && isEmpty(photos) && <Text paddingBottom={rem(20)}>No result found</Text>}
          {Children.toArray(
            photos?.map((photo) => (
              <AspectRatio maxWidth="100%" ratio={1}>
                <Box
                  position="relative"
                  cursor="pointer"
                  onClick={() => onImageSelection(photo)}
                  onMouseEnter={() => setHoverPhotoId(photo.id)}
                  onMouseLeave={() => setHoverPhotoId(null)}
                  role="group"
                >
                  <Image boxSize="100%" fit="cover" src={photo?.urls?.small} />
                  <AnimatePresence>
                    {hoverPhotoId === photo.id && (
                      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <Box
                          position="absolute"
                          bottom={0}
                          left={0}
                          style={{
                            display: 'flex',
                            flex: 1,
                            width: '100%',
                            height: '100%',
                            fontSize: 'small',
                            background: 'linear-gradient(0deg, rgba(0,0,0,0.62) 0%, rgba(255,255,255,0) 37%)',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-start',
                            paddingLeft: rem(10),
                            paddingBottom: rem(8),
                          }}
                        >
                          <Text>
                            <a
                              style={{ textDecoration: 'underline' }}
                              target="_blank"
                              href={`${photo.user.links.html}?utm_source=${import.meta.env.VITE_UNSPLASH_APP_NAME}&utm_medium=referral"`}
                            >
                              {photo?.user?.name}
                            </a>
                          </Text>
                        </Box>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Box>
              </AspectRatio>
            )),
          )}
        </Grid>

        {hasNextPage && (
          <Flex ref={loadMoreButtonRef} justifyContent="center" width="full" marginTop={rem(15)}>
            {isFetchingNextPage ? (
              <HStack justifyContent="center" width="full">
                <Spinner />
              </HStack>
            ) : (
              <Button
                onClick={() => {
                  fetchNextPage();
                  setPage((prevState) => prevState + 1);
                }}
              >
                Load more
              </Button>
            )}
          </Flex>
        )}
      </ModalBody>
    </StyledModal>
  );
};

export default UnsplashModal;
