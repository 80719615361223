import DialogModal from '@/components/DialogModal';
import { trackActionDeleted } from '@/utils/tracking';

type Props = {
  isOpen: boolean;
  onCloseDeleteActionModal: () => void;
  onDeleteAction: () => void;
};

const DeleteActionModal = ({ isOpen, onCloseDeleteActionModal, onDeleteAction }: Props) => {
  return (
    <DialogModal
      isOpen={isOpen}
      title="Delete Action"
      message="Do you really want to delete this action?"
      onCancel={onCloseDeleteActionModal}
      onConfirm={() => {
        onDeleteAction();
        trackActionDeleted();
      }}
      confirmVariant="danger"
      confirmText="Delete action"
    />
  );
};

export default DeleteActionModal;
