export const satoshi = `
    @font-face {
        font-family: 'Satoshi';
        src: url('/fonts/satoshi/Satoshi-Variable.woff2') format('woff2'),
            url('/fonts/satoshi/Satoshi-Variable.woff') format('woff'),
            url('/fonts/satoshi/Satoshi-Variable.ttf') format('truetype');
            font-weight: 300 900;
            font-display: swap;
            font-style: normal;
    }

    @font-face {
        font-family: 'Satoshi';
        src: url('/fonts/satoshi/Satoshi-VariableItalic.woff2') format('woff2'),
            url('/fonts/satoshi/Satoshi-VariableItalic.woff') format('woff'),
            url('/fonts/satoshi/Satoshi-VariableItalic.ttf') format('truetype');
            font-weight: 300 900;
            font-display: swap;
            font-style: italic;
    }


    @font-face {
        font-family: 'Satoshi';
        src: url('/fonts/satoshi/Satoshi-Light.woff2') format('woff2'),
            url('/fonts/satoshi/Satoshi-Light.woff') format('woff'),
            url('/fonts/satoshi/Satoshi-Light.ttf') format('truetype');
            font-weight: 300;
            font-display: swap;
            font-style: normal;
    }


    @font-face {
        font-family: 'Satoshi';
        src: url('/fonts/satoshi/Satoshi-LightItalic.woff2') format('woff2'),
            url('/fonts/satoshi/Satoshi-LightItalic.woff') format('woff'),
            url('/fonts/satoshi/Satoshi-LightItalic.ttf') format('truetype');
            font-weight: 300;
            font-display: swap;
            font-style: italic;
    }


    @font-face {
        font-family: 'Satoshi';
        src: url('/fonts/satoshi/Satoshi-Regular.woff2') format('woff2'),
            url('/fonts/satoshi/Satoshi-Regular.woff') format('woff'),
            url('/fonts/satoshi/Satoshi-Regular.ttf') format('truetype');
            font-weight: 400;
            font-display: swap;
            font-style: normal;
    }


    @font-face {
        font-family: 'Satoshi';
        src: url('/fonts/satoshi/Satoshi-Italic.woff2') format('woff2'),
            url('/fonts/satoshi/Satoshi-Italic.woff') format('woff'),
            url('/fonts/satoshi/Satoshi-Italic.ttf') format('truetype');
            font-weight: 400;
            font-display: swap;
            font-style: italic;
    }


    @font-face {
        font-family: 'Satoshi';
        src: url('/fonts/satoshi/Satoshi-Medium.woff2') format('woff2'),
            url('/fonts/satoshi/Satoshi-Medium.woff') format('woff'),
            url('/fonts/satoshi/Satoshi-Medium.ttf') format('truetype');
            font-weight: 500;
            font-display: swap;
            font-style: normal;
    }


    @font-face {
        font-family: 'Satoshi';
        src: url('/fonts/satoshi/Satoshi-MediumItalic.woff2') format('woff2'),
            url('/fonts/satoshi/Satoshi-MediumItalic.woff') format('woff'),
            url('/fonts/satoshi/Satoshi-MediumItalic.ttf') format('truetype');
            font-weight: 500;
            font-display: swap;
            font-style: italic;
    }


    @font-face {
        font-family: 'Satoshi';
        src: url('/fonts/satoshi/Satoshi-Bold.woff2') format('woff2'),
            url('/fonts/satoshi/Satoshi-Bold.woff') format('woff'),
            url('/fonts/satoshi/Satoshi-Bold.ttf') format('truetype');
            font-weight: 700;
            font-display: swap;
            font-style: normal;
    }


    @font-face {
        font-family: 'Satoshi';
        src: url('/fonts/satoshi/Satoshi-BoldItalic.woff2') format('woff2'),
            url('/fonts/satoshi/Satoshi-BoldItalic.woff') format('woff'),
            url('/fonts/satoshi/Satoshi-BoldItalic.ttf') format('truetype');
            font-weight: 700;
            font-display: swap;
            font-style: italic;
    }


    @font-face {
        font-family: 'Satoshi';
        src: url('/fonts/satoshi/Satoshi-Black.woff2') format('woff2'),
            url('/fonts/satoshi/Satoshi-Black.woff') format('woff'),
            url('/fonts/satoshi/Satoshi-Black.ttf') format('truetype');
            font-weight: 900;
            font-display: swap;
            font-style: normal;
    }


    @font-face {
        font-family: 'Satoshi';
        src: url('/fonts/satoshi/Satoshi-BlackItalic.woff2') format('woff2'),
            url('/fonts/satoshi/Satoshi-BlackItalic.woff') format('woff'),
            url('/fonts/satoshi/Satoshi-BlackItalic.ttf') format('truetype');
            font-weight: 900;
            font-display: swap;
            font-style: italic;
    }


`;

export const barlowCond = ` 
    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-ThinItalic.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-ThinItalic.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-ThinItalic.ttf') format('truetype');
        font-weight: 100;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-Thin.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-Thin.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-Thin.ttf') format('truetype');
        font-weight: 100;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-ExtraLightItalic.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-ExtraLightItalic.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-ExtraLightItalic.ttf') format('truetype');
        font-weight: 200;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-ExtraLight.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-ExtraLight.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-ExtraLight.ttf') format('truetype');
        font-weight: 200;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-Light.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-Light.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-Light.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-LightItalic.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-LightItalic.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-LightItalic.ttf') format('truetype');
        font-weight: 300;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-Italic.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-Italic.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-Italic.ttf') format('truetype');
        font-weight: 400;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-Regular.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-Regular.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-Regular.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-Medium.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-Medium.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-Medium.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-MediumItalic.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-MediumItalic.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-MediumItalic.ttf') format('truetype');
        font-weight: 500;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-SemiBold.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-SemiBold.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-SemiBold.ttf') format('truetype');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-SemiBoldItalic.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-SemiBoldItalic.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-SemiBoldItalic.ttf') format('truetype');
        font-weight: 600;
        font-style: italic;
        font-display: swap;
    }



    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-Bold.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-Bold.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-Bold.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-BoldItalic.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-BoldItalic.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-BoldItalic.ttf') format('truetype');
        font-weight: 700;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-ExtraBold.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-ExtraBold.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-ExtraBold.ttf') format('truetype');
        font-weight: 800;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-ExtraBoldItalic.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-ExtraBoldItalic.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-ExtraBoldItalic.ttf') format('truetype');
        font-weight: 800;
        font-style: italic;
        font-display: swap;
    }

     @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-Black.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-Black.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-Black.ttf') format('truetype');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Barlow Condensed';
        src: url('/fonts/barlowCond/BarlowCondensed-BlackItalic.woff2') format('woff2'),
            url('/fonts/barlowCond/BarlowCondensed-BlackItalic.woff') format('woff'),
            url('/fonts/barlowCond/BarlowCondensed-BlackItalic.ttf') format('truetype');
        font-weight: 900;
        font-style: italic;
        font-display: swap;
    }
`;
