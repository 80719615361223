import { GetBlocksResponse } from '@/gql/block/types';
import { GetBlockEventResponse } from '@/gql/blockEvent/types';
import { keys } from '@/gql/global/keys';
import { GetProjectResponse } from '@/gql/project/types';
import { queryClient } from '@/services/graphql/queryClient';
import { getWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { getSelectedDate } from '@/stores/useCalendar';
import { Action, ActionResponse } from '@/types/actions';
import { Block } from '@/types/block';

import { getMonthlyCalendarQueryKey } from './query';

export function getBlockById(blocks: Block[], blockId: string) {
  return blocks.find((block) => block.id === blockId);
}

export function deleteBlockCache(blockId: string) {
  queryClient.setQueryData<GetBlocksResponse>(keys.blocks.all.queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      ...state,
      block: state.block.filter((block) => block.id !== blockId),
    };
  });

  queryClient.setQueriesData<GetBlockEventResponse>(keys.blockEvents.list._def, (state) => {
    if (state == null) {
      return state;
    }

    return {
      ...state,
      blockEvent: state.blockEvent.filter((item) => item.blockId !== blockId),
    };
  });
}

// TODO we probably can get away with categoryId and projectId instead of full objects
export const updateBlockCategoryAndProjectInCache = (
  { id: updatedBlockId, category, project }: Partial<Block>,
  previousCategoryId: string,
  previousProjectId: string | undefined,
) => {
  const selectedDate = getSelectedDate();

  if (!selectedDate || !category) {
    return;
  }

  const weeklyPlanId = getWeeklyPlanId(selectedDate);

  const cacheKeys = [];
  cacheKeys.push(getMonthlyCalendarQueryKey());
  weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
  // when category ID changes it's a separate case, see below
  category.id &&
    category.id === previousCategoryId &&
    cacheKeys.push(keys.actions.all._ctx.categoryManager(category.id).queryKey);

  cacheKeys.forEach((cache) =>
    queryClient.setQueryData<ActionResponse>(cache, (state) => {
      if (state == null) {
        return state;
      }

      return {
        action: state.action.map((action) => {
          if (action.blockId === updatedBlockId) {
            return {
              ...action,
              projectId: project?.id,
              project,
            };
          }
          return action;
        }),
      };
    }),
  );

  if (category.id && category.id !== previousCategoryId) {
    // remove actions from the previous category
    // add actions to the new category
    const movingActions: Action[] = [];
    queryClient.setQueryData<ActionResponse>(
      keys.actions.all._ctx.categoryManager(previousCategoryId).queryKey,
      (state) => {
        if (state == null) {
          return state;
        }
        return {
          action: state.action.filter((action) => {
            if (action.blockId !== updatedBlockId) {
              movingActions.push(action);
              return true;
            }
            return false;
          }),
        };
      },
    );

    queryClient.setQueryData<ActionResponse>(keys.actions.all._ctx.categoryManager(category.id).queryKey, (state) => {
      if (state == null) {
        return state;
      }
      return {
        action: [
          ...state.action,
          ...movingActions.map((action) => ({
            ...action,
            categoryId: category.id,
          })),
        ],
      };
    });
  }

  if (previousProjectId && previousProjectId !== project?.id) {
    // remove all actions of the block that is moved out of the project
    queryClient.setQueryData<GetProjectResponse>(keys.project.detail(previousProjectId).queryKey, (state) => {
      if (!state) {
        return state;
      }
      return {
        projectByPk: {
          ...state.projectByPk,
          actions: state.projectByPk.actions.filter((action) => {
            return action.blockId !== updatedBlockId;
          }),
          blocks: state.projectByPk.blocks.filter((block) => {
            return block.id !== updatedBlockId;
          }),
        },
      };
    });
  }

  queryClient.setQueryData<GetBlocksResponse>(keys.blocks.all.queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      ...state,
      block: state.block.map((block) => {
        if (block.id === updatedBlockId) {
          return {
            ...block,
            category,
            categoryId: category.id,
            project,
            projectId: project?.id,
          };
        }

        return block;
      }),
    };
  });
};
