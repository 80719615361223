import { CATEGORIES_ICONS } from '@/constants/category';
import { useCategories } from '@/services/categories/hooks';
import { useProjects } from '@/services/project/hooks';
import { IconClear, IconProject } from '@/theme/icons';
import { Category } from '@/types/category';
import { ProjectType } from '@/types/project';
import rem from '@/utils/rem';
import {
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  StyleProps,
  Text,
} from '@chakra-ui/react';
import { isEmpty, truncate } from 'lodash';
import { MouseEvent, useCallback, useMemo, useState } from 'react';

const CategoryIcon = ({ color, icon }: Partial<Category>) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    minWidth={rem(24)}
    height={rem(24)}
    marginRight={rem(8)}
    borderRadius="full"
    backgroundColor={color ?? 'gray.400'}
  >
    <Icon as={CATEGORIES_ICONS?.[icon ?? 'uncategorized']} boxSize={rem(12)} fill="white.500" />
  </Flex>
);

type ProjectsMenuProps = {
  onOpen?: () => void;
  onClose?: () => void;
  isLoading?: boolean;
  categoryId?: string;
  project: ProjectType | null;
  setSelectedProject?: (project: ProjectType | null) => void;
  readonly?: boolean;
  width?: StyleProps['width'];
  showCategoryIcon?: boolean;
  variant?: 'default' | 'bordered';
};

const ProjectsMenu = ({
  onOpen,
  onClose,
  isLoading = false,
  categoryId,
  project,
  setSelectedProject,
  readonly,
  width = rem(200),
  showCategoryIcon = false,
  variant = 'default',
}: ProjectsMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: categories } = useCategories();
  const { data: projects } = useProjects();

  const allProjects = projects?.project;

  const filteredProjects = useMemo(
    () => (categoryId ? projects?.project?.filter((el) => el?.categoryId === categoryId) : (projects?.project ?? [])),
    [categoryId, projects?.project],
  );

  const projectsWithCategory = useMemo(() => {
    return filteredProjects?.map((p) => {
      const cat = categories?.category?.find((c) => p.categoryId === c.id);

      return {
        ...p,
        color: cat?.color,
        icon: cat?.icon,
        isSelected: project?.id === p.id,
      };
    });
  }, [filteredProjects, categories?.category, project?.id]);

  const currentProject = useMemo(() => projectsWithCategory?.find((p) => p.isSelected), [projectsWithCategory]);

  const handleSelectProject = useCallback(
    (projectId: string | null) => {
      if (!projectId) {
        return;
      }

      const selected = filteredProjects?.find(({ id }) => id === projectId);

      if (!selected) {
        return;
      }

      setSelectedProject && setSelectedProject(selected);
    },
    [filteredProjects, setSelectedProject],
  );

  const handleClearProject = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setSelectedProject && setSelectedProject(null);
    },
    [setSelectedProject],
  );

  const onOpenMenu = useCallback(() => {
    setIsOpen(true);
    onOpen && onOpen();
  }, [onOpen]);

  const onCloseMenu = useCallback(() => {
    setIsOpen(false);
    onClose && onClose();
  }, [onClose]);

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" width={rem(160)}>
        <Spinner />
      </Flex>
    );
  }

  return (
    <Menu isLazy isOpen={isOpen} onClose={onCloseMenu} onOpen={onOpenMenu}>
      {/* lineHeight: 0 to reset the line-height set by the parent element
          of the categories menu, e.g. a modal header.
          'initial' doesn't work for this */}
      <Box position="relative" width={width} lineHeight={0}>
        <MenuButton
          as={Button}
          width={width}
          height={rem(28)}
          color="text-primary"
          border={variant === 'bordered' ? `${rem(1)} solid var(--chakra-colors-stroke-primary)` : undefined}
          borderRadius={rem(4)}
          _hover={{
            backgroundColor: 'background-quaternary',
          }}
          _active={{
            backgroundColor: 'background-secondary',
          }}
          pointerEvents={readonly ? 'none' : 'visible'}
          backgroundColor="background-primary"
          leftIcon={<Icon as={IconProject} boxSize={rem(14)} />}
          paddingX={rem(8)}
        >
          <Flex alignItems="center">
            {currentProject && showCategoryIcon && (
              <CategoryIcon color={currentProject.color} icon={currentProject.icon} />
            )}

            <Text
              textStyle="small"
              overflow="hidden"
              paddingRight={currentProject ? rem(24) : 0}
              color="text-primary"
              textAlign="left"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {currentProject?.name || 'Choose Project'}
            </Text>
          </Flex>
        </MenuButton>

        {currentProject && !readonly && (
          <Button
            position="absolute"
            top="50%"
            right={rem(8)}
            minWidth={rem(16)}
            height={rem(16)}
            padding="0"
            borderRadius="99"
            _hover={{
              backgroundColor: 'background-queternary',
            }}
            transform={`translateY(-50%)`}
            aria-label="Clear Project"
            backgroundColor="gray.900"
            onClick={handleClearProject}
          >
            <Icon as={IconClear} boxSize={rem(8)} color="white.500" />
          </Button>
        )}
      </Box>

      <MenuList
        zIndex={3}
        overflowX="hidden"
        overflowY="scroll"
        maxHeight={rem(300)}
        padding={0}
        borderRadius={4}
        boxShadow="0px 0px 10px 0px var(--chakra-colors-black-900a06)"
        backgroundColor={!isEmpty(projectsWithCategory) ? 'gray.700' : 'gray.800'}
      >
        {isEmpty(allProjects) && (
          <MenuItem
            key="empty-project"
            justifyContent="flex-start"
            textTransform="initial"
            cursor="not-allowed"
            backgroundColor="background-secondary"
            onClick={() => null}
          >
            <Text textStyle="small" color="text-primary" noOfLines={1}>
              There are no projects created yet
            </Text>
          </MenuItem>
        )}
        {!isEmpty(allProjects) && isEmpty(projectsWithCategory) && (
          <MenuItem
            key="empty-project-with-category"
            justifyContent="flex-start"
            fontSize="sm"
            textTransform="initial"
            cursor="not-allowed"
            backgroundColor="background-secondary"
            onClick={() => null}
          >
            <Text textStyle="small" color="text-primary" noOfLines={1}>
              There are no projects for this category
            </Text>
          </MenuItem>
        )}
        {projectsWithCategory?.map(({ id, icon, name, color }) => (
          <MenuItem
            key={id}
            as={Button}
            sx={{
              '&:hover, &:focus': {
                backgroundColor: 'background-secondary',
              },
            }}
            justifyContent="flex-start"
            fontSize="sm"
            textTransform="initial"
            borderRadius={0}
            backgroundColor="background-tertiary"
            onClick={() => handleSelectProject(id)}
          >
            {showCategoryIcon && <CategoryIcon color={color} icon={icon} />}
            <Text textStyle="small" color="text-primary" noOfLines={1}>
              {truncate(name)}
            </Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default ProjectsMenu;
