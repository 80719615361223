import { keys } from '@/gql/global/keys';
import { APIError } from '@/gql/global/types';
import {
  CREATE_KEY_RESULT,
  CREATE_PROJECT,
  DELETE_INSPIRATION_BOARD_ITEM_BY_PK,
  DELETE_KEY_RESULT,
  DELETE_PROJECT,
  GET_PROJECT,
  GET_PROJECTS_LIST,
  GET_PROJECTS_LIST_BY_ID,
  INSERT_IMAGE_ONE,
  INSERT_NOTE_ONE,
  UPDATE_ACTIONS_PROJECT_ORDER,
  UPDATE_BLOCK_ORDER,
  UPDATE_KEY_RESULT,
  UPDATE_KEY_RESULT_ORDER,
  UPDATE_NOTE_BY_PK,
  UPDATE_PROJECT,
  UPDATE_PROJECT_IMAGE,
} from '@/gql/project';
import {
  AddActionToBlockPayload,
  CreateKeyResultPayload,
  CreateKeyResultResponse,
  CreateProjectPayload,
  CreateProjectResponse,
  DeleteInspirationBoardPayload,
  DeleteInspirationBoardResponse,
  DeleteKeyResultPayload,
  DeleteKeyResultResponse,
  DeleteProjectPayload,
  DeleteProjectResponse,
  EditInspirationNotePayload,
  EditInspirationNoteResponse,
  GetProjectResponse,
  InsertImagePayload,
  InsertImageResponse,
  InsertNotePayload,
  InsertNoteResponse,
  ProjectDataTypeById,
  QueryDataType,
  QueryImageDataType,
  UpdateActionsProjectOrderPayload,
  UpdateActionsProjectOrderResponse,
  UpdateBlockActionsBlockOrderPayload,
  UpdateBlockProjectOrderResponse,
  UpdateKeyResultOrderPayload,
  UpdateKeyResultOrderResponse,
  UpdateKeyResultPayload,
  UpdateKeyResultResponse,
  UpdateProjectImagePayload,
  UpdateProjectImageResponse,
  UpdateProjectPayload,
  UpdateProjectResponse,
  UpdateWeeklyBlockOrderPayload,
} from '@/gql/project/types';
import { useCategories } from '@/services/categories/hooks';
import { fetchData } from '@/services/graphql';
import { queryClient } from '@/services/graphql/queryClient';
import { Action } from '@/types/actions';
import { Block } from '@/types/block';
import { Category } from '@/types/category';
import { getActionById, updateAction } from '@/utils/action';
import { getCategoryById } from '@/utils/category';
import { useToast } from '@chakra-ui/react';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { orderBy } from 'lodash';

type UpdateActionsOrderDTO = {
  actionId: string;
  projectOrder: number;
};

type UpdateBlockActionsOrderDTO = {
  actionId: string;
  blockOrder: number;
};

type UpdateKeyResultsOrderDTO = {
  keyResultId: string;
  order: number;
};

type UpdateBlockProjectOrderDTO = {
  blockId: string;
  projectOrder: number;
};

export const useProjects = (options?: UseQueryOptions<QueryDataType, APIError, QueryDataType>) => {
  return useQuery({
    queryKey: keys.project.all.queryKey,
    queryFn: () => fetchData(GET_PROJECTS_LIST),
    ...options,
  });
};

export const useProjectsByCategoryId = (
  categoryId: string,
  options?: UseQueryOptions<ProjectDataTypeById, APIError, ProjectDataTypeById>,
) => {
  return useQuery({
    queryKey: keys.project.category(categoryId).queryKey,

    queryFn: () =>
      fetchData(GET_PROJECTS_LIST_BY_ID, {
        categoryId,
      }),
    ...options,
    keepPreviousData: true,
  });
};

export const useCreateProject = (
  options?: Omit<UseMutationOptions<CreateProjectResponse, APIError, CreateProjectPayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload) => fetchData(CREATE_PROJECT, payload),

    onSuccess: (data) => {
      queryClient.invalidateQueries(keys.project.all);

      if (data?.insertProjectOne?.categoryId) {
        queryClient.resetQueries({
          queryKey: keys.project.category(data.insertProjectOne.categoryId).queryKey,
          exact: true,
        });
      }
    },

    ...options,
  });
};

export const useUpdateProject = () => {
  const toast = useToast();

  return useMutation({
    mutationFn: (payload: UpdateProjectPayload) => fetchData<UpdateProjectResponse>(UPDATE_PROJECT, payload),

    onMutate: (variables) => {
      const previousDetailData = queryClient.getQueryData<GetProjectResponse>(
        keys.project.detail(variables.id).queryKey,
      );

      queryClient.setQueryData<GetProjectResponse>(keys.project.detail(variables.id).queryKey, (state) => {
        if (state == null) {
          return state;
        }

        return {
          projectByPk: {
            ...state.projectByPk,
            ...variables,
          },
        };
      });

      const previousAllData = queryClient.getQueryData<QueryDataType>(keys.project.all.queryKey);

      queryClient.setQueryData<QueryDataType>(keys.project.all.queryKey, (state) => {
        if (state == null) {
          return state;
        }

        return {
          project: state.project.map((project) => {
            if (project.id === variables.id) {
              return {
                ...variables,
                actions: project.actions,
              };
            }
            return project;
          }),
        };
      });

      queryClient.resetQueries(keys.project.category(variables.categoryId).queryKey);

      return {
        previousDetailData,
        previousAllData,
      };
    },

    onError: (error: any, variables, context) => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });

      queryClient.setQueryData<GetProjectResponse>(
        keys.project.detail(variables.id).queryKey,
        (context as any).previousDetailData,
      );

      queryClient.setQueryData<QueryDataType>(keys.project.all.queryKey, (context as any).previousAllData);
    },
  });
};

export const useUpdateProjectImage = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: (payload: UpdateProjectImagePayload) =>
      fetchData<UpdateProjectImageResponse>(UPDATE_PROJECT_IMAGE, payload),

    onMutate: (variables) => {
      const previousDetailData = queryClient.getQueryData<GetProjectResponse>(
        keys.project.detail(variables.id).queryKey,
      );

      queryClient.setQueryData<GetProjectResponse>(keys.project.detail(variables.id).queryKey, (state) => {
        if (state == null) {
          return state;
        }

        return {
          projectByPk: {
            ...state.projectByPk,
            ...variables,
          },
        };
      });

      const previousAllData = queryClient.getQueryData<QueryImageDataType>(keys.project.all.queryKey);

      queryClient.setQueryData<QueryImageDataType>(keys.project.all.queryKey, (state) => {
        if (state == null) {
          return state;
        }

        return {
          project: state.project.map((project) => {
            if (project.id === variables.id) {
              return {
                ...project,
                ...variables,
                actions: project.actions,
              };
            }
            return project;
          }),
        };
      });

      return {
        previousDetailData,
        previousAllData,
      };
    },

    onError: (error: any, variables, context) => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });

      queryClient.setQueryData<GetProjectResponse>(
        keys.project.detail(variables.id).queryKey,
        (context as any).previousDetailData,
      );

      queryClient.setQueryData<QueryImageDataType>(keys.project.all.queryKey, (context as any).previousAllData);
    },
  });
};

export const useDeleteProject = (
  options?: Omit<UseMutationOptions<DeleteProjectResponse, APIError, DeleteProjectPayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload) => fetchData(DELETE_PROJECT, payload),

    onSuccess: (data) => {
      queryClient.invalidateQueries(keys.project.all);

      queryClient.setQueryData<QueryDataType>(keys.project.all.queryKey, (state) => {
        if (state == null) {
          return state;
        }

        return {
          project: state.project.filter((project) => project.id !== data?.deleteProjectByPk?.id),
        };
      });
    },

    ...options,
  });
};

export const addCategoryToActions = (actions: Action[], categories: Category[]): Action[] => {
  return actions.map((action) => {
    let category;

    if (action.categoryId) {
      category = getCategoryById(categories, action.categoryId);
    }

    if (!category) {
      return action;
    }

    return {
      ...action,
      category,
    };
  });
};

export const addCategoryToBlocks = (blocks: Block[], categories: Category[]) => {
  return blocks.map((block: Block) => {
    const category = getCategoryById(categories, block.categoryId);

    if (!category) {
      return block;
    }

    return {
      ...block,
      category,
    };
  });
};

export const useProjectDetail = (
  id?: string,
  options?: UseQueryOptions<GetProjectResponse, APIError, GetProjectResponse>,
) => {
  const { refetch: fetchCategories } = useCategories({
    enabled: false,
  });

  return useQuery({
    queryKey: keys.project.detail(id ?? '').queryKey,

    queryFn: async () => {
      const response = await fetchData(GET_PROJECT, {
        id,
      });

      const categories = await fetchCategories();

      if (!categories?.data) {
        return response;
      }

      const actions = addCategoryToActions(response.projectByPk.actions, categories.data.category);

      const blocks = response.projectByPk.blocks.map((block: Block) => ({
        ...block,
        projectId: id,
      }));

      return {
        projectByPk: {
          ...response.projectByPk,
          actions,
          blocks: addCategoryToBlocks(blocks, categories.data.category),
        },
      };
    },
    ...options,
  });
};

export const useUpdateActionsProjectOrder = (
  options?: UseMutationOptions<UpdateActionsProjectOrderResponse, APIError, UpdateActionsProjectOrderPayload[]>,
) => {
  return useMutation({
    mutationFn: (actionUpdates: UpdateActionsProjectOrderPayload[]) =>
      fetchData<UpdateActionsProjectOrderResponse>(UPDATE_ACTIONS_PROJECT_ORDER, { actionUpdates }),

    ...options,
  });
};

export const addProjectActionToCache = (updatedAction: Action, projectId: string) => {
  queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      projectByPk: {
        ...state.projectByPk,
        actions: [...state.projectByPk.actions, updatedAction],
      },
    };
  });
};

export const deleteActionInProjectFromCache = (deletedAction: Action, projectId: string) => {
  queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      projectByPk: {
        ...state.projectByPk,
        actions: state.projectByPk.actions.filter((action) => action.id !== deletedAction.id),
      },
    };
  });
};

export const updateActionsProjectOrderOnCache = (payload: UpdateActionsProjectOrderPayload[], projectId: string) => {
  let actions: UpdateActionsOrderDTO[] = [];

  payload.map(({ where, _set }) => {
    actions = [
      ...actions,
      {
        actionId: String(where.id._eq),
        projectOrder: Number(_set.projectOrder),
      },
    ];
  });

  queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      projectByPk: {
        ...state.projectByPk,
        actions: orderBy(
          state.projectByPk.actions.map((action) => {
            const updatedAction = actions.find((a) => a.actionId === action.id);

            if (updatedAction) {
              return {
                ...action,
                projectOrder: updatedAction.projectOrder,
              };
            }

            return action;
          }),
          'projectOrder',
          'asc',
        ),
      },
    };
  });
};

export const addProjectBlockToCache = (block: Block, projectId: string) => {
  queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      projectByPk: {
        ...state.projectByPk,
        blocks: [...state.projectByPk.blocks, { ...block, actions: [] }],
      },
    };
  });
};

export const addActionToBlockInProjectCache = (
  { blockId, actionId, blockOrder }: AddActionToBlockPayload,
  projectId: string,
) => {
  queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      projectByPk: {
        ...state.projectByPk,
        blocks: state.projectByPk.blocks.map((block) => {
          if (block.id === blockId) {
            return {
              ...block,
              isCompleted: false,
            };
          }

          return block;
        }),
        actions: state.projectByPk.actions.map((action) => {
          if (action.id === actionId) {
            return {
              ...action,
              blockId,
              blockOrder,
            };
          }

          return action;
        }),
      },
    };
  });

  const updatedAction = getActionById(
    queryClient.getQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey)?.projectByPk?.actions ?? [],
    actionId,
  );

  if (!updatedAction) {
    return;
  }

  updateAction(updatedAction);
};

export const addActionsToBlockOnProjectCache = (blockId: string, projectId: string, actionIds: string[]) => {
  if (!projectId) return;

  queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      projectByPk: {
        ...state.projectByPk,
        blocks: state.projectByPk.blocks.map((block) => {
          if (block.id === blockId) {
            return {
              ...block,
              isCompleted: false,
            };
          }

          return block;
        }),
        actions: state.projectByPk.actions.map((action) => {
          if (actionIds.includes(action.id)) {
            const index = actionIds.findIndex((item) => item === action.id);

            return {
              ...action,
              blockId,
              blockOrder: index + 1,
            };
          }

          return action;
        }),
      },
    };
  });
};

export const updateBlockActionsOrderOnCache = (payload: UpdateBlockActionsBlockOrderPayload[], projectId: string) => {
  let actions: UpdateBlockActionsOrderDTO[] = [];

  payload.map(({ where, _set }) => {
    actions = [
      ...actions,
      {
        actionId: String(where.id._eq),
        blockOrder: Number(_set.blockOrder),
      },
    ];
  });

  queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      projectByPk: {
        ...state.projectByPk,
        actions: state.projectByPk.actions.map((action) => {
          const updatedAction = actions.find((a) => a.actionId === action.id);

          if (updatedAction) {
            return {
              ...action,
              blockOrder: updatedAction.blockOrder,
            };
          }

          return action;
        }),
      },
    };
  });
};

export const removeActionFromTheBlockOnCache = (actionId: string, projectId: string) => {
  queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      projectByPk: {
        ...state.projectByPk,
        actions: state.projectByPk.actions.map((action) => {
          if (action.id === actionId) {
            return {
              ...action,
              blockId: null,
              blockOrder: null,
            };
          }

          return action;
        }),
      },
    };
  });
};

export const deleteBlockFromProjectCache = (blockId: string, projectId: string) => {
  queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      projectByPk: {
        ...state.projectByPk,
        blocks: state.projectByPk.blocks.filter((block) => block.id !== blockId),
        actions: state.projectByPk.actions.map((action) => {
          if (action.blockId === blockId) {
            return {
              ...action,
              blockId: null,
              blockOrder: null,
            };
          }

          return action;
        }),
      },
    };
  });
};

export const updateBlockInsideProjectCache = ({ id: updatedBlockId, result, purpose }: Block, projectId: string) => {
  queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      projectByPk: {
        ...state.projectByPk,
        blocks: state.projectByPk.blocks.map((block) => {
          if (block.id === updatedBlockId) {
            return {
              ...block,
              result,
              purpose,
            };
          }

          return block;
        }),
      },
    };
  });
};

export const updateBlockProgressStatus = (blockId: string, isCompleted: boolean, projectId: string) => {
  queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      projectByPk: {
        ...state.projectByPk,
        blocks: state.projectByPk.blocks.map((block) => {
          if (block.id === blockId) {
            return {
              ...block,
              isCompleted,
            };
          }

          return block;
        }),
      },
    };
  });
};

export const completeActionsOfBlockInProjectCache = (blockId: string, projectId: string) => {
  queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      projectByPk: {
        ...state.projectByPk,
        actions: state.projectByPk.actions.map((action) => {
          if (action.blockId === blockId) {
            return {
              ...action,
              progressStatus: 'complete',
            };
          }

          return action;
        }),
      },
    };
  });
};

export const removeIncompleteActionsFromBlockInProjectCache = (blockId: string, projectId: string) => {
  queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      projectByPk: {
        ...state.projectByPk,
        actions: state.projectByPk.actions.map((action) => {
          if (action.blockId === blockId && action.progressStatus !== 'complete') {
            return {
              ...action,
              blockId: null,
              blockOrder: null,
            };
          }

          return action;
        }),
      },
    };
  });
};

export const updateCacheInjectingProjectId = (projectId?: string) => {
  if (!projectId) {
    return null;
  }

  return {
    onCreateAction: (action: Action) => addProjectActionToCache(action, projectId),
    updateBlockProgressStatus: (blockId: string, isCompleted = false) =>
      updateBlockProgressStatus(blockId, isCompleted, projectId),
  };
};

export const useInsertImageOne = (
  projectId: string,
  options?: UseMutationOptions<InsertImageResponse, APIError, InsertImagePayload>,
) => {
  return useMutation({
    mutationFn: (payload: InsertImagePayload) => fetchData<InsertImageResponse>(INSERT_IMAGE_ONE, payload),
    ...options,

    onMutate: (payload) => {
      queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (state) => {
        if (!state) {
          return state;
        }

        return {
          projectByPk: {
            ...state.projectByPk,
            inspiration_board_items: [
              ...state.projectByPk.inspiration_board_items,
              {
                id: payload?.id,
                imageUrl: payload?.imageUrl,
                body: '',
                color: '',
              },
            ],
          },
        };
      });
    },
  });
};

export const useDeleteInspirationBoardItemByPk = (
  projectId: string,
  options?: Omit<
    UseMutationOptions<DeleteInspirationBoardResponse, APIError, DeleteInspirationBoardPayload>,
    'onMutate'
  >,
) => {
  return useMutation({
    mutationFn: (payload: DeleteInspirationBoardPayload) =>
      fetchData<DeleteInspirationBoardResponse>(DELETE_INSPIRATION_BOARD_ITEM_BY_PK, payload),
    ...options,

    onMutate: (payload) => {
      if (!payload.id) {
        return;
      }

      queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (cache) => {
        if (!cache) {
          return;
        }

        return {
          projectByPk: {
            ...cache.projectByPk,
            inspiration_board_items: cache?.projectByPk?.inspiration_board_items?.filter(({ id }) => id !== payload.id),
          },
        };
      });
    },
  });
};

export const useInsertNoteOne = (
  projectId: string,
  options?: UseMutationOptions<InsertNoteResponse, APIError, InsertNotePayload>,
) => {
  return useMutation({
    mutationFn: (payload: InsertNotePayload) => fetchData<InsertNoteResponse>(INSERT_NOTE_ONE, payload),
    ...options,

    onMutate: (payload) => {
      queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (state) => {
        if (!state) {
          return state;
        }

        return {
          projectByPk: {
            ...state.projectByPk,
            inspiration_board_items: [
              ...state.projectByPk.inspiration_board_items,
              {
                id: payload?.id,
                body: payload?.body,
                color: payload?.color,
                imageUrl: null,
                mimeType: 'txt',
              },
            ],
          },
        };
      });
    },
  });
};

export const useEditNoteByPk = (
  projectId: string,
  options?: Omit<UseMutationOptions<EditInspirationNoteResponse, APIError, EditInspirationNotePayload>, 'onMutate'>,
) => {
  return useMutation({
    mutationFn: (payload: EditInspirationNotePayload) =>
      fetchData<EditInspirationNoteResponse>(UPDATE_NOTE_BY_PK, payload),
    ...options,

    onMutate: (payload) => {
      if (!payload.id) {
        return;
      }

      queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (cache) => {
        if (!cache) {
          return;
        }

        return {
          projectByPk: {
            ...cache.projectByPk,
            inspiration_board_items: [
              ...cache.projectByPk.inspiration_board_items.map((item) => {
                if (item.id === payload.id) {
                  return {
                    id: payload.id,
                    body: payload.body,
                    color: payload.color,
                    imageUrl: null,
                    mimeType: 'txt',
                  };
                }

                return item;
              }),
            ],
          },
        };
      });
    },
  });
};

export const useCreateKeyResult = (
  options?: Omit<UseMutationOptions<CreateKeyResultResponse, APIError, CreateKeyResultPayload>, 'onMutate'>,
) => {
  return useMutation({
    mutationFn: (payload) => fetchData(CREATE_KEY_RESULT, payload),

    onMutate: (response) => {
      queryClient.setQueryData<GetProjectResponse>(keys.project.detail(response.projectId).queryKey, (cache) => {
        if (!cache) {
          return;
        }

        return {
          projectByPk: {
            ...cache.projectByPk,
            key_results: [...cache.projectByPk.key_results, response],
          },
        };
      });
    },

    ...options,
  });
};

export const useUpdateKeyResult = (
  options?: Omit<UseMutationOptions<UpdateKeyResultResponse, APIError, UpdateKeyResultPayload>, 'onMutate'>,
) => {
  return useMutation({
    mutationFn: (payload: Partial<UpdateKeyResultPayload>) => {
      delete payload.projectId;
      return fetchData(UPDATE_KEY_RESULT, payload);
    },

    onMutate: (response) => {
      queryClient.setQueryData<GetProjectResponse>(keys.project.detail(response.projectId).queryKey, (cache) => {
        if (!cache) {
          return;
        }

        return {
          projectByPk: {
            ...cache.projectByPk,
            key_results: cache.projectByPk.key_results.map((keyResult) =>
              keyResult.id === response.id ? response : keyResult,
            ),
          },
        };
      });
    },

    ...options,
  });
};

export const updateKeyResultOrderOnCache = (payload: UpdateKeyResultOrderPayload[], projectId: string) => {
  let keyResults: UpdateKeyResultsOrderDTO[] = [];

  payload.map(({ where, _set }) => {
    keyResults = [
      ...keyResults,
      {
        keyResultId: String(where.id._eq),
        order: Number(_set.order),
      },
    ];
  });

  queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      projectByPk: {
        ...state.projectByPk,
        key_results: orderBy(
          state.projectByPk.key_results.map((keyResult) => {
            const updatedKeyResult = keyResults.find((k) => k.keyResultId === keyResult.id);

            if (updatedKeyResult) {
              return {
                ...keyResult,
                order: updatedKeyResult.order,
              };
            }

            return keyResult;
          }),
          'order',
          'asc',
        ),
      },
    };
  });
};

export const useUpdateKeyResultsOrder = (
  options?: UseMutationOptions<UpdateKeyResultOrderResponse, APIError, UpdateKeyResultOrderPayload[]>,
) => {
  return useMutation({
    mutationFn: (updates: UpdateKeyResultOrderPayload[]) =>
      fetchData<UpdateKeyResultOrderResponse>(UPDATE_KEY_RESULT_ORDER, { updates }),

    ...options,
  });
};

export const useDeleteKeyResult = (
  options?: Omit<UseMutationOptions<DeleteKeyResultResponse, APIError, DeleteKeyResultPayload>, 'onMutate'>,
) => {
  return useMutation({
    mutationFn: (payload: Partial<DeleteKeyResultPayload>) => {
      delete payload.projectId;
      return fetchData(DELETE_KEY_RESULT, payload);
    },

    onMutate: (response) => {
      queryClient.setQueryData<GetProjectResponse>(keys.project.detail(response.projectId).queryKey, (cache) => {
        if (!cache) {
          return;
        }

        return {
          projectByPk: {
            ...cache.projectByPk,
            key_results: cache.projectByPk.key_results.filter((keyResult) => keyResult.id !== response.id),
          },
        };
      });
    },

    ...options,
  });
};

export const updateBlockProjectOrderOnCache = (payload: UpdateWeeklyBlockOrderPayload[], projectId: string) => {
  let blocks: UpdateBlockProjectOrderDTO[] = [];

  payload.map(({ where, _set }) => {
    blocks = [
      ...blocks,
      {
        blockId: String(where.id._eq),
        projectOrder: Number(_set.projectOrder),
      },
    ];
  });

  queryClient.setQueryData<GetProjectResponse>(keys.project.detail(projectId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      projectByPk: {
        ...state.projectByPk,
        blocks: orderBy(
          state.projectByPk.blocks.map((block) => {
            const updatedKeyResult = blocks.find((b) => b.blockId === block.id);

            if (updatedKeyResult) {
              return {
                ...block,
                projectOrder: updatedKeyResult.projectOrder,
              };
            }

            return block;
          }),
          'projectOrder',
          'asc',
        ),
      },
    };
  });
};

export const useUpdateBlockProjectOrder = (
  options?: UseMutationOptions<UpdateBlockProjectOrderResponse, APIError, UpdateWeeklyBlockOrderPayload[]>,
) => {
  return useMutation({
    mutationFn: (blockUpdates: UpdateWeeklyBlockOrderPayload[]) =>
      fetchData<UpdateBlockProjectOrderResponse>(UPDATE_BLOCK_ORDER, { blockUpdates }),

    ...options,
  });
};
