import { Category } from '@/types/category';
import { omit } from 'lodash';

export const cleanCategoryForMutation = (category: Category) => {
  return omit(category, [
    'createdAt',
    'modifiedAt',
    'createdById',
    'incompleteActions',
    'value',
    'completedActions',
    'order',
  ]) as Category;
};
