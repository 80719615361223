import { secondaryNavObj } from '@/constants/secondaryNav';
import { RoutesList } from '@/routes/routesList';
import { Category } from '@/types/category';
import { UniqueIdentifier } from '@dnd-kit/core';
import { formatDuration, intervalToDuration } from 'date-fns';
import { Location } from 'react-router-dom';

export { default as sleep } from './sleep';

const shortFormatDistanceLocale = {
  xSeconds: '{{count}}s',
  xMinutes: '{{count}}m',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  xWeeks: '{{count}}w',
  xMonths: '{{count}}mo',
  xYears: '{{count}}y',
};

const shortEnLocale = {
  formatDistance: (token: keyof typeof shortFormatDistanceLocale, count: string) => {
    return shortFormatDistanceLocale[token].replace('{{count}}', count);
  },
};

export const getCurrentOverCategory = (categories?: Category[], overContainer?: UniqueIdentifier): Category | null => {
  return categories?.find((el: Category) => el.name === overContainer) ?? null;
};

export const checkIfOnlySpaces = (str?: string) => {
  return str?.trim().length === 0;
};

export function humanDuration(seconds: number, locale = shortEnLocale) {
  return formatDuration(intervalToDuration({ start: 0, end: seconds * 1000 }), {
    format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'],
    locale,
  });
}

/**
 * @method
 * Gets the number of seconds from a duration in the format of HH:MM:SS
 */
export function getSecondsFromDuration(duration?: string | null) {
  const [hours, minutes, seconds] = (duration || '00:00:00').split(':').map((n) => +n);

  return hours * 60 * 60 + (minutes || 0) * 60 + (seconds || 0);
}

export function isPlanningPage(location: Location) {
  return (
    location.pathname.includes(RoutesList.MyPlanPage) ||
    location.pathname.includes(RoutesList.MyPlanDailyPage) ||
    location.pathname.includes(RoutesList.SchedulePage) ||
    location.pathname.includes(RoutesList.CelebratePage)
  );
}

export function isPlanSchedulePage(location: Location) {
  return (
    location.pathname.includes(RoutesList.MyPlanPage) ||
    location.pathname.includes(RoutesList.MyPlanDailyPage) ||
    location.pathname.includes(RoutesList.SchedulePage)
  );
}

export function convertDurationToMinutes(duration?: string | null): number {
  const [hours, minutes, seconds] = (duration || '00:00:00').split(':').map(Number);
  return hours * 60 + (minutes || 0) + (seconds || 0) / 60;
}

export function isCurrentPageActive(pagePath: string): boolean {
  // Get the current path of the page.
  const currentPath = window.location.pathname;

  // Check if the current path matches the given page path.
  return currentPath === pagePath;
}
export function isInCategoryManagerPage() {
  const pathname = window.location.pathname;

  return pathname.includes(RoutesList.CategoryManagerPage);
}

export const isOnProjectDetailPage = () => window.location.pathname.includes(RoutesList.ProjectDetailPage);

export function getCategoryIdFromUrl(urlString = window.location.href) {
  // Create a URL object
  const url = new URL(urlString);

  // Access the pathname and split it to get the last part
  const matches = url.toString().match(/\/[\w-]+\/([0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12})/);

  if (matches) {
    const UUID = matches[1];

    return UUID;
  } else {
    return null;
  }
}

export function isUncategorized(category: Category | undefined) {
  if (!category) {
    return;
  }

  return category?.name?.toLowerCase() === 'uncategorized';
}

export function fixUncategorizedName(categoryName: string) {
  // Client wanted Uncategorized to be renamed to Capture very last minute,
  // and we simply couldn't change backend too and deal with all the
  // implications. This code renames the surface labels appearing in the app
  // without changing the underliying name of Uncategorized and touching any
  // logic attached to it.
  // TODO: if the change is confirmed, propagate to backend too
  if (categoryName?.toLowerCase() === 'uncategorized') return 'Capture';
  else return categoryName;
}

export function showSecondaryNavItemsBasedOnLocation(location: Location) {
  return secondaryNavObj.find((item) => item.href === location.pathname);
}
