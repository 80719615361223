import rem from '@/utils/rem';
import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

export default defineMultiStyleConfig({
  variants: {
    duration: definePartsStyle({
      field: {
        backgroundColor: 'gray.700',
        color: 'white.500',
        fontWeight: 'black',
        minHeight: rem(50),
        maxWidth: rem(108),
        padding: `0 ${rem(44)} 0 ${rem(16)}`,
        borderRadius: rem(10),
      },
    }),
  },
});
