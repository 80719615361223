import SettingsSidebar from '@/components/SettingsSidebar';
import { useCronofyProfiles } from '@/services/cronofy/hooks';
import { useUser } from '@/services/user/hooks';
import { initials } from '@/utils/people';
import rem from '@/utils/rem';
import { Button, ButtonProps, Flex, Text } from '@chakra-ui/react';
import { useMemo, useState } from 'react';

type SettingsButtonProps = {
  showLabel?: boolean;
} & ButtonProps;

const SettingsButton = ({ showLabel, ...buttonProps }: SettingsButtonProps) => {
  const { data: user } = useUser();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { data: cronofyProfiles } = useCronofyProfiles();

  const calendarNeedsRelink = useMemo(() => cronofyProfiles?.some((profile) => profile.relinkUrl), [cronofyProfiles]);

  return (
    <>
      {showLabel ? (
        <Button
          justifyContent="flex-start"
          gap={rem(8)}
          padding={0}
          _focusVisible={{
            boxShadow: 'none',
          }}
          onClick={() => setSidebarOpen(true)}
          variant="ghost"
          {...buttonProps}
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            width={rem(36)}
            height={rem(36)}
            color="text-primary"
            fontSize="md"
            textTransform="uppercase"
            borderRadius={rem(4)}
            backgroundColor="background-secondary"
          >
            {initials(`${user?.firstName} ${user?.lastName}`)}

            {calendarNeedsRelink && (
              <Flex
                position="absolute"
                top={rem(-3)}
                right={rem(-5)}
                width={rem(10)}
                height={rem(10)}
                backgroundColor="yellow.500"
                rounded="full"
              />
            )}
          </Flex>
          <Text
            textStyle={{
              base: 'medium',
              lg: 'large',
            }}
          >
            Settings
          </Text>
        </Button>
      ) : (
        <Button
          width={rem(36)}
          minWidth="unset"
          height={rem(36)}
          color="text-primary"
          fontSize="md"
          textTransform="uppercase"
          borderRadius={rem(4)}
          backgroundColor="background-secondary"
          onClick={() => setSidebarOpen(true)}
          {...buttonProps}
        >
          {initials(`${user?.firstName} ${user?.lastName}`)}

          {calendarNeedsRelink && (
            <Flex
              position="absolute"
              top={rem(-3)}
              right={rem(-5)}
              width={rem(10)}
              height={rem(10)}
              backgroundColor="yellow.500"
              rounded="full"
            />
          )}
        </Button>
      )}

      <SettingsSidebar isOpen={sidebarOpen} onClose={() => setSidebarOpen(false)} />
    </>
  );
};

export default SettingsButton;
