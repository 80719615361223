import useIsDarkMode from '@/hooks/useIsDarkMode';
import { IconActionTrash, IconPencilDraw, IconThreeDots } from '@/theme/icons';
import rem from '@/utils/rem';
import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Tooltip } from '@chakra-ui/react';

type Props = {
  onEdit: () => void;
  onRemove: () => void;
};

const ThreeDotsDiscussionButton = ({ onEdit, onRemove }: Props) => {
  const isDarkMode = useIsDarkMode();

  return (
    <Menu isLazy>
      <Tooltip label="Discussion Point Menu" placement="top">
        <MenuButton
          as={IconButton}
          display="flex"
          minWidth={rem(24)}
          height={rem(24)}
          minHeight={rem(24)}
          border={`${rem(1)} solid`}
          borderColor="#FFFFFF50"
          borderRadius={rem(6)}
          aria-label="Actions with discussion points"
          icon={<Icon as={IconThreeDots} boxSize={rem(10)} margin="0 auto" />}
          variant="unstyled"
        />
      </Tooltip>
      <MenuList
        width={rem(144)}
        minWidth="0"
        borderWidth={rem(1)}
        borderStyle="solid"
        borderColor="gray.700"
        borderRadius="4"
        backgroundColor={isDarkMode ? 'blue.1000' : 'grey.50'}
        paddingX={rem(8)}
      >
        <MenuItem
          textStyle="small"
          marginBottom={rem(8)}
          padding={`${rem(4)} ${rem(8)}`}
          color="text-primary"
          _hover={{ backgroundColor: 'background-secondary' }}
          backgroundColor="inherit"
          icon={<Icon as={IconPencilDraw} boxSize={rem(14)} color="text-primary" />}
          iconSpacing={rem(8)}
          onClick={onEdit}
        >
          Edit
        </MenuItem>
        <MenuItem
          textStyle="small"
          marginBottom={rem(8)}
          padding={`${rem(4)} ${rem(8)}`}
          color="red.500"
          borderRadius="4"
          _hover={{ backgroundColor: 'background-secondary' }}
          backgroundColor="inherit"
          icon={<Icon as={IconActionTrash} boxSize={rem(14)} />}
          onClick={onRemove}
        >
          Remove
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ThreeDotsDiscussionButton;
