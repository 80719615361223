import { createQueryKeyStore } from '@lukemorales/query-key-factory';

export const keys = createQueryKeyStore({
  user: {
    current: null,
    info: null,
  },
  actions: {
    all: {
      queryKey: null,
      contextQueries: {
        week: (weeklyPlanId: string) => ({
          queryKey: [weeklyPlanId],
        }),
        month: (startDate: Date, endDate: Date) => ({
          queryKey: [startDate, endDate],
        }),
        categoryManager: (categoryId: string) => ({
          queryKey: [categoryId],
        }),
      },
    },
    detail: (actionId: string) => ({
      queryKey: [actionId],
    }),
    leveragedAndCommitted: null,
  },
  blocks: {
    all: null,
  },
  blockEvents: {
    list: (startDate: Date, endDate: Date) => ({
      // For the key use only the date part, remove the time part
      queryKey: [startDate.toISOString().substring(0, 10), endDate.toISOString().substring(0, 10)],
    }),
  },
  categories: {
    all: null,
  },
  categoryEvents: {
    list: (startDate: Date, endDate: Date) => ({
      // For the key use only the date part, remove the time part
      queryKey: [startDate.toISOString().substring(0, 10), endDate.toISOString().substring(0, 10)],
    }),
  },
  cronofy: {
    authUrl: null,
    profiles: null,
    events: (from: Date, to: Date, calendarsIds: string[]) => ({
      queryKey: [from.toISOString().substring(0, 10), to.toISOString().substring(0, 10), calendarsIds],
    }),
    all: {
      queryKey: null,
      contextQueries: {
        externalEvents: (startDate: string, endDate: string) => ({
          queryKey: [startDate, endDate],
        }),
      },
    },
  },
  dailyPlan: {
    all: {
      queryKey: null,
      contextQueries: {
        from: (startDate: string) => ({
          queryKey: [startDate],
        }),
      },
    },
  },
  people: {
    all: null,
    detail: (personId: string) => ({
      queryKey: [personId],
    }),
  },
  project: {
    all: null,
    category: (categoryId: string) => ({
      queryKey: [categoryId],
    }),
    detail: (projectId: string) => ({
      queryKey: [projectId],
    }),
  },
  publicLeverageRequests: {
    list: (token: string) => [token],
  },
  unsplash: {
    all: null,
    list: (searchQuery: string) => [searchQuery],
  },
  weeklyPlan: {
    all: {
      queryKey: null,
      contextQueries: {
        from: (startDate: string) => ({
          queryKey: [startDate],
        }),
      },
    },
  },
});
