import rem from '@/utils/rem';
import { GroupBase, StylesConfig } from 'react-select';

type Variant = 'default' | 'compact';

export const selectStyles = (variant: Variant = 'default'): StylesConfig<any, boolean, GroupBase<any>> | undefined => ({
  input: (provided) => ({
    ...provided,
    alignItems: 'center',
    display: 'flex',
    margin: 0,
    padding: 0,
    color: 'var(--chakra-colors-text-primary)',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--chakra-colors-text-primary)',
    cursor: 'pointer',
    marginRight: variant === 'compact' ? rem(6) : rem(2),
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: 'hidden',
    padding: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? 'rgba(239, 239, 239, 0.3)' : 'var(--chakra-colors-background-primary)',
    border: variant === 'compact' ? '0' : `${rem(1)} solid var(--chakra-colors-stroke-primary) !important`,
    borderRadius: 4,
    boxShadow: 'none',
    fontSize: 'var(--chakra-fontSizes-sm)',
    minHeight: '1.75rem',
    paddingLeft: variant === 'compact' ? 0 : '0.5rem',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: 'var(--chakra-colors-background-secondary)',
    color: 'var(--chakra-colors-text-primary)',
    flex: '0 0 auto',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'var(--chakra-colors-text-primary)',
    fontWeight: 600,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'var(--chakra-colors-text-primary)',
    '&:hover': {
      color: 'var(--chakra-colors-text-primary)',
      backgroundColor: 'var(--chakra-colors-background-secondary)',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 'var(--chakra-fontSizes-sm)',
    backgroundColor: state.isFocused
      ? 'var(--chakra-colors-background-secondary)'
      : 'var(--chakra-colors-background-primary)',
    color: 'var(--chakra-colors-text-primary)',
    ':active': {
      backgroundColor: state.isSelected
        ? 'var(--chakra-colors-background-primary)'
        : 'var(--chakra-colors-background-secondary)',
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'var(--chakra-colors-background-primary)',
    border: `${rem(1)} solid var(--chakra-colors-stroke-primary) !important`,
    borderRadius: 4,
    zIndex: 999,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: variant === 'compact' ? 0 : 4,
    opacity: variant === 'compact' ? 1 : base.opacity,
    color: variant === 'compact' ? 'currentColor' : base.color,
    '&:hover': variant === 'compact' ? { color: 'currentcolor' } : undefined,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  placeholder: (base) => ({
    ...base,
    color: 'var(--chakra-colors-text-primary)',
  }),
});
