import { defineStyleConfig } from '@chakra-ui/react';

export default defineStyleConfig({
  variants: {
    error: {
      color: 'red.600',
      fontWeight: 'medium',
    },
  },
});
