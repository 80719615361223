import { create } from 'zustand';

type StateActionProgressStatus = {
  isCompleted: boolean;
  setIsCompleted: (action: StateActionProgressStatus['isCompleted']) => void;
};

const useActionProgressStatus = create<StateActionProgressStatus>((set) => ({
  isCompleted: false,
  setIsCompleted: (isCompleted) => set(() => ({ isCompleted })),
}));

export { useActionProgressStatus };
