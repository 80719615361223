import rem from '@/utils/rem';
import { Icon, IconButton, IconButtonProps } from '@chakra-ui/react';
import { BsStar, BsStarFill } from 'react-icons/bs';

type Props = {
  starred: boolean;
  onClick: () => void;
};

const IconStarred = ({ starred, onClick, ...rest }: Props & Partial<IconButtonProps>) => {
  return (
    <IconButton
      border="none"
      aria-checked={starred}
      aria-label={starred ? 'Unstar Action' : 'Star Action'}
      icon={<Icon as={starred ? BsStarFill : BsStar} boxSize={rem(14)} color="text-primary" />}
      isActive={starred}
      onClick={onClick}
      role="checkbox"
      variant="secondary"
      {...rest}
    />
  );
};

export default IconStarred;
