import EllipsisTooltipText from '@/components/ActionRow/EllipsisTooltipText';
import DialogModal from '@/components/DialogModal';
import ManageDiscussionPointModal from '@/components/ManageDiscussionPointModal';
import { CompleteAnimation } from '@/constants/animations';
import { useDiscussionPointList } from '@/contexts/DiscussionPointsList';
import ThreeDotsDiscussionButton from '@/pages/People/PersonDetailView/DiscussionPointItem/ThreeDotsDiscussionButton';
import { useCompleteDiscussionPoint, useDeleteDiscussionPoint } from '@/services/people/hooks';
import { IconIncomplete } from '@/theme/icons';
import { convertToMinutes } from '@/utils/events';
import { humanDuration } from '@/utils/index';
import rem from '@/utils/rem';
import { Flex, HStack, Icon, IconButton, Text, useDisclosure, useToast } from '@chakra-ui/react';
import Lottie from 'lottie-react';
import { memo, useCallback } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';

type Props = {
  id: string;
  body: string;
  duration: string;
  order: number;
  isCompleted: boolean;
  isOpen?: boolean;
  onOpen: () => void;
  onClose: () => void;
};

function DiscussionPointItem({ id, body, duration, order, isCompleted, onOpen, onClose }: Props) {
  const toast = useToast();
  const completeDiscussionPoint = useCompleteDiscussionPoint();
  const deleteDiscussionPoint = useDeleteDiscussionPoint();

  const { onToggleProgressDiscussionPoint } = useDiscussionPointList();

  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure({ id: 'delete' });
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure({ id: 'edit' });

  const handleComplete = useCallback(async () => {
    // if (isCompleted && onIncompleteDiscussionPoint) {
    //   completeDiscussionPoint.mutate(onIncompleteDiscussionPoint(id));
    //   return;
    // }
    //
    // if (onCompleteDiscussionPoint) {
    //   completeDiscussionPoint.mutate(onCompleteDiscussionPoint(id));
    //   return;
    // }
    onToggleProgressDiscussionPoint && completeDiscussionPoint.mutate(onToggleProgressDiscussionPoint(id));
  }, [completeDiscussionPoint, id, onToggleProgressDiscussionPoint]);

  const handleDeleteDiscussionPoint = useCallback(async () => {
    await deleteDiscussionPoint.mutateAsync({ id });

    toast({
      title: 'Discussion point deleted successfully',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  }, [deleteDiscussionPoint, id, toast]);

  const handleOpen = useCallback(() => {
    onOpen();
    onEditOpen();
  }, [onEditOpen, onOpen]);

  const handleClose = useCallback(() => {
    onClose();
    onEditClose();
  }, [onClose, onEditClose]);

  return (
    <>
      <Flex width="full" marginBottom={rem(5)}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={rem(10)}
          width="full"
          padding={`${rem(6)} ${rem(7)}`}
          borderTopRightRadius="4"
          borderBottomRightRadius="4"
          backgroundColor="background-tertiary"
        >
          <IconButton
            width={rem(14)}
            minWidth={rem(14)}
            maxWidth={rem(14)}
            height={rem(14)}
            border="none"
            _hover={{
              backgroundColor: 'transparent',
            }}
            aria-checked={isCompleted}
            aria-label=""
            // backgroundColor="background-tertiary' !important"
            disabled={completeDiscussionPoint.isLoading}
            icon={
              isCompleted ? (
                // <IconComplete boxSize={rem(14)}  />
                <Lottie
                  animationData={CompleteAnimation}
                  loop={false}
                  style={{
                    width: rem(14),
                    height: rem(14),
                  }}
                />
              ) : (
                <Icon as={IconIncomplete} boxSize={rem(14)} />
              )
            }
            isActive={isCompleted}
            onClick={handleComplete}
            role="checkbox"
            variant="unstyled"
          />

          <HStack width="full" onClick={handleOpen}>
            <Text as="span" textStyle="actionRowIndex">
              {order}
            </Text>
            <EllipsisTooltipText flex={1} textStyle="actionRowTitle" color="text-primary" isTruncated textAlign="left">
              {body}
            </EllipsisTooltipText>
            <HStack opacity={isCompleted ? '0.5' : '1'} spacing={1}>
              <Icon as={AiOutlineClockCircle} boxSize={rem(14)} color="gray.400" />
              <Text as="span" fontSize={rem(12)}>
                {humanDuration(convertToMinutes(duration) * 60)}
              </Text>
            </HStack>
          </HStack>
          <ThreeDotsDiscussionButton onEdit={handleOpen} onRemove={onDeleteOpen} />
        </Flex>
      </Flex>
      <ManageDiscussionPointModal
        isOpen={isEditOpen}
        onClose={handleClose}
        personId={id}
        id={id}
        name={body}
        duration={duration}
      />
      <DialogModal
        isOpen={isDeleteOpen}
        title="Confirm"
        message="Do you really want to delete this discussion point?"
        messageSize={rem(24)}
        onCancel={onDeleteClose}
        onConfirm={handleDeleteDiscussionPoint}
        confirmText="Delete"
        confirmVariant="danger"
        modalSize="xl"
      />
    </>
  );
}

export default memo(DiscussionPointItem);
