import SpinnerFullPage from '@/components/SpinnerFullPage';
import { GET_OR_CREATE_USER } from '@/gql/user';
import { fetchData } from '@/services/graphql';
import { useUser } from '@/services/user/hooks';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

const ProtectedRoutes = () => {
  // This component should run only once when protected routes are accessed.
  // That is currently achieved instantiating it in AppRoutes as a wrapper of
  // all the protected routes.
  // Keep it in mind if you change this file or AppRoutes.tsx.

  // 1) Fetch user data from the RRI SSO. If session is expired, user will be
  // redirected to the login page. If user is retrieved with no problems we
  // can proceed to the initialization phase.

  const { data: user } = useUser();

  // 2) After user is retrieved, we must ensure backend and database are properly
  // initialized. This is done by calling getOrCreateUser query on GQL.

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    async function init() {
      // Exit function if already initialized or user not retrieved yet.
      if (initialized || !user?.id) return;
      // If this call fails user will be stuck on a loading spinner, but that's
      // ok for now, it naturally suggests to refresh the page.
      // TODO: offer a retry mechanism
      await fetchData(GET_OR_CREATE_USER);
      setInitialized(true);
    }
    init();

    // Dependency list has user?.id instead of just user to avoid re-running the
    // effect when the instance changes but the user is the same.
  }, [initialized, user?.id]);

  // While initialization is in progress, show a spinner.
  if (!initialized) {
    return <SpinnerFullPage />;
  }

  // After initialization is done, render the protected routes.
  return <Outlet />;
};

export default ProtectedRoutes;
