import PersonAvatar from '@/components/PersonAvatar';
import { RoutesList } from '@/routes/routesList';
import rem from '@/utils/rem';
import { HStack, Link, Text } from '@chakra-ui/react';
import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
  id: string;
  name: string;
  isActive: boolean;
};

function PersonItem({ id, name, isActive }: Props) {
  return (
    <Link
      as={RouterLink}
      _hover={{
        textDecoration: 'none',
      }}
      to={`${RoutesList.PeoplePage}/${id}`}
    >
      <HStack
        alignItems="center"
        width="full"
        padding={rem(8)}
        _hover={{
          backgroundColor: !isActive ? 'initial' : 'background-tertiary',
        }}
        cursor="pointer"
        backgroundColor={isActive ? 'background-tertiary' : 'inherit'}
        spacing={4}
      >
        <PersonAvatar name={name} isActive={isActive} />

        <Text as="p" textStyle="large" noOfLines={1}>
          {name}
        </Text>
      </HStack>
    </Link>
  );
}

export default memo(PersonItem);
