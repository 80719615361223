import DashboardLayout from '@/layouts/Dashboard';
import { ComponentType, ReactElement } from 'react';

interface WithDashboardLayoutProps {
  showAsPage: boolean;
  pageTitle?: string;
}

interface DashboardLayoutExtraProps {
  // Define additional props for DashboardLayout here
  [key: string]: any; // Flexible definition for extra props
}

// Higher-Order Component
function withDashboardLayout<T extends object>(
  WrappedComponent: ComponentType<T>,
  dashboardLayoutExtraProps?: DashboardLayoutExtraProps,
): ComponentType<WithDashboardLayoutProps & T> {
  return function WithDashboardLayout(props: WithDashboardLayoutProps & T): ReactElement {
    const { showAsPage, pageTitle, ...rest } = props;

    const componentProps = rest as T;

    // If showAsPage is true, wrap with DashboardLayout
    if (showAsPage) {
      return (
        <DashboardLayout pageTitle={pageTitle ?? ''} {...dashboardLayoutExtraProps}>
          <WrappedComponent {...componentProps} />
        </DashboardLayout>
      );
    }

    // Otherwise, render the component as is
    return <WrappedComponent {...componentProps} />;
  };
}

export default withDashboardLayout;
