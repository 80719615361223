import TimeInput from '@/components/TimeInput';
import ArrowDown from '@/components/TimeInput/components/ArrowDown';
import { selectStyles } from '@/theme/components/custom/select';
import { IconClear } from '@/theme/icons';
import { formatDateToString, localDateToUTC, utcToLocalDate } from '@/utils/calendar';
import rem from '@/utils/rem';
import { trackTimezoneSelected } from '@/utils/tracking';
import { Box, Button, ButtonProps, Flex, HStack, Icon } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { DropdownIndicatorProps, GroupBase, OptionContext, components } from 'react-select';
import TimezoneSelect, { ITimezone } from 'react-timezone-select';

export type ScheduledTimeProps = {
  scheduledTime: Date | null;
  selectedDate: Date;
  removeTimeOnClickHeader?: boolean;
  updateScheduledTime: (date: Date | null) => void;
  selectedTimezone?: ITimezone;
  setSelectedTimezone?: (timezone: ITimezone) => void;
} & Omit<ButtonProps, 'tabIndex'>;

const OptionLabel = (option: ITimezone, { context }: { context: OptionContext }) => {
  const label = typeof option === 'string' ? option : context === 'value' ? option.abbrev : option.label;
  return <Box fontWeight={context === 'value' ? '500' : '400'}>{label}</Box>;
};

const DropdownIndicator = (props: DropdownIndicatorProps<ITimezone, boolean, GroupBase<ITimezone>>) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDown />
    </components.DropdownIndicator>
  );
};

const ScheduledTime = ({
  scheduledTime,
  selectedDate,
  updateScheduledTime,
  selectedTimezone,
  setSelectedTimezone,
  ...rest
}: ScheduledTimeProps) => {
  const time = scheduledTime ?? new Date();

  const hours = time?.getHours().toString().padStart(2, '0');
  const minutes = time?.getMinutes().toString().padStart(2, '0');
  const formattedTime = hours + ':' + minutes;

  const [timeValue, setTimeValue] = useState<string | undefined>(formattedTime);

  const handleSetTime = useCallback(() => {
    if (scheduledTime) return;

    updateScheduledTime(new Date());
  }, [scheduledTime, updateScheduledTime]);

  const onTimeChange = useCallback(
    (dateString: string) => {
      setTimeValue(dateString);

      updateScheduledTime(
        utcToLocalDate(
          formatDateToString(selectedDate),
          localDateToUTC(`${formatDateToString(selectedDate)} ${dateString}`).scheduledTime,
        ),
      );
    },
    [selectedDate, updateScheduledTime],
  );

  const onResetTime = useCallback(() => {
    const hours = new Date()?.getHours().toString().padStart(2, '0');
    const minutes = new Date()?.getMinutes().toString().padStart(2, '0');
    const formattedTime = hours + ':' + minutes;

    setTimeValue(formattedTime);

    updateScheduledTime(null);
    setSelectedTimezone && setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, [updateScheduledTime, setSelectedTimezone]);

  useEffect(() => {
    if (selectedTimezone && selectedTimezone !== Intl.DateTimeFormat().resolvedOptions().timeZone) {
      const timeZone = typeof selectedTimezone === 'string' ? selectedTimezone : selectedTimezone.value;
      trackTimezoneSelected(timeZone);
    }
  }, [selectedTimezone]);

  return (
    <HStack
      gap={rem(4)}
      maxWidth="100%"
      height={rem(65)}
      backgroundColor={scheduledTime ? 'background-primary' : 'transparent'}
      paddingX={scheduledTime ? rem(8) : 0}
    >
      {!scheduledTime && (
        <Button
          padding={rem(16)}
          isActive={Boolean(scheduledTime)}
          isDisabled={!!scheduledTime}
          onClick={handleSetTime}
          size={{ base: 'sm', md: 'lg' }}
          variant="outline"
          {...rest}
        >
          Set Time
        </Button>
      )}

      {scheduledTime && (
        <>
          <Flex
            alignItems="center"
            gap={rem(3)}
            paddingRight={rem(8)}
            border={selectedTimezone ? `${rem(1)} solid var(--chakra-colors-stroke-primary)` : '0'}
            borderRadius={rem(4)}
          >
            <TimeInput
              value={timeValue}
              hour12Format
              fullTimeDropdown
              showAMPMDropdown
              minutesIncrement={15}
              onChange={onTimeChange}
            />
            {selectedTimezone && setSelectedTimezone && (
              <Box
                as={TimezoneSelect}
                maxWidth={{ base: rem(40), md: rem(100) }}
                components={{ DropdownIndicator }}
                formatOptionLabel={OptionLabel}
                isSearchable={false}
                labelStyle="abbrev"
                menuPlacement="auto"
                menuPosition="absolute"
                onChange={setSelectedTimezone}
                styles={{
                  ...selectStyles('compact'),
                  menu: (baseStyles: any) => ({
                    ...baseStyles,
                    width: rem(200),
                    border: `${rem(1)} solid var(--chakra-colors-stroke-primary) !important`,
                  }),
                }}
                value={selectedTimezone}
              />
            )}
          </Flex>

          <Button
            minWidth={rem(16)}
            height={rem(16)}
            padding="0"
            borderRadius="99"
            _hover={{
              backgroundColor: 'background-queternary',
            }}
            aria-label="Remove Time"
            backgroundColor="gray.900"
            onClick={onResetTime}
          >
            <Icon as={IconClear} boxSize={rem(8)} color="white.500" />
          </Button>
        </>
      )}
    </HStack>
  );
};

export default ScheduledTime;
