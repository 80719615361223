import Input from '@/components/Input';
import StyledModal from '@/components/StyledModal';
import { useCreatePerson, useEditPerson } from '@/services/people/hooks';
import { Person } from '@/types/people';
import rem from '@/utils/rem';
import { Button, ModalBody, ModalFooter, VStack, useToast } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormEvent, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

const CreatePersonFormSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: 'Name requires at least 1 character' })
    .max(50, { message: 'Name must be 50 characters or less' })
    .refine((data) => isNaN(parseInt(data[0], 10)), { message: 'Name must begin with a letter' }),
  email: z.string().email('Please enter a valid email').optional().or(z.literal('')),
  relation: z.string().trim().max(300, { message: 'Relation must be 300 characters or less' }),
});

type FormData = z.infer<typeof CreatePersonFormSchema>;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  person?: Person;
  onCreate?: (person: Person) => void;
};

function ManagePersonModal({ isOpen, onClose, person, onCreate }: Props) {
  const toast = useToast();
  const createPerson = useCreatePerson();
  const editPerson = useEditPerson();

  const isEdit = !!person;

  const {
    control,
    handleSubmit,
    reset,
    setFocus,
    formState: { errors, isValid },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      email: '',
      relation: '',
    },
    resolver: zodResolver(CreatePersonFormSchema),
    mode: 'onChange',
  });

  const handleCreate = useCallback(
    async (data: FormData) => {
      const res = await createPerson.mutateAsync({ id: uuidv4(), ...data });

      onCreate && onCreate(res.insertPersonOne);

      toast({
        title: 'Person created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      reset();
      onClose();
    },
    [createPerson, onCreate, toast, reset, onClose],
  );

  const handleEdit = useCallback(
    async (data: FormData) => {
      if (!person) {
        return;
      }

      await editPerson.mutateAsync({ id: person.id, notes: person.notes, ...data });

      toast({
        title: 'Person edited successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      reset();
      onClose();
    },
    [onClose, editPerson, person, reset, toast],
  );

  const onCancel = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.stopPropagation();

    return handleSubmit(isEdit ? handleEdit : handleCreate)(e);
  };

  useEffect(() => {
    if (person) {
      const { name, relation, email } = person;
      reset({ name, relation, email });
    }
  }, [person, reset, isOpen]);

  useEffect(() => {
    if (isOpen) {
      // Does not work without setTimeout
      // must be removed after focus trap is enabled
      // (lookup the comments in the codebase by "RRI-2918")
      const timeoutId = setTimeout(() => setFocus('name'), 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isOpen, setFocus]);

  return (
    <StyledModal isOpen={isOpen} onClose={onCancel} title={isEdit ? 'Edit Person' : 'Create a new Person'}>
      <form onSubmit={onSubmit}>
        <ModalBody>
          <VStack alignItems="normal" width="full" spacing={rem(24)}>
            <Controller
              name="name"
              control={control}
              render={({ field: { value, onChange, ref } }) => (
                <Input
                  ref={ref}
                  label="Name"
                  paddingBottom={rem(16)}
                  error={errors.name?.message}
                  onChange={onChange}
                  value={value}
                  maxWidth="100%"
                  fontWeight={500}
                  fontSize="3xl"
                  autoComplete="off"
                  placeholder="e.g. John Doe"
                  _placeholder={{
                    color: 'text-tertiary',
                    opacity: 0.3,
                    fontSize: '3xl',
                    fontWeight: 500,
                  }}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field: { value, onChange, ref } }) => (
                <Input
                  ref={ref}
                  label="Email"
                  optional
                  type="email"
                  paddingBottom={rem(16)}
                  error={errors.email?.message}
                  onChange={onChange}
                  value={value}
                  maxWidth="100%"
                  fontWeight={500}
                  fontSize="3xl"
                  autoComplete="off"
                  placeholder="e.g. John@rpm.com"
                  _placeholder={{
                    color: 'text-tertiary',
                    opacity: 0.3,
                    fontSize: '3xl',
                    fontWeight: 500,
                  }}
                />
              )}
            />
            <Controller
              name="relation"
              control={control}
              render={({ field: { value, onChange, ref } }) => (
                <Input
                  ref={ref}
                  label="Relation to you"
                  optional
                  paddingBottom={rem(16)}
                  error={errors.relation?.message}
                  onChange={onChange}
                  value={value}
                  maxWidth="100%"
                  fontWeight={500}
                  fontSize="3xl"
                  autoComplete="off"
                  placeholder="e.g. My Financial Coach"
                  _placeholder={{
                    color: 'text-tertiary',
                    opacity: 0.3,
                    fontSize: '3xl',
                    fontWeight: 500,
                  }}
                />
              )}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCancel} size="lg" variant="secondary">
            Cancel
          </Button>
          <Button isDisabled={!isValid || createPerson.isLoading} size="lg" type="submit" variant="primary">
            {isEdit ? 'Update Person' : 'Create Person'}
          </Button>
        </ModalFooter>
      </form>
    </StyledModal>
  );
}

export default ManagePersonModal;
