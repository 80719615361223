import Input from '@/components/Input';
import PersonItem from '@/components/PeopleSidebar/PersonItem';
import { FilterOptions, selectFilterOptions, usePeopleList } from '@/contexts/PeopleCtx';
import { selectStyles } from '@/theme/components/custom/select';
import { IconClear, IconPlus } from '@/theme/icons';
import rem from '@/utils/rem';
import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { memo, useCallback } from 'react';
import { BsSearch } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

const SIDEBAR_HEADING_HEIGHT = rem(140);

type Props = {
  onOpen: () => void;
};

function PeopleSidebar({ onOpen }: Props) {
  const { id: selectedPersonId } = useParams();
  const {
    people,
    searchQuery,
    setSearchQuery,
    filterSelected,
    setFilterSelected,
    filterFn,
    filteredPeopleDeferred,
    allFilteredPeople,
  } = usePeopleList();

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  const handleSelect = useCallback(
    (selectedOption: FilterOptions) => {
      const selectedValue = selectedOption.value;

      if (Object.keys(filterFn).includes(selectedValue)) {
        setFilterSelected(selectedValue);
      }
    },
    [filterFn, setFilterSelected],
  );

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" paddingY={4}>
        <Text as="h4" textStyle="large">
          People
        </Text>

        <HStack>
          <Box
            as={Select}
            textStyle="small"
            width={rem(128)}
            minWidth={rem(128)}
            classNamePrefix="react-select"
            defaultValue={selectFilterOptions[0]}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange={handleSelect}
            options={selectFilterOptions}
            styles={{ ...selectStyles() }}
            value={selectFilterOptions.find((option) => option.value === filterSelected)}
          />

          <Tooltip label="Add person" placement="top">
            <IconButton
              minWidth={rem(28)}
              maxWidth={rem(28)}
              height={rem(28)}
              aria-label="Add a person"
              icon={<Icon as={IconPlus} boxSize={rem(14)} />}
              onClick={onOpen}
              variant="secondary"
            />
          </Tooltip>
        </HStack>
      </Flex>
      <Box margin="0 0 1rem 0">
        <InputGroup width="full">
          <InputLeftElement height="full" children={<Icon as={BsSearch} boxSize={rem(14)} />} />

          <Input
            textStyle="small"
            width="full"
            height={rem(28)}
            paddingRight={rem(45)}
            paddingLeft={rem(38)}
            border={rem(1)}
            borderStyle="solid"
            borderColor="stroke-primary"
            borderRadius={4}
            autoComplete="off"
            formControlWidth="full"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.currentTarget.value)}
            placeholder="Search for people"
            type="text"
            _placeholder={{
              fontSize: 'small',
              color: 'text-primary',
            }}
            _focus={{
              _placeholder: {
                opacity: 0.5,
              },
            }}
            fontSize="small"
            color="text-primary"
          />
          {searchQuery.length && (
            <InputRightElement
              width={rem(30)}
              height="full"
              children={
                <IconButton
                  width={rem(30)}
                  height={rem(28)}
                  _hover={{
                    backgroundColor: 'transparent',
                  }}
                  aria-label="Delete search field"
                  backgroundColor="transparent"
                  icon={<Icon as={IconClear} width={rem(10)} color="text-primary" />}
                  onClick={handleClearSearch}
                />
              }
            />
          )}
        </InputGroup>
      </Box>
      {people?.length ? (
        <Flex flexDirection="column" paddingBottom={rem(16)}>
          {!!searchQuery && !filteredPeopleDeferred?.length && (
            <VStack marginTop={rem(32)}>
              <Text
                as="p"
                textStyle="small"
                color="text-primary"
                fontWeight={900}
                textAlign="center"
                textTransform="uppercase"
              >
                No results matched your search
              </Text>
              <Text as="p" textStyle="medium" color="text-primary" textAlign="center">
                Try searching again
              </Text>
            </VStack>
          )}
          {allFilteredPeople?.map(({ id, name }) => (
            <PersonItem isActive={selectedPersonId === id} id={id} name={name} key={id} />
          ))}
        </Flex>
      ) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          height={`calc(100% - ${SIDEBAR_HEADING_HEIGHT})`}
        >
          <Text as="p" textStyle="xsmall" color="text-tertiary" textTransform="uppercase" opacity="0.5">
            No People created yet
          </Text>
        </Flex>
      )}
    </>
  );
}

export default memo(PeopleSidebar);
