import { ActionRelation } from '@/constants/action';
import { IconCommitPersonOutline, IconLeveragePersonOutline } from '@/theme/icons';
import rem from '@/utils/rem';
import { Flex, HStack, Icon, MenuItem, Text } from '@chakra-ui/react';
import { SlArrowRight } from 'react-icons/sl';

type Props = {
  onChangeMode: (mode: ActionRelation) => void;
};

function SelectRelationMode({ onChangeMode }: Props) {
  const handleCommit = () => {
    onChangeMode(ActionRelation.Commit);
  };

  const handleLeverage = () => {
    onChangeMode(ActionRelation.Leverage);
  };

  return (
    <>
      <MenuItem
        padding={rem(14)}
        fontSize={rem(12)}
        borderTopRightRadius={rem(10)}
        borderTopLeftRadius={rem(10)}
        _hover={{ backgroundColor: 'background-tertiary' }}
        backgroundColor="background-primary"
        closeOnSelect={false}
        onClick={handleCommit}
      >
        <Flex alignItems="center" justifyContent="space-between" width="full">
          <HStack spacing={rem(6)}>
            <Icon as={IconCommitPersonOutline} color="text-primary" />
            <Text as="span" color="text-primary" fontSize={rem(12)}>
              Commit an Action to
            </Text>
          </HStack>
          <Icon as={SlArrowRight} />
        </Flex>
      </MenuItem>
      <MenuItem
        padding={rem(14)}
        fontSize={rem(12)}
        borderBottomLeftRadius={rem(10)}
        borderBottomRightRadius={rem(10)}
        _hover={{ backgroundColor: 'background-tertiary' }}
        backgroundColor="background-primary"
        closeOnSelect={false}
        onClick={handleLeverage}
      >
        <Flex alignItems="center" justifyContent="space-between" width="full">
          <HStack spacing={rem(6)}>
            <Icon as={IconLeveragePersonOutline} color="text-primary" />
            <Text as="span" color="text-primary" fontSize={rem(12)}>
              Leverage an Action to
            </Text>
          </HStack>
          <Icon as={SlArrowRight} />
        </Flex>
      </MenuItem>
    </>
  );
}

export default SelectRelationMode;
