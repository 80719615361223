import { DataType, EventsPerDayType } from '@/components/MyPlanStep/WeeklyCalendar';
import { CronofyProfile } from '@/gql/cronofy/types';
import { createExternalActionAdapter } from '@/pages/WeeklyPlan/MyWeeklyPlan/helpers';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { Action, isPlannedAction } from '@/types/actions';
import { Block } from '@/types/block';
import { Category } from '@/types/category';
import { ActionEventType, ActionType, CategoryType, ExternalEventType, PlannedActionType } from '@/types/myPlan';
import { calculateTotalDuration, getActionsByBlockId, getActionsByCategoryId } from '@/utils/action';
import { getTotalDurationOfStarredActions } from '@/utils/events';
import { fixUncategorizedName, humanDuration } from '@/utils/index';
import { UniqueIdentifier } from '@dnd-kit/core';
import { format, isEqual, parseISO } from 'date-fns';
import { isEmpty, orderBy } from 'lodash';

import { endOfTheWeek, formatDateToString, getDaysWithinDatesRange, startOfTheWeek, utcToLocalDate } from './calendar';

export type ItemType = Record<UniqueIdentifier, ActionEventType[]>;

function createActionEvent(action: ActionType): ActionEventType {
  return {
    id: action.id,
    // TODO: we should not use "as" here, but fixing it requires basically a
    // full rewrite of the calendars events handling
    action: action as PlannedActionType,
    actionId: action.id,
    typeName: action.typeName,
  };
}

function formatActions(actions: ActionType[]): ActionEventType[] {
  return actions?.map(createActionEvent);
}

function makeBlockItemKey(blockId: string) {
  return `block-${blockId}`;
}

function eventFor(event: ActionEventType, day: string) {
  if (!event.action.scheduledDate) {
    return;
  }

  const eventDate = utcToLocalDate(event.action.scheduledDate, event.action.scheduledTime);

  // To find events that match the day of a week, as we have scheduledDate and scheduledTime splited,
  // we have to create a new date based on the day of the range of days of a week and the scheduledTime of an event.
  // In this way, it's possible to compare all the cases.

  // !TODO we need to fix the date to use unix or ISO, this is not a long term solution
  const fullDateDay = new Date(`${format(parseISO(day), 'yyyy/MM/dd')} ${eventDate.toTimeString()}`);

  if (isEqual(eventDate, fullDateDay)) {
    return event;
  }

  return undefined;
}

function groupActionsByBlockId(blocks: Block[], actions: Action[]) {
  return blocks.reduce<ItemType>((acc, curr) => {
    const blockActions: ActionEventType[] = formatActions(getActionsByBlockId(curr.id, actions) as ActionType[]);

    const key = makeBlockItemKey(curr.id);

    return {
      ...acc,
      [key]: blockActions,
    };
  }, {});
}

function groupEventsByWeekDay(category: CategoryType[], weekDays: string[]) {
  const events: Record<string, ActionEventType[]> = category.reduce((acc, c) => {
    let keys: Record<string, ActionEventType[]> = {};

    weekDays.forEach((day, index) => {
      keys[`event-${c.id}-${index + 1}`] = [];
    });

    return {
      ...acc,
      ...keys,
    };
  }, {});

  category?.forEach((c) => {
    weekDays?.forEach((day, index) => {
      const key = `event-${c.id}-${index + 1}`;

      let filteredEvents: ActionEventType[] = [];

      c?.actions?.forEach(({ event }) => {
        const eventOnDay = eventFor(event, day);

        if (eventOnDay) {
          filteredEvents.push(eventOnDay);
        }
      });

      events[key] = filteredEvents;
    });
  });

  return events;
}

function getEventByActionId(actions: Action[], actionId: string) {
  const action = actions.find((action) => action.id === actionId);

  if (!action?.event?.id) {
    return undefined;
  }

  return {
    id: action.event.id,
    action,
    actionId: action.id,
    typeName: 'Event',
  };
}

function getEventById(actions: Action[], eventId: string) {
  const action = actions.find((action) => action?.event?.id === eventId);

  if (!action?.event?.id) {
    return undefined;
  }

  return {
    id: action.event.id,
    action,
    actionId: action.id,
    typeName: 'Event',
  };
}

function checkIfEventExists(id: string, events: ActionEventType[]): boolean {
  return events.findIndex((event) => event.id === id) !== -1;
}

function isSameEventDay(activeDay: string, overDay: string) {
  return activeDay === overDay;
}

function getDailyActionBackgroundColor(action: Action, selectedDate: Date) {
  if (!action.scheduledDate) {
    return 'gray.900';
  }

  const eventDate = utcToLocalDate(action.scheduledDate, action.scheduledTime);

  if (formatDateToString(eventDate) === formatDateToString(selectedDate)) {
    return 'gray.900';
  }

  return 'gray.950';
}

function getWeeklyActionBackgroundColor(action: Action, weeklyPlanId: string) {
  if (action.weeklyPlanId === weeklyPlanId) {
    return 'gray.900';
  }

  return 'gray.950';
}

function makeEvents(
  category: Category[],
  action: Action[],
  externalEvents: ExternalEventType[],
  cronofyProfiles?: CronofyProfile[],
) {
  let events: ActionEventType[] = [];
  const selectedDate = useCalendarMonthlyStore.getState().selectedDate;
  const startDate = selectedDate && startOfTheWeek(selectedDate);
  const endDate = selectedDate && endOfTheWeek(selectedDate);

  const weekDays = getDaysWithinDatesRange(startDate, endDate);

  category.forEach((category) => {
    const actions = orderBy(getActionsByCategoryId(action, category.id)?.filter(isPlannedAction), [
      'scheduledDate',
      'scheduledTime',
      'title',
    ]);

    const actionEvents: ActionEventType[] = actions?.map((action) => ({
      id: String(action.event?.id),
      action: { ...action, typeName: 'Action' },
      actionId: action.id,
      typeName: 'Event',
    }));

    if (actionEvents.length) {
      events = [...events, ...actionEvents];
    }
  });

  let categories = category ?? [];

  let externalCategory: Category;

  if (externalEvents?.length) {
    const formattedExternalEvents = externalEvents.map((event) => createExternalActionAdapter(event, cronofyProfiles));

    externalCategory = {
      id: 'external',
      order: null,
      color: 'gray.300',
      icon: null,
      name: 'External',
    };

    events = [...events, ...formattedExternalEvents];
    categories = [...categories, externalCategory];
  }
  let categoryWithEvents: DataType[] = [];

  categories?.forEach((category) => {
    let eventsPerCategory: Record<string, EventsPerDayType> = {};

    const actions = getActionsByCategoryId(
      (action ?? [])?.map((a) => a),
      category.id,
    );

    if (category?.id !== 'external' && isEmpty(actions)) return {};

    weekDays.forEach((day, index) => {
      const filteredEvents: ActionEventType[] = [];

      const eventsForAction = events.filter((e) => e.action.categoryId === category.id);

      eventsForAction.forEach((e) => {
        const eventOnDay = eventFor(e, day);

        if (eventOnDay) {
          filteredEvents.push(eventOnDay);
        }
      });

      const key = `event-${category.id}-${index + 1}`;

      eventsPerCategory = {
        ...eventsPerCategory,
        [key]: {
          // for the future, we will have a request to order by action scheduledTime all the events with animation
          // this is the code without the animation
          // events: orderBy(filteredEvents, (event) => event.action.scheduledTime, 'asc'),
          events: filteredEvents,
          day,
        },
      };
    });

    categoryWithEvents.push({
      category: {
        order: category.order,
        color: category.color,
        icon: category.icon,
        id: category.id,
        name: fixUncategorizedName(category.name),
      },
      events: { ...eventsPerCategory },
    });
  });

  const header = weekDays.map((day) => {
    // TODO: this is a duplicate of makeHeaderDuration function in DragAndDropCalendar/context.tsx, fix
    const dateDay = new Date(day);

    const filteredEvents = events?.reduce<Action[]>((acc, event) => {
      if (!event.action.scheduledDate) {
        return acc;
      }

      if (event.action.progressStatus === 'complete') {
        return acc;
      }

      const eventDate = utcToLocalDate(event.action.scheduledDate, event.action.scheduledTime);

      // !TODO we need to fix the date to use unix or ISO, this is not a long term solution
      const fullDateDay = new Date(`${format(parseISO(day), 'yyyy/MM/dd')} ${eventDate.toTimeString()}`);

      if (isEqual(eventDate, fullDateDay)) {
        return [...acc, event.action];
      }

      return acc;
    }, []);

    return {
      weekDay: format(dateDay, 'eee'),
      monthDay: format(dateDay, 'dd'),
      totalStarredActions: getTotalDurationOfStarredActions(filteredEvents) || '0h',
      totalDuration: humanDuration(calculateTotalDuration(filteredEvents) * 60) || '0h',
      dateDay,
    };
  });

  return {
    header,
    data: categoryWithEvents,
  };
}

export {
  checkIfEventExists,
  createActionEvent,
  eventFor,
  formatActions,
  getDailyActionBackgroundColor,
  getEventByActionId,
  getEventById,
  getWeeklyActionBackgroundColor,
  groupActionsByBlockId,
  groupEventsByWeekDay,
  isSameEventDay,
  makeBlockItemKey,
  makeEvents,
};
