import { EventsPerDayType } from '@/components/MyPlanStep/WeeklyCalendar';
import { COMPLETE } from '@/constants/action';
import { Action } from '@/types/actions';
import { MyEvent } from '@/types/calendar';
import { differenceInSeconds, format, isBefore } from 'date-fns';

import { getSecondsFromDuration, humanDuration } from '.';

export function convertToMinutes(timeString?: string | null) {
  const hms = (timeString || '00:00:00').split(':');
  return Math.ceil(parseInt(hms[2]) / 60) + parseInt(hms[1]) + parseInt(hms[0]) * 60;
}

const longFormatDistanceLocaleSingular = {
  xSeconds: '{{count}} second',
  xMinutes: '{{count}} minute',
  xHours: '{{count}} hour',
  xDays: '{{count}} day',
  xWeeks: '{{count}} week',
  xMonths: '{{count}} month',
  xYears: '{{count}} year',
};

const longFormatDistanceLocalePlural = {
  xSeconds: '{{count}} seconds',
  xMinutes: '{{count}} minutes',
  xHours: '{{count}} hours',
  xDays: '{{count}} days',
  xWeeks: '{{count}} weeks',
  xMonths: '{{count}} months',
  xYears: '{{count}} years',
};

export const longEnLocale = {
  formatDistance: (token: keyof typeof longFormatDistanceLocaleSingular, count: string) => {
    if (parseInt(count) === 1) {
      return longFormatDistanceLocaleSingular[token].replace('{{count}}', count);
    }

    return longFormatDistanceLocalePlural[token].replace('{{count}}', count);
  },
};

export function getTotalDurationOfStarredActions(action: Action[]) {
  const minutes = action?.reduce((acc, curr) => {
    if (curr?.dateOfStarring) {
      return acc + getSecondsFromDuration(curr.duration || '00:00:00');
    }
    return acc;
  }, 0);

  if (minutes === 0) {
    return '0h';
  }

  return humanDuration(minutes);
}

export function calculateTotalDurationOfHomeEvents(events: MyEvent[]) {
  const totalDuration = events.reduce((acc, event) => {
    const seconds = differenceInSeconds(event.end, event.start);
    return acc + seconds;
  }, 0);

  if (totalDuration === 0) {
    return '0h';
  }

  return humanDuration(totalDuration);
}

export function addDurationToTime(time: string, duration: string): string {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  const [durationHours, durationMinutes, durationSeconds] = duration.split(':').map(Number);

  const totalHours = hours + durationHours;
  const totalMinutes = minutes + durationMinutes;
  const totalSeconds = seconds + durationSeconds;

  // Handle overflow from seconds to minutes and minutes to hours
  let carryMinutes = Math.floor(totalSeconds / 60);
  let carryHours = Math.floor((totalMinutes + carryMinutes) / 60);

  const finalHours = totalHours + carryHours;
  const finalMinutes = (totalMinutes + carryMinutes) % 60;
  const finalSeconds = totalSeconds % 60;

  // Ensure leading zeros in the output
  const formattedHours = String(finalHours).padStart(2, '0');
  const formattedMinutes = String(finalMinutes).padStart(2, '0');
  const formattedSeconds = String(finalSeconds).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export function extractTimeFromDate(date: Date | null): string {
  if (!date) {
    return '';
  }

  return format(date, 'HH:mma');
}

export function isCompleted(eventStatus: string) {
  return eventStatus === COMPLETE ? true : false;
}

export function getCalendarDayTextColor(day: Date) {
  if (isBefore(day, new Date())) {
    return 'gray.400';
  }

  return 'text-primary';
}

// Get the current time
const currentTime: Date = new Date(); // Assuming this is in the same timezone as event times

export function isBetweenEventTimes(start: Date, end: Date) {
  const eventStartTime = new Date(start);
  const eventEndTime = new Date(end);

  return currentTime >= eventStartTime && currentTime <= eventEndTime;
}

export function mergeDurations(durations: string[]): string {
  const totalSeconds = durations.reduce((acc, duration) => {
    const [hours, minutes, seconds] = duration.split(':').map(Number);
    return acc + hours * 3600 + minutes * 60 + seconds;
  }, 0);

  return humanDuration(totalSeconds);
}

export const getTotalDuration = (events: Record<string, EventsPerDayType>, starredOnly = false) => {
  const eventsValues = Object.values(events);

  const durations = eventsValues.reduce((acc, { events }) => {
    const durationStrings = events.map(({ action }) => {
      if (action.duration && !starredOnly) {
        return action.duration;
      }

      if (action.duration && starredOnly && !!action.dateOfStarring) {
        return action.duration;
      }

      return '00:00:00';
    });
    return [...acc, ...durationStrings];
  }, [] as string[]);

  return mergeDurations(durations);
};
