export const MAGNIFICENT_7 = 0;
export const ACTIONS_AND_BLOCKS = 1;

export const OPTIONS = [
  {
    value: MAGNIFICENT_7,
    label: 'Magnificent 7',
  },
  {
    value: ACTIONS_AND_BLOCKS,
    label: 'Actions and Blocks',
  },
];

export const emptyData = {
  category: null,
  actions: [],
  blocks: [],
};

export type FilterOptions = {
  value: 'all' | 'starred' | 'planned' | 'unplanned' | 'completed';
  label: string;
};

export const selectFilterOptions: FilterOptions[] = [
  { value: 'all', label: 'View All' },
  { value: 'starred', label: 'Starred' },
  { value: 'planned', label: 'Planned' },
  { value: 'unplanned', label: 'Unplanned' },
  { value: 'completed', label: 'Completed' },
];
