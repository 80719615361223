import { create } from 'zustand';

export const DailyPlanView = {
  Condense: 0,
  Expand: 1,
} as const;

export type DailyPlanViewType = (typeof DailyPlanView)[keyof typeof DailyPlanView];

type ToggleCalendarVisibilityType = {
  isCalendarExpanded: boolean;
  setIsCalendarExpanded: () => void;
  dailyPlanView: DailyPlanViewType;
  setDailyPlanView: (index: DailyPlanViewType) => void;
};

const useToggleExpandCalendar = create<ToggleCalendarVisibilityType>((set, get) => ({
  isCalendarExpanded: false,
  setIsCalendarExpanded: () => set({ isCalendarExpanded: !get().isCalendarExpanded }),
  dailyPlanView: DailyPlanView.Condense,
  setDailyPlanView: (dailyPlanView) => set({ dailyPlanView }),
}));

export default useToggleExpandCalendar;
