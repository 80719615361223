import CompleteAnimation from '@/assets/complete-animation.json';

export { CompleteAnimation };

export const FadeInOutVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

export const SlideXInOutVariants = {
  hidden: {
    opacity: 0,
    x: '20%', // Start off-screen to the right
  },
  visible: {
    opacity: 1,
    x: '0', // End at the original position
    transition: {
      type: 'spring', // Adds the bounce effect
      stiffness: 300, // Adjust for more/less bounce
      damping: 20, // Controls how bounce slows down
    },
  },
  exit: {
    opacity: 0, // Fades out
    transition: {
      duration: 0.2, // Adjust the duration of fade-out
    },
  },
};

export const SlideYInOutVariants = {
  hidden: {
    opacity: 0,
    y: '20%',
  },
  visible: {
    opacity: 1,
    y: '0',
  },
  exit: {
    opacity: 0,
    y: '-20%',
  },
};

export const ActionControleSlideYInOutVariants = {
  hidden: {
    opacity: 0,
    y: '-20px',
  },
  visible: {
    opacity: 1,
    y: '0',
  },
  exit: {
    opacity: 0,
    y: '-20px',
  },
};
