import rem from '@/utils/rem';
import { modalAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(modalAnatomy.keys);

const modalStyles = helpers.defineMultiStyleConfig({
  baseStyle: {
    dialog: {
      padding: rem(8),
    },
    header: {
      //h5SatBlack
      fontFamily: 'Satoshi',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: rem(20),
      lineHeight: '160%',
    },
    footer: {
      gap: rem(16),
    },
    closeButton: {
      top: rem(16),
      right: rem(16),
    },
  },
  variants: {
    h4SatMedium: {
      header: {
        fontFamily: 'Satoshi',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: rem(24),
        lineHeight: '160%',
      },
    },
  },
});

export { modalStyles };
