import DynamicSegmentedButton from '@/components/DynamicSegmentedButton';
import { PLAN_SCHEDULE_PAGE_INDEX } from '@/constants/secondaryNav';
import { useAnimatePlanPages } from '@/contexts/AnimatedPlanPages';
import DashboardLayout from '@/layouts/Dashboard';
import { useSelectPlanSchedulePage } from '@/stores/useSelectPlanSchedulePage';
import { pageTransition } from '@/utils/pageAnimation';
import rem from '@/utils/rem';
import { Flex, Spinner } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Suspense, lazy, useEffect, useRef } from 'react';

import PlanPage from './MyWeeklyPlan';

const SchedulePage = lazy(() => import('./Schedule'));

type ChildMethods = {
  onHandleSegmentedButtonSelection: (el: number) => void;
};

function WeeklyPlanPage() {
  const { pageNavigationEffect } = useAnimatePlanPages();
  const childMethodRef = useRef<ChildMethods | null>(null);

  const selectedPlanSchedulePageIndex = useSelectPlanSchedulePage((state) => state.selectedPlanSchedulePageIndex);
  const updateSelectedPlanSchedulePageIndex = useSelectPlanSchedulePage(
    (state) => state.updateSelectedPlanSchedulePageIndex,
  );

  useEffect(() => {
    childMethodRef.current?.onHandleSegmentedButtonSelection(selectedPlanSchedulePageIndex);
  }, [selectedPlanSchedulePageIndex]);

  return (
    <DashboardLayout
      pageTitle="Weekly Plan"
      editActionModalTooltip="The category of this action cannot be changed because it is included in a block"
      rightSectionElements={
        <DynamicSegmentedButton
          onChange={updateSelectedPlanSchedulePageIndex}
          options={PLAN_SCHEDULE_PAGE_INDEX}
          startIndex={selectedPlanSchedulePageIndex}
          height="full"
          innerHeight={rem(24)}
          marginTop={rem(-2)}
          layoutId="PlanSchedule"
          ref={childMethodRef}
          fullWidth
        />
      }
    >
      <Suspense
        fallback={
          <Flex justifyContent="center" marginTop={rem(80)}>
            <Spinner size="xl" speed="0.65s" thickness={rem(4)} />
          </Flex>
        }
      >
        <motion.div
          animate="in"
          exit="out"
          initial="initial"
          variants={pageNavigationEffect}
          transition={pageTransition}
        >
          {selectedPlanSchedulePageIndex === 0 && <PlanPage />}
          {selectedPlanSchedulePageIndex === 1 && <SchedulePage />}
        </motion.div>
      </Suspense>
    </DashboardLayout>
  );
}

export default WeeklyPlanPage;
