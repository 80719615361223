import rem from '@/utils/rem';
import { extendTheme } from '@chakra-ui/react';

import buttonStyles from './components/buttonStyles';
import checkboxStyles from './components/checkboxStyles';
import { actionRow } from './components/custom/ActionRow';
import formStyles from './components/formStyles';
import inputStyles from './components/inputStyles';
import { modalStyles } from './components/modalStyles';
import switchStyles from './components/switchStyles';
import textStyles from './components/textStyles';

export const breakpoints = {
  xxs: rem(375, true),
  xs: rem(480, true),
  sm: rem(640, true),
  md: rem(768, true),
  lg: rem(992, true),
  xl: rem(1024, true),
  '2xl': rem(1280, true),
  '3xl': rem(1360, true),
  '4xl': rem(1440, true),
  '5xl': rem(1600, true),
  '6xl': rem(1920, true),
  '7xl': rem(2560, true),
};

const colorsOld = {
  black: {
    400: '#161616',
    500: '#0E0E0E',
    '900a06': 'rgba(0, 0, 0, 0.6)',
  },
  gray: {
    '400a02': '#8F8F8F20',
    '700a05': '#1A1A1A50',
    '700a03': '#1A1A1A30',
    '700a97': 'rgba(18, 16, 16, 0.97)', // #121010
    '800a75': 'rgba(14,14,14, 0.75)', // #0E0E0E
    // '900a1': 'rgba(34, 34, 34, 1)',
  },
  white: {
    500: '#FFFFFF',
    '500a015': '#FFFFFF26', // rgba(255,255,255,0.15)
    '500a031': '#FFFFFF50', // rgba(255,255,255,0.31)
    '500a05': '#FFFFFF80', // rgba(255, 255, 255, 0.5)
  },
  purple: {
    '500a06': '#756AFA99', // rgba(117,106,250,0.6)
  },

  green: {
    '500a06': '#22882099',
  },
  pink: {
    500: '#C12455',
  },
  red: {
    '500a06': '#EC332399', // rgba(236,51,35,0.6)
  },
};

const colors = {
  red: {
    50: '#fff1f3',
    100: '#ffe4e9',
    200: '#fecdd5',
    300: '#fda4b3',
    400: '#fb7189',
    500: '#f43f5e',
    600: '#e11d3f',
    700: '#be122f',
    800: '#9f122a',
    900: '#881327',
    950: '#4c0511',
  },
  rose: {
    50: '#fdf2f7',
    100: '#fce7f1',
    200: '#fbcfe5',
    300: '#f9a8d0',
    400: '#f472b2',
    500: '#ec4899',
    600: '#db2780',
    700: '#be186a',
    800: '#9d1759',
    900: '#83184d',
    950: '#50072b',
  },
  magenta: {
    50: '#fef4ff',
    100: '#fce8ff',
    200: '#f8d0fe',
    300: '#f1abfc',
    400: '#e879f9',
    500: '#d946ef',
    600: '#bc26d3',
    700: '#9c1caf',
    800: '#80198f',
    900: '#691a75',
    950: '#44044e',
  },
  purple: {
    50: '#FAF5FF',
    100: '#F4E8FF',
    200: '#EBD5FF',
    300: '#DAB4FE',
    400: '#C184FC',
    500: '#A855F7',
    600: '#9133EA',
    700: '#7A22CE',
    800: '#6621A8',
    900: '#531C87',
    950: '#370764',
  },
  blue: {
    50: '#EFF5FF',
    100: '#DBE8FE',
    200: '#BFD7FE',
    300: '#93BBFD',
    400: '#609AFA',
    500: '#3B82F6',
    600: '#2570EB',
    700: '#1D64D8',
    800: '#1E55AF',
    900: '#1E478A',
    950: '#172E54',
    1000: '#111A26',
    '1000a05': '#111A2680',
  },
  cyan: {
    50: '#F0FAFF',
    100: '#E0F5FE',
    200: '#BAE8FD',
    300: '#7DD5FC',
    400: '#38BDF8',
    500: '#0EA6E9',
    600: '#028AC7',
    700: '#0370A1',
    800: '#075E85',
    900: '#0C506E',
    950: '#083549',
  },
  teal: {
    50: '#F0FDFC',
    100: '#CCFBF6',
    200: '#99F6EC',
    300: '#5EEADB',
    400: '#2DD4C2',
    500: '#14B8A6',
    600: '#0D9485',
    700: '#0F766B',
    800: '#115E56',
    900: '#134E48',
    950: '#042F2A',
  },
  green: {
    50: '#F0FDF5',
    100: '#DCFCE8',
    200: '#BBF7D1',
    300: '#86EFAD',
    400: '#4ADE80',
    500: '#22C55E',
    600: '#16A34A',
    700: '#15803C',
    800: '#166533',
    900: '#14532B',
    950: '#052E14',
  },
  lime: {
    50: '#F5FEE7',
    100: '#E9FCCB',
    200: '#D5F99D',
    300: '#BAF264',
    400: '#A0E635',
    500: '#84CC16',
    600: '#68A30D',
    700: '#517C0F',
    800: '#426212',
    900: '#3A5314',
    950: '#1E2E05',
  },
  yellow: {
    50: '#FEFAE8',
    100: '#FEF2C3',
    200: '#FEE78A',
    300: '#FDD847',
    400: '#FACC15',
    500: '#EABD08',
    600: '#CAA204',
    700: '#A18207',
    800: '#856D0E',
    900: '#715E12',
    950: '#423606',
  },
  amber: {
    50: '#FFF8EB',
    100: '#FEEAC7',
    200: '#FDD28A',
    300: '#FCBB4D',
    400: '#FBAB24',
    500: '#F59E0B',
    600: '#D98B06',
    700: '#B47409',
    800: '#92610E',
    900: '#78510F',
    950: '#452C03',
  },
  orange: {
    50: '#FFF4ED',
    100: '#FFE6D5',
    200: '#FECCAA',
    300: '#FDAC74',
    400: '#FB8A3C',
    500: '#F97316',
    600: '#EA670C',
    700: '#C2570C',
    800: '#9A4A12',
    900: '#7C3D12',
    950: '#432007',
  },
  gray: {
    50: '#FCFDFF',
    '50a05': '#FCFDFF80',
    100: '#F5F5F7',
    200: '#D5D9E2',
    300: '#B1BBC8',
    400: '#8695AA',
    500: '#64748B',
    600: '#526077',
    700: '#434E61',
    800: '#3A4252',
    900: '#343A46',
    950: '#142737',
    1000: '#0F1013',
  },
  // example: {
  //   50: '',
  //   100: '',
  //   200: '',
  //   300: '',
  //   400: '',
  //   500: '',
  //   600: '',
  //   700: '',
  //   800: '',
  //   900: '',
  //   950: '',
  // },
};
const semanticTokens = {
  colors: {
    'background-default': {
      _dark: '#0B131E',
      _light: '#FFFFFF',
    },
    'background-primary': {
      _dark: colors.blue['1000'],
      _light: colors.gray['50'],
    },
    'background-secondary': {
      _dark: colors.blue['950'],
      _light: colors.cyan['100'],
    },
    'background-tertiary': {
      _dark: colors.gray['950'],
      _light: colors.gray['100'],
    },
    'background-quaternary': {
      _dark: colors.gray['900'],
      _light: colors.cyan['100'],
    },
    'text-primary': {
      _dark: colors.gray['50'],
      _light: colors.blue['1000'],
    },
    'text-secondary': {
      _dark: colors.gray['100'],
      _light: colors.blue['950'],
    },
    'text-tertiary': {
      _dark: colors.cyan['100'],
      _light: colors.cyan['800'],
    },
    'text-primary-inverted': {
      _dark: colors.blue['1000'],
      _light: colors.gray['50'],
    },
    'text-secondary-inverted': {
      _dark: colors.blue['950'],
      _light: colors.gray['100'],
    },
    'text-tertiary-inverted': {
      _dark: colors.cyan['800'],
      _light: colors.cyan['100'],
    },
    'stroke-primary': {
      _dark: colors.gray['700'],
      _light: colors.gray['200'],
    },
    'button-bg-primary': {
      _dark: colors.cyan['400'],
      _light: colors.cyan['400'],
    },
    'button-hover-primary': {
      _dark: colors.cyan['300'],
      _light: colors.cyan['300'], // @TODO: Missed on Figma
    },
    'button-pressed-primary': {
      _dark: colors.cyan['600'],
      _light: colors.cyan['100'], // @TODO: Missed on Figma
    },
    'button-bg-secondary': {
      _dark: colors.blue['1000'],
      _light: colors.gray['50'], // @TODO: Missed on Figma
    },
    'button-hover-secondary': {
      _dark: colors.blue['1000'],
      _light: colors.cyan['100'], // @TODO: Missed on Figma
    },
    'button-pressed-secondary': {
      _dark: colors.blue['1000'],
      _light: colors.cyan['100'], // @TODO: Missed on Figma
    },
    'border-primary': {
      _dark: colors.gray['50a05'],
      _light: colors.blue['1000a05'],
    },
    'print-background': '#fff',
    'print-background-alt': colors.gray['100'],
    'print-text': '#000',
    'print-stroke': colors.gray['300'],
  },
};

const styles = {
  global: () => ({
    '*': {
      margin: 0,
      padding: 0,
      outline: 0,
      border: 'none',
      boxSizing: 'inherit',
      color: 'inherit',
      font: 'inherit',
      textDecoration: 'inherit',
      listStyle: 'none',
      background: 'none',
    },
    html: {
      width: '100%',
      height: '100%',
      // TODO: re-enable the scroll on html, body and #root when the scroll
      // bug in daily planner is fixed (see PR #1046 for details)
      overflow: 'hidden',
      WebkitTextSizeAdjust: 'none',
      textDecoration: 'none',
      boxSizing: 'border-box',
      userSelect: 'none',
      cursor: 'default',
      '--scrollbar-width': rem(12),
      '--scrollbar-min-size': rem(32),
    },
    body: {
      background: 'background-primary',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      color: 'text-primary',
      overflowX: 'hidden',
      fontFamily: 'Satoshi',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
    },
    '#root': {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      position: 'relative',
      whiteSpace: 'pre-line', // needed for /n in translations eventually
      display: 'flex',
      flexDirection: 'column',
    },

    // 'html, html *': {
    //   'scrollbar-color': 'var(--chakra-colors-gray-800) var(--chakra-colors-gray-950)',
    //   'scrollbar-width': 'thin',
    // },

    // '::-webkit-scrollbar': {
    //   width: '13px',
    //   height: '13px',
    // },
    // '::-webkit-scrollbar-thumb:hover': {
    //   backgroundColor: 'var(--chakra-colors-gray-700)',
    // },
    // '::-webkit-scrollbar-thumb': {
    //   backgroundColor: 'var(--chakra-colors-gray-800)',
    //   borderRadius: '12px',
    //   border: '3px solid transparent',
    //   backgroundClip: 'content-box',
    // },
  }),
};

const typography = {
  lineHeights: {
    body: '130%', // Set the line height for the body text to 150%
  },

  fontSize: {
    // New design system
    '2xs': rem(8),
    xs: rem(10),
    sm: rem(12),
    md: rem(14),
    lg: rem(16),
    xl: rem(18),
    '2xl': rem(20),
    '3xl': rem(24),
    '4xl': rem(30),
    '5xl': rem(36),
    '6xl': rem(48),
    '7xl': rem(60),
    '8xl': rem(72),
    '9xl': rem(96),
    h1: {
      base: rem(48),
      sm: rem(72),
      '2xl': rem(100),
    },
    h2: {
      base: rem(36),
      sm: rem(48),
      '2xl': rem(64),
    },
    h3: rem(32),
    h4: rem(24),
    h5: rem(20),
  },

  letterSpacings: {
    normal: '0', // Set the letter spacing for normal text
    heading: '0',
    captions: '5%',
  },
};

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  breakpoints,
  colors: {
    ...colorsOld,
    ...colors,
  },
  fontSizes: {
    ...typography.fontSize,
  },
  lineHeights: {
    ...typography.lineHeights,
  },
  letterSpacings: {
    ...typography.letterSpacings,
  },
  semanticTokens,
  styles,
  shadows: {
    outline: '0 0 0 3px var(--chakra-colors-cyan-500)',
  },
  textStyles: {
    h1SatMedium: {
      fontFamily: 'Satoshi',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: typography.fontSize['h1'],
      lineHeight: '130%',
    },
    h1SatBlack: {
      fontFamily: 'Satoshi',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: typography.fontSize['h1'],
      lineHeight: '130%',
    },

    h2SatMedium: {
      fontFamily: 'Satoshi',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: typography.fontSize['h2'],
      lineHeight: '130%',
    },
    h2SatBlack: {
      fontFamily: 'Satoshi',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: typography.fontSize['h2'],
      lineHeight: '130%',
    },

    h3SatMedium: {
      fontFamily: 'Satoshi',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: typography.fontSize['h3'],
      lineHeight: '160%',
    },
    h3SatBlack: {
      fontFamily: 'Satoshi',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: typography.fontSize['h3'],
      lineHeight: '160%',
    },

    h4SatMedium: {
      fontFamily: 'Satoshi',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: typography.fontSize['h4'],
      lineHeight: '160%',
    },
    h4SatBlack: {
      fontFamily: 'Satoshi',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: typography.fontSize['h4'],
      lineHeight: '160%',
    },

    h5SatMedium: {
      fontFamily: 'Satoshi',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: typography.fontSize['h5'],
      lineHeight: '160%',
    },
    h5SatBlack: {
      fontFamily: 'Satoshi',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: typography.fontSize['h5'],
      lineHeight: '160%',
    },

    h1BarSB: {
      fontFamily: 'Barlow Condensed',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: typography.fontSize['h1'],
      lineHeight: '130%',
    },
    h2BarSB: {
      fontFamily: 'Barlow Condensed',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: typography.fontSize['h2'],
      lineHeight: '130%',
    },
    h3BarSB: {
      fontFamily: 'Barlow Condensed',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: typography.fontSize['h3'],
      lineHeight: '130%',
    },
    h4BarSB: {
      fontFamily: 'Barlow Condensed',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: typography.fontSize['h4'],
      lineHeight: '130%',
    },
    h5BarSB: {
      fontFamily: 'Barlow Condensed',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: typography.fontSize['h5'],
      lineHeight: '130%',
    },

    xsmall: {
      fontSize: rem(10),
      fontWeight: 500,
      lineHeight: '130%',
      letterSpacing: '0',
    },
    small: {
      fontSize: rem(12),
      fontWeight: 500,
      lineHeight: '130%',
      letterSpacing: '0',
    },
    medium: {
      fontSize: rem(14),
      fontWeight: 500,
      lineHeight: '130%',
      letterSpacing: '0',
    },
    large: {
      fontSize: rem(16),
      fontWeight: 500,
      lineHeight: '130%',
      letterSpacing: '0',
    },

    xsmallBlack: {
      fontSize: rem(10),
      fontWeight: 900,
      lineHeight: '130%',
      letterSpacing: '0',
    },
    smallBlack: {
      fontSize: rem(12),
      fontWeight: 900,
      lineHeight: '130%',
      letterSpacing: '0',
    },
    mediumBlack: {
      fontSize: rem(14),
      fontWeight: 900,
      lineHeight: '130%',
      letterSpacing: '0',
    },
    largeBlack: {
      fontSize: rem(16),
      fontWeight: 900,
      lineHeight: '130%',
      letterSpacing: '0',
    },
    caption: {
      fontFamily: 'Satoshi',
      fontSize: rem(12),
      fontWeight: 900,
      lineHeight: '130%',
      // letterSpacing: '0.05em', !TODO: leaving out for now to keep consistent
      marginBottom: 0,
      textTransform: 'uppercase',
    },
    // Old Styles
    quotes: {
      fontStyle: 'italic',
      fontWeight: 400,
      fontSize: rem(22),
      lineHeight: rem(26),
    },
    default: {
      fontWeight: 400,
      fontSize: rem(16),
      lineHeight: rem(20),
    },
    cardEvents: {
      fontWeight: 600,
      fontSize: rem(10),
      lineHeight: rem(14),
    },
    captureCategoryTitle: {
      fontWeight: 400,
      fontSize: rem(32),
      lineHeight: rem(34),
    },
    ...actionRow,
  },
  // the radii keys represent the size in PX
  radii: {
    4: rem(4),
    6: rem(6),
    8: rem(8),
    16: rem(16),
  },
  components: {
    Button: buttonStyles,
    Checkbox: checkboxStyles,
    Form: formStyles,
    Text: textStyles,
    Switch: switchStyles,
    Input: inputStyles,
    Modal: modalStyles,
  },
});

export default theme;
