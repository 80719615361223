import { CATEGORIES_ICONS } from '@/constants/category';
import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { Category } from '@/types/category';
import { getColorFromToken } from '@/utils/color';
import { fixUncategorizedName } from '@/utils/index';
import rem from '@/utils/rem';
import {
  Collapse,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Text,
  VStack,
  useColorMode,
  useMediaQuery,
} from '@chakra-ui/react';
import { isEmpty, map } from 'lodash';
import { Children, useState } from 'react';
import { useTimeout } from 'usehooks-ts';

import TimeText from './TimeText';

type Props = {
  data: {
    value: number;
    backgroundColor: string;
    allActions: number;
    categoryAllActionsDuration: number;
    categoryAllCompletedActionsDuration: number;
    category: Category;
  }[];
  width: number;
  maxCategoryDuration: number;
};

let allElement = 8;
const fifteenMinutes = 15;

const setMinimumDuration = (param: number) => {
  if (param < 120) {
    return 120;
  }
  return param;
};

const CelebrateChart = ({ data, width = 0, maxCategoryDuration }: Props) => {
  const [showChart, setShowChart] = useState(false);

  const isDailyPlanPage = useIsDailyPlanPage();

  const { colorMode } = useColorMode();
  const isDarkTheme = colorMode === 'dark';

  const artifactedDuration = setMinimumDuration(maxCategoryDuration);
  const [isLargerThanXL] = useMediaQuery('(min-width: 64em)'); //1024px
  const chartWidth = isLargerThanXL ? (width - 150) / 2 : width - 100;
  const barsUnits = Math.pow(2, Math.floor(Math.log2(artifactedDuration / 60)) - 1) * fifteenMinutes;

  allElement = Math.floor(artifactedDuration / barsUnits);

  const measureUnit = (barsUnits * chartWidth) / Math.floor(artifactedDuration);

  const maxBarWidth = measureUnit * allElement;

  const onShowChart = () => setShowChart(true);

  useTimeout(onShowChart, 2000);

  if (isEmpty(data)) {
    return (
      <VStack alignItems="center" justifyContent="center" gap={rem(30)} width="full" height="full">
        <Text color="gray.400" fontSize={rem(14)}>
          {`No ${!isDailyPlanPage ? 'Weekly' : 'Daily'} Work by Category`}
        </Text>
      </VStack>
    );
  }

  return (
    <Collapse className="collapseChartContainer" animateOpacity in={showChart}>
      <VStack position="relative" gap={rem(30)} width="full" paddingBottom={rem(80)}>
        <VStack position="relative" gap={rem(30)} width="full">
          {Children.toArray(
            map(data, (el) => {
              const grayWidth = (el?.categoryAllActionsDuration * chartWidth) / artifactedDuration;
              const singleCatCompletedWidth =
                (el?.categoryAllCompletedActionsDuration * grayWidth) / el?.categoryAllActionsDuration;

              return (
                <VStack zIndex={2} alignItems="flex-start" width="full">
                  <HStack>
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      width={rem(24)}
                      height={rem(24)}
                      marginRight={rem(8)}
                      borderRadius="full"
                      aspectRatio={1}
                      backgroundColor={el?.category?.color}
                    >
                      <Icon
                        as={CATEGORIES_ICONS?.[el?.category?.icon ?? 'uncategorized']}
                        width={rem(14)}
                        height={rem(14)}
                        color="blue.1000"
                      />
                    </Flex>
                    <Text
                      textStyle="h5BarSB"
                      marginTop={rem(-3)}
                      color={getColorFromToken(el?.category?.color, undefined, isDarkTheme ? '100' : '800')}
                      textTransform="uppercase"
                      noOfLines={2}
                    >
                      {fixUncategorizedName(el?.category?.name)}
                    </Text>
                  </HStack>

                  <Flex
                    position="relative"
                    width={grayWidth}
                    height={rem(20)}
                    marginLeft={10}
                    borderRadius={4}
                    backgroundColor="background-secondary"
                  >
                    <Flex
                      position="absolute"
                      alignItems="center"
                      width={singleCatCompletedWidth}
                      // minWidth={rem(20)}
                      height={rem(20)}
                      borderRadius={4}
                      transition="width 1s ease-in-out"
                      backgroundColor={el?.backgroundColor}
                    />
                  </Flex>
                </VStack>
              );
            }),
          )}

          <Grid
            position="absolute"
            top={rem(25)}
            left={0}
            gridTemplateColumns={`repeat(${allElement}, 1fr)`}
            width="full"
            maxWidth={maxBarWidth}
            height="full"
            marginLeft={10}
          >
            {Children.toArray(
              [...Array(allElement)].map((_, index) => (
                <GridItem
                  position="relative"
                  justifyContent="center"
                  width={measureUnit}
                  height="full"
                  _first={{
                    marginLeft: measureUnit / 2,
                  }}
                >
                  <Flex
                    position="relative"
                    left="50%"
                    width={rem(1)}
                    height="full"
                    marginBottom={rem(10)}
                    opacity="0.3"
                    transform="translateX(-50%)"
                    backgroundColor="gray.500"
                  />
                  <TimeText measureUnit={measureUnit} durationSegment={barsUnits * (index + 1)} />
                </GridItem>
              )),
            )}
          </Grid>
        </VStack>
      </VStack>
    </Collapse>
  );
};

export default CelebrateChart;
