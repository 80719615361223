import {
  Modal as ChakraModal,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import { isDesktop } from 'react-device-detect';

type StyledModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title?: React.ReactNode;
  titleTextStyle?: string;
  children: React.ReactNode;
  contentProps?: ModalContentProps;
  size?: ModalProps['size'];
  showCloseButton?: boolean;
  scrollBehavior?: ModalProps['scrollBehavior'];
};

const StyledModal = ({
  isOpen,
  onClose,
  title,
  titleTextStyle = 'h5SatBlack',
  children,
  contentProps,
  size = '4xl',
  showCloseButton = false,
  scrollBehavior = 'outside',
}: StyledModalProps) => {
  return (
    <ChakraModal
      blockScrollOnMount={isDesktop}
      isCentered={isDesktop}
      isOpen={isOpen}
      onClose={onClose}
      onEsc={onClose}
      onOverlayClick={onClose}
      returnFocusOnClose
      scrollBehavior={scrollBehavior}
      size={size}
      // Focus trap disabled as it prevents copy-pasting from the chatbot (RRI-2918)
      // TODO: find a better fix
      // TODO: after focus trap is enabled, remove the manual focus
      // (lookup the comments in the codebase by "RRI-2918")
      trapFocus={false}
      variant={titleTextStyle}
    >
      <ModalOverlay />
      <ModalContent borderRadius="2xl" backgroundColor="background-tertiary" {...contentProps}>
        {title && <ModalHeader>{title}</ModalHeader>}
        {showCloseButton && <ModalCloseButton />}
        {children}
      </ModalContent>
    </ChakraModal>
  );
};

export default StyledModal;
