import { keys } from '@/gql/global/keys';
import {
  CompleteDiscussionPointResponse,
  CreateDiscussionPointResponse,
  DeleteDiscussionPointResponse,
  GetPeopleResponse,
} from '@/gql/people/types';
import { queryClient } from '@/services/graphql/queryClient';
import { DiscussionPoint } from '@/types/discussionPoints';
import { calculateTotalMinutesBasedOnDuration } from '@/utils/action';
import { orderBy } from 'lodash';

import { sortPeopleAlphabetically } from './people';
import { getUserIdFromUrl } from './promises';

export function updateDiscussionPointsOrder(discussionPoints: DiscussionPoint[]): DiscussionPoint[] {
  return discussionPoints.map((action, index) => {
    return {
      ...action,
      order: index + 1,
    };
  });
}

export function removeCurrentFromDiscussionPointsList(discussionPoints: DiscussionPoint[], currentId: string) {
  return discussionPoints.filter((d) => d.id !== currentId);
}

function updateItemsOrders(actions: DiscussionPoint[], nearestIncompleteItemOrder: number) {
  return actions.map((item) => {
    if (item.order > nearestIncompleteItemOrder) {
      return { ...item, order: item.order + 1 };
    }
    return item;
  });
}

export function getIncompleteDiscussionPoints(discussionPoints: DiscussionPoint[]) {
  return discussionPoints.filter((item) => !item.isCompleted);
}

function findNearestIncompleteItem(discussionPoints: DiscussionPoint[], updatedDiscussionPointOrder: number) {
  return discussionPoints.reduce((nearestItem, currentItem) => {
    const currentItemOrder = currentItem.order;
    const nearestItemOrder = nearestItem.order;

    if (currentItemOrder < updatedDiscussionPointOrder && currentItemOrder > nearestItemOrder) {
      return currentItem;
    }
    return nearestItem;
  }, discussionPoints[0]);
}

function findIndexById(discussionPoints: DiscussionPoint[], discussionPointId: string) {
  return discussionPoints.findIndex((item) => item.id === discussionPointId);
}

export function updateOrderOnIncompleteDiscussionPoint(
  discussionPoints: DiscussionPoint[],
  updatedDiscussionPoint: DiscussionPoint,
) {
  const incompleteItems = getIncompleteDiscussionPoints(discussionPoints);

  if (incompleteItems.length === 0) {
    let modifiedItems = updateItemsOrders(
      removeCurrentFromDiscussionPointsList(discussionPoints, updatedDiscussionPoint.id),
      0,
    );
    modifiedItems = orderBy([...modifiedItems, { ...updatedDiscussionPoint, order: 1 }], 'order', 'asc');

    return updateDiscussionPointsOrder(modifiedItems);
  }

  const nearestIncompleteItem = findNearestIncompleteItem(incompleteItems, updatedDiscussionPoint.order);

  const nearestIncompleteItemOrder = nearestIncompleteItem.order ?? 0;

  const modifiedItems = updateItemsOrders(discussionPoints, nearestIncompleteItemOrder);

  const updatedItemIndex = findIndexById(discussionPoints, updatedDiscussionPoint.id);

  modifiedItems[updatedItemIndex] = {
    ...updatedDiscussionPoint,
    order: nearestIncompleteItemOrder + 1,
  };

  return updateDiscussionPointsOrder(orderBy(modifiedItems, 'order', 'asc'));
}

export function calculateDurationOfIncompleteDiscussionPoints(discussionPoints: DiscussionPoint[]) {
  const incompleteDiscussionPoints = getIncompleteDiscussionPoints(discussionPoints);

  return incompleteDiscussionPoints.reduce((acc, discussionPoint) => {
    return acc + calculateTotalMinutesBasedOnDuration(discussionPoint.duration);
  }, 0);
}

export function addDiscussionPointsToPersonCache(response: CreateDiscussionPointResponse) {
  queryClient.setQueryData<GetPeopleResponse>(keys.people.all.queryKey, (cache) => {
    if (!cache?.person) {
      return;
    }

    const person = cache.person.map((item) => {
      if (item.id === response.insertDiscussionItemOne.personId) {
        if (Array.isArray(item.discussion_items)) {
          item.discussion_items = [...item.discussion_items, response.insertDiscussionItemOne];
        } else {
          item.discussion_items = [response.insertDiscussionItemOne];
        }

        return {
          ...item,
          discussion_items: [...item.discussion_items, response.insertDiscussionItemOne],
        };
      }

      return item;
    });

    return {
      person: sortPeopleAlphabetically(person),
    };
  });
}

export function updateDiscussionPointsToPersonCache(response: CompleteDiscussionPointResponse) {
  queryClient.setQueryData<GetPeopleResponse>(keys.people.all.queryKey, (cache) => {
    const userIdFromUrl = getUserIdFromUrl();

    if (!cache?.person) {
      return;
    }

    if (!userIdFromUrl) {
      return;
    }

    const transformedData: DiscussionPoint[] = [];

    // Processing the JSON data to transform it into the desired output
    response.updateDiscussionItemMany.forEach((item) => {
      item.returning.forEach((discussionItem) => {
        transformedData.push({ ...discussionItem });
      });
    });

    const person = cache.person.map((item) => {
      if (item.id === userIdFromUrl) {
        return {
          ...item,
          discussion_items: transformedData,
        };
      }

      return item;
    });

    return {
      person: sortPeopleAlphabetically(person),
    };
  });
}

export function deleteDiscussionPointsFromPersonCache(response: DeleteDiscussionPointResponse) {
  queryClient.setQueryData<GetPeopleResponse>(keys.people.all.queryKey, (cache) => {
    if (!cache?.person) {
      return;
    }

    const person = cache.person.map((item) => {
      if (item.id === response.deleteDiscussionItemByPk.personId) {
        return {
          ...item,
          discussion_items: item?.discussion_items?.filter((item) => item.id !== response.deleteDiscussionItemByPk.id),
        };
      }

      return item;
    });

    return {
      person: sortPeopleAlphabetically(person),
    };
  });
}
