import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type ToggleDurationVisibilityType = {
  isDurationVisible: boolean;
  setIsDurationVisible?: () => void;
};

const useToggleDurationVisibility = create(
  persist<ToggleDurationVisibilityType>(
    (set, get) => ({
      isDurationVisible: true,
      setIsDurationVisible: () => set({ isDurationVisible: !get().isDurationVisible }),
    }),
    {
      name: 'is-duration-visible',
      partialize: (state) => ({
        isDurationVisible: state.isDurationVisible,
      }),
    },
  ),
);

export default useToggleDurationVisibility;
