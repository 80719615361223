import CategoryDragOverlay from '@/components/CategoryDragOverlay';
import CreateEditCategoryModal from '@/components/CreateEditCategoryModal';
import { CATEGORIES_ICONS } from '@/constants/category';
import useCaptureListData from '@/hooks/useCaptureListData';
import { useUpdateCategoriesOrder } from '@/services/categories/hooks';
import { IconPlus } from '@/theme/icons';
import { Action } from '@/types/actions';
import { Block } from '@/types/block';
import { Category } from '@/types/category';
import { fixUncategorizedName, isUncategorized } from '@/utils/index';
import rem from '@/utils/rem';
import { trackCreateCategorySelected } from '@/utils/tracking';
import { Box, Flex, Icon, Tooltip, VisuallyHidden, useDisclosure } from '@chakra-ui/react';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SVGProps, memo, useCallback, useEffect, useState } from 'react';

type OptionIconProps = {
  label: string;
  icon: React.FunctionComponent<SVGProps<SVGSVGElement>>;
  backgroundColor: string;
  iconColor?: string;
  highlight?: boolean;
};

const OptionIcon = ({
  label,
  icon,
  backgroundColor,
  iconColor = 'background-primary',
  highlight = false,
}: OptionIconProps) => {
  return (
    <>
      <Flex
        as="span"
        alignItems="center"
        justifyContent="center"
        width={rem(32)}
        height={rem(32)}
        opacity={highlight ? 1 : 0.5}
        border={highlight ? `${rem(2)} solid var(--chakra-colors-cyan-400)` : undefined}
        borderRadius="full"
        aria-hidden
      >
        <Flex
          as="span"
          alignItems="center"
          justifyContent="center"
          width={rem(28)}
          height={rem(28)}
          border={`${rem(2)} solid var(--chakra-colors-background-primary)`}
          borderRadius="full"
          backgroundColor={backgroundColor}
        >
          <Icon as={icon} boxSize={rem(14)} color={iconColor} />
        </Flex>
      </Flex>
      <VisuallyHidden>{label}</VisuallyHidden>
    </>
  );
};

type DroppableProps = {
  id: string;
  categories: CategoryWithExtraProps[];
};

const Droppable = memo(({ id, categories }: DroppableProps) => {
  const { setNodeRef } = useDroppable({
    id,
  });

  const focusStyle = { boxShadow: `0 0 ${rem(10)} 0 var(--chakra-colors-cyan-400)`, borderRadius: '100%' };
  const {
    isOpen: isCreateCategoryOpen,
    onOpen: onCreateCategoryOpen,
    onClose: onCreateCategoryClose,
  } = useDisclosure();

  return (
    <SortableContext items={categories} strategy={verticalListSortingStrategy}>
      <Flex ref={setNodeRef} flexShrink={0} gap={rem(15)} overflowY="auto" height="100%" flexFlow="column">
        {categories.map((category, index) => (
          <SortableItem
            id={category.id}
            aria-label={fixUncategorizedName(category.name)}
            category={category}
            index={index}
            key={category.id}
          />
        ))}
        <Tooltip label="Create a new Category" placement="right">
          <Box
            marginBottom="auto"
            _focusVisible={focusStyle}
            cursor="pointer"
            onClick={() => {
              onCreateCategoryOpen();
              trackCreateCategorySelected('side bar');
            }}
          >
            <OptionIcon
              label="Add Category"
              icon={IconPlus}
              backgroundColor="background-secondary"
              iconColor="text-primary"
            />
          </Box>
        </Tooltip>
        <CreateEditCategoryModal
          isOpen={isCreateCategoryOpen}
          onClose={onCreateCategoryClose}
          showEntityModalOnCreate
        />
      </Flex>
    </SortableContext>
  );
});

type SortableItemProps = {
  id: string;
  category: CategoryWithExtraProps;
  index: number;
};

const SortableItem = ({ id, category, index }: SortableItemProps) => {
  const { setNodeRef, attributes, listeners, transform, transition } = useSortable({
    id,
    data: { category },
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'grab',
    marginTop: index === 0 ? 'auto' : undefined,
  };

  return (
    <Box
      ref={setNodeRef}
      id={category.id}
      style={style}
      {...attributes}
      {...listeners}
      aria-label={fixUncategorizedName(category.name)}
    >
      <OptionIcon
        label={fixUncategorizedName(category.name)}
        aria-label={fixUncategorizedName(category.name)}
        icon={CATEGORIES_ICONS?.[category.icon ?? 'uncategorized']}
        backgroundColor={category.color}
        highlight={
          isUncategorized(category) ||
          category.completedActions.length > 0 ||
          category.incompleteActions.length > 0 ||
          category.blocks.length > 0
        }
      />
    </Box>
  );
};

export type CategoryWithExtraProps = Category & {
  completedActions: Action[];
  incompleteActions: Action[];
  blocks: Block[];
};

const CategorySelector = () => {
  const [draggedCategoryId, setDraggedCategoryId] = useState(null);

  const { categories } = useCaptureListData();

  // keeping a separate list helps prevent issues w/animation when dropping
  const [cats, setCats] = useState<CategoryWithExtraProps[]>([]);
  useEffect(() => {
    setCats(categories ?? []);
  }, [categories]);

  const draggedCategory = cats.find((category) => category.id === draggedCategoryId);

  const { mutate: updateCategoriesOrderMutation } = useUpdateCategoriesOrder();

  const updateCategoriesOrder = useCallback(
    (updatedCategories: Category[]) => {
      const mutationPayload = updatedCategories.map((category, index) => ({
        _set: {
          order: updatedCategories.length - index,
        },
        where: {
          id: {
            _eq: category.id,
          },
        },
      }));

      updateCategoriesOrderMutation({
        updates: mutationPayload,
      });
    },
    [updateCategoriesOrderMutation],
  );

  const handleDragStart = useCallback(({ active }: DragStartEvent) => {
    setDraggedCategoryId(active.data.current?.category.id);
  }, []);

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      const activeIndex = active?.data?.current?.sortable?.index;
      const overIndex = over?.data?.current?.sortable?.index;
      if (activeIndex === overIndex) {
        return;
      }
      const reorderedCategories = arrayMove(cats, activeIndex, overIndex);
      setCats(reorderedCategories);
      setDraggedCategoryId(null);
      updateCategoriesOrder(reorderedCategories);
    },
    [cats, updateCategoriesOrder],
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  return (
    <Flex
      flexShrink={0}
      gap={rem(15)}
      overflowY="auto"
      height="100%"
      marginRight={rem(22)}
      flexFlow="column"
      style={{
        // note: legacy code, doesn't work in safari
        scrollbarWidth: 'none',
      }}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <Droppable id="categories-selector" categories={cats} />
        {/* dnd-kit documentation recommends to make the zIndex as small as possible */}
        <DragOverlay style={{ width: 'unset' }} zIndex={2}>
          {draggedCategory ? <CategoryDragOverlay category={draggedCategory} variant="selector" /> : null}
        </DragOverlay>
      </DndContext>
    </Flex>
  );
};

export default memo(CategorySelector);
