import Input from '@/components/Input';
import RPMDateTimePicker from '@/components/RPMDateTimePicker/RPMDateTimePicker';
import StyledModal from '@/components/StyledModal';
import { useCreateKeyResult, useUpdateKeyResult } from '@/services/project/hooks';
import { IconCalendar, IconTrash } from '@/theme/icons';
import { KeyResult } from '@/types/project';
import rem from '@/utils/rem';
import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  InputGroup,
  ModalBody,
  ModalFooter,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  VStack,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

const ManageKeyResultFormSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: 'Key Result is required' })
    .max(180, { message: 'Key Result must be 180 characters or less' }),
});

type FormData = z.infer<typeof ManageKeyResultFormSchema>;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDeleteOpen?: () => void;
  projectId: string;
  keyResult?: KeyResult;
  count?: number;
};

function ManageKeyResultModal({ isOpen, onClose, onDeleteOpen, projectId, keyResult, count = 0 }: Props) {
  const toast = useToast();
  const calendarToolbarIconButtonBG = useColorModeValue('rgba(20, 39, 55, 0.40)', 'rgba(255, 255, 255, 0.40)');

  const createKeyResult = useCreateKeyResult({
    onSuccess: () => {
      toast({
        title: 'Key Result created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      reset();
      setDate(null);
      onClose();
    },
  });
  const updateKeyResult = useUpdateKeyResult({
    onSuccess: () => {
      toast({
        title: 'Key Result updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      reset();
      setDate(null);
      onClose();
    },
  });

  const { isOpen: isDatePickerOpen, onOpen: onDatePickerOpen, onClose: onDatePickerClose } = useDisclosure();

  const [date, setDate] = useState<Date | null>(null);

  const isEdit = !!keyResult;

  const {
    control,
    handleSubmit,
    reset,
    setFocus,
    formState: { errors, isValid },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
    },
    resolver: zodResolver(ManageKeyResultFormSchema),
    mode: 'onChange',
  });

  const handleChangeDate = (date: Date) => {
    setDate(date);
    onDatePickerClose();
  };

  const onCreate = useCallback(
    (form: FormData) => {
      if (!date) {
        return;
      }

      createKeyResult.mutate({
        id: uuidv4(),
        name: form.name,
        dueDate: date,
        projectId,
        order: count + 1,
        isStarred: false,
        isCompleted: false,
      });
    },
    [count, createKeyResult, date, projectId],
  );

  const onEdit = useCallback(
    (form: FormData) => {
      if (!keyResult || !date) {
        return;
      }

      updateKeyResult.mutate({
        id: keyResult.id,
        name: form.name,
        dueDate: date,
        isCompleted: keyResult.isCompleted,
        isStarred: keyResult.isStarred,
        projectId,
      });
    },
    [date, keyResult, updateKeyResult, projectId],
  );

  const handleDatePickerOpen = () => !isDatePickerOpen && onDatePickerOpen();

  const onCancel = useCallback(() => {
    reset();
    setDate(null);
    onClose();
  }, [onClose, reset]);

  useEffect(() => {
    if (keyResult) {
      setDate(new Date(keyResult.dueDate));
      reset({ name: keyResult.name });
    }
  }, [isOpen, reset, keyResult]);

  useEffect(() => {
    if (isOpen) {
      // Does not work without setTimeout
      // must be removed after focus trap is enabled
      // (lookup the comments in the codebase by "RRI-2918")
      const timeoutId = setTimeout(() => setFocus('name'), 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isOpen, setFocus]);

  return (
    <StyledModal isOpen={isOpen} onClose={onCancel} size="3xl" title={isEdit ? 'Edit Key Result' : 'Add a Key Result'}>
      <form onSubmit={handleSubmit(isEdit ? onEdit : onCreate)}>
        <ModalBody>
          <VStack alignItems="normal" width="full" spacing={rem(32)}>
            <Controller
              name="name"
              control={control}
              render={({ field: { value, onChange, ref } }) => (
                <Input
                  ref={ref}
                  label="Name *"
                  error={errors.name?.message}
                  onChange={onChange}
                  value={value}
                  maxWidth="100%"
                  fontWeight="normal"
                  fontSize={rem(16)}
                  autoComplete="off"
                  placeholder="Enter a key result"
                  containerProps={{
                    variant: 'floatingWithPlaceholder',
                  }}
                />
              )}
            />
            <HStack>
              <InputGroup>
                <Input
                  label="DUE DATE *"
                  value={date ? format(date, 'MM/dd/yyyy') : ''}
                  maxWidth="100%"
                  fontWeight="normal"
                  fontSize={rem(16)}
                  autoComplete="off"
                  placeholder="Select a due date from the calendar"
                  formControlWidth="full"
                  containerProps={{
                    variant: 'floatingWithPlaceholder',
                  }}
                  isReadOnly
                  onMouseDown={handleDatePickerOpen}
                />
              </InputGroup>

              <Popover
                isLazy
                isOpen={isDatePickerOpen}
                offset={[-30, 10]}
                onClose={onDatePickerClose}
                onOpen={onDatePickerOpen}
                placement="start"
              >
                <PopoverTrigger>
                  <IconButton
                    borderRadius={rem(8)}
                    aria-label="Select a date range"
                    backgroundColor={isDatePickerOpen ? calendarToolbarIconButtonBG : 'background-tertiary'}
                    icon={
                      <Tooltip label="Select a date range" placement="top">
                        <Box width={rem(16)} height={rem(16)} color={isDatePickerOpen ? 'black.500' : 'text-primary'}>
                          <IconCalendar width={rem(16)} height={rem(16)} />
                        </Box>
                      </Tooltip>
                    }
                    size="sm"
                    variant="calendarToolbarNextPrev"
                  />
                </PopoverTrigger>

                <PopoverContent
                  width={{ lg: rem(416) }}
                  padding={{ base: rem(8), lg: rem(16) }}
                  border={`${rem(1)} solid`}
                  borderColor="stroke-primary"
                  borderRadius={rem(4)}
                  backgroundColor="background-primary"
                >
                  <PopoverBody className="week-style" padding={0}>
                    <FocusLock returnFocus persistentFocus>
                      <RPMDateTimePicker
                        selectedDate={date || new Date()}
                        updateSelectedDate={handleChangeDate}
                        withActionButtons={false}
                      />
                    </FocusLock>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </HStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          {isEdit && (
            <IconButton
              marginRight="auto"
              aria-label="Delete Key Result"
              icon={<Icon as={IconTrash} boxSize={rem(20)} />}
              onClick={onDeleteOpen}
              variant="secondary"
            />
          )}

          <Button onClick={onCancel} size="lg" variant="secondary">
            Cancel
          </Button>
          <Button isDisabled={!isValid || !date || createKeyResult.isLoading} size="lg" type="submit" variant="primary">
            {isEdit ? 'Save' : 'Create key result'}
          </Button>
        </ModalFooter>
      </form>
    </StyledModal>
  );
}

export default ManageKeyResultModal;
