import rem from '@/utils/rem';
import { defineStyleConfig } from '@chakra-ui/react';

const activeLabelStyles = {
  transform: `translateY(${rem(-24)})`,
};

export default defineStyleConfig({
  variants: {
    floating: {
      // taken from Chakra-UI doc: https://chakra-ui.com/community/recipes/floating-labels
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      container: {
        _focusWithin: {
          label: {
            ...activeLabelStyles,
          },
        },
        'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
          {
            ...activeLabelStyles,
          },
        label: {
          left: 0,
          zIndex: 2,
          position: 'absolute',
          pointerEvents: 'none',
          transformOrigin: 'left top',
          WebkitMarginStart: 0,
          WebkitPaddingStart: 0,
        },
        _focusVisible: {
          borderColor: 'cyan.400',
        },
        'input:-webkit-autofill': {
          WebkitTextFillColor: '#fff',
        },
      },
    },
    floatingWithPlaceholder: {
      // taken from Chakra-UI doc: https://chakra-ui.com/community/recipes/floating-labels
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      container: {
        _focusWithin: {
          label: {
            ...activeLabelStyles,
          },
        },
        label: {
          left: 0,
          zIndex: 1,
          position: 'absolute',
          pointerEvents: 'none',
          transformOrigin: 'left top',
          WebkitMarginStart: 0,
          WebkitPaddingStart: 0,
          ...activeLabelStyles,
        },
        _focusVisible: {
          borderColor: 'cyan.400',
        },
        'input:-webkit-autofill': {
          WebkitTextFillColor: '#fff',
        },
      },
    },
  },
});
