import { GetLeveragedAndCommittedActionsResponse } from '@/gql/actions/types';
import { keys } from '@/gql/global/keys';
import { APIError } from '@/gql/global/types';
import {
  COMPLETE_DISCUSSION_POINT,
  CREATE_DISCUSSION_POINT,
  CREATE_PERSON,
  DELETE_DISCUSSION_POINT,
  DELETE_PERSON,
  EDIT_DISCUSSION_POINT,
  EDIT_PERSON,
  GET_PEOPLE_LIST,
  GET_PERSON,
  UPDATE_COMMITTED_ACTIONS_TO_OTHER_ORDER,
  UPDATE_COMMITTED_ACTIONS_TO_SINGLE_USER_ORDER,
  UPDATE_DISCUSSION_POINTS_ORDER,
  UPDATE_LEVERAGED_ACTIONS_TO_OTHER_ORDER,
  UPDATE_LEVERAGED_ACTIONS_TO_SINGLE_USER_ORDER,
} from '@/gql/people';
import {
  CompleteDiscussionPointPayload,
  CompleteDiscussionPointResponse,
  CreateDiscussionPointPayload,
  CreateDiscussionPointResponse,
  CreatePersonPayload,
  CreatePersonResponse,
  DeleteDiscussionPointPayload,
  DeleteDiscussionPointResponse,
  DeletePersonPayload,
  DeletePersonResponse,
  EditDiscussionPointPayload,
  EditDiscussionPointResponse,
  EditPersonResponse,
  GetPeopleResponse,
  GetPersonResponse,
  UpdateCommittedActionsToOtherOrderPayload,
  UpdateCommittedActionsToOtherOrderResponse,
  UpdateCommittedActionsToSingleUserOrderPayload,
  UpdateCommittedActionsToSingleUserOrderResponse,
  UpdateDiscussionPointsOrderPayload,
  UpdateDiscussionPointsOrderResponse,
  UpdateLeveragedActionsToOtherOrderPayload,
  UpdateLeveragedActionsToOtherOrderResponse,
  UpdateLveragedActionsToSingleUserOrderPayload,
  UpdateLveragedActionsToSingleUserOrderResponse,
} from '@/gql/people/types';
import { fetchData } from '@/services/graphql';
import { queryClient } from '@/services/graphql/queryClient';
import { getWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { getSelectedDate } from '@/stores/useCalendar';
import { getIncompleteActions } from '@/utils/action';
import {
  addDiscussionPointsToPersonCache,
  deleteDiscussionPointsFromPersonCache,
  updateDiscussionPointsToPersonCache,
} from '@/utils/discussion-point';
import { sortPeopleAlphabetically } from '@/utils/people';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { orderBy } from 'lodash';

type UpdateDiscussionPointsOrderDTO = {
  id: string;
  order: number;
};

type UpdateCommittedActionsToOtherOrderDTO = {
  id: string;
  globalCommitOrder: number;
};

type UpdateLeveragedActionsOrderDTO = {
  id: string;
  globalLeverageOrder: number;
};

type UpdateCommittedActionsToSingleUserOrderDTO = {
  actionId?: string;
  commitOrder?: number | null;
};

type UpdateLeveragedActionsToSingleUserOrderDTO = {
  actionId?: string;
  leverageOrder?: number | null;
};

export const usePeople = (options?: UseQueryOptions<GetPeopleResponse, APIError, GetPeopleResponse>) => {
  return useQuery({
    queryKey: keys.people.all.queryKey,
    queryFn: () => fetchData(GET_PEOPLE_LIST),
    ...options,
  });
};

export const useCreatePerson = (
  options?: Omit<UseMutationOptions<CreatePersonResponse, APIError, CreatePersonPayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload: CreatePersonPayload) => fetchData<CreatePersonResponse>(CREATE_PERSON, payload),
    ...options,

    onSuccess: (response) => {
      if (!response.insertPersonOne) {
        return;
      }

      queryClient.setQueryData<GetPeopleResponse>(keys.people.all.queryKey, (cache) => {
        if (!cache?.person) {
          return;
        }

        return {
          person: sortPeopleAlphabetically([...cache.person, response.insertPersonOne]),
        };
      });
    },
  });
};

export const usePerson = (id?: string, options?: UseQueryOptions<GetPersonResponse, APIError, GetPersonResponse>) => {
  return useQuery({
    queryKey: keys.people.detail(id ?? '').queryKey,
    queryFn: () => fetchData(GET_PERSON, { id }),
    ...options,
    onSuccess: (response) => {
      response.personByPk.discussion_items.sort((a, b) => a.order - b.order);
    },
  });
};

export const useEditPerson = (
  options?: Omit<UseMutationOptions<EditPersonResponse, APIError, CreatePersonPayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload: CreatePersonPayload) => fetchData<EditPersonResponse>(EDIT_PERSON, payload),
    ...options,

    onSuccess: (response) => {
      if (!response.updatePersonByPk) {
        return;
      }

      queryClient.setQueryData<GetPersonResponse>(
        keys.people.detail(response.updatePersonByPk.id).queryKey,
        (cache) => {
          if (!cache) {
            return;
          }

          return {
            personByPk: {
              ...cache.personByPk,
              ...response.updatePersonByPk,
            },
          };
        },
      );

      queryClient.setQueryData<GetPeopleResponse>(keys.people.all.queryKey, (cache) => {
        if (!cache?.person) {
          return;
        }

        const person = cache.person.map((item) => {
          if (item.id === response.updatePersonByPk.id) {
            return {
              ...item,
              id: response.updatePersonByPk.id,
              name: response.updatePersonByPk.name,
              email: response.updatePersonByPk.email,
              relation: response.updatePersonByPk.relation,
            };
          }

          return item;
        });

        return {
          person: sortPeopleAlphabetically(person),
        };
      });

      queryClient.setQueryData<GetLeveragedAndCommittedActionsResponse>(
        keys.actions.leveragedAndCommitted.queryKey,
        (cache) => {
          if (!cache) {
            return;
          }

          const leveragedActions = cache.leveragedActions.map((task) => {
            return {
              ...task,
              promises: task?.promises?.map((promise) => {
                if (promise.personId === response.updatePersonByPk.id) {
                  return {
                    ...promise,
                    person: {
                      ...promise.person,
                      name: response.updatePersonByPk.name,
                      email: response.updatePersonByPk.email,
                      relation: response.updatePersonByPk.relation,
                    },
                  };
                }
                return promise;
              }),
            };
          });

          const committedActions = cache.committedActions.map((task) => {
            return {
              ...task,
              promises: task?.promises?.map((promise) => {
                if (promise.personId === response.updatePersonByPk.id) {
                  return {
                    ...promise,
                    person: {
                      ...promise.person,
                      name: response.updatePersonByPk.name,
                      email: response.updatePersonByPk.email,
                      relation: response.updatePersonByPk.relation,
                    },
                  };
                }
                return promise;
              }),
            };
          });

          return {
            leveragedActions: getIncompleteActions(leveragedActions),
            committedActions: getIncompleteActions(committedActions),
          };
        },
      );
    },
  });
};

export const useDeletePerson = (
  options?: Omit<UseMutationOptions<DeletePersonResponse, APIError, DeletePersonPayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload: DeletePersonPayload) => fetchData<DeletePersonResponse>(DELETE_PERSON, payload),
    ...options,

    onSuccess: async (response) => {
      if (!response.deletePersonByPk) {
        return;
      }

      queryClient.setQueryData<GetPeopleResponse>(keys.people.all.queryKey, (cache) => {
        if (!cache?.person) {
          return;
        }

        return {
          person: cache.person.filter(({ id }) => id !== response.deletePersonByPk.id),
        };
      });

      const selectedDate = getSelectedDate();

      if (!selectedDate) {
        return;
      }

      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      if (!weeklyPlanId) {
        return;
      }

      await queryClient.invalidateQueries(keys.actions.all._ctx.week(weeklyPlanId).queryKey, { type: 'all' }); //
      await queryClient.invalidateQueries(keys.actions.leveragedAndCommitted.queryKey);
    },
  });
};

export const useCreateDiscussionPoint = (
  options?: Omit<
    UseMutationOptions<CreateDiscussionPointResponse, APIError, CreateDiscussionPointPayload>,
    'onSuccess'
  >,
) => {
  return useMutation({
    mutationFn: (payload: CreateDiscussionPointPayload) =>
      fetchData<CreateDiscussionPointResponse>(CREATE_DISCUSSION_POINT, payload),
    ...options,

    onSuccess: (response) => {
      if (!response.insertDiscussionItemOne) {
        return;
      }

      queryClient.setQueryData<GetPersonResponse>(
        keys.people.detail(response.insertDiscussionItemOne.personId).queryKey,
        (cache) => {
          if (!cache) {
            return;
          }

          return {
            personByPk: {
              ...cache.personByPk,
              discussion_items: [...cache.personByPk.discussion_items, response.insertDiscussionItemOne],
            },
          };
        },
      );

      addDiscussionPointsToPersonCache(response);
    },
  });
};

export const useCompleteDiscussionPoint = (
  options?: Omit<
    UseMutationOptions<CompleteDiscussionPointResponse, APIError, CompleteDiscussionPointPayload[]>,
    'onSuccess'
  >,
) => {
  return useMutation({
    mutationFn: (payload: CompleteDiscussionPointPayload[]) =>
      fetchData<CompleteDiscussionPointResponse>(COMPLETE_DISCUSSION_POINT, {
        updates: payload,
      }),

    ...options,

    onSuccess: (response) => {
      const data = response.updateDiscussionItemMany.map((i) => i.returning).flat();

      const personId = data[0].personId;

      queryClient.setQueryData<GetPersonResponse>(keys.people.detail(personId).queryKey, (cache) => {
        if (!cache) {
          return;
        }

        return {
          personByPk: {
            ...cache.personByPk,
            discussion_items: orderBy(data, 'order', 'asc'),
          },
        };
      });

      updateDiscussionPointsToPersonCache(response);
    },
  });
};

export const useEditDiscussionPoint = (
  options?: Omit<UseMutationOptions<EditDiscussionPointResponse, APIError, EditDiscussionPointPayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload: EditDiscussionPointPayload) =>
      fetchData<EditDiscussionPointResponse>(EDIT_DISCUSSION_POINT, payload),
    ...options,

    onSuccess: (response) => {
      if (!response.updateDiscussionItemByPk) {
        return;
      }

      queryClient.setQueryData<GetPersonResponse>(
        keys.people.detail(response.updateDiscussionItemByPk.personId).queryKey,
        (cache) => {
          if (!cache) {
            return;
          }

          return {
            personByPk: {
              ...cache.personByPk,
              discussion_items: cache.personByPk.discussion_items.map((item) =>
                item.id === response.updateDiscussionItemByPk.id ? response.updateDiscussionItemByPk : item,
              ),
            },
          };
        },
      );

      queryClient.setQueryData<GetPeopleResponse>(keys.people.all.queryKey, (cache) => {
        if (!cache?.person) {
          return;
        }

        const person = cache.person.map((item) => {
          if (item.id === response.updateDiscussionItemByPk.personId) {
            return {
              ...item,
              discussion_items: item?.discussion_items?.map((item) =>
                item.id === response.updateDiscussionItemByPk.id ? response.updateDiscussionItemByPk : item,
              ),
            };
          }

          return item;
        });

        return {
          person: sortPeopleAlphabetically(person),
        };
      });
    },
  });
};

export const useDeleteDiscussionPoint = (
  options?: Omit<
    UseMutationOptions<DeleteDiscussionPointResponse, APIError, DeleteDiscussionPointPayload>,
    'onSuccess'
  >,
) => {
  return useMutation({
    mutationFn: (payload: DeleteDiscussionPointPayload) =>
      fetchData<DeleteDiscussionPointResponse>(DELETE_DISCUSSION_POINT, payload),
    ...options,

    onSuccess: (response) => {
      if (!response.deleteDiscussionItemByPk) {
        return;
      }

      queryClient.setQueryData<GetPersonResponse>(
        keys.people.detail(response.deleteDiscussionItemByPk.personId).queryKey,
        (cache) => {
          if (!cache) {
            return;
          }

          return {
            personByPk: {
              ...cache.personByPk,
              discussion_items: cache.personByPk.discussion_items.filter(
                (item) => item.id !== response.deleteDiscussionItemByPk.id,
              ),
            },
          };
        },
      );

      deleteDiscussionPointsFromPersonCache(response);
    },
  });
};

export const useUpdateDiscussionPointsOrder = (
  options?: UseMutationOptions<UpdateDiscussionPointsOrderResponse, APIError, UpdateDiscussionPointsOrderPayload[]>,
) => {
  return useMutation({
    mutationFn: (updates: UpdateDiscussionPointsOrderPayload[]) =>
      fetchData<UpdateDiscussionPointsOrderResponse>(UPDATE_DISCUSSION_POINTS_ORDER, { updates }),

    ...options,
  });
};

export const updateDiscussionPointsOrderOnCache = (payload: UpdateDiscussionPointsOrderPayload[], personId: string) => {
  let discussionPoints: UpdateDiscussionPointsOrderDTO[] = [];

  payload.map(({ where, _set }) => {
    discussionPoints = [
      ...discussionPoints,
      {
        id: String(where.id._eq),
        order: Number(_set.order),
      },
    ];
  });

  queryClient.setQueryData<GetPersonResponse>(keys.people.detail(personId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      personByPk: {
        ...state.personByPk,
        discussion_items: orderBy(
          state.personByPk.discussion_items.map((discussionPoint) => {
            const updatedDiscussionPoint = discussionPoints.find((k) => k.id === discussionPoint.id);
            if (updatedDiscussionPoint) {
              return {
                ...discussionPoint,
                order: updatedDiscussionPoint.order,
              };
            }
            return discussionPoint;
          }),
          'order',
          'asc',
        ),
      },
    };
  });
};

export const useUpdateCommittedActionsToOtherOrder = (
  options?: UseMutationOptions<
    UpdateCommittedActionsToOtherOrderResponse,
    APIError,
    UpdateCommittedActionsToOtherOrderPayload[]
  >,
) => {
  return useMutation({
    mutationFn: (actionUpdates: UpdateCommittedActionsToOtherOrderPayload[]) =>
      fetchData<UpdateCommittedActionsToOtherOrderResponse>(UPDATE_COMMITTED_ACTIONS_TO_OTHER_ORDER, { actionUpdates }),

    ...options,

    onMutate: (payload) => {
      updatePeopleCommittedActionsToOtherOnCache(payload);
    },
  });
};

export const updatePeopleCommittedActionsToOtherOnCache = (payload: UpdateCommittedActionsToOtherOrderPayload[]) => {
  let actions: UpdateCommittedActionsToOtherOrderDTO[] = [];

  payload.map(({ where, _set }) => {
    actions = [
      ...actions,
      {
        id: String(where.id._eq),
        globalCommitOrder: Number(_set.globalCommitOrder),
      },
    ];
  });

  queryClient.setQueryData<GetLeveragedAndCommittedActionsResponse>(
    keys.actions.leveragedAndCommitted.queryKey,
    (state) => {
      if (state == null) {
        return state;
      }

      return {
        leveragedActions: state.leveragedActions,
        committedActions: state.committedActions.map((action) => {
          const updatedAction = actions.find((a) => a.id === action.id);

          if (updatedAction) {
            return {
              ...action,
              globalCommitOrder: updatedAction.globalCommitOrder,
            };
          }

          return action;
        }),
      };
    },
  );
};

export const useUpdateLeveragedActionsToOtherOrder = (
  options?: UseMutationOptions<
    UpdateLeveragedActionsToOtherOrderResponse,
    APIError,
    UpdateLeveragedActionsToOtherOrderPayload[]
  >,
) => {
  return useMutation({
    mutationFn: (actionUpdates: UpdateLeveragedActionsToOtherOrderPayload[]) =>
      fetchData<UpdateLeveragedActionsToOtherOrderResponse>(UPDATE_LEVERAGED_ACTIONS_TO_OTHER_ORDER, { actionUpdates }),

    ...options,

    onMutate: (payload) => {
      updatePeopleLeveragedActionsToOtherOnCache(payload);
    },
  });
};

export const updatePeopleLeveragedActionsToOtherOnCache = (payload: UpdateLeveragedActionsToOtherOrderPayload[]) => {
  let actions: UpdateLeveragedActionsOrderDTO[] = [];

  payload.map(({ where, _set }) => {
    actions = [
      ...actions,
      {
        id: String(where.id._eq),
        globalLeverageOrder: Number(_set.globalLeverageOrder),
      },
    ];
  });

  queryClient.setQueryData<GetLeveragedAndCommittedActionsResponse>(
    keys.actions.leveragedAndCommitted.queryKey,
    (state) => {
      if (state == null) {
        return state;
      }

      return {
        leveragedActions: state.leveragedActions.map((action) => {
          const updatedAction = actions.find((a) => a.id === action.id);

          if (updatedAction) {
            return {
              ...action,
              globalLeverageOrder: updatedAction.globalLeverageOrder,
            };
          }

          return action;
        }),
        committedActions: state.committedActions,
      };
    },
  );
};

export const useUpdateCommittedActionsToSingleUserOrder = (
  personId: string,
  options?: UseMutationOptions<
    UpdateCommittedActionsToSingleUserOrderResponse,
    APIError,
    UpdateCommittedActionsToSingleUserOrderPayload[]
  >,
) => {
  return useMutation({
    mutationFn: (actionUpdates: UpdateCommittedActionsToSingleUserOrderPayload[]) =>
      fetchData<UpdateCommittedActionsToSingleUserOrderResponse>(UPDATE_COMMITTED_ACTIONS_TO_SINGLE_USER_ORDER, {
        actionUpdates,
      }),

    ...options,

    onMutate: (payload) => {
      if (!personId) return;

      updatePeopleCommittedActionsToSingleUserOnCache(payload);
    },
  });
};

export const updatePeopleCommittedActionsToSingleUserOnCache = (
  payload: UpdateCommittedActionsToSingleUserOrderPayload[],
) => {
  let actions: UpdateCommittedActionsToSingleUserOrderDTO[] = [];

  payload.map(({ where, _set }) => {
    actions = [
      ...actions,
      {
        actionId: String(where.actionId._eq),
        commitOrder: Number(_set.commitOrder),
      },
    ];
  });

  queryClient.setQueryData<GetLeveragedAndCommittedActionsResponse>(
    keys.actions.leveragedAndCommitted.queryKey,
    (state) => {
      if (state == null) {
        return state;
      }

      return {
        leveragedActions: state.leveragedActions,
        committedActions: state.committedActions.map((action) => {
          const updatedAction = actions.find((a) => a.actionId === action.id);

          if (updatedAction) {
            return {
              ...action,
              promises: action?.promises?.map((promise) => ({
                ...promise,
                commitOrder: updatedAction?.commitOrder || promise.commitOrder,
              })),
            };
          }

          return action;
        }),
      };
    },
  );
};

export const useUpdateLeveragedActionsToSingleUserOrder = (
  personId: string,
  options?: UseMutationOptions<
    UpdateLveragedActionsToSingleUserOrderResponse,
    APIError,
    UpdateLveragedActionsToSingleUserOrderPayload[]
  >,
) => {
  return useMutation({
    mutationFn: (actionUpdates: UpdateLveragedActionsToSingleUserOrderPayload[]) =>
      fetchData<UpdateLveragedActionsToSingleUserOrderResponse>(UPDATE_LEVERAGED_ACTIONS_TO_SINGLE_USER_ORDER, {
        actionUpdates,
      }),

    ...options,

    onMutate: (payload) => {
      if (!personId) return;

      updatePeopleLeveragedActionsToSingleUserOnCache(payload);
    },
  });
};

export const updatePeopleLeveragedActionsToSingleUserOnCache = (
  payload: UpdateLveragedActionsToSingleUserOrderPayload[],
) => {
  let actions: UpdateLeveragedActionsToSingleUserOrderDTO[] = [];

  payload.map(({ where, _set }) => {
    actions = [
      ...actions,
      {
        actionId: String(where.actionId._eq),
        leverageOrder: Number(_set.leverageOrder),
      },
    ];
  });

  queryClient.setQueryData<GetLeveragedAndCommittedActionsResponse>(
    keys.actions.leveragedAndCommitted.queryKey,
    (state) => {
      if (state == null) {
        return state;
      }

      return {
        leveragedActions: state.leveragedActions.map((action) => {
          const updatedAction = actions.find((a) => a.actionId === action.id);

          if (updatedAction) {
            return {
              ...action,
              promises: action?.promises?.map((promise) => ({
                ...promise,
                leverageOrder: updatedAction?.leverageOrder || promise.leverageOrder,
              })),
            };
          }

          return action;
        }),
        committedActions: state.committedActions,
      };
    },
  );
};
