import { ReactNode, createContext, useContext } from 'react';

export interface FeatureFlags {
  [key: string]: boolean;
}

export interface FeatureFlagContextProps {
  [key: string]: FeatureFlags;
}

export interface FeatureFlagProviderProps {
  environment: string;
  children: ReactNode;
}

export const FeatureFlagContext = createContext<FeatureFlags>({});

export const featureFlags: FeatureFlagContextProps = {
  // Define your feature flags for each environment here
  staging: {
    darkTheme: true,
    M7ComingSoon: false,
  },
  uat: {
    darkTheme: true,
    M7ComingSoon: false,
  },
  production: {
    darkTheme: true,
    M7ComingSoon: false,
  },
};

export const useFeatureFlags = (): FeatureFlags => {
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw new Error('useFeatureFlags should be used within a FeatureFlagProvider.');
  }

  return context;
};
