import WeeklyEventItem from '@/components/MyPlanStep/WeeklyCalendar/WeeklyEventItem';
import { Category } from '@/types/category';
import { getColorWithOpacityFromToken } from '@/utils/color';
import rem from '@/utils/rem';
import { BoxProps, Flex, FlexProps, TableCellProps, Tbody, Td, VStack } from '@chakra-ui/react';
import { Data, UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactNode, memo } from 'react';

type InfoCellProps = {
  children: ReactNode;
  containerProps?: FlexProps;
};

function InfoCell({ children, containerProps }: InfoCellProps) {
  return (
    <Td border="none" paddingInline={rem(5)}>
      <VStack>
        <Flex
          alignItems="center"
          justifyContent="center"
          width={rem(32)}
          height={rem(32)}
          borderRadius="full"
          backgroundColor="gray.300"
          {...containerProps}
        >
          {children}
        </Flex>
      </VStack>
    </Td>
  );
}

type CellProps = {
  categoryInfo: Category;
  bgColorOpacity?: string;
} & TableCellProps;

const Cell = ({ children, categoryInfo, bgColorOpacity = '0.1', ...rest }: CellProps) => {
  return (
    <Td
      verticalAlign="top"
      minHeight="100%"
      opacity="0.2 !important"
      border="none"
      backgroundColor={getColorWithOpacityFromToken(categoryInfo.color, bgColorOpacity)}
      paddingX="unset"
      paddingY={rem(8)}
      {...rest}
    >
      {children}
    </Td>
  );
};

type DroppableContainerProps = {
  children: React.ReactNode;
  disabled?: boolean;
  id: UniqueIdentifier;
  isOver: boolean;
  overColor: string;
  payload?: Data;
};

const DroppableContainer = ({
  children,
  disabled = false,
  id,
  isOver = false,
  payload,
  overColor,
  ...rest
}: DroppableContainerProps & BoxProps) => {
  const { setNodeRef, transform, transition } = useSortable({
    id,
    data: payload,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: isOver ? getColorWithOpacityFromToken(overColor) : 'transparent',
    borderRadius: rem(4),
  };

  return (
    <VStack ref={disabled ? undefined : setNodeRef} width="full" paddingX={rem(4)} style={style} {...rest}>
      {children}
    </VStack>
  );
};

type BodyProps = {
  children: ReactNode;
};

function Body({ children }: BodyProps) {
  return <Tbody height="100%">{children}</Tbody>;
}

Body.InfoCell = memo(InfoCell);
Body.Cell = memo(Cell);
Body.WeeklyEventItem = memo(WeeklyEventItem);
Body.DroppableContainer = memo(DroppableContainer);

export default Body;
