import DialogModal from '@/components/DialogModal';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

function DeleteBlockModal({ isOpen, onClose, onSubmit }: Props) {
  return (
    <DialogModal
      isOpen={isOpen}
      title="Delete Block"
      message="Do you really want to delete this block?"
      onCancel={onClose}
      onConfirm={onSubmit}
      confirmVariant="danger"
      confirmText="Delete block"
    />
  );
}

export default DeleteBlockModal;
