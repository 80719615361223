import { gql } from 'graphql-request';

export const GET_PUBLIC_LEVERAGE_REQUESTS = gql`
  query getPublicLeverageRequests($token: String!) {
    publicLeverageRequests(token: $token) {
      promiseId
      actionTitle
      senderName
      leverageRequestStatus
      leverageRequestedAt
      leverageRequestDueDate
      leverageRequestText
      leverageRequestReplyText
      leverageRequestRepliedAt
    }
  }
`;

export const UPDATE_PUBLIC_LEVERAGE_REQUESTS = gql`
  mutation updatePublicLeverageRequest(
    $token: String!
    $promiseId: uuid!
    $leverageRequestStatus: String!
    $leverageRequestReplyText: String
  ) {
    publicUpdateLeverageRequest(
      token: $token
      promiseId: $promiseId
      leverageRequestStatus: $leverageRequestStatus
      leverageRequestReplyText: $leverageRequestReplyText
    ) {
      ok
    }
  }
`;
