import useIsDarkMode from '@/hooks/useIsDarkMode';
import { PeopleOverviewBgImage } from '@/theme/StaticImages';
import { IconPlus } from '@/theme/icons';
import { getColorFromToken } from '@/utils/color';
import rem from '@/utils/rem';
import { Box, Button, Icon, Text, VStack } from '@chakra-ui/react';

const NAV_HEIGHT = rem(50);

const opaciedBlue = getColorFromToken('blue.1000', '0.80');
const opaciedGray = getColorFromToken('gray.50', '0.80');

type Props = {
  onAddPersonClick: () => void;
};

function NoPeopleOverview({ onAddPersonClick }: Props) {
  const isDarkMode = useIsDarkMode();
  const gradientColor = isDarkMode ? opaciedBlue : opaciedGray;

  return (
    <Box
      height={`calc(100vh - ${NAV_HEIGHT})`}
      background={`linear-gradient(180deg, rgba(0, 0, 0, 0.00) 48.96%, ${gradientColor} 100%), linear-gradient(0deg, ${gradientColor} 0%, ${gradientColor} 100%), url(${PeopleOverviewBgImage})`}
      backgroundSize="cover"
      backgroundPosition="center"
    >
      <VStack
        justifyContent="center"
        gap={rem(32)}
        height="100%"
        textAlign="center"
        paddingX={{ base: rem(16), lg: rem(144) }}
      >
        <Text as="h2" textStyle="h2BarSB" color="text-tertiary" textTransform="uppercase">
          Unleashing the Power of Connection
        </Text>
        <Text as="h5" textStyle="h5SatMedium" color="text-primary">
          Reach out, expand your circle, and add those incredible connections into your journey.{'\n'}
          Every individual can bring a unique magic to your life!
        </Text>
        <Button
          leftIcon={<Icon as={IconPlus} boxSize={rem(16)} />}
          onClick={onAddPersonClick}
          paddingX={rem(32)}
          paddingY={rem(16)}
          variant="primary"
        >
          Add a person
        </Button>
      </VStack>
    </Box>
  );
}

export default NoPeopleOverview;
