import { humanDuration } from '@/utils/index';
import { GridItem, Text } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

type Props = {
  measureUnit: number;
  durationSegment: number;
};

const TimeText = ({ measureUnit, durationSegment }: Props) => {
  const durationRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (durationRef.current && durationRef.current.parentElement) {
      const textWidth = durationRef?.current?.offsetWidth;
      const transformValue = `translateX(${measureUnit / textWidth}px)`;

      durationRef.current.parentElement.style.transform = transformValue;
    }
  }, [measureUnit]);

  return (
    <GridItem backgroundColor="transparent">
      <Text textStyle="xsmall" color="gray.500" textAlign="center">
        {humanDuration(durationSegment * 60)}
      </Text>
    </GridItem>
  );
};

export default TimeText;
