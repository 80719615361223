import { getColorFromToken } from '@/utils/color';
import rem from '@/utils/rem';
import { Box, BoxProps, Flex, Text, Tooltip, VStack } from '@chakra-ui/react';

type Props = {
  title: string;
  body: string;
  categoryColor: string;
  opacity?: number;
} & BoxProps;

function GradientCard({ title, body, categoryColor, opacity = 1, ...rest }: Props) {
  return (
    <VStack
      sx={{
        '@media print': {
          bgGradient: 'unset',
          backgroundColor: 'print-background-alt',
        },
      }}
      alignItems="start"
      gap={rem(10)}
      width="full"
      marginTop={rem(16)}
      padding={rem(16)}
      bgGradient={`linear-gradient(90deg, ${getColorFromToken(categoryColor, '.10')} 0%, rgba(153, 113, 133, 0.10) 100%)`}
      opacity={opacity}
      borderRadius="base"
      {...rest}
    >
      <Flex alignItems="center">
        <Box width={rem(4)} height={rem(4)} marginRight={rem(4)} borderRadius="50%" backgroundColor={categoryColor} />
        <Text color="text-primary" fontSize={rem(9)} fontWeight={700} textTransform="uppercase">
          {title}
        </Text>
      </Flex>
      <Tooltip label={body} placement="top">
        <Text textStyle="medium" color="text-primary" fontWeight={700} noOfLines={2}>
          {body}
        </Text>
      </Tooltip>
    </VStack>
  );
}

export default GradientCard;
