import { GetBlocksResponse } from '@/gql/block/types';
import { GetCategoriesResponse } from '@/gql/category/types';
import { keys } from '@/gql/global/keys';
import { queryClient } from '@/services/graphql/queryClient';
import {
  CMImageEight,
  CMImageEleven,
  CMImageFive,
  CMImageFour,
  CMImageNine,
  CMImageSeven,
  CMImageSix,
  CMImageTen,
  CMImageTwelve,
} from '@/theme/StaticImages';
import { Category } from '@/types/category';

type CategoryImage = {
  image: string; // Assuming CMImageAutumn, CMImageWinter, etc., are of type string
  name: string;
};

export const categoriesDefaultImages: CategoryImage[] = [
  {
    image: CMImageFour,
    name: 'image4',
  },
  {
    image: CMImageFive,
    name: 'image5',
  },
  {
    image: CMImageSix,
    name: 'image6',
  },
  {
    image: CMImageSeven,
    name: 'image7',
  },
  {
    image: CMImageEight,
    name: 'image8',
  },
  {
    image: CMImageNine,
    name: 'image9',
  },
  {
    image: CMImageTen,
    name: 'image10',
  },
  {
    image: CMImageEleven,
    name: 'image11',
  },
  {
    image: CMImageTwelve,
    name: 'image12',
  },
];

export function getCategoryById(categories: Category[], categoryId: string) {
  return categories?.find((category) => category.id === categoryId);
}

export function getRandomCategoryImage(): CategoryImage {
  const randomIndex: number = Math.floor(Math.random() * categoriesDefaultImages.length);

  return categoriesDefaultImages[randomIndex];
}

export function resolveCategoryImageByName(imageName: string) {
  if (imageName.includes('https://')) {
    return imageName;
  }

  const imageCategory = categoriesDefaultImages.find((category) => category.name === imageName);

  return imageCategory?.image;
}

export function updateCategoryInCache(category: Category) {
  queryClient.setQueryData<GetCategoriesResponse>(keys.categories.all.queryKey, (cache) => {
    if (!cache) {
      return;
    }

    return {
      category: cache.category.map((c) => {
        if (c.id === category.id) {
          return {
            ...c,
            ...category,
          };
        }
        return c;
      }),
    };
  });

  queryClient.setQueryData<GetBlocksResponse>(keys.blocks.all.queryKey, (cache) => {
    if (!cache) {
      return;
    }

    return {
      block: cache.block.map((c) => {
        if (c.categoryId === category.id) {
          return {
            ...c,
            category,
          };
        }
        return c;
      }),
    };
  });
}
