import rem from '@/utils/rem';
import { Flex, FlexProps, Spinner } from '@chakra-ui/react';

interface Props extends FlexProps {
  spinnerColor?: string;
  backgroundSpinnerColor?: string;
}

function SpinnerFullPage({ spinnerColor = 'white.500', backgroundSpinnerColor = 'gray.900', ...rest }: Props) {
  return (
    <Flex
      position="absolute"
      zIndex={99}
      alignItems="center"
      justifyContent="center"
      width="full"
      height="full"
      backgroundColor="rgba(0, 0, 0, 0.5)"
      {...rest}
    >
      <Spinner color={spinnerColor} emptyColor={backgroundSpinnerColor} size="xl" speed="0.65s" thickness={rem(4)} />
    </Flex>
  );
}

export default SpinnerFullPage;
