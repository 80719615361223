import rem from '@/utils/rem';
import { Flex, HStack, Icon, Text } from '@chakra-ui/react';
import { FC, JSXElementConstructor, ReactNode, SVGProps } from 'react';

type Props = {
  color: string | undefined;
  icon?: FC<SVGProps<SVGSVGElement>> | JSXElementConstructor<SVGProps<SVGSVGElement>>;
  rightButton?: ReactNode;
  sectionTitle?: string;
};

// Helper function for rendering the title section
const TitleSection = ({ icon, sectionTitle, color, rightButton }: Props) => {
  return (
    <HStack alignItems="center" width="full">
      {(icon || sectionTitle) && (
        <Flex alignItems="center" justifyContent="space-between" width="full">
          <HStack alignItems="center" gap={rem(20)}>
            {icon && (
              <Flex
                alignItems="center"
                justifyContent="center"
                width={rem(36)}
                height={rem(36)}
                borderRadius={4}
                backgroundColor={color}
              >
                <Icon as={icon} boxSize={rem(24)} color="text-secondary" />
              </Flex>
            )}

            {sectionTitle && (
              <Text
                sx={{
                  '@media print': {
                    color: 'print-text',
                  },
                }}
                textStyle="smallBlack"
                color={color}
                lineHeight="110%"
                textTransform="uppercase"
              >
                {sectionTitle}
              </Text>
            )}
          </HStack>
        </Flex>
      )}

      {rightButton && <Flex alignSelf="start">{rightButton}</Flex>}
    </HStack>
  );
};

export default TitleSection;
