import { create } from 'zustand';

type StateSelected = {
  selectedDate: Date;
};

type CalendarMonthly = {
  selectedDate: Date;
  updateSelectedDate: (selectedDate: StateSelected['selectedDate']) => void;
};

const useCalendarMonthlyStore = create<CalendarMonthly>((set) => ({
  selectedDate: new Date(),
  updateSelectedDate: (selectedDate) => set(() => ({ selectedDate })),
}));

const getSelectedDate = (): Date => {
  return useCalendarMonthlyStore.getState().selectedDate;
};

export { useCalendarMonthlyStore, getSelectedDate };
