import { CATEGORIES_ICONS } from '@/constants/category';
import useIsDarkMode from '@/hooks/useIsDarkMode';
import { useCategories } from '@/services/categories/hooks';
import { Category } from '@/types/category';
import { getColorFromToken } from '@/utils/color';
import { fixUncategorizedName } from '@/utils/index';
import rem from '@/utils/rem';
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { MdAddCircle } from 'react-icons/md';

const ReadOnlyWrapper = ({
  isReadOnly,
  wrapper,
  children,
}: {
  isReadOnly: boolean;
  wrapper: (children: React.ReactNode) => React.ReactElement;
  children: React.ReactNode;
}) => (isReadOnly ? wrapper(children) : <>{children}</>);

type CategoriesMenuProps = {
  onOpen?: () => void;
  onClose?: () => void;
  shouldCreateCategory?: boolean;
  readonly?: boolean;
  readonlyTooltipLabel?: string;
  menuButtonVariant?: string;
  onOpenCreateCategoryModal?: () => void;
  category?: Category | null;
  onSelectCategory: (category: Category) => void;
  customHeight?: string;
  customMaxWidth?: string;
};

const CategoriesMenu = ({
  onOpen,
  onClose,
  shouldCreateCategory = true,
  readonly = false,
  readonlyTooltipLabel = undefined,
  menuButtonVariant = 'categoriesMenu',
  onOpenCreateCategoryModal,
  category: selectedCategory,
  onSelectCategory,
  customHeight = rem(28),
  customMaxWidth = rem(200),
}: CategoriesMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isDarkMode = useIsDarkMode();
  const { data, isLoading } = useCategories();
  const categories = data?.category;

  const handleSetCategory = useCallback(
    (categoryId: string | null) => {
      const selected = categories?.find(({ id }) => id === categoryId);

      if (!selected) {
        return;
      }

      onSelectCategory(selected);
    },
    [categories, onSelectCategory],
  );

  const onOpenMenu = useCallback(() => {
    if (readonly) {
      return;
    }

    setIsOpen(true);

    onOpen && onOpen();
  }, [onOpen, readonly]);

  const onCloseMenu = useCallback(() => {
    setIsOpen(false);

    onClose && onClose();
  }, [onClose]);

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" width={rem(160)}>
        <Spinner />
      </Flex>
    );
  }

  return (
    <ReadOnlyWrapper
      isReadOnly={readonly}
      wrapper={(children) => (
        <Tooltip label={readonlyTooltipLabel} placement="top">
          {/* lineHeight: 0 to reset the line-height set by the parent element
              of the categories menu, e.g. a modal header.
              'initial' doesn't work for this */}
          <Box __css={{ lineHeight: 0, button: { cursor: 'not-allowed' } }}>{children}</Box>
        </Tooltip>
      )}
    >
      <Menu isLazy isOpen={isOpen} onClose={onCloseMenu} onOpen={onOpenMenu}>
        <MenuButton
          as={Button}
          sx={{
            '&:hover, &:focus': {
              backgroundColor: `${selectedCategory?.color} !important`,
            },
          }}
          width="full"
          maxWidth={customMaxWidth}
          height={customHeight}
          minHeight={customHeight}
          cursor={readonly ? 'default' : 'pointer'}
          backgroundColor={selectedCategory?.color}
          leftIcon={
            <Box
              as={CATEGORIES_ICONS?.[selectedCategory?.icon ?? 'uncategorized']}
              width={rem(14)}
              height={rem(14)}
              color={getColorFromToken(selectedCategory?.color, undefined, '800')}
            />
          }
          rightIcon={readonly ? undefined : <Icon as={HiChevronDown} boxSize={rem(14)} color="blue.1000" />}
          variant={menuButtonVariant}
        >
          <Text overflow="hidden" color="blue.1000" textAlign="left" whiteSpace="nowrap" textOverflow="ellipsis">
            {selectedCategory ? fixUncategorizedName(selectedCategory.name) : undefined}
          </Text>
        </MenuButton>
        <MenuList
          zIndex={5}
          overflowY="auto"
          minWidth={rem(304)}
          maxWidth={rem(304)}
          height={rem(256)}
          padding="2"
          borderRadius={rem(10)}
          boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.15)"
          backgroundColor="background-primary"
        >
          {categories?.map(({ id, name, color, icon }) => (
            <MenuItem
              key={id}
              as={Button}
              sx={{
                '&:hover, &:focus': {
                  backgroundColor: `${color} !important`,
                },
              }}
              height={customHeight}
              minHeight={customHeight}
              marginTop="2"
              backgroundColor={color}
              leftIcon={
                <Box
                  as={CATEGORIES_ICONS?.[icon ?? 'uncategorized']}
                  width={rem(14)}
                  height={rem(14)}
                  color={getColorFromToken(color, undefined, '800')}
                />
              }
              onClick={() => handleSetCategory(id)}
              variant="categoriesMenu"
            >
              <Text
                overflow="hidden"
                color="blue.1000"
                fontSize={{ base: '2xs', xl: 'xs', '4xl': 'sm' }}
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                {fixUncategorizedName(name)}
              </Text>
            </MenuItem>
          ))}

          {shouldCreateCategory && (
            <MenuItem
              sx={{
                '&:hover, &:focus': {
                  backgroundColor: 'rgba(255, 255, 255, 0.40)',
                  cursor: 'pointer',
                },
              }}
              justifyContent="center"
              height={rem(42)}
              marginTop="2"
              fontSize="sm"
              borderWidth={rem(1)}
              borderStyle="dashed"
              borderColor={isDarkMode ? 'white.500' : 'text-primary'}
              borderRadius={rem(8)}
              cursor={readonly ? 'not-allowed' : 'default'}
              backgroundColor="transparent"
              onClick={onOpenCreateCategoryModal}
            >
              <HStack>
                <Box
                  as={MdAddCircle}
                  width={rem(20)}
                  height={rem(20)}
                  color={isDarkMode ? 'white.500' : 'text-primary'}
                />
                <Text color={isDarkMode ? 'white.500' : 'text-primary'} fontSize={rem(14)} fontWeight="normal">
                  Create new category
                </Text>
              </HStack>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </ReadOnlyWrapper>
  );
};

export default CategoriesMenu;
