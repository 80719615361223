import { useColorMode } from '@chakra-ui/react';

function useIsDarkMode() {
  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === 'dark';

  return isDarkMode;
}

export default useIsDarkMode;
