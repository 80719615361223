import { DELETE_CATEGORY, GET_CATEGORIES, UPDATE_CATEGORIES_ORDER, UPDATE_CATEGORY_ONE } from '@/gql/category';
import {
  DeleteCategoryPayload,
  DeleteCategoryResponse,
  GetCategoriesResponse,
  UpdateCategoryOrderPayload,
  UpdateCategoryOrderResponse,
  UpdateCategoryResponse,
} from '@/gql/category/types';
import { keys } from '@/gql/global/keys';
import { APIError } from '@/gql/global/types';
import { useCMActions } from '@/services/action/hooks';
import { useBlocks } from '@/services/block/hooks';
import { fetchData } from '@/services/graphql';
import { queryClient } from '@/services/graphql/queryClient';
import { addCategoryToActions } from '@/services/project/hooks';
import { Category } from '@/types/category';
import { getCategoryById } from '@/utils/category';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { isUndefined, orderBy } from 'lodash';

export const useCategories = (options?: UseQueryOptions<GetCategoriesResponse, APIError>) =>
  useQuery({
    ...keys.categories.all,
    queryFn: () => fetchData<GetCategoriesResponse>(GET_CATEGORIES),
    ...options,
  });

export const useUpdateCategoryMutation = (options?: UseMutationOptions<UpdateCategoryResponse, APIError, Category>) => {
  return useMutation({
    mutationKey: keys.categories.all.queryKey,
    mutationFn: (data: Category) => fetchData<UpdateCategoryResponse>(UPDATE_CATEGORY_ONE, data),
    ...options,
  });
};

export const useDeleteCategory = (
  options?: UseMutationOptions<DeleteCategoryResponse, APIError, DeleteCategoryPayload>,
) => {
  return useMutation({
    mutationFn: (payload: DeleteCategoryPayload) => fetchData<DeleteCategoryResponse>(DELETE_CATEGORY, payload),
    ...options,
  });
};

export const useCategoryManager = ({ categoryId }: { categoryId: string }) => {
  const { data: categories } = useCategories();
  const {
    data: actions,
    isLoading: isLoadingActions,
    isError: isErrorActions,
    error: errorActions,
  } = useCMActions(categoryId);
  const { data: blocks, isLoading: isLoadingBlocks, isError: isErrorBlocks, error: errorBlocks } = useBlocks();

  return {
    isLoadingActions,
    isLoadingBlocks,
    isErrorActions,
    isErrorBlocks,
    errorActions,
    errorBlocks,
    data: {
      category: getCategoryById(categories?.category ?? [], categoryId),
      actions: addCategoryToActions(actions?.action ?? [], categories?.category ?? []),
      blocks: (blocks?.block ?? []).filter((b) => b.categoryId === categoryId),
    },
  };
};

export const useUpdateCategoriesOrder = (
  options?: Omit<UseMutationOptions<UpdateCategoryOrderResponse, APIError, UpdateCategoryOrderPayload>, 'onMutate'>,
) => {
  return useMutation({
    mutationFn: (categoryUpdates: UpdateCategoryOrderPayload) =>
      fetchData<UpdateCategoryOrderResponse>(UPDATE_CATEGORIES_ORDER, {
        categoryUpdates: categoryUpdates.updates,
      }),

    onMutate: (variables) => {
      let dtos: UpdateCategoriesOrderDTO[] = [];

      variables.updates.map(({ where, _set }) => {
        dtos = [
          ...dtos,
          {
            categoryId: String(where.id._eq),
            order: Number(_set.order),
          },
        ];
      });

      queryClient.setQueryData<GetCategoriesResponse>(keys.categories.all.queryKey, (previous) => {
        if (isUndefined(previous)) {
          return;
        }
        const nextCategories = orderBy(
          previous.category.map((c) => {
            const dto = dtos.find((d) => d.categoryId === c.id);
            return dto
              ? {
                  ...c,
                  order: dto.order,
                }
              : c;
          }),
          ['order'],
          ['desc'],
        );
        return {
          category: nextCategories,
        };
      });
    },
    ...options,
  });
};

type UpdateCategoriesOrderDTO = {
  categoryId: string;
  order: number;
};
