import StyledModal from '@/components/StyledModal';
import { MyEvent } from '@/types/calendar';
import { getEventLength } from '@/utils/calendar';
import rem from '@/utils/rem';
import { Divider, Flex, HStack, Icon, ModalBody, ModalCloseButton, ModalHeader, Text, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useMemo } from 'react';

type Props = {
  event: MyEvent;
  isOpen: boolean;
  title: string;
  onClose: () => void;
};

function EventDetailModal({ event, isOpen, title, onClose }: Props) {
  const eventLength = useMemo(() => getEventLength(event), [event]);

  return (
    <StyledModal isOpen={isOpen} onClose={onClose} contentProps={{ backgroundColor: 'white.500' }} size="lg">
      <ModalHeader>
        <HStack>
          {event?.resource?.icon && (
            <Flex
              padding={rem(5)}
              borderRadius={99}
              backgroundColor={event?.resource?.isExternal ? 'gray.300' : event?.resource?.color}
            >
              <Icon as={event?.resource?.icon} boxSize={rem(14)} />
            </Flex>
          )}

          <Text textStyle="h5SatMedium" color="black.500" noOfLines={eventLength <= '30m' ? 1 : 2}>
            {title}
          </Text>
        </HStack>

        <VStack alignItems="start" marginTop={rem(10)}>
          <HStack>
            <Text textStyle="medium" color="black.500">
              Start:
            </Text>
            <Text textStyle="medium" color="rgba(26, 26, 26, 0.5)">
              {format(new Date(event?.start), 'dd-MM-yyyy hh:mm a')}
            </Text>

            <Text textStyle="medium" color="black.500">
              End:
            </Text>
            <Text textStyle="medium" color="rgba(26, 26, 26, 0.5)">
              {format(new Date(event?.end), 'dd-MM-yyyy hh:mm a')}
            </Text>
          </HStack>

          <HStack>
            <Text textStyle="medium" color="black.500">
              Duration:
            </Text>
            <Text textStyle="medium" color="rgba(26, 26, 26, 0.5)">
              {eventLength}
            </Text>
          </HStack>
        </VStack>
      </ModalHeader>
      <ModalCloseButton color="black.500" />
      {event?.description && (
        <ModalBody padding={`${rem(16)} ${rem(24)}`}>
          <Divider marginBottom={rem(10)} color="black" />
          <Text textStyle="medium" color="black.500">
            <div dangerouslySetInnerHTML={{ __html: event?.description }} />
          </Text>
        </ModalBody>
      )}
    </StyledModal>
  );
}

export default EventDetailModal;
