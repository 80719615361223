import { COMPLETE_BLOCK_ACTIONS, REMOVE_BLOCK_UNCOMPLETED_ACTIONS } from '@/gql/block';
import { CompleteBlockActions, DeleteBlockPayload, RemoveBlockUncompletedActions } from '@/gql/block/types';
import { fetchData } from '@/services/graphql';
import { Action } from '@/types/actions';
import rem from '@/utils/rem';
import { trackBlockActionsMovedOnComplete, trackBlockCompleteCanceled, trackBlockCompleted } from '@/utils/tracking';
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { isEmpty, isUndefined } from 'lodash';
import { useCallback } from 'react';
import { isDesktop } from 'react-device-detect';

type Props = {
  blockId: string;
  completedActions: Action[];
  isOpen: boolean;
  onClose: () => void;
  onDeleteBlock: (payload: DeleteBlockPayload) => void;
  onCompleteBlockActions: () => void;
  onRemoveBlockActions: () => void;
  onCompleteBlock: (blockId: string) => void;
};

function UncompletedActionsModal({
  blockId,
  completedActions,
  isOpen,
  onClose,
  onDeleteBlock,
  onCompleteBlockActions,
  onRemoveBlockActions,
  onCompleteBlock,
}: Props) {
  const toast = useToast();

  const removeBlockActions = useMutation({
    mutationFn: () =>
      fetchData<RemoveBlockUncompletedActions>(REMOVE_BLOCK_UNCOMPLETED_ACTIONS, {
        blockId,
      }),

    onSuccess: (data) => {
      if (!data?.blockRemoveUncompletedActions?.ok) {
        toast({
          title: 'Unexpected error, please try again',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (isUndefined(completedActions) || isEmpty(completedActions)) {
        onDeleteBlock({ id: blockId });
      } else {
        onRemoveBlockActions();
      }

      onClose();
    },
  });

  const completeBlockActions = useMutation({
    mutationFn: () => fetchData<CompleteBlockActions>(COMPLETE_BLOCK_ACTIONS, { blockId }),

    onSuccess: (data) => {
      if (!data?.blockCompleteAllActions?.ok) {
        toast({
          title: 'Unexpected error, please try again',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      onCompleteBlockActions();
      onClose();
    },
  });

  const handleRemoveActions = useCallback(() => {
    removeBlockActions.mutate();
    trackBlockActionsMovedOnComplete();
  }, [removeBlockActions]);

  const handleCompleteActions = useCallback(() => {
    completeBlockActions.mutate();
    onCompleteBlock(blockId);
    trackBlockCompleted();
  }, [blockId, completeBlockActions, onCompleteBlock]);

  const handleCancel = useCallback(() => {
    trackBlockCompleteCanceled();
    onClose();
  }, [onClose]);

  return (
    <Modal
      blockScrollOnMount={isDesktop ? true : false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered={isDesktop}
      isOpen={isOpen}
      onClose={handleCancel}
      size="xl"
      // Focus trap disabled as it prevents copy-pasting from the chatbot (RRI-2918)
      // TODO: find a better fix
      // TODO: after focus trap is enabled, remove the manual focus
      // (lookup the comments in the codebase by "RRI-2918")
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius="2xl" backgroundColor="background-tertiary">
        <ModalHeader fontSize={rem(32)} fontWeight="normal">
          Incomplete Actions
        </ModalHeader>
        <ModalBody color="gray.400" fontSize="lg">
          <Text>You have incomplete actions within this block.</Text>
          <Text>What would you like to do with them?</Text>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
            <Button onClick={handleRemoveActions} variant="secondary">
              Move Actions Out of Block
            </Button>
            <Button onClick={handleCompleteActions} variant="success">
              Complete Actions
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default UncompletedActionsModal;
