import Input from '@/components/Input';
import ActionMenuPersonItem from '@/components/ManageActionRelations/ActionMenuPersonitem';
import { ActionRelation } from '@/constants/action';
import { IconClear, IconCommitPersonOutline, IconLeveragePersonOutline } from '@/theme/icons';
import { PersonListItem } from '@/types/people';
import rem from '@/utils/rem';
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ChangeEvent, memo, useMemo, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { SlArrowLeft } from 'react-icons/sl';

const RelationIcon = {
  [ActionRelation.Leverage]: IconLeveragePersonOutline,
  [ActionRelation.Commit]: IconCommitPersonOutline,
};

type Props = {
  onSelectPerson: (id: string) => void;
  people: PersonListItem[];
  selectedPeople: string[];
  mode: ActionRelation.Leverage | ActionRelation.Commit;
  onCreateModalOpen: () => void;
  onReset: () => void;
};

function ManageActionMenu({ onSelectPerson, people, selectedPeople, mode, onCreateModalOpen, onReset }: Props) {
  const [search, setSearch] = useState('');
  const [filteredPeople, setFilteredPeople] = useState<PersonListItem[]>(people);

  const selectedPeopleToShow = useMemo(
    () => filteredPeople.filter(({ id }) => selectedPeople.includes(id)),
    [filteredPeople, selectedPeople],
  );

  const unSelectedPeopleToShow = useMemo(
    () => filteredPeople.filter(({ id }) => !selectedPeople.includes(id)),
    [filteredPeople, selectedPeople],
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchedName = e.target.value;

    setFilteredPeople(people.filter(({ name }) => name.toLowerCase().includes(searchedName)));
    setSearch(searchedName);
  };

  const handleClearSearch = () => {
    setSearch('');
    setFilteredPeople(people);
  };

  return (
    <Box borderRadius={rem(10)} backgroundColor="background-primary">
      <Flex
        gap={rem(22)}
        padding={`${rem(19)} ${rem(14)}`}
        borderTopRightRadius={rem(10)}
        borderTopLeftRadius={rem(10)}
        backgroundColor="gray.700a05"
      >
        <IconButton
          minWidth="unset"
          height="unset"
          minHeight="unset"
          cursor="pointer"
          aria-label="reset"
          icon={<SlArrowLeft />}
          onClick={onReset}
          variant="unstyled"
        />
        <HStack spacing={1}>
          <Icon as={RelationIcon[mode]} boxSize={rem(14)} />
          <Text as="span" fontSize={rem(12)}>
            {mode === ActionRelation.Leverage ? 'Leverage an Action to' : 'Commit an Action to'}
          </Text>
        </HStack>
      </Flex>
      <InputGroup width="full">
        <InputLeftElement height="full" children={<Icon as={BsSearch} boxSize={rem(14)} />} />

        <Input
          textStyle="small"
          width="full"
          height={rem(28)}
          paddingRight={rem(45)}
          paddingLeft={rem(38)}
          margin={`0 ${rem(8)}`}
          color="text-primary"
          fontSize="small"
          border={rem(1)}
          borderStyle="solid"
          borderColor="stroke-primary"
          borderRadius={4}
          _focus={{
            _placeholder: {
              opacity: 0.5,
            },
          }}
          _placeholder={{
            fontSize: 'small',
            color: 'text-primary',
          }}
          autoComplete="off"
          formControlWidth="full"
          onChange={handleChange}
          placeholder="Search for people"
          type="text"
          value={search}
        />

        {!!search.length && (
          <InputRightElement
            width={rem(30)}
            height="full"
            children={
              <IconButton
                width={rem(30)}
                height={rem(28)}
                color="text-primary"
                _hover={{
                  backgroundColor: 'transparent',
                }}
                aria-label="Clear search"
                backgroundColor="transparent"
                icon={<Icon as={IconClear} width={rem(10)} color="text-primary" />}
                onClick={handleClearSearch}
              />
            }
          />
        )}
      </InputGroup>

      <VStack overflowY="auto" maxHeight={rem(240)} marginTop={rem(16)} spacing={0}>
        {selectedPeopleToShow.map(({ id, name }) => (
          <ActionMenuPersonItem isSelected key={id} id={id} name={name} onSelectPerson={onSelectPerson} />
        ))}

        {unSelectedPeopleToShow.map(({ id, name }) => (
          <ActionMenuPersonItem key={id} id={id} name={name} onSelectPerson={onSelectPerson} />
        ))}
      </VStack>
      <Box padding={`${rem(16)} ${rem(8)}`}>
        <Button width="full" onClick={onCreateModalOpen}>
          {mode === ActionRelation.Leverage ? 'Leverage a new person' : 'Commit to a new person'}
        </Button>
      </Box>
    </Box>
  );
}

export default memo(ManageActionMenu);
