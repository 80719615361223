import { Box, BoxProps } from '@chakra-ui/react';
import { Data, UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactNode, memo } from 'react';

type DroppableContainerProps = {
  children: ReactNode;
  disabled?: boolean;
  id: UniqueIdentifier;
  payload?: Data;
};

function DroppableContainer({ children, disabled = false, id, payload, ...rest }: DroppableContainerProps & BoxProps) {
  const { setNodeRef, transform, transition } = useSortable({
    id,
    data: payload,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box ref={disabled ? undefined : setNodeRef} width="full" style={style} {...rest}>
      {children}
    </Box>
  );
}

export default memo(DroppableContainer);
