import ScheduledTime from '@/components/ScheduledTime';
import StyledModal from '@/components/StyledModal';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { IconClock } from '@/theme/icons';
import { localToGivenTimezoneDateToUTC } from '@/utils/calendar';
import rem from '@/utils/rem';
import { Button, ModalBody, ModalFooter } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { ITimezone } from 'react-timezone-select';

export type ScheduleEventDateTime = { scheduledDate: string; scheduledTime: string };

type ScheduleEventModalProps = {
  isOpen: boolean;
  onSave: (date: ScheduleEventDateTime) => void;
  onClose: () => void;
};

function ScheduleEventModal({ isOpen, onSave, onClose }: ScheduleEventModalProps) {
  const [time, setTime] = useState<Date | null>(null);
  const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const selectedDate = useCalendarMonthlyStore((state) => state.selectedDate);

  const onChangeTime = (time: Date | null) => {
    setTime(time);
  };

  const handleOnSave = useCallback(() => {
    if (!time) {
      return;
    }

    time.setDate(selectedDate.getDate());
    time.setMonth(selectedDate.getMonth());
    time.setFullYear(selectedDate.getFullYear());

    const timeZone = typeof selectedTimezone === 'string' ? selectedTimezone : selectedTimezone.value;
    const timezoneTime = localToGivenTimezoneDateToUTC(time, timeZone);

    onSave(timezoneTime);
  }, [onSave, selectedDate, time, selectedTimezone]);

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      title="What time would you like to schedule this action for?"
    >
      <ModalBody>
        <ScheduledTime
          scheduledTime={time}
          selectedDate={selectedDate}
          updateScheduledTime={onChangeTime}
          maxWidth={time === null ? 'auto' : rem(152)}
          leftIcon={<IconClock color="text-primary" width={rem(20)} height={rem(20)} />}
          color="text-primary"
          textStyle="large"
          fontWeight="500"
          variant="secondary"
          selectedTimezone={selectedTimezone}
          setSelectedTimezone={setSelectedTimezone}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} size="lg" variant="secondary">
          Cancel
        </Button>
        <Button isDisabled={!time} onClick={handleOnSave} size="lg" variant="primary">
          Save
        </Button>
      </ModalFooter>
    </StyledModal>
  );
}

export default ScheduleEventModal;
