import PersonAvatar from '@/components/PersonAvatar';
import rem from '@/utils/rem';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { memo } from 'react';

type Props = {
  id: string;
  name: string;
  onSelectPerson: (id: string) => void;
  isSelected?: boolean;
};

function ActionMenuPersonItem({ id, name, onSelectPerson, isSelected = false }: Props) {
  const handleSelectPerson = () => {
    onSelectPerson(id);
  };

  return (
    <Box width="full" margin="0" padding="0" cursor="pointer" backgroundColor="inherit" onClick={handleSelectPerson}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        width="full"
        padding={rem(8)}
        _hover={{ backgroundColor: 'gray.700a03' }}
      >
        <HStack>
          <PersonAvatar
            name={name[0].toUpperCase()}
            backgroundColor="background-secondary"
            width={rem(20)}
            height={rem(20)}
            textStyle="xsmallBlack"
          />

          <Text as="span" fontSize={rem(12)}>
            {name}
          </Text>
        </HStack>
        <Flex
          alignItems="center"
          justifyContent="center"
          width={rem(16)}
          height={rem(16)}
          border={`${rem(1)} solid`}
          borderColor="gray.400"
          borderRadius="full"
        >
          <Box
            display={isSelected ? 'block' : 'none'}
            width={rem(10)}
            height={rem(10)}
            borderRadius="full"
            backgroundColor="cyan.500"
          />
        </Flex>
      </Flex>
    </Box>
  );
}

export default memo(ActionMenuPersonItem);
