import ManagePersonModal from '@/components/ManagePersonModal';
import NoPeopleOverview from '@/components/NoPeople';
import PeopleSidebar from '@/components/PeopleSidebar';
import { useAnimatePlanPages } from '@/contexts/AnimatedPlanPages';
import { usePeopleList } from '@/contexts/PeopleCtx';
import PeopleGeneralView from '@/pages/People/PeopleGeneralView';
import PersonDetailView from '@/pages/People/PersonDetailView';
import { pageTransition } from '@/utils/pageAnimation';
import rem from '@/utils/rem';
import { Box, HStack, useDisclosure } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { memo } from 'react';
import { useParams } from 'react-router-dom';

function People() {
  const { id } = useParams();
  const { people } = usePeopleList();

  const { pageNavigationEffect } = useAnimatePlanPages();

  const {
    isOpen: isManagePersonModalOpen,
    onOpen: onManagePersonModalOpen,
    onClose: onManagePersonModalClose,
  } = useDisclosure();

  return (
    <>
      {!people?.length ? (
        <NoPeopleOverview onAddPersonClick={onManagePersonModalOpen} />
      ) : (
        <HStack
          gridGap={{ base: 8, lg: 16 }}
          gridAutoRows="1fr"
          gridTemplateColumns="30% 2fr"
          height="full"
          paddingX={rem(16)}
          paddingY={rem(16)}
        >
          <Box width="30%" minWidth={0} height="full">
            <PeopleSidebar onOpen={onManagePersonModalOpen} />
          </Box>

          <Box width="70%" minWidth={0} height="full">
            <motion.div
              className="row"
              initial="initial"
              animate="in"
              exit="out"
              variants={pageNavigationEffect}
              transition={pageTransition}
            >
              {id ? <PersonDetailView id={id} /> : <PeopleGeneralView />}
            </motion.div>
          </Box>
        </HStack>
      )}

      <ManagePersonModal isOpen={isManagePersonModalOpen} onClose={onManagePersonModalClose} />
    </>
  );
}

export default memo(People);
