import { DiscussionPoint } from '@/types/discussionPoints';
import { PersonListItem } from '@/types/people';
import rem from '@/utils/rem';

export const HEADER_HEIGHT = rem(72);
export const EMPTY_STATE_MIN_HEIGHT = rem(260);

export function sortPeopleAlphabetically(people: PersonListItem[]) {
  return people.sort((a, b) => a.name.localeCompare(b.name));
}

export function initials(name: string, onlyFirstName = false) {
  const splittedName = name.split(' ');

  const firstName = splittedName[0];

  if (onlyFirstName) {
    return firstName.charAt(0);
  }

  let lastName = null;

  if (splittedName.length > 1) {
    lastName = splittedName[splittedName.length - 1];
  }

  return firstName && lastName ? `${firstName.charAt(0)}${lastName.charAt(0)}` : firstName.charAt(0);
}

export function getDiscussionPointsById(discussionPoints: DiscussionPoint[], discussionPointId: string) {
  return discussionPoints.find((discussionPoint) => discussionPoint.id === discussionPointId);
}
