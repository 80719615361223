import BlockItem from '@/components/BlockItem';
import { Block } from '@/types/block';
import { calculateTotalDuration, getIncompleteActions } from '@/utils/action';
import { getTotalDurationOfStarredActions } from '@/utils/events';
import { humanDuration } from '@/utils/index';

type BlockDragOverlayProps = {
  block: Block;
};

const BlockDragOverlay = ({ block }: BlockDragOverlayProps) => {
  const incompleteActions = block.actions ? getIncompleteActions(block.actions, 'blockOrder') : [];

  return (
    <BlockItem
      item={block}
      actions={[]}
      completedActions={undefined}
      incompleteActions={undefined}
      numberOfCompletedActions={0}
      totalDuration={humanDuration(calculateTotalDuration(incompleteActions) * 60)}
      starredActionsTotalDuration={getTotalDurationOfStarredActions(incompleteActions)}
      onCompleteBlock={() => null}
      onSelectBlock={() => null}
      onUncompleteBlock={() => null}
      openSetUncompletedActionsModal={() => null}
      openEditBlockModal={() => null}
      onDeleteBlock={() => null}
      showCompletedActions={false}
      isDragOverlay
    />
  );
};

export default BlockDragOverlay;
