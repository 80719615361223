import { Helmet } from 'react-helmet-async';

function StenoAiChatbot() {
  return (
    <Helmet>
      <script
        src="https://cdn.jsdelivr.net/gh/aisteno/embed@latest/steno-chat.js"
        data-id="tony-rpm"
        data-url={import.meta.env.VITE_STENO_DATA_URL}
        data-origin="/rpm/tony-rpm"
        data-position="right"
      />
    </Helmet>
  );
}

export default StenoAiChatbot;
