import { gql } from 'graphql-request';

export const BLOCK_FRAGMENT_WITH_ACTIONS = gql`
  fragment Block on Block {
    id
    result
    purpose
    isCompleted
    categoryId
    category {
      name
      icon
      id
      color
    }
    project {
      name
    }
    actions {
      id
      block {
        isCompleted
      }
      categoryId
      category {
        name
        icon
        id
        color
      }
      blockId
      title
      duration
      dateOfStarring
      isLocked
      categoryOrder
      blockOrder
      notes
      progressStatus
      scheduledDate
      scheduledTime
      timezone
      weeklyPlanId
      project {
        name
      }
      promises {
        kind
        leverageRequestStatus
        person {
          name
          email
        }
        personId
        id
      }
    }
  }
`;

export const INSERT_BLOCK_ONE = gql`
  mutation insertBlockOne(
    $id: uuid
    $result: String
    $purpose: String
    $weeklyPlanId: uuid
    $projectId: uuid
    $scheduledDate: date
    $categoryId: uuid
    $isCompleted: Boolean
  ) {
    insertBlockOne(
      object: {
        id: $id
        result: $result
        purpose: $purpose
        weeklyPlanId: $weeklyPlanId
        projectId: $projectId
        scheduledDate: $scheduledDate
        categoryId: $categoryId
        isCompleted: $isCompleted
      }
    ) {
      id
      result
      purpose
      isCompleted
      weeklyPlanId
      categoryId
      category {
        name
        icon
        id
        color
      }
      projectId
      project {
        name
      }
    }
  }
`;

export const CHANGE_BLOCK_CATEGORY_AND_PROJECT = gql`
  mutation changeBlockCategoryAndProject($blockId: uuid!, $categoryId: uuid!, $projectId: uuid) {
    changeBlockCategoryAndProject(blockId: $blockId, categoryId: $categoryId, projectId: $projectId) {
      ok
    }
  }
`;

export const UPDATE_BLOCK = gql`
  mutation updateBlockByPk(
    $id: uuid!
    $result: String
    $purpose: String
    $weeklyPlanId: uuid
    $scheduledDate: date
    $isCompleted: Boolean
  ) {
    updateBlockByPk(
      _set: {
        result: $result
        purpose: $purpose
        weeklyPlanId: $weeklyPlanId
        scheduledDate: $scheduledDate
        isCompleted: $isCompleted
      }
      pkColumns: { id: $id }
    ) {
      id
      result
      purpose
      categoryId
      category {
        name
        icon
        id
        color
      }
    }
  }
`;

export const TOGGLE_BLOCK_COMPLETE = gql`
  mutation updateBlockByPk($id: uuid!, $isCompleted: Boolean) {
    updateBlockByPk(_set: { isCompleted: $isCompleted }, pkColumns: { id: $id }) {
      id
      isCompleted
    }
  }
`;

export const DELETE_BLOCK = gql`
  mutation deleteBlockByPk($id: uuid!) {
    deleteBlockByPk(id: $id) {
      id
    }
  }
`;

export const REMOVE_BLOCK_UNCOMPLETED_ACTIONS = gql`
  mutation blockRemoveUncompletedActions($blockId: uuid!) {
    blockRemoveUncompletedActions(blockId: $blockId) {
      ok
    }
  }
`;

export const COMPLETE_BLOCK_ACTIONS = gql`
  mutation blockCompleteAllActions($blockId: uuid!) {
    blockCompleteAllActions(blockId: $blockId) {
      ok
    }
  }
`;

// REFACTOR useBlocks
export const GET_ONLY_BLOCKS = gql`
  query GetBlocks {
    block {
      id
      result
      purpose
      isCompleted
      weeklyPlanId
      categoryId
      projectId
      project {
        name
      }
      weeklyOrder
      dailyOrder
    }
  }
`;

export const ADD_ACTIONS_TO_BLOCK = gql`
  mutation addActionsToBlock($actionIds: [uuid]!, $blockId: uuid!, $blockOrders: [Int]!) {
    addActionsToBlock(actionIds: $actionIds, blockId: $blockId, blockOrders: $blockOrders) {
      ok
    }
  }
`;
