import CategoryHeader from '@/components/CategoryHeader';
import { IconChevronDown } from '@/theme/icons';
import { Category } from '@/types/category';
import rem from '@/utils/rem';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
} from '@chakra-ui/react';
import { ReactNode, memo } from 'react';

type CaptureListProps = {
  category: Category;
  children?: ReactNode;
  draggableCategory?: boolean;
  totalDurationOfIncompleteActions?: string;
  totalDurationOfPreferredIncompleteActions?: string;
  showDurationTimers?: boolean;
  hideAccordion?: boolean;
  threeDotsButton?: ReactNode;
} & BoxProps;

type CaptureWrapperProps = { header: ReactNode } & Pick<CaptureListProps, 'hideAccordion' | 'children'>;

function CaptureWrapper({ header, children, hideAccordion }: CaptureWrapperProps) {
  return hideAccordion ? (
    <>
      <Box>{header}</Box>
      {children && (
        <Box overflow="hidden" marginTop={rem(16)}>
          {children}
        </Box>
      )}
    </>
  ) : (
    <Accordion allowToggle defaultIndex={[0]} reduceMotion>
      <AccordionItem>
        <Box>{header}</Box>
        <AccordionPanel marginTop={rem(16)} padding="0">
          <Box overflow="hidden">{children}</Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

function CaptureList({
  category,
  children,
  draggableCategory,
  totalDurationOfIncompleteActions,
  totalDurationOfPreferredIncompleteActions,
  showDurationTimers,
  hideAccordion = false,
  threeDotsButton,
  ...spaceProps
}: CaptureListProps) {
  return (
    <Box
      gridTemplateColumns="1.5rem 1fr"
      width="100%"
      padding={rem(16)}
      borderWidth={1}
      borderStyle="solid"
      borderColor={category.color ?? 'gray.300'}
      borderRadius="base"
      backgroundColor="background-primary"
      {...spaceProps}
    >
      <CaptureWrapper
        header={
          <CategoryHeader
            category={category}
            draggable={draggableCategory}
            duration={totalDurationOfIncompleteActions}
            stars={totalDurationOfPreferredIncompleteActions}
            showDurationTimers={showDurationTimers}
            threeDotsButton={threeDotsButton}
            accordionButton={
              hideAccordion ? undefined : (
                <AccordionButton justifyContent="flex-end" width={rem(24)} height={rem(24)} margin={0} padding={0}>
                  <AccordionIcon as={IconChevronDown} width={rem(14)} height={rem(14)} color="text-primary" />
                </AccordionButton>
              )
            }
          />
        }
        hideAccordion={hideAccordion}
      >
        {children}
      </CaptureWrapper>
    </Box>
  );
}

export default memo(CaptureList);
