import { useCallback, useState } from 'react';

// Whitelist keys here like "prefix-key1" | "prefix-key2" | ...
// Remember to always use a prefix to avoid clashes on localhost
type LocalStorageKeys = 'rpm-settings-collapsed-profiles';

function localStorageGet(key: LocalStorageKeys) {
  const rawValue = localStorage.getItem(key);
  if (!rawValue) return undefined;
  try {
    return JSON.parse(rawValue);
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

function localStorageSet(key: LocalStorageKeys, data: any) {
  localStorage.setItem(key, JSON.stringify(data));
}

export default function usePersistedState<T>(localStorageKey: LocalStorageKeys, initialState: T) {
  const [state, setState] = useState<T>(() => localStorageGet(localStorageKey) || initialState);

  const persistedSetState = useCallback(
    (state: T) => {
      // Call setState as first thing to improve UI responsiveness
      setState(state);
      localStorageSet(localStorageKey, state);
    },
    [localStorageKey],
  );

  return [state, persistedSetState] as const;
}
