import ProjectListItem from '@/components/ProjectStep/ProjectListItem';
import { ProjectType } from '@/types/project';
import rem from '@/utils/rem';
import { Grid } from '@chakra-ui/react';

type ProjectListProps = {
  projects: ProjectType[];
};

function ProjectList({ projects }: ProjectListProps) {
  return (
    <Grid
      gridRowGap={rem(16)}
      gridColumnGap={rem(16)}
      gridTemplateColumns="repeat(auto-fill, minmax(400px, 1fr))"
      width="full"
      paddingBottom={rem(32)}
    >
      {projects.map((project) => (
        <ProjectListItem key={project.id} project={project} />
      ))}
    </Grid>
  );
}

export default ProjectList;
