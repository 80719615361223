import DialogModal from '@/components/DialogModal';
import StyledModal from '@/components/StyledModal';
import { ActionPromiseResponse } from '@/gql/actions/types';
import { useUpdatePromiseRemoveLeverageRequest } from '@/services/action/hooks';
import { IconCalendar, IconTrash } from '@/theme/icons';
import { getLeverageStatusIcon } from '@/utils/promises';
import rem from '@/utils/rem';
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useCallback } from 'react';

type Promise = Pick<
  ActionPromiseResponse,
  | 'id'
  | 'person'
  | 'leverageRequestText'
  | 'leverageRequestedAt'
  | 'leverageRequestStatus'
  | 'leverageRequestReplyText'
  | 'leverageRequestRepliedAt'
  | 'leverageRequestDueDate'
>;

type Props = {
  title: string;
  promise: Promise;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

function LeverageRequestModal({ title, promise, isOpen, onClose, onSuccess }: Props) {
  const updatePromiseRemoveLeverageRequest = useUpdatePromiseRemoveLeverageRequest();
  const toast = useToast();

  const { isOpen: isConfirmModalOpen, onOpen: onOpenConfirmModal, onClose: onCloseConfirmModal } = useDisclosure();

  const cancelRequest = useCallback(() => {
    return updatePromiseRemoveLeverageRequest.mutateAsync(
      {
        id: promise.id,
      },
      {
        onSuccess: () => {
          toast({ title: 'Request canceled successfully', status: 'success', duration: 3000, isClosable: true });
          !!onSuccess && onSuccess();
          onCloseConfirmModal();
          onClose();
        },
      },
    );
  }, [promise.id, onCloseConfirmModal, onSuccess, onClose, toast, updatePromiseRemoveLeverageRequest]);

  return (
    <>
      <StyledModal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalHeader paddingTop="8" fontSize="3xl" fontWeight="medium">
          <Flex alignItems="center" justifyContent="space-between">
            {title}
            {promise.leverageRequestStatus && (
              <Flex alignItems="center" gap={rem(8)}>
                <Text as="span" textStyle="medium" textTransform="capitalize">
                  {promise.leverageRequestStatus}
                </Text>
                <Icon
                  as={getLeverageStatusIcon(promise.leverageRequestStatus)}
                  width={rem(18)}
                  height={rem(18)}
                  aria-hidden
                />
              </Flex>
            )}
          </Flex>
          <Divider marginTop={rem(19)} opacity="1" borderColor="stroke-primary" aria-hidden />
        </ModalHeader>
        <ModalBody paddingTop={0}>
          <Text as="h2" textStyle="caption" color="text-tertiary">
            Name
          </Text>
          <HStack gap={rem(16)}>
            <Text textStyle="h5SatMedium">{promise.person.name}</Text>
            {promise.person.email && (
              <Text textStyle="large" color="cyan.400">
                {promise.person.email}
              </Text>
            )}
          </HStack>
          <Divider marginTop={rem(8)} marginBottom={rem(27)} opacity="1" borderColor="stroke-primary" aria-hidden />
          <HStack color="gray.400" spacing="2">
            <Box boxSize={rem(14)}>
              <Icon as={IconCalendar} boxSize={rem(14)} color="text-primary" />
            </Box>
            {promise.leverageRequestDueDate && (
              <Text textStyle="small" color="text-primary">
                {format(new Date(promise.leverageRequestDueDate), 'E, MMM dd, yyyy')}
              </Text>
            )}
          </HStack>
          <Divider marginTop={rem(27)} marginBottom={rem(16)} opacity="1" borderColor="stroke-primary" aria-hidden />
          {promise.leverageRequestText && promise.leverageRequestedAt && (
            <>
              <Text textStyle="small" opacity={0.5}>
                You Sent on {format(new Date(promise.leverageRequestedAt), 'E, MMM dd')}
              </Text>
              <Text textStyle="large" marginBottom={rem(16)} opacity={0.5}>
                {promise.leverageRequestText}
              </Text>
            </>
          )}
          {promise.leverageRequestReplyText && promise.leverageRequestRepliedAt && (
            <>
              <Text textStyle="small" opacity={0.5}>
                {promise.person.name} Replied on {format(new Date(promise.leverageRequestRepliedAt), 'E, MMM dd')}
              </Text>
              <Text textStyle="large" opacity={0.5}>
                {promise.leverageRequestReplyText}
              </Text>
            </>
          )}
        </ModalBody>
        <ModalFooter justifyContent="space-between" display="flex">
          <IconButton
            aria-label="Delete Leverage Request"
            icon={<Icon as={IconTrash} boxSize={rem(20)} />}
            onClick={onOpenConfirmModal}
            size="lg"
            variant="secondary"
          />
          <Button isLoading={false} onClick={onClose} size="lg" variant="secondary">
            Close
          </Button>
        </ModalFooter>
      </StyledModal>
      {isConfirmModalOpen && (
        <DialogModal
          isOpen={isConfirmModalOpen}
          title="Delete Request"
          message="Are you sure you want to delete this request? It will cancel it for person you have made the request of."
          cancelText="No, Go Back"
          confirmText="Yes, Delete"
          confirmVariant="danger"
          onCancel={onCloseConfirmModal}
          onConfirm={() => cancelRequest()}
        />
      )}
    </>
  );
}

export default LeverageRequestModal;
