import { gql } from 'graphql-request';

export const CREATE_EVENT = gql`
  mutation insertEventOne($id: uuid, $actionId: uuid) {
    insertEventOne(object: { id: $id, actionId: $actionId }) {
      id
      actionId
      action {
        id
        categoryId
        blockId
        title
        duration
        dateOfStarring
        categoryOrder
        blockOrder
        notes
        progressStatus
        isLocked
        category {
          name
          icon
          id
          color
        }
        scheduledDate
        scheduledTime
        timezone
        weeklyPlanId
        weekly_plan {
          dateFrom
          dateTo
        }
        project {
          name
        }
        promises {
          kind
          leverageRequestStatus
          person {
            name
          }
          personId
          id
        }
      }
    }
  }
`;

export const CREATE_EVENTS = gql`
  mutation insertEvent($objects: [EventInsertInput!]!) {
    insertEvent(objects: $objects) {
      returning {
        id
        actionId
        action {
          id
          categoryId
          blockId
          title
          duration
          dateOfStarring
          categoryOrder
          blockOrder
          notes
          progressStatus
          isLocked
          category {
            name
            icon
            id
            color
          }
          scheduledDate
          scheduledTime
          timezone
          weeklyPlanId
          weekly_plan {
            dateFrom
            dateTo
          }
          project {
            name
          }
          promises {
            kind
            leverageRequestStatus
            person {
              name
            }
            personId
            id
          }
        }
      }
    }
  }
`;

export const DELETE_EVENT_BY_ID = gql`
  mutation deleteEventByPk($id: uuid!) {
    deleteEventByPk(id: $id) {
      id
    }
  }
`;
