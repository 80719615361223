import Input from '@/components/Input';
import InputDuration from '@/components/InputDuration';
import StyledModal from '@/components/StyledModal';
import { useCreateDiscussionPoint, useEditDiscussionPoint } from '@/services/people/hooks';
import rem from '@/utils/rem';
import { Box, Button, HStack, ModalBody, ModalFooter, VStack, useToast } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

const ManageDiscussionPointFormSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: 'Discussion Points must be at least 1 character' })
    .max(100, { message: 'Discussion Points must be 100 characters or less' }),
  duration: z
    .string()
    .trim()
    .refine((value) => !!value, {
      message: 'Set a minimum duration in the format hh:mm',
    })
    .refine(
      (value) => {
        const [hours, minutes] = value.split(':').map(Number);
        if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
          return false;
        }
        return true;
      },
      {
        message: 'Invalid time format',
      },
    )
    .refine(
      (value) => {
        const [hours, minutes] = value.split(':').map(Number);

        if (hours === 0 && minutes === 0) {
          return false;
        }
        return true;
      },
      {
        message: 'The minimum duration is 5 minutes',
      },
    ),
});

type FormData = z.infer<typeof ManageDiscussionPointFormSchema>;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  personId: string;
  id?: string;
  name?: string;
  duration?: string;
  count?: number;
};

function ManageDiscussionPointModal({ isOpen, onClose, personId, id, name, duration, count = 0 }: Props) {
  const toast = useToast();

  const createDiscussionPoint = useCreateDiscussionPoint();
  const editDiscussionPoint = useEditDiscussionPoint();

  const isEdit = !!id;

  const {
    control,
    handleSubmit,
    reset,
    setFocus,
    formState: { errors, isValid },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      duration: '00:05',
    },
    resolver: zodResolver(ManageDiscussionPointFormSchema),
    mode: 'onChange',
  });

  const handleCreate = useCallback(
    async (form: FormData) => {
      await createDiscussionPoint.mutateAsync({
        id: uuidv4(),
        body: form.name,
        duration: form.duration,
        personId,
        order: count + 1,
      });

      toast({
        title: 'Discussion point created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      reset();
      onClose();
    },
    [count, createDiscussionPoint, onClose, personId, reset, toast],
  );

  const handleEdit = useCallback(
    async (form: FormData) => {
      if (!id) {
        return;
      }

      await editDiscussionPoint.mutateAsync({
        id: id,
        body: form.name,
        duration: form.duration,
      });

      toast({
        title: 'Discussion point edited successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      reset();
      onClose();
    },
    [editDiscussionPoint, id, onClose, reset, toast],
  );

  const onCancel = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  useEffect(() => {
    if (id) {
      reset({ name, duration });
    }
  }, [duration, id, name, reset]);

  useEffect(() => {
    if (isOpen) {
      // Does not work without setTimeout
      // must be removed after focus trap is enabled
      // (lookup the comments in the codebase by "RRI-2918")
      const timeoutId = setTimeout(() => setFocus('name'), 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isOpen, setFocus]);

  return (
    <StyledModal isOpen={isOpen} onClose={onCancel} size="xl" title={`${isEdit ? 'Edit' : 'Add'} a Discussion Point`}>
      <form onSubmit={handleSubmit(isEdit ? handleEdit : handleCreate)}>
        <ModalBody>
          <VStack alignItems="normal" width="full" spacing={rem(32)}>
            <Controller
              name="name"
              control={control}
              render={({ field: { value, onChange, ref } }) => (
                <Input
                  ref={ref}
                  label="Name *"
                  error={errors.name?.message}
                  onChange={onChange}
                  value={value}
                  maxWidth="100%"
                  fontWeight="normal"
                  fontSize={rem(16)}
                  autoComplete="off"
                  placeholder="eg. Collaborate on research for local galleries"
                  containerProps={{
                    variant: 'floatingWithPlaceholder',
                  }}
                />
              )}
            />
          </VStack>
        </ModalBody>

        <ModalFooter justifyContent="space-between">
          <VStack align="start">
            <Controller
              name="duration"
              control={control}
              render={({ field: { value, onChange } }) => <InputDuration value={value} onChange={onChange} />}
            />

            {errors.duration && (
              <Box as="span" position="absolute" bottom={rem(5)} color="red.500" fontSize="sm">
                {errors.duration.message}
              </Box>
            )}
          </VStack>
          <HStack gap={rem(16)}>
            <Button onClick={onCancel} size="lg" variant="secondary">
              Cancel
            </Button>
            <Button isDisabled={!isValid} size="lg" type="submit" variant="primary">
              {isEdit ? 'Save' : 'Create'} discussion point
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </StyledModal>
  );
}

export default ManageDiscussionPointModal;
