import rem from '@/utils/rem';
import { StyleFunctionProps, defineStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

export default defineStyleConfig({
  baseStyle: {
    fontFamily: 'Satoshi',
    borderRadius: rem(4),
    color: 'text-primary',
  },
  sizes: {
    sm: {
      padding: `${rem(6)} ${rem(16)}`,
      fontSize: {
        base: rem(12),
        xl: rem(12),
        '2xl': rem(12),
      },
    },
    md: {
      fontSize: {
        base: rem(12),
        xl: rem(14),
        '2xl': rem(16),
      },
    },
    lg: {
      padding: `${rem(16)} ${rem(32)}`,
      fontSize: {
        base: rem(12),
        xl: rem(14),
        '2xl': rem(16),
      },
    },
  },
  variants: {
    primary: {
      backgroundColor: 'button-bg-primary',
      lineHeight: '130%',
      letterSpacing: '0',
      color: 'blue.1000',
      fontWeight: 900,
      _hover: {
        backgroundColor: 'button-hover-primary',

        _disabled: {
          backgroundColor: 'button-bg-primary',
        },
      },
      _focusVisible: {
        boxShadow: 'none',
      },
      _pressed: {
        backgroundColor: 'button-pressed-primary',
      },
    },
    secondary: {
      backgroundColor: 'button-bg-secondary',
      lineHeight: '130%',
      letterSpacing: '0',
      color: 'text-secondary',
      fontWeight: 500,
      border: `${rem(1)} solid`,
      borderColor: 'var(--chakra-colors-stroke-primary)',
      _hover: {
        backgroundColor: 'button-hover-secondary',

        _disabled: {
          backgroundColor: 'button-bg-secondary',
        },
      },
      _focusVisible: {
        boxShadow: 'none',
      },
      _pressed: {
        backgroundColor: 'button-pressed-secondary',
      },
    },
    tertiary: {
      backgroundColor: 'transparent',
      color: 'text-primary',
      lineHeight: '130%',
      letterSpacing: '0',
      _hover: {
        backgroundColor: 'transparent',
      },

      _focusVisible: {
        boxShadow: 'none',
      },
    },
    link: {
      backgroundColor: 'transparent',
      border: 'none',
      color: 'aqua.500',
      textDecoration: 'underline',
      textTransform: 'unset',
      fontSize: 'xs',
      '&:hover, &:active': {
        color: '#fff',
      },
    },
    segmented: {
      width: 'auto',
      height: '100%',
      border: 'none',
      borderRadius: rem(100),
      backgroundColor: 'transparent',
      _hover: {
        backgroundColor: 'rgba(255, 255, 255, 0.40)',
      },
      _selected: {
        backgroundColor: 'white.500',
      },
    },
    calendarToolbarNextPrev: (props: StyleFunctionProps) => ({
      width: 'auto',
      border: 'none',
      borderRadius: rem(100),
      _hover: {
        backgroundColor: mode('rgba(20, 39, 55, 0.40)', 'rgba(255, 255, 255, 0.40)')(props),
      },
      _active: {
        backgroundColor: mode('rgba(20, 39, 55, 0.40)', 'rgba(255, 255, 255, 0.40)')(props),
      },
      backgroundColor: 'background-tertiary',
    }),
    calendarToolbarWeekDay: (props: StyleFunctionProps) => ({
      width: 'auto',
      minWidth: rem(100),
      height: rem(32),
      border: 'none',
      borderRadius: rem(100),
      _hover: {
        backgroundColor: mode('rgba(20, 39, 55, 0.40)', 'rgba(255, 255, 255, 0.40)')(props),
      },
      backgroundColor: 'background-tertiary',
      paddingX: rem(12),
      paddingY: rem(6),
    }),
    edit: {
      width: rem(24),
      height: rem(24),
      minWidth: 'auto',
      padding: rem(6),
      backgroundColor: '#ffffff0d',
      borderRadius: rem(4),
      _hover: {
        backgroundColor: '#ffffff26',
      },
    },
    auth: {
      backgroundColor: 'cyan.500',
      borderRadius: '10',
      color: 'white.500',
      fontSize: rem(14),
      height: rem(48),
      paddingInline: rem(64),
      textTransform: 'uppercase',
      '&:disabled': {
        backgroundColor: 'cyan.500',
        color: 'white.500',
        cursor: 'not-allowed',
        pointerEvents: 'none',
        '&:hover': {
          backgroundColor: 'cyan.500',
          cursor: 'not-allowed',
        },
      },
      '&:hover': {
        backgroundColor: 'cyan.500',
        color: 'white.500',
        filter: 'brightness(85%)',
      },
      '&:active': {
        backgroundColor: 'cyan.500',
        color: 'white.500',
        filter: 'brightness(85%)',
      },
    },
    categoriesMenu: {
      borderRadius: rem(8),
      fontSize: 'sm',
      fontWeight: 'black',
      minHeight: rem(50),
      justifyContent: 'flex-start !important',
      textTransform: 'uppercase',
      transition: 'filter 200ms linear !important',
      '&:hover, &:focus': {
        filter: 'brightness(1.15)',
      },
    },
    categoriesMenuShrink: {
      borderRadius: rem(4),
      fontSize: 'sm',
      fontWeight: 'black',
      minWidth: rem(184),
      height: rem(28),
      justifyContent: 'flex-start !important',
      textTransform: 'uppercase',
      transition: 'filter 200ms linear !important',
      '&:hover, &:focus': {
        filter: 'brightness(1.15)',
      },
    },
    outline: {
      color: 'white.500',
      display: 'flex',
      border: `${rem(1)} solid #8f8f8f !important`,
      borderRadius: rem(10),
      _hover: {
        backgroundColor: 'white.500a015',
      },
      // '&[data-active]': {
      //   backgroundColor: 'white.500',
      //   color: 'gray.800',
      // },
    },
    danger: {
      backgroundColor: 'red.500',
      fontSize: {
        base: rem(12),
        xl: rem(14),
        '2xl': rem(16),
      },
      lineHeight: '130%',
      letterSpacing: '0',
      fontWeight: 900,
      _active: {
        backgroundColor: 'red.600',
      },
      _focusVisible: {
        backgroundColor: 'red.500',
        outline: 'none',
      },
      _hover: {
        backgroundColor: 'red.600',

        _disabled: {
          backgroundColor: 'red.500',
        },
      },
    },
    success: {
      backgroundColor: 'green.500',
      fontSize: {
        base: rem(12),
        xl: rem(14),
        '2xl': rem(16),
      },
      lineHeight: '130%',
      letterSpacing: '0',
      fontWeight: 900,
      _active: {
        backgroundColor: 'green.600',
      },
      _focusVisible: {
        backgroundColor: 'green.500',
        outline: 'none',
      },
      _hover: {
        backgroundColor: 'green.600',

        _disabled: {
          backgroundColor: 'green.500',
        },
      },
    },
    text: {
      backgroundColor: 'button-bg-secondary',
      color: 'text-primary',
      padding: `${rem(4)} ${rem(8)}`,
      fontSize: rem(10),
      lineHeight: '130%',
      fontWeight: 500,
      height: 'auto',
      border: `${rem(1)} solid var(--chakra-colors-stroke-primary)`,
    },
  },
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
});
