import { FC } from 'react';
import { useState } from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from 'usehooks-ts';

type Props = {
  nConfetti?: number;
};

const ConfettiCelebration: FC<Props> = ({ nConfetti = 3000 }) => {
  const { width } = useWindowSize();
  const [party, setParty] = useState(true);

  return (
    <Confetti
      width={width}
      height={document.body.scrollHeight}
      style={{ pointerEvents: 'none', zIndex: 9999 }}
      numberOfPieces={party ? nConfetti : 0}
      recycle={false}
      onConfettiComplete={(confetti) => {
        setParty(false);
        confetti?.reset();
      }}
      gravity={0.3}
      initialVelocityY={50}
    />
  );
};

export default ConfettiCelebration;
