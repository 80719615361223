import { ActionRelation } from '@/constants/action';
import {
  ActionPromiseResponse,
  CreatePromisesResponse,
  DeletePromisesResponse,
  GetLeveragedAndCommittedActionsResponse,
  LeverageRequestStatus,
  UpdatePromiseWithLevereageRequestResponse,
} from '@/gql/actions/types';
import { keys } from '@/gql/global/keys';
import { GetPeopleResponse } from '@/gql/people/types';
import { GetProjectResponse } from '@/gql/project/types';
import { queryClient } from '@/services/graphql/queryClient';
import { IconCheckedMark, IconDeclined, IconPending, IconProgress } from '@/theme/icons';
import { ActionResponse, UpdateActionResponse } from '@/types/actions';

import { getIncompleteActions } from './action';
import { endOfTheMonth, startOfTheMonth } from './calendar';
import { sortPeopleAlphabetically } from './people';

export function getJoinedPromisesByPersonName(
  promises: Omit<ActionPromiseResponse, 'id' | 'personId' | 'actionId'>[],
  type: ActionRelation,
) {
  return promises
    .filter((promise) => promise.kind === type)
    .map((promise) => promise.person.name)
    .join(', ');
}

export function addPromiseToCache(data: CreatePromisesResponse) {
  queryClient.setQueryData<GetPeopleResponse>(keys.people.all.queryKey, (cache) => {
    if (!cache?.person || !data.insertPromise.returning) {
      return cache;
    }

    const person = cache.person.map((item) => {
      if (item.id === data.insertPromise.returning[0].personId) {
        const updatedPromises = item.promises ?? []; // Use an empty array if promises is undefined

        return {
          ...item,
          promises: [
            ...updatedPromises,
            {
              id: data.insertPromise.returning[0].id,
              kind: data.insertPromise.returning[0].kind,
              actionId: data.insertPromise.returning[0].actionId ?? '',
              personId: data.insertPromise.returning[0].personId,
              leverageRequestDueDate: data.insertPromise.returning[0].leverageRequestDueDate,
              leverageRequestStatus: data.insertPromise.returning[0].leverageRequestStatus,
              leverageRequestText: data.insertPromise.returning[0].leverageRequestText,
              leverageRequestedAt: data.insertPromise.returning[0].leverageRequestedAt,
              leverageRequestRepliedAt: data.insertPromise.returning[0].leverageRequestRepliedAt,
              leverageRequestReplyText: data.insertPromise.returning[0].leverageRequestText,
              action: {
                progressStatus: data.insertPromise.returning[0]?.action?.progressStatus,
                title: data.insertPromise.returning[0]?.action?.title,
              },
            },
          ],
        };
      }

      return item;
    });

    return {
      person: sortPeopleAlphabetically(person),
    };
  });
}

export function updatePromiseWithLeverageToPersonCache(data: UpdatePromiseWithLevereageRequestResponse) {
  queryClient.setQueryData<GetPeopleResponse>(keys.people.all.queryKey, (cache) => {
    if (!cache?.person || !data.updatePromiseByPk) {
      return cache;
    }

    const person = cache.person.map((item) => {
      item.promises?.map((promise) => {
        if (promise.id === data?.updatePromiseByPk?.id) {
          const updatedPromise = data?.updatePromiseByPk;

          if (updatedPromise) {
            promise = {
              ...promise,
              leverageRequestDueDate: updatedPromise.leverageRequestDueDate,
              leverageRequestStatus: updatedPromise.leverageRequestStatus,
              leverageRequestText: updatedPromise.leverageRequestText,
              leverageRequestedAt: updatedPromise.leverageRequestedAt,
            };
          }
          return {
            ...item,
          };
        }
      });

      return item;
    });

    return {
      person: sortPeopleAlphabetically(person),
    };
  });
}

export function updatePromiseWithLeverageToLeverageCommittedCache(data: UpdatePromiseWithLevereageRequestResponse) {
  queryClient.setQueryData<GetLeveragedAndCommittedActionsResponse>(
    keys.actions.leveragedAndCommitted.queryKey,
    (cache) => {
      if (!cache) {
        return;
      }

      const leveragedActions = cache.leveragedActions.map((item) =>
        item.id === data.updatePromiseByPk.action.id
          ? {
              ...item,
              promises: item.promises?.map((promise) =>
                promise.id === data.updatePromiseByPk.id ? { ...promise, ...data.updatePromiseByPk } : promise,
              ),
            }
          : item,
      );
      const committedActions = cache.committedActions;

      return {
        leveragedActions: getIncompleteActions(leveragedActions),
        committedActions: getIncompleteActions(committedActions),
      };
    },
  );
}

export function updatePromiseWithLevereageToActionCache(data: UpdatePromiseWithLevereageRequestResponse) {
  const createNewActions = (cache: ActionResponse) => {
    return cache.action.map((action) => {
      if (action.id === data.updatePromiseByPk.action.id) {
        return {
          ...action,
          promises: action.promises?.map((promise) =>
            promise.id === data.updatePromiseByPk.id
              ? {
                  ...promise,
                  leverageRequestDueDate: data.updatePromiseByPk.leverageRequestDueDate,
                  leverageRequestStatus: data.updatePromiseByPk.leverageRequestStatus,
                  leverageRequestText: data.updatePromiseByPk.leverageRequestText,
                  leverageRequestedAt: data.updatePromiseByPk.leverageRequestedAt,
                }
              : promise,
          ),
        };
      }
      return action;
    });
  };

  queryClient.setQueryData<ActionResponse>(
    keys.actions.all._ctx.week(data.updatePromiseByPk.action.weeklyPlanId ?? '').queryKey,
    (cache) => {
      if (!cache) {
        return;
      }

      return {
        action: createNewActions(cache),
      };
    },
  );

  if (data.updatePromiseByPk.action.scheduledDate) {
    const startMonth = startOfTheMonth(new Date(data.updatePromiseByPk.action.scheduledDate));
    const endMonth = endOfTheMonth(new Date(data.updatePromiseByPk.action.scheduledDate));
    queryClient.setQueryData<ActionResponse>(keys.actions.all._ctx.month(startMonth, endMonth).queryKey, (cache) => {
      if (!cache) {
        return;
      }

      return {
        action: createNewActions(cache),
      };
    });
  }

  queryClient.setQueryData<ActionResponse>(
    keys.actions.all._ctx.categoryManager(data.updatePromiseByPk.action.categoryId ?? '').queryKey,
    (cache) => {
      if (!cache) {
        return;
      }

      return {
        action: createNewActions(cache),
      };
    },
  );
}

export function updatePromiseWithLeverageToProjectDetailCache(data: UpdatePromiseWithLevereageRequestResponse) {
  queryClient.setQueryData<GetProjectResponse>(
    keys.project.detail(data.updatePromiseByPk.action.projectId ?? '').queryKey,
    (cache) => {
      if (!cache) {
        return;
      }

      const actions = cache.projectByPk.actions.map((action) => {
        if (action.id === data.updatePromiseByPk.action.id) {
          return {
            ...action,
            promises: action.promises?.map((promise) =>
              promise.id === data.updatePromiseByPk.id
                ? {
                    ...promise,
                    leverageRequestDueDate: data.updatePromiseByPk.leverageRequestDueDate,
                    leverageRequestStatus: data.updatePromiseByPk.leverageRequestStatus,
                    leverageRequestText: data.updatePromiseByPk.leverageRequestText,
                    leverageRequestedAt: data.updatePromiseByPk.leverageRequestedAt,
                  }
                : promise,
            ),
          };
        }
        return action;
      });

      return {
        projectByPk: {
          ...cache.projectByPk,
          actions,
        },
      };
    },
  );
}

export function deletePromiseFromPersonCacheByActionId(actionId: string) {
  queryClient.setQueryData<GetPeopleResponse>(keys.people.all.queryKey, (cache) => {
    const userIdFromUrl = getUserIdFromUrl();

    if (!cache?.person) {
      return;
    }

    if (!userIdFromUrl) {
      return;
    }

    const person = cache.person.map((item) => {
      if (item.id === userIdFromUrl) {
        return {
          ...item,
          promises: item.promises?.filter((p) => p.actionId !== actionId),
        };
      }

      return item;
    });

    return {
      person: sortPeopleAlphabetically(person),
    };
  });
}

export function updatePromiseToCacheAfterUpdateAction(data: UpdateActionResponse) {
  queryClient.setQueryData<GetPeopleResponse>(keys.people.all.queryKey, (cache) => {
    if (!cache?.person) {
      return;
    }

    const person = cache.person.map((item) => {
      item.promises?.map((promise) => {
        if (promise.actionId === data?.updateActionByPk?.id) {
          const updatedPromise = data?.updateActionByPk?.promises?.find((ua) => ua.id === promise?.id);

          if (updatedPromise) {
            promise.action = updatedPromise.action;
          }
          return {
            ...item,
          };
        }
      });

      return item;
    });

    return {
      person: sortPeopleAlphabetically(person),
    };
  });
}

export function deletePromiseFromPersonCache(data: DeletePromisesResponse) {
  queryClient.setQueryData<GetPeopleResponse>(keys.people.all.queryKey, (cache) => {
    if (!cache?.person) {
      return;
    }

    const person = cache.person.map((item) => {
      return {
        ...item,
        promises: item.promises?.filter((p) => p.id !== data.deletePromise.returning[0].id),
      };
    });

    return {
      person: sortPeopleAlphabetically(person),
    };
  });
}

export function getUserIdFromUrl(urlString = window.location.href) {
  // Create a URL object
  const url = new URL(urlString);

  // Access the pathname and split it to get the last part
  const matches = url.toString().match(/\/[\w-]+\/([0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12})/);

  if (matches) {
    const UUID = matches[1];

    return UUID;
  } else {
    return null;
  }
}

export function getLeverageStatusIcon(status: LeverageRequestStatus) {
  switch (status) {
    case 'pending':
      return IconPending;
    case 'accepted':
      return IconProgress;
    case 'completed':
      return IconCheckedMark;
    case 'declined':
      return IconDeclined;

    default:
      console.error('Unexpected leverage request status:', status);
      return undefined;
  }
}
