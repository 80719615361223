// Future features:
// - Icon
// - Link/CTA
// - Dismissable (close button)
import rem from '@/utils/rem';
import { Box, BoxProps, Text } from '@chakra-ui/react';

type CalloutProps = {
  title?: string;
  variant?: 'bordered';
} & BoxProps;

export const Callout = ({
  title,
  children,
  variant,
  backgroundColor = 'background-primary',
  ...props
}: CalloutProps) => {
  return (
    <Box
      alignContent="center"
      justifyContent="center"
      color="text-tertiary"
      border={`${rem(1)} solid`}
      borderColor={variant === 'bordered' ? 'stroke-primary' : backgroundColor}
      borderRadius={rem(4)}
      backgroundColor={backgroundColor}
      paddingX={rem(29)}
      paddingY={rem(15)}
      {...props}
    >
      {title && (
        <Text as="h2" textStyle="caption" marginBottom={rem(8)}>
          {title}
        </Text>
      )}
      {typeof children === 'string' ? (
        <Text as="p" textStyle="small">
          {children}
        </Text>
      ) : (
        children
      )}
    </Box>
  );
};

export default Callout;
