import { IconChevronLeft, IconChevronRight } from '@/theme/icons';
import rem from '@/utils/rem';
import {
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { addMonths, subMonths } from 'date-fns';
import { useCallback } from 'react';
import { Navigate } from 'react-big-calendar';
import DatePicker from 'react-datepicker';

import InnerDate from './innerDate';

type Props = {
  selectedDate: Date;
  updateSelectedDate: (date: Date) => void;
  showCalendarIcon?: boolean;
  showDatepicker?: boolean;
};

const MonthYearNavigator = ({ selectedDate, updateSelectedDate, showCalendarIcon, showDatepicker = false }: Props) => {
  const {
    isOpen: isDatePickerOpen,
    onOpen: onDatePickerOpen,
    onClose: onDatePickerClose,
  } = useDisclosure({ id: 'datepicker-month-year-navigation' });

  const onHandlePrevNextCalendarNavigation = useCallback(
    (date: Date, action: 'PREV' | 'NEXT') => {
      switch (action) {
        case Navigate.PREVIOUS: {
          const newDate = subMonths(date, 1);
          updateSelectedDate(newDate);

          return newDate;
        }

        case Navigate.NEXT: {
          const newDate = addMonths(date, 1);
          updateSelectedDate(newDate);

          return newDate;
        }

        default: {
          updateSelectedDate(date);
          return date;
        }
      }
    },
    [updateSelectedDate],
  );

  const onSelectedDate = useCallback(
    (selectedDate: Date) => {
      updateSelectedDate(selectedDate);
      onDatePickerClose();
    },
    [onDatePickerClose, updateSelectedDate],
  );

  if (showDatepicker) {
    return (
      <HStack
        position="relative"
        justifyContent="center"
        gap={rem(5)}
        width="fit-content"
        minWidth="fit-content"
        height="full"
        paddingInlineEnd={rem(8)}
        paddingInlineStart={rem(8)}
      >
        <Tooltip label="Previous month" placement="top">
          <IconButton
            width={rem(28)}
            minWidth={0}
            height={rem(28)}
            margin={0}
            padding={0}
            borderWidth={rem(1)}
            borderStyle="solid"
            borderColor="stroke-primary"
            borderRadius={rem(4)}
            aria-label="Previous month"
            backgroundColor="background-primary"
            icon={<Icon as={IconChevronLeft} boxSize={rem(14)} />}
            onClick={() => onHandlePrevNextCalendarNavigation(selectedDate, Navigate.PREVIOUS)}
            size="xs"
            variant="ghost"
          />
        </Tooltip>

        <Popover closeOnBlur closeOnEsc isLazy isOpen={isDatePickerOpen} onClose={onDatePickerClose} placement="bottom">
          <PopoverTrigger>
            <InnerDate
              selectedDate={selectedDate}
              showCalendarIcon={showCalendarIcon}
              showDatePicker={showDatepicker}
              onDatePickerOpen={onDatePickerOpen}
            />
          </PopoverTrigger>
          <PopoverContent
            width={rem(230)}
            height={rem(200)}
            padding={rem(16)}
            border={`${rem(1)} solid`}
            borderColor="stroke-primary"
            borderRadius={rem(4)}
            backgroundColor="background-primary"
          >
            <PopoverBody className="month-style" padding={0}>
              <DatePicker selected={selectedDate} onChange={onSelectedDate} showMonthYearPicker open />
            </PopoverBody>
          </PopoverContent>
        </Popover>

        <Tooltip label="Next month" placement="top">
          <IconButton
            width={rem(28)}
            minWidth={0}
            height={rem(28)}
            margin={0}
            padding={0}
            borderWidth={rem(1)}
            borderStyle="solid"
            borderColor="stroke-primary"
            borderRadius={rem(4)}
            aria-label="Next month"
            backgroundColor="background-primary"
            icon={<Icon as={IconChevronRight} boxSize={rem(14)} />}
            onClick={() => onHandlePrevNextCalendarNavigation(selectedDate, Navigate.NEXT)}
            size="xs"
            variant="ghost"
          />
        </Tooltip>
      </HStack>
    );
  }

  return (
    <HStack
      position="relative"
      justifyContent="center"
      gap={rem(5)}
      width="fit-content"
      minWidth="fit-content"
      height="full"
      paddingInlineEnd={rem(8)}
      paddingInlineStart={rem(8)}
    >
      <Tooltip label="Previous month" placement="top">
        <IconButton
          width={rem(38)}
          minWidth={0}
          height="full"
          margin={0}
          padding={0}
          borderWidth={rem(1)}
          borderStyle="solid"
          borderColor="stroke-primary"
          borderRadius={rem(4)}
          aria-label="Previous month"
          aspectRatio={1}
          backgroundColor="background-primary"
          icon={<Icon as={IconChevronLeft} boxSize={rem(14)} />}
          onClick={() => onHandlePrevNextCalendarNavigation(selectedDate, Navigate.PREVIOUS)}
          size="xs"
          variant="ghost"
        />
      </Tooltip>

      <InnerDate selectedDate={selectedDate} showCalendarIcon={showCalendarIcon} showDatePicker={showDatepicker} />

      <Tooltip label="Next month" placement="top">
        <IconButton
          width={rem(38)}
          minWidth={0}
          height="full"
          margin={0}
          padding={0}
          borderWidth={rem(1)}
          borderStyle="solid"
          borderColor="stroke-primary"
          borderRadius={rem(4)}
          aria-label="Next month"
          aspectRatio={1}
          backgroundColor="background-primary"
          icon={<Icon as={IconChevronRight} boxSize={rem(14)} />}
          onClick={() => onHandlePrevNextCalendarNavigation(selectedDate, Navigate.NEXT)}
          size="xs"
          variant="ghost"
        />
      </Tooltip>
    </HStack>
  );
};

export default MonthYearNavigator;
