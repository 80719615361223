import { GetPersonResponse } from '@/gql/people/types';
import { useEditPerson } from '@/services/people/hooks';
import rem from '@/utils/rem';
import { Textarea, TextareaProps, useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

type Props = {
  personData?: GetPersonResponse;
} & TextareaProps;

const PersonNotesForm = ({ personData, ...textareaProps }: Props) => {
  const editPerson = useEditPerson();
  const toast = useToast();
  const [notes, setNotes] = useState('');

  const handleSubmit = useCallback(async () => {
    const isNotesEmpty = notes && !notes.trim();
    const isNotesUnchanged = personData?.personByPk.notes === notes;
    const personId = personData?.personByPk.id;

    if (!personId || isNotesUnchanged || isNotesEmpty) {
      return;
    }

    await editPerson.mutateAsync({
      id: personData?.personByPk.id,
      name: personData?.personByPk?.name,
      email: personData?.personByPk.email,
      relation: personData?.personByPk?.relation,
      notes,
    });

    toast({
      title: 'Notes updated successfully',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  }, [personData, editPerson, toast, notes]);

  useEffect(() => {
    if (personData?.personByPk) {
      const { notes } = personData.personByPk;
      setNotes(notes ?? '');
    }
  }, [personData?.personByPk]);

  return (
    <Textarea
      width="full"
      height="auto"
      padding={rem(12)}
      fontSize={rem(12)}
      fontWeight={500}
      lineHeight="130%"
      border={`${rem(1)} solid`}
      borderColor="stroke-primary"
      borderRadius={rem(4)}
      _focusVisible={{ borderColor: 'cyan.400' }}
      _placeholder={{ color: 'gray.400' }}
      resize="none"
      onBlur={handleSubmit}
      onChange={(e) => setNotes(e.target.value)}
      placeholder="Add notes about this person..."
      value={notes}
      {...textareaProps}
    />
  );
};

export default PersonNotesForm;
