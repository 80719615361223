import theme from '@/theme/index';
import { isBefore, isToday } from 'date-fns';

import { isCompleted } from './events';

export function lightenDarkenColor(col: string, amt: number) {
  let usePound = false;

  if (col[0] == '#') {
    col = col.slice(1);
    usePound = true;
  }

  let num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export function darken(amount: number, color: string) {
  // Parse the color string into RGB components
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);

  // Calculate the darkened RGB values
  const darkenedR = Math.max(0, Math.floor(r - amount * 255));
  const darkenedG = Math.max(0, Math.floor(g - amount * 255));
  const darkenedB = Math.max(0, Math.floor(b - amount * 255));

  // Convert the darkened RGB values back to a color string
  return `#${darkenedR.toString(16).padStart(2, '0')}${darkenedG.toString(16).padStart(2, '0')}${darkenedB
    .toString(16)
    .padStart(2, '0')}`;
}

const OPACITY_40 = '0.40';

export function getColorFromToken(color = 'gray.300', opacity?: string, tone?: string) {
  const [token, cTone] = color.split('.');

  if (opacity) {
    const rgbaColor = theme.colors[token][tone ?? cTone];
    const hexWithOpacity = convertHexToRGBA(rgbaColor, Number(opacity));

    return hexWithOpacity;
  }

  return `${theme.colors[token][tone ?? cTone]}`;
}

export function getGradientColorFromToken(color = 'gray.300') {
  const [token] = color.split('.');

  const rgbaColor = theme.colors[token][400];
  const hexWithOpacity = convertHexToRGBA(rgbaColor, Number(0.2));

  return hexWithOpacity;
}

export function getColorWithOpacityFromToken(color = 'gray.300', opacity = OPACITY_40) {
  return getColorFromToken(color, opacity);
}

export function rgbaToHex(rgba: string): string {
  // Convert an RGBA color string to HEX
  const match = rgba.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/);
  if (!match) {
    return '';
  }

  const r = parseInt(match[1]);
  const g = parseInt(match[2]);
  const b = parseInt(match[3]);
  const a = parseFloat(match[4]);

  const hexR = r.toString(16).padStart(2, '0');
  const hexG = g.toString(16).padStart(2, '0');
  const hexB = b.toString(16).padStart(2, '0');
  const hexA = Math.round(a * 255)
    .toString(16)
    .padStart(2, '0');

  return `#${hexR}${hexG}${hexB}${hexA}`;
}

export function convertHexToRGBA(hex: string, opacity?: number): string {
  // Check if the hex string is valid.
  if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    throw new Error('Invalid hex string');
  }

  // Extract the red, green, and blue values from the hex string.
  const hexWithoutHash = hex.substring(1);
  const r = parseInt(hexWithoutHash.substring(0, 2), 16);
  const g = parseInt(hexWithoutHash.substring(2, 4), 16);
  const b = parseInt(hexWithoutHash.substring(4, 6), 16);

  // Calculate the opacity value.
  opacity = opacity !== undefined ? opacity : 1;

  // Return the RGBA string.
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function generateLinearGradient(
  color: string,
  bgColor = 'var(--chakra-colors-background-tertiary)',
  deg = 270,
  opacity = 0.3,
) {
  const [token, tone] = color.split('.');
  const rgbaColor = convertHexToRGBA(`${theme.colors[token][tone]}`, opacity);

  return `linear-gradient(${deg}deg, ${rgbaColor} 0%, rgba(245, 245, 247, 0.00) 66.97%), ${bgColor}`;
}

export function generateCategoryBGColorEvent(
  eventStart: Date | string | null,
  categoryColor: string,
  eventStatus: string | undefined,
  dateOfStarring: Date | null,
  mainTone?: string | undefined,
) {
  const dateIsBeforeToday = eventStart && isBefore(new Date(eventStart), new Date());

  // Define default alpha value
  const alpha = dateIsBeforeToday ? '0.6' : '1';

  if (eventStatus) {
    if (isCompleted(eventStatus)) {
      // Use alpha 200 for completed events
      return getColorFromToken(categoryColor, alpha, mainTone ?? '200');
    }

    if (dateOfStarring) {
      // Use alpha 300 for starred events that are not completed
      return getColorFromToken(categoryColor, alpha, '300');
    }
  }

  // Default to alpha 200 for other cases
  return getColorFromToken(categoryColor, alpha, mainTone ?? '200');
}

export function getCalenderItemGradient(
  categoryColor: string,
  isStarred?: boolean,
  isDarkTheme?: boolean,
  isExternal?: boolean,
) {
  const bgOpacized = getColorFromToken(isDarkTheme ? 'gray.950' : 'gray.100', '1');

  if (isExternal) {
    return `linear-gradient(0deg, rgba(143, 143, 143, 0.20) 0%, rgba(143, 143, 143, 0.20) 100%), linear-gradient(0deg, ${bgOpacized} 0%, ${bgOpacized} 100%);`;
  }

  if (isStarred) {
    return `linear-gradient(90deg, transparent 0%, ${getColorFromToken(categoryColor, '.20')} 100%), linear-gradient(0deg, ${getGradientColorFromToken(categoryColor)} 0%, ${getGradientColorFromToken(categoryColor)} 100%), linear-gradient(0deg, ${bgOpacized} 0%, ${bgOpacized} 100%);`;
  }

  return `linear-gradient(0deg, ${getGradientColorFromToken(categoryColor)} 0%, ${getGradientColorFromToken(categoryColor)} 100%), linear-gradient(0deg, ${bgOpacized} 0%, ${bgOpacized} 100%);`;
}

export function getDatePickerBorderColor(selectedDate = new Date(), isCurrentDay = isToday(selectedDate)) {
  const today = new Date();

  const isFuture = !isCurrentDay && selectedDate > today;
  const isPast = !isCurrentDay && selectedDate < today;

  if (isCurrentDay) {
    return 'green.500';
  }

  if (isFuture) {
    return 'amber.400';
  }

  if (isPast) {
    return 'red.500';
  }

  return 'stroke-primary';
}
