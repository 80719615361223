import WeekNavigator from '@/components/WeekNavigator';
import { CATEGORIES_ICONS } from '@/constants/category';
import useIsDarkMode from '@/hooks/useIsDarkMode';
import { useActionsCategories } from '@/stores/useActionsCategories';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { Category } from '@/types/category';
import { getColorFromToken } from '@/utils/color';
import { fixUncategorizedName } from '@/utils/index';
import rem from '@/utils/rem';
import { Box, Button, Flex, Spinner, Text, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { AnimationControls } from 'framer-motion';
import { Children, memo, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const VisionFormSchema = z.object({
  ultimateVision: z
    .string()
    .trim()
    // .min(1, { message: 'Ultimate Vision cannot be empty' })
    // .min(5, { message: 'Please enter an Ultimate Vision that is 5 characters or more' })
    .max(10000, { message: 'Ultimate Vision must be 10000 characters or less' })
    .nullable()
    .optional(),
});

type FormData = z.infer<typeof VisionFormSchema>;

type Props = {
  isCategoriesLoading?: boolean;
  categories: Category[];
  controlsAnimation?: AnimationControls;
  onCreateCategoryOpen: () => void;
};

function LeftSection({ isCategoriesLoading, categories, controlsAnimation }: Props) {
  const selectedDate = useCalendarMonthlyStore((state) => state.selectedDate);
  const selectedCategory = useActionsCategories((state) => state.selectedCategory);
  const updateSelectedCategory = useActionsCategories((state) => state.updateSelectedCategory);
  const isDarkMode = useIsDarkMode();

  const { reset } = useForm<FormData>({
    defaultValues: {
      ultimateVision: selectedCategory?.ultimateVision ?? null,
    },
    resolver: zodResolver(VisionFormSchema),
    mode: 'onChange',
  });

  const handleAnimation = useCallback(
    async (category: Category) => {
      await controlsAnimation?.start('exit');
      await controlsAnimation?.start('hidden');

      setTimeout(() => {
        controlsAnimation?.start('visible');
        updateSelectedCategory(category);
      }, 200);
    },
    [controlsAnimation, updateSelectedCategory],
  );

  useEffect(() => {
    reset({
      ultimateVision: selectedCategory?.ultimateVision ?? null,
    });
  }, [selectedCategory?.ultimateVision, reset]);

  return (
    <VStack alignItems="start" width="full">
      <Box width="full" marginBottom={rem(60)}>
        <VStack alignItems="start" width="full">
          <WeekNavigator selectedDate={selectedDate} />

          <Text as="h2" textStyle="h2BarSB" lineHeight="110%" textTransform="uppercase">
            Category Spotlight
          </Text>
        </VStack>
      </Box>

      <VStack alignItems="start" gap={rem(21)} width="full" marginBottom={rem(80)}>
        {!categories && isCategoriesLoading ? (
          <Flex justifyContent="center" width="full">
            <Spinner />
          </Flex>
        ) : (
          <>
            {Children.toArray(
              categories?.map((category) => {
                return (
                  <>
                    <Button
                      justifyContent="start"
                      width="full"
                      height="auto"
                      _hover={{
                        backgroundColor: 'transparent',
                      }}
                      whiteSpace="initial"
                      onClick={() => {
                        handleAnimation(category);
                      }}
                      variant="ghost"
                    >
                      {selectedCategory?.id === category?.id && (
                        <Box
                          as="span"
                          position="absolute"
                          left={rem(-55)}
                          width={rem(50)}
                          height={rem(2)}
                          aria-hidden
                          backgroundColor={category?.color}
                        />
                      )}

                      <Flex
                        as="span"
                        alignItems="center"
                        justifyContent="center"
                        width={rem(32)}
                        minWidth={rem(32)}
                        marginRight={rem(8)}
                        aria-hidden
                      >
                        <Flex
                          as="span"
                          alignItems="center"
                          justifyContent="center"
                          width={category?.id === selectedCategory?.id ? rem(32) : rem(24)}
                          minWidth={category?.id === selectedCategory?.id ? rem(32) : rem(24)}
                          height={category?.id === selectedCategory?.id ? rem(32) : rem(24)}
                          borderRadius="full"
                          backgroundColor={selectedCategory?.id === category?.id ? category?.color : 'gray.300'}
                        >
                          <Box
                            as={CATEGORIES_ICONS?.[category?.icon ?? 'uncategorized']}
                            width={category?.id === selectedCategory?.id ? rem(20) : rem(14)}
                            height={category?.id === selectedCategory?.id ? rem(20) : rem(14)}
                            color="background-primary"
                          />
                        </Flex>
                      </Flex>

                      <Text
                        as="span"
                        textStyle={category?.id === selectedCategory?.id ? 'h3BarSB' : 'h5BarSB'}
                        color={
                          category?.id === selectedCategory?.id
                            ? getColorFromToken(category?.color, undefined, '100')
                            : isDarkMode
                              ? 'gray.300'
                              : 'gray.800'
                        }
                        textAlign="left"
                        textTransform="uppercase"
                        noOfLines={1}
                      >
                        {fixUncategorizedName(category?.name)}
                      </Text>
                    </Button>

                    {categories.length === 1 && category?.name?.toLowerCase() === 'uncategorized' && (
                      <Text textStyle="small" paddingLeft={rem(59)} color="text-tertiary">
                        As you add more categories they will begin to appear here.
                      </Text>
                    )}
                  </>
                );
              }),
            )}
          </>
        )}
      </VStack>
    </VStack>
  );
}

export default memo(LeftSection);
