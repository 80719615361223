export default function getPlannerMotionVariants(isDailyPlanPage: boolean) {
  const CAPTURE_LIST_VARIANTS = {
    plannerExpanded: {
      width: '0%',
      minWidth: '0%',
      opacity: 0,
      transition: {
        duration: 0.5,
        when: 'beforeChildren',
        staggerChildren: 0.1,
      },
    },
    default: {
      width: isDailyPlanPage ? '60%' : '33%',
      minWidth: isDailyPlanPage ? '60%' : '33%',
      opacity: 1,
      transition: {
        duration: 0.5,
        when: 'afterChildren',
        staggerChildren: 0.1,
      },
    },
  };

  const PLANNER_VARIANTS = {
    default: {
      width: '100%',
      opacity: 1,
      transition: {
        duration: 0.5,
        when: 'afterChildren',
        staggerChildren: 0.1,
      },
    },
  };

  return { PLANNER_VARIANTS, CAPTURE_LIST_VARIANTS };
}
