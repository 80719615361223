const createArrayOfElements = <T>(array: T[], length: number): T[][] | null => {
  // Check for empty input array
  if (array.length === 0) {
    return null;
  }

  // Initialize an array to store the divided results
  const results: T[][] = new Array(length).fill([]).map(() => []);

  // Loop through the desired number of divisions
  for (let i = 0; i < array.length; i++) {
    const columnIndex = i % length;
    results[columnIndex].push(array[i]);
  }

  // Filter out any empty sub-arrays from the results
  const filteredResults = results.filter((itm) => itm.length > 0);

  // Check for empty output
  if (filteredResults.length === 0) {
    return null;
  }

  return filteredResults;
};

export default createArrayOfElements;
