import CategoryManagerPage from '@/pages/CategoryManager';
import CategoryManagerDetails from '@/pages/CategoryManagerDetails';
import Celebrate from '@/pages/Celebrate';
import DailyPlanPage from '@/pages/DailyPlan';
import Home from '@/pages/Home';
import LeverageRequestsPage from '@/pages/LeverageRequestsPage';
import PeopleWrapper from '@/pages/People';
import PrintBlockPage from '@/pages/Print/Block';
import ProjectDetail from '@/pages/ProjectDetail';
import Projects from '@/pages/Projects';
import WeeklyCapturePage from '@/pages/WeeklyCapturePage';
import WeeklyPlanPage from '@/pages/WeeklyPlan';
import ProtectedRoutes from '@/routes/ProtectedRoutes';
import { RoutesList } from '@/routes/routesList';
import * as Sentry from '@sentry/react';
import { Route, Routes } from 'react-router-dom';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function AppRoutes() {
  return (
    <SentryRoutes>
      <Route element={<ProtectedRoutes />}>
        <Route path={RoutesList.HomePage} element={<Home />} />

        <Route path={RoutesList.WeeklyCapturePage} element={<WeeklyCapturePage />} />

        <Route path={RoutesList.MyPlanPage} element={<WeeklyPlanPage />} />
        <Route path={RoutesList.MyPlanDailyPage} element={<DailyPlanPage />} />
        <Route path={RoutesList.CelebratePage} element={<Celebrate />} />

        <Route path={RoutesList.ProjectsPage} element={<Projects />} />
        <Route path={RoutesList.ProjectDetailPage} element={<ProjectDetail />} />
        <Route path={RoutesList.PeoplePage} element={<PeopleWrapper />} />
        <Route path={RoutesList.PersonPage} element={<PeopleWrapper />} />

        <Route path={RoutesList.CategoryManagerPage} element={<CategoryManagerPage />} />
        <Route path={RoutesList.CategoryManagerDetailsPage} element={<CategoryManagerDetails />} />
        <Route path={RoutesList.PrintBlockPage} element={<PrintBlockPage />} />
      </Route>

      {/* Public routes */}
      <Route path={RoutesList.LeverageRequestsPage} element={<LeverageRequestsPage />} />

      {/* Redirect to homepage if unknown url */}
      <Route path="*" element={<Home />} />
    </SentryRoutes>
  );
}

export default AppRoutes;
