/* eslint-disable no-undef */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import AmPmInputHelper from './AmPmInputHelper';
import ArrowDown from './ArrowDown';
import InputTimeHelper from './InputTimeHelper';
import './TimeInput.css';
import UnitDropdown from './UnitDropdown';
import { getDatePartsByProps, getTimeString, isOnMobileDevice, timers } from './actions';

function TimeInput(props) {
  const {
    hour12Format,
    value,
    onChange,
    onChangeEveryFormat,
    disabled,
    allowDelete,
    fullTimeDropdown,
    minutesIncrement,
    showAMPMDropdown,
    showTimeChevron,
  } = props;
  const dateParts = getDatePartsByProps(value, hour12Format);
  const [time, setTime] = useState(dateParts);
  const [isMobile, setIsMobile] = useState(isOnMobileDevice());
  const hourRef = useRef(null);
  const minuteRef = useRef(null);
  const amPmRef = useRef(null);

  const setHour = useCallback((newHour, other = {}) => setTime((t) => ({ ...t, hour: newHour, ...other })), [setTime]);
  const setMinutes = useCallback(
    (newMinute, other = {}) => setTime((t) => ({ ...t, minute: newMinute, ...other })),
    [setTime],
  );
  const setAmPM = useCallback((newAmPm, other = {}) => setTime((t) => ({ ...t, amPm: newAmPm, ...other })), [setTime]);

  const hourRange = useMemo(() => (hour12Format ? { start: 1, end: 12 } : { start: 0, end: 23 }), [hour12Format]);
  const focusOnMinute = useCallback(() => focusOn(minuteRef), []);
  const blurOnMinute = useCallback(() => blurOn(minuteRef), []);
  const focusOnHour = useCallback(() => focusOn(hourRef), []);
  const focusOnAmPm = useCallback(() => focusOn(amPmRef), []);
  const blurOnAmPm = useCallback(() => blurOn(amPmRef), []);

  const toggleAmPm = useCallback((other) => setAmPM(time?.amPm === 'AM' ? 'PM' : 'AM', other), [setAmPM, time?.amPm]);
  const updateTouchDevice = () => setIsMobile(isOnMobileDevice());

  const setTimeHourString = useCallback(
    (value) => {
      const dateParts = getDatePartsByProps(value?.replace(/ /g, ''), hour12Format);
      setHour(dateParts.hour);
      setMinutes(dateParts.minute);
      setAmPM(dateParts.amPm);
      if (value?.toLowerCase().includes('am')) {
        setAmPM('AM');
      } else if (value?.toLowerCase().includes('pm')) {
        setAmPM('PM');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hour12Format],
  );

  useEffect(() => {
    const { hour, minute, amPm } = time || {};
    const dateString = getTimeString(hour, minute, amPm, hour12Format);

    onChangeEveryFormat && onChangeEveryFormat(dateString);

    if (hour !== '' && minute !== '' && !isMobile) {
      onChange && onChange(dateString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  useEffect(() => {
    if (!isMobile) value && setTimeHourString(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    window.addEventListener('resize', updateTouchDevice);

    return () => {
      timers.forEach(clearTimeout);
      window.removeEventListener('resize', updateTouchDevice);
    };
  }, []);

  const amPmInputProps = {
    disabled,
    // eachInputDropdown: showTimeChevron && !fullTimeDropdown,
    showTimeChevron: showTimeChevron,
    // manuallyDisplayDropdown: manuallyDisplayDropdown && !fullTimeDropdown,
    fullTimeDropdown,
    showAMPMDropdown,
  };
  const sameInputProps = { ...amPmInputProps, allowDelete, placeholder: '- -' };

  return (
    <div className="react-time-input-picker-wrapper">
      <div className={`react-time-input-picker ${disabled ? 'is-disabled' : ''}`}>
        {isMobile ? (
          <MobileInput value={value} onChange={onChange} />
        ) : (
          <React.Fragment>
            <InputTimeHelper
              inputRef={hourRef}
              value={time?.hour}
              setValue={setHour}
              {...sameInputProps}
              moveNext={focusOnMinute}
              range={hourRange}
              amPm={time?.amPm}
            />
            <InputTimeHelper
              inputRef={minuteRef}
              value={time?.minute}
              {...sameInputProps}
              setValue={setMinutes}
              moveNext={hour12Format ? focusOnAmPm : blurOnMinute}
              movePrev={focusOnHour}
              range={minuteRange}
            />
            {hour12Format && (
              <div className="inputWrapper">
                <AmPmInputHelper
                  {...amPmInputProps}
                  inputRef={amPmRef}
                  amPm={time?.amPm}
                  movePrev={focusOnMinute}
                  moveNext={blurOnAmPm}
                  toggleAmPm={toggleAmPm}
                  setValue={setAmPM}
                />
              </div>
            )}

            {/* full time selectors */}
            <Options
              timeString={getTimeString(time?.hour, time?.minute, time?.amPm, hour12Format)}
              {...{
                hour12Format,
                fullTimeDropdown,
                setTimeHourString,
                minutesIncrement,
              }}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

const focusOn = (ref) => ref?.current?.focus?.();
const blurOn = (ref) => ref?.current?.blur?.();

let minutesInc;

const Options = memo((props) => {
  const { hour12Format, fullTimeDropdown, setTimeHourString, timeString, minutesIncrement } = props;
  const [fullTimeDropdownVisibility, setFullTimeDropdownVisibility] = useState(false);

  if (minutesIncrement > 0 && minutesIncrement <= 59) {
    minutesInc = minutesIncrement;
  } else {
    console.error('Invalid minutesIncrement value. Setting to default (1).');
    minutesInc = 30; // default value
  }

  const format24Data = useMemo(
    () =>
      Array.from({ length: 24 * (60 / minutesInc) }, (_, index) => {
        const hour = Math.floor((index * minutesInc) / 60);
        const minutes = (index * minutesInc) % 60;
        return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      }),
    [],
  );

  const format12Data = useMemo(
    () =>
      Array.from({ length: 24 * (60 / minutesInc) }, (_, index) => {
        const hour = Math.floor((index * minutesInc) / 60);
        const minutes = (index * minutesInc) % 60;
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        const period = hour < 12 ? 'AM' : 'PM';
        return `${formattedHour}:${minutes.toString().padStart(2, '0')} ${period}`;
      }),
    [],
  );

  useEffect(() => {
    const hideDropdown = () => setFullTimeDropdownVisibility(false);

    window.addEventListener('click', hideDropdown);
    document?.querySelector('body')?.addEventListener('click', hideDropdown);
    return () => {
      window.removeEventListener('click', hideDropdown);
      document?.querySelector('body')?.removeEventListener('click', hideDropdown);
    };
  }, []);

  const onArrowDown = useCallback(
    (e) => {
      e.stopPropagation();
      setFullTimeDropdownVisibility((prevVal) => !prevVal);
    },
    [setFullTimeDropdownVisibility],
  );

  return (
    <React.Fragment>
      {fullTimeDropdown && (
        <div style={{ height: '100%' }}>
          <ArrowDown onClick={onArrowDown} />
        </div>
      )}
      <div className="fullTime__wrapper">
        <UnitDropdown
          fullTimeDropdownVisibility
          data={hour12Format ? format12Data : format24Data}
          shouldDisplay={fullTimeDropdown && fullTimeDropdownVisibility}
          type="notRange"
          className="fullTime"
          hour12Format={hour12Format}
          {...{
            value: timeString,
            setValue: setTimeHourString,
            dropdownVisibility: fullTimeDropdownVisibility,
            setDropdownVisibility: setFullTimeDropdownVisibility,
          }}
        />
      </div>
    </React.Fragment>
  );
});

const minuteRange = { start: 0, end: 59 };

const MobileInput = memo((props) => {
  const { value, onChange } = props;
  const [valueMobile, setValueMobile] = useState(value);

  return (
    <div className="input-time-mobile">
      <input
        type="time"
        value={valueMobile}
        onChange={(e) => {
          setValueMobile(e.target.value);
          onChange && onChange(e.target.value);
        }}
      />
    </div>
  );
});

TimeInput.defaultProps = {
  hour12Format: false,
  disabled: false,
  allowDelete: false,
  fullTimeDropdown: false,
  showAMPMDropdown: false,
  showTimeChevron: false,
  minutesIncrement: 30,
};

export default React.memo(TimeInput);
